import React from 'react';
import { Flex, Form, Typography, Input, InputNumber, Select, Button } from 'antd';

const { Text } = Typography;

const AddressesSearchFilterComponent = ({citiesOptions, totalAddresses, onSearch}) => {

  const handleSearch = (values) => {
    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(([_, v]) => v !== undefined && v !== null && v !== '')
    );
  
    onSearch(filteredValues);
  };

  return (
    <Form
      layout='vertical'
      onFinish={handleSearch}
    >
      <Flex gap={16} wrap='wrap'>
        <Form.Item
          style={{ flex: '1 1 80px', marginBottom: '10px' }}
          label='ID адреса'
          name='addressId'
        >
          <Input placeholder='ID адреса'/>
        </Form.Item>

        <Form.Item
          style={{ flex: '3 1 240px', marginBottom: '10px' }}
          label='Город'
          name='cityId'
        >
          <Select
            showSearch
            placeholder="Выберите город"
            optionFilterProp="children"
            options={citiesOptions}
            filterOption={(input, option) =>
              (option?.name?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
            }
            optionRender={(option) => (
              <>{option.data.name}</>
            )}
          />
        </Form.Item>

        <Form.Item
          style={{ flex: '3 1 240px', marginBottom: '10px' }}
          label='Улица'
          name='street'
        >
          <Input placeholder='Улица'/>
        </Form.Item>

        <Form.Item
          style={{ flex: '1 1 80px', marginBottom: '10px' }}
          label='Дом'
          name='house'
        >
          <InputNumber placeholder='д.'/>
        </Form.Item>

        <Form.Item
          style={{ flex: '1 1 80px', marginBottom: '10px' }}
          label='Строение'
          name='structure'
        >
          <Input placeholder='ст.'/>
        </Form.Item>

        <Form.Item
          style={{ flex: '1 1 80px', marginBottom: '10px' }}
          label='Квартира'
          name='flat'
        >
          <InputNumber placeholder='кв.'/>
        </Form.Item>

        <Form.Item
          style={{ flex: '1 1 80px', marginBottom: '10px' }}
          label='Этаж'
          name='floor'
        >
          <InputNumber placeholder='э.'/>
        </Form.Item>

        <Form.Item
          style={{ flex: '1 1 80px', marginBottom: '10px' }}
          label='Подъезд'
          name='entrance'
        >
          <InputNumber placeholder='п.'/>
        </Form.Item>
      </Flex>

      <Flex style={{marginTop: '20px'}} justify='space-between' gap={16}>
        <Text type='secondary'>Найдено адресов: {new Intl.NumberFormat().format(totalAddresses)}</Text>
        <Flex gap={16}>
          <Button type='primary' htmlType='submit'>Поиск</Button>
          <Button htmlType='reset'>Сбросить</Button>
        </Flex>
      </Flex>
    </Form>
  );
};

export default AddressesSearchFilterComponent;
