import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sendCodeToPhone, sendCodeToPhoneStatusClear, createTokenBySession } from '../../redux/reducers/utils/authReducer';
import { setDevApiUrl, setProdApiUrl } from '../../redux/reducers/utils/commonReducer.js';
import { Page } from '../../core/styles'
import { Button } from 'antd';

import LoginForm from './components/LoginForm';
import MessagePopup from '../../components/MessagePopup';

import { updateServerURL } from '../../api.js';
import NotWorkingDatePicker from '../../components/NotWorkingDatePicker.jsx';

const AuthPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const { messagePopupContent, isMobile, apiUrl } = useSelector((store) => store.common);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSwitchToDevURL = () => {
    dispatch(setDevApiUrl());
  };

  const handleSwitchToProdURL = () => {
    dispatch(setProdApiUrl());
  };

  useEffect(() => (
    updateServerURL(apiUrl)
  ), [apiUrl])

  useEffect(() => {
    if (isAuth) {
      navigate('main');
    } 
  }, [isAuth]);

  return (
    <Page>

      <LoginForm/>

      <MessagePopup messagePopup={messagePopupContent}/>
      {!isMobile &&
        <div style={{ position: 'fixed', bottom: 20, left: 10, display: 'flex', flexDirection: 'column' }}>
          <Button type={apiUrl === 'https://bottest2.menu4me.ru/api/v1/admin' ? 'primary' : 'default'} onClick={handleSwitchToDevURL}>Dev</Button>
          <Button type={apiUrl === 'https://api-test.menuforme.online/api/v1/admin' ? 'primary' : 'default'} onClick={handleSwitchToProdURL}>Prod</Button>
        </div>
      }
    </Page>
  );
};

export default AuthPage;
