import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setDevices } from '../../../../../redux/reducers/utils/clientsReducers/clientsNotificationsReducer';
import { Flex, Typography, Modal, Form, Select, Input, DatePicker, Checkbox, Divider, Upload, Popconfirm, Button, message } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ImgUploader from '../../../../../components/ImgUploader';

dayjs.extend(customParseFormat);
const serverFormat = 'YYYY-MM-DD';
const clientFormat = 'DD-MM-YYYY';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const NotificationCreateModalComponent = ({ devices, citiesOptions, deliveryServicesOptions, onSubmit }) => {
  const dispatch = useDispatch();
  const [filtersForm] = Form.useForm();
  const [notificationForm] = Form.useForm();
  const [isCreate, setIsCreate] = useState(false);
  const [requestFilters, setRequestFilters] = useState({});
  const [csvFile, setCsvFile] = useState([]);
  const [csvFileItemLength, setCsvFileItemLength] = useState(0);
  const [imgMessege, setImgMessege] = useState([]);
  const [imgPush, setImgPush] = useState([]);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const phonePattern = /^\+7[0-9]{10}$/;
  const [isFormEmpty, setIsFormEmpty] = useState(true);
  const [isButtonActive, setIsButtonActive] = useState(false);

  const handleOpen = () => { setIsCreate(true); }
  const handleCancel = () => {
    filtersForm.resetFields();
    notificationForm.resetFields();
    setIsCreate(false);
    setCsvFile([]);
    setImgMessege([]);
    setImgPush([]);
    setCsvFileItemLength(0);
    dispatch(setDevices(0));
  };
  const handleCheckboxChange = (e) => { setCheckboxValue(e.target.checked); }

  const handleFiltersChange = (changedValues, allValues) => {
    const updatedRequestFilters = { ...requestFilters };
  
    Object.keys(allValues).forEach(key => {
      if (allValues[key] != null) {
        if (['dateUsersRegistrations', 'dateUsersOrders', 'dateUsersNotOrders'].includes(key)) {
          const [start, end] = allValues[key];
          if (start && end) {
            updatedRequestFilters[key + 'From'] = start.format(serverFormat);
            updatedRequestFilters[key + 'To'] = end.format(serverFormat);
          }
        } else {
          updatedRequestFilters[key] = allValues[key];
        }

        if (!allValues[key].length) {
          delete updatedRequestFilters[key];
        }

      } else {
        delete updatedRequestFilters[key + 'From'];
        delete updatedRequestFilters[key + 'To'];
      }
    });

    setRequestFilters(updatedRequestFilters);
  };

  useEffect(() => {
    if (requestFilters && Object.keys(requestFilters).length > 0) {
      onSubmit({
        action: 'COUNT',
        ...requestFilters
      });
      setIsFormEmpty(false);
    } else {
      setIsFormEmpty(true);
      dispatch(setDevices(0));
    }

  }, [requestFilters]);

  useEffect(() => {
    if (csvFile.length > 0 && csvFileItemLength !== 0) {
      setIsButtonActive(true);
    } else if (!isFormEmpty && devices !== 0) {
      setIsButtonActive(true);
    } else {
      setIsButtonActive(false);
    }
  }, [csvFile, csvFileItemLength, devices, isFormEmpty]);

  const handleNotificationSend = () => {
    filtersForm.validateFields()
      .then(() => {
        notificationForm.validateFields()
          .then((values) => {
            onSubmit({
              action: 'SEND',
              
              ...requestFilters,
              customerFile: csvFile.length ? csvFile[0].originFileObj : null,
              ...values,
              imgMessege: imgMessege.length ? imgMessege[0].originFileObj : null,
              imgPush: imgPush.length ? imgPush[0].originFileObj : null,
            });
        
            filtersForm.resetFields();
            notificationForm.resetFields();
            setRequestFilters({});
            setIsCreate(false);
            setCsvFile([]);
            setImgMessege([]);
            setImgPush([]);
            setCsvFileItemLength(0);
          })
          .catch((errorInfo) => {
            console.error('Ошибка при валидации формы:', errorInfo);
          });
      })
      .catch((errorInfo) => {
        console.error('Ошибка при валидации формы:', errorInfo);
      });
  };
  
  const checkPhonePattern = (phone) => {
    if (phonePattern.test(phone)) {
      return true;
    } else {
      return false;
    }
  }

  const readFile = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const content = event.target.result;
      const lines = content.split('\n');

      lines.forEach(line => {
        const columns = line.split(',');
      });

      setCsvFileItemLength(lines.length)
    };

    reader.readAsText(file);
  };

  useEffect(() => {
    if (csvFile.length > 0) {
      readFile(csvFile[0].originFileObj);
    }
  }, [csvFile])

  const beforeUpload = (file) => {
    const isCsv = file?.type.startsWith('text/csv');
    if (isCsv) {
      return true;
    } else {
      message.error('Не является файлом .csv!');
      return false;
    }
  };

  const handleCsvChange = ({ fileList: newFileList }) => {
    const isCsv = newFileList[0]?.originFileObj ? beforeUpload(newFileList[0]?.originFileObj) : false;

    if (isCsv) {
      setCsvFile(newFileList);
    } else {
      setCsvFile([]);
      setCsvFileItemLength(0);
    }
  }

  return (
    <>
      <Button
        type='primary'
        size='large'
        icon={<PlusOutlined />}
        onClick={handleOpen}
        style={{margin: '0 0 20px auto'}}
      >
        Создать PUSH-уведомление
      </Button>
      <Modal
        style={{ maxWidth: '998px' }}
        width="fit-content"
        title="Создание PUSH-уведомления"
        open={isCreate}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          style={{ width: 'fit-content' }}
          form={filtersForm}
          name='filtersForm'
          layout='vertical'
          disabled={csvFile.length > 0 ? true : false}
          onValuesChange={handleFiltersChange}
        >
          <Flex
            justify='space-between'
            align='flex-end'
            gap='small'
            wrap='wrap'
          >
            <Form.Item
              style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }}
              label='Период дат когда пользователь получал заказ (по дате доставки)'
              name='dateUsersOrders'
            >
              <RangePicker style={{ width: '100%' }}/>
            </Form.Item>
            <Form.Item
              style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }}
              label='Период дат когда пользователь НЕ получал заказ (по дате доставки)'
              name='dateUsersNotOrders'
            >
              <RangePicker style={{ width: '100%' }}/>
            </Form.Item>
            <Form.Item
              style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }}
              label='Период дат регистрации пользователя'
              name='dateUsersRegistrations'
            >
              <RangePicker style={{ width: '100%' }}/>
            </Form.Item>
          </Flex>
          <Flex
            justify='space-between'
            align='flex-end'
            gap='small'
            wrap='wrap'
          >
            <Form.Item
              style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }}
              label='Выбор зон доставки по имеющимся зонам доставки СД'
              name='deliverServices'
            >
              <Select
                disabled
                mode="multiple"
                showSearch
                placeholder="Выберите СД"
                optionFilterProp="children"
                options={deliveryServicesOptions}
                filterOption={(input, option) =>
                  (option?.name?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionRender={(option) => (
                  <Flex vertical>
                    <Title level={5}>{option.data.name}</Title>
                  </Flex>
                )}
              />
            </Form.Item>
            <Form.Item
              style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }}
              label='Выбранный текущий город пользователя (мультивыбор)'
              name='cityIds'
            >
              <Select
                mode="multiple"
                showSearch
                placeholder="Выберите город"
                optionFilterProp="children"
                options={citiesOptions}
                filterOption={(input, option) =>
                  (option?.name?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionRender={(option) => (
                  <Flex vertical>
                    <Title level={5}>{option.data.name}</Title>
                  </Flex>
                )}
              />
            </Form.Item>
          </Flex>
          <Flex
            justify='space-between'
            gap='small'
            wrap='wrap'
          >
            <Form.Item
              style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }}
              label='ID пользователя (отправить точечно на устройство)'
              name='userId'
            >
              <Input
                style={{ width: '100%' }}
                placeholder='000001'
                maxLength={10}
              />
            </Form.Item>
            <Form.Item
              style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }}
              label='Номер телефона (отправить точечно на устройство)'
              name='phone'
              rules={[
                {
                  validator: (_, value) => {
                    if (value && !checkPhonePattern(value)) {
                      return Promise.reject(new Error('Введите корректный номер телефона в формате +7XXXXXXXXXX'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                style={{ width: '100%' }}
                placeholder='+7 --- --- -- --'
                maxLength={12}
              />
            </Form.Item>
          </Flex>
        </Form>

        <Flex
          style={{ marginTop: '20px', width: '100%' }}
          justify='space-between'
          align='center'
          gap={20}
        >
          <Text strong>Найдено устройств: {csvFile.length > 0 ? csvFileItemLength : devices}</Text>
          <Upload 
            customRequest={({ file, onSuccess }) => {
              setTimeout(() => {
                onSuccess(null, file);
              }, 0);
            }}
            name='file'
            fileList={csvFile}
            onChange={handleCsvChange}
          >
            <Button icon={<UploadOutlined />}>Загрузить .csv</Button>
          </Upload>
        </Flex>
        <Divider/>

        <Form
          style={{ width: '100%' }}
          form={notificationForm}
          name='notificationForm'
          layout='vertical'
          onFinish={handleNotificationSend}
        >
          <Flex gap='small' wrap='wrap'>
            <Flex vertical style={{ flex: '1' }} gap='small'>
              <Form.Item
                style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }}
                label='Заголовок PUSH-уведомления'
                name={['push','title']}
                rules={[{ required: true, message: 'Обязательное поле' }]}
              >
                <Input style={{ width: '100%' }}/>
              </Form.Item>
              <Form.Item
                style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }}
                label='Текст PUSH-уведомления'
                name={['push','body']}
                rules={[{ required: true, message: 'Обязательное поле' }]}
              >
                <TextArea style={{ width: '100%' }} rows={4}/>
              </Form.Item>
            </Flex>
            <Flex vertical style={{ flex: '0 1 100px' }}>
              <ImgUploader fileList={imgPush} onUpload={setImgPush}/>
            </Flex>
          </Flex>
          <div style={{ marginTop: '20px' }}>
            <Checkbox checked={checkboxValue} onChange={handleCheckboxChange}>Дополнительно отправить в чат</Checkbox>
          </div>
          {checkboxValue && 
            <Flex gap='small' wrap='wrap'>
              <Flex vertical style={{ flex: '1' }} gap='small'>
                <Form.Item
                  style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }}
                  label='Заголовок уведомления в чат'
                  name={['message','title']}
                  rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                  <Input style={{ width: '100%' }}/>
                </Form.Item>
                <Form.Item
                  style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }}
                  label='Текст уведомления в чат'
                  name={['message','body']}
                  rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                  <TextArea style={{ width: '100%' }} rows={4}/>
                </Form.Item>
              </Flex>
              <Flex vertical style={{ flex: '0 1 100px' }}>
                <ImgUploader fileList={imgMessege} onUpload={setImgMessege}/>
              </Flex>
            </Flex>
          }
        </Form>
        <Popconfirm
          title="Отправка нового уведомления!"
          description={'Вы уверены что необходимо отправить?'}
          okText="Да"
          cancelText="Нет"
          placement="top"
          onConfirm={handleNotificationSend}
        >
          <Button type='primary' style={{ display: 'flex', marginLeft: 'auto' }} disabled={(!isButtonActive)}>Отправить</Button>
        </Popconfirm>
      </Modal>
    </>
  );
};

export default NotificationCreateModalComponent;
