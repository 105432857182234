import React, { useEffect } from 'react';
import { Form, Input } from 'antd';

const KitchenNameComponent = ({ isAdmin, data, onChange }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ value: data });
  }, [data]);

  const onFinish = (values) => {
    onChange(values.value);
  }

  return (
    <Form
      form={form}
      layout='vertical'
      name="kitchen_name_form"
      initialValues={{value: data}}
      onFinish={onFinish}
      disabled={!isAdmin}
    >
      <Form.Item name='value' label='Название кухни' rules={[{ required: true, message: 'Обязательное поле!' }]}>
        <Input
          style={{fontSize: '24px', fontWeight: 'bold'}}
          placeholder='Введите название'
        />
      </Form.Item>
    </Form>
  );
};

export default KitchenNameComponent;
