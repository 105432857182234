import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentRoute } from '../../redux/reducers/utils/navReducer';

const ServicesModule = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  dispatch(setCurrentRoute('services/rightsmanagement'));
  navigate('rightsmanagement');
}

export default ServicesModule;
