import { takeLatest } from 'redux-saga/effects';
import {
  //Organizations
  handleGetOrganizations,
  handleCreateOrganizations,
  handleUpdateOrganizations,
  handleDeleteOrganizations,

  //Cities
  handleGetCities,
  handleCreateCities,
  handleUpdateCities,
  handleDeleteCities,
} from './settingsCatalogsHandler';
import {
  //Organizations
  sendRequestOrganizationsGet,
  sendRequestOrganizationsCreate,
  sendRequestOrganizationsUpdate,
  sendRequestOrganizationsDelete,

  //Cities
  sendRequestCitiesGet,
  sendRequestCitiesCreate,
  sendRequestCitiesUpdate,
  sendRequestCitiesDelete,
} from '../../../../reducers/utils/settingsReducers/settingsCatalogsReducer';

export function* settingsCatalogsWatcherSaga() {
  //Organizations
  yield takeLatest(sendRequestOrganizationsGet.type, handleGetOrganizations);
  yield takeLatest(sendRequestOrganizationsCreate.type, handleCreateOrganizations);
  yield takeLatest(sendRequestOrganizationsUpdate.type, handleUpdateOrganizations);
  yield takeLatest(sendRequestOrganizationsDelete.type, handleDeleteOrganizations);

  //Cities
  yield takeLatest(sendRequestCitiesGet.type, handleGetCities);
  yield takeLatest(sendRequestCitiesCreate.type, handleCreateCities);
  yield takeLatest(sendRequestCitiesUpdate.type, handleUpdateCities);
  yield takeLatest(sendRequestCitiesDelete.type, handleDeleteCities);
}