import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestCreateMenuTemplate, setSelectedTemplate, sendRequestMenuTemplateProducts } from '../../../../../../redux/reducers/utils/kitchenReducers/kitchenMenuReducer';
import styled from 'styled-components';

const MenuTemplateListComponent = () => {
  const { selectKitchen } = useSelector((store) => store.kitchen);
  const { menuTemplates, selectedTemplate } = useSelector((store) => store.kitchenMenu);
  const dispatch = useDispatch();

  const handleAddMenuTemplate = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      name: 'Новый шаблон',
      kitchenId: selectKitchen.id,
    }

    dispatch(sendRequestCreateMenuTemplate(requestData));
  }

  const handleGetTemplateProducts = (menuTemplate) => {
    const requestData = {
      token: localStorage.getItem('token'),
      menuTemplateId: menuTemplate.id,
      kitchenId: selectKitchen.id,
    }

    dispatch(setSelectedTemplate(menuTemplate));
    dispatch(sendRequestMenuTemplateProducts(requestData));
  }

  return (
    <MenuTemplateList>
      {menuTemplates.length > 0 && menuTemplates.map((menuTemplate) => (
        <MenuTemplateItem selected={selectedTemplate?.id === menuTemplate.id} onClick={() => handleGetTemplateProducts(menuTemplate)}>
          {menuTemplate.name}
        </MenuTemplateItem>
      ))}
      <AddMenuTemplateButton onClick={handleAddMenuTemplate}>+</AddMenuTemplateButton>
    </MenuTemplateList>
  );
};

export default MenuTemplateListComponent;

const MenuTemplateList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 200px;
  height: calc(100vh - 40px);
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
  }
`
const MenuTemplateItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  min-height: 50px;
  background: ${({ selected }) => (selected ? 'var(--color-accent);' : 'var(--color-light);')};
  border-radius: var(--radius-small);
  transition: all .3s;
  cursor: pointer;
  &:hover {
    background: var(--color-deep-light);
  }
`
const AddMenuTemplateButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: 50px;
  background: var(--color-light);
  border-radius: var(--radius-small);
  font-size: 36px;
  line-height: .5;
  transition: all .3s;
  cursor: pointer;
  &:hover {
    background: var(--color-deep-light);
  }
`
