import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  kitchenComplaintGetRequestStatus: null,
  kitchenComplaint: [],
  kitchenComplaintCreateRequestStatus: null,
  kitchenComplaintUpdateRequestStatus: null,
  kitchenComplaintDeleteRequestStatus: null,
  kitchenComplaintConfirmRequestStatus: null,

  kitchenComplainTableServicesTypeGetRequestStatus: null,
  tableServicesType: [],

  kitchenComplaintTablePretensionsStatusGetRequestStatus: null,
  tablePretensionsStatus: [],
};

const kitchenComplaintSlice = createSlice({
  name: 'kitchenComplaint',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    sendRequestKitchenComplaintGet: (state) => { state.kitchenComplaintGetRequestStatus = 'pending'; },
    sendRequestKitchenComplaintGetSuccess: (state) => { state.kitchenComplaintGetRequestStatus = 'resolved'; },
    sendRequestKitchenComplaintGetError: (state) => { state.kitchenComplaintGetRequestStatus = 'rejected'; },
    clearKitchenComplaintGetStatus: (state) => { state.kitchenComplaintGetRequestStatus = null; },
    setKitchenComplaintData: (state, action) => {
      state.kitchenComplaint = action.payload;
    },

    sendRequestKitchenComplaintCreate: (state) => { state.kitchenComplaintCreateRequestStatus = 'pending'; },
    sendRequestKitchenComplaintCreateSuccess: (state) => { state.kitchenComplaintCreateRequestStatus = 'resolved'; },
    sendRequestKitchenComplaintCreateError: (state) => { state.kitchenComplaintCreateRequestStatus = 'rejected'; },
    clearKitchenComplaintCreateStatus: (state) => { state.kitchenComplaintCreateRequestStatus = null; },

    sendRequestKitchenComplaintUpdate: (state) => { state.kitchenComplaintUpdateRequestStatus = 'pending'; },
    sendRequestKitchenComplaintUpdateSuccess: (state) => { state.kitchenComplaintUpdateRequestStatus = 'resolved'; },
    sendRequestKitchenComplaintUpdateError: (state) => { state.kitchenComplaintUpdateRequestStatus = 'rejected'; },
    clearKitchenComplaintUpdateStatus: (state) => { state.kitchenComplaintUpdateRequestStatus = null; },

    sendRequestKitchenComplaintDelete: (state) => { state.kitchenComplaintDeleteRequestStatus = 'pending'; },
    sendRequestKitchenComplaintDeleteSuccess: (state) => { state.kitchenComplaintDeleteRequestStatus = 'resolved'; },
    sendRequestKitchenComplaintDeleteError: (state) => { state.kitchenComplaintDeleteRequestStatus = 'rejected'; },
    clearKitchenComplaintDeleteStatus: (state) => { state.kitchenComplaintDeleteRequestStatus = null; },

    sendRequestKitchenComplaintConfirm: (state) => { state.kitchenComplaintConfirmRequestStatus = 'pending'; },
    sendRequestKitchenComplaintConfirmSuccess: (state) => { state.kitchenComplaintConfirmRequestStatus = 'resolved'; },
    sendRequestKitchenComplaintConfirmError: (state) => { state.kitchenComplaintConfirmRequestStatus = 'rejected'; },
    clearKitchenComplaintConfirmStatus: (state) => { state.kitchenComplaintConfirmRequestStatus = null; },

    sendRequestKitchenComplaintTableServicesTypeGet: (state) => { state.kitchenComplainTableServicesTypeGetRequestStatus = 'pending'; },
    sendRequestKitchenComplaintTableServicesTypeGetSuccess: (state) => { state.kitchenComplainTableServicesTypeGetRequestStatus = 'resolved'; },
    sendRequestKitchenComplaintTableServicesTypeGetError: (state) => { state.kitchenComplainTableServicesTypeGetRequestStatus = 'rejected'; },
    clearKitchenComplaintTableServicesTypeGetStatus: (state) => { state.kitchenComplainTableServicesTypeGetRequestStatus = null; },
    setKitchenComplaintTableServicesType: (state, action) => {
      state.tableServicesType = action.payload;
    },

    sendRequestKitchenComplaintTablePretensionsStatusGet: (state) => { state.kitchenComplaintTablePretensionsStatusGetRequestStatus = 'pending'; },
    sendRequestKitchenComplaintTablePretensionsStatusGetSuccess: (state) => { state.kitchenComplaintTablePretensionsStatusGetRequestStatus = 'resolved'; },
    sendRequestKitchenComplaintTablePretensionsStatusGetError: (state) => { state.kitchenComplaintTablePretensionsStatusGetRequestStatus = 'rejected'; },
    clearKitchenComplaintTablePretensionsStatusGetStatus: (state) => { state.kitchenComplaintTablePretensionsStatusGetRequestStatus = null; },
    setKitchenComplaintTablePretensionsStatus: (state, action) => {
      state.tablePretensionsStatus = action.payload;
    },
  },
});

export const {
  sendRequestKitchenComplaintGet,
  sendRequestKitchenComplaintGetSuccess,
  sendRequestKitchenComplaintGetError,
  clearKitchenComplaintGetStatus,
  setKitchenComplaintData,
  sendRequestKitchenComplaintCreate,
  sendRequestKitchenComplaintCreateSuccess,
  sendRequestKitchenComplaintCreateError,
  clearKitchenComplaintCreateStatus,
  sendRequestKitchenComplaintUpdate,
  sendRequestKitchenComplaintUpdateSuccess,
  sendRequestKitchenComplaintUpdateError,
  clearKitchenComplaintUpdateStatus,
  sendRequestKitchenComplaintDelete,
  sendRequestKitchenComplaintDeleteSuccess,
  sendRequestKitchenComplaintDeleteError,
  clearKitchenComplaintDeleteStatus,
  sendRequestKitchenComplaintConfirm,
  sendRequestKitchenComplaintConfirmSuccess,
  sendRequestKitchenComplaintConfirmError,
  clearKitchenComplaintConfirmStatus,
  sendRequestKitchenComplaintTableServicesTypeGet,
  sendRequestKitchenComplaintTableServicesTypeGetSuccess,
  sendRequestKitchenComplaintTableServicesTypeGetError,
  clearKitchenComplaintTableServicesTypeGetStatus,
  setKitchenComplaintTableServicesType,
  sendRequestKitchenComplaintTablePretensionsStatusGet,
  sendRequestKitchenComplaintTablePretensionsStatusGetSuccess,
  sendRequestKitchenComplaintTablePretensionsStatusGetError,
  clearKitchenComplaintTablePretensionsStatusGetStatus,
  setKitchenComplaintTablePretensionsStatus,
} = kitchenComplaintSlice.actions;

export default kitchenComplaintSlice.reducer;
