import React, { useState } from 'react';
import { Table, Input, Switch } from 'antd';
import styled from 'styled-components';

const { Search } = Input;

const AccessListRoleComponent = ({ selectedRole, components, roleComponents, onSwitchChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const componentsWithKey = components.map(item => ({...item, key: item.id}))
  const filteredComponents = componentsWithKey.filter((component) =>
    component.keyComponent.toLowerCase().includes(searchTerm.toLowerCase()) ||
    component.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    component.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredRoleComponents = roleComponents.filter(
    (roleComponent) => roleComponent.roleId === selectedRole.id
  );

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const columns = [
    {
      title: 'Ключ',
      dataIndex: 'keyComponent',
      key: 'keyComponent',
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: '',
      key: 'actions',
      render: (_, record) => {
        const roleComponent = filteredRoleComponents?.find(
          (rc) => rc.componentId === record.id
        );
  
        return (
          <AccessAction
            selectedRole={selectedRole}
            component={record}
            isChecked={!!roleComponent}
            roleComponentId={roleComponent ? roleComponent.id : null}
            onSwitchChange={onSwitchChange}
          />
        )
      },
    },
  ];

  return (
    <>
      <Search
        placeholder="Поиск по названию или описанию"
        onChange={(e) => handleSearch(e.target.value)}
        style={{ margin: '20px 0' }}
      />
      <Table
        dataSource={filteredComponents}
        columns={columns}
        size="small"
        pagination={{
          defaultPageSize: 50,
        }}
      />
    </>
  );
};

export default AccessListRoleComponent;

const AccessAction = ({selectedRole, component, isChecked, roleComponentId, onSwitchChange }) => {
  const handleChange = (checked) => {
    onSwitchChange(checked, component.id, selectedRole.id, roleComponentId);
  };

  return (
    <Action>
      <Switch checked={isChecked} onChange={handleChange} />
    </Action>
  );
};

const Action = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`