import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  kitchensRequestStatus: null,
  kitchens: [],
};

const kitchenOrdersSlice = createSlice({
  name: 'kitchenOrders',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    sendRequestKitchens: (state) => { state.kitchensRequestStatus = 'pending'; },
    sendRequestKitchensSuccess: (state) => { state.kitchensRequestStatus = 'resolved'; },
    sendRequestKitchensError: (state) => { state.kitchensRequestStatus = 'rejected'; },
    clearKitchensStatus: (state) => { state.kitchensRequestStatus = null; },
    setKitchensData: (state, action) => {
      const data = action.payload;
      state.kitchens = data

      if (data.length === 1) {
        state.selectKitchen = data[0];
      }
    },
  },
});

export const {
  sendRequestKitchens,
  sendRequestKitchensSuccess,
  sendRequestKitchensError,
  clearKitchensStatus,
  setKitchensData,
} = kitchenOrdersSlice.actions;

export default kitchenOrdersSlice.reducer;
