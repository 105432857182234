export function formatPrice(price) {
  // функция для преобразования числа в цену 1 000,50
  const roundedPrice = Math.round(price * 100) / 100;

  const formattedPrice = roundedPrice.toLocaleString('ru-RU', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
    style: 'decimal',
    currency: 'RUB',
  });

  return formattedPrice;
}

export function formatDate(str) {
  // функция для преобразования даты в 29-12-2015 11:41:23 или 29-12-2015
  const date = new Date(str);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  let formattedDate = `${day}-${month}-${year}`;

  if (str.includes(':')) {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    formattedDate += ` ${hours}:${minutes}:${seconds}`;
  }

  return formattedDate;
}

export function checkAccess(accesses, access) {
  return accesses?.some(({ keyComponent }) => {
    return keyComponent === access
  });
}

export function getCorrectWordForm(number, forms) {
  // Функция склонения слов
  number = Math.abs(number) % 100;
  const lastDigit = number % 10;
  
  if (number > 10 && number < 20) {
    return `${number} ${forms[2]}`; // Например: 11, 12, 13 договоров
  }
  
  if (lastDigit > 1 && lastDigit < 5) {
    return `${number} ${forms[1]}`; // Например: 2, 3, 4 договора
  }
  
  if (lastDigit == 1) {
    return `${number} ${forms[0]}`; // Например: 1 договор
  }
  
  return `${number} ${forms[2]}`; // Например: 5, 6, 7 договоров
}

export function checkElementAccess(element, accessData) {
  if (!element || !accessData) {
    return console.log('Element и accessData обязательные параметры')
  }

  const foundKey = accessData.keys.find(key => key.id === element);

  if (foundKey) {
    const role = accessData.keysRoles.find(role => role.entitySettingKeyId === element);
    const kitchenValue = accessData.keysKitchen.find(kitchen => kitchen.entitySettingKeyId === element);

    const action = role ? role.action : null;
    const value = kitchenValue ? kitchenValue.value : '';

    return {
      id: foundKey.id,
      visible: action !== false,
      editable: action === 'readwrite',
      value: value
    };
  } else {
    return { id: null, visible: false, editable: false, value: '' };
  }
}
