import React, { useState } from 'react';
import { Modal, Form, Input, Button, Select, InputNumber } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const EntityKeysCreateModal = ({ elements, types, onCreate }) => {
  const [ isCreate, setIsCreate] = useState(false);
  const [form] = Form.useForm();

  const handleOpenModal = () => {
    setIsCreate(true);
  };

  const handleCancel = () => {
    setIsCreate(false);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    onCreate(values);
    setIsCreate(false);
    form.resetFields();
  }
  
  return (
    <>
      <Button
        type={'primary'}
        shape="round"
        icon={<PlusOutlined />}
        onClick={handleOpenModal}
      >
        Добавить ключ
      </Button>
      <Modal
        title="Создание ключа сущности"
        open={isCreate}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} name='createForm' onFinish={handleSubmit}>
          <Form.Item label='Порядковый №' name='sort'>
            <InputNumber placeholder='0'/>
          </Form.Item>
          <Form.Item label='Тип элемента' name='element' rules={[{ required: true, message: 'Выберите тип элемента' }]}>
            <Select options={elements} placeholder='Выберите тип элемента'/>
          </Form.Item>
          <Form.Item label='Название' name='name' rules={[{ required: true, message: 'Введите название' }]}>
            <Input placeholder='Название'/>
          </Form.Item>
          <Form.Item label='Описание' name='description' rules={[{ required: true, message: 'Введите описание' }]}>
            <Input placeholder='Описание'/>
          </Form.Item>
          <Form.Item label='Тип данных' name='type' rules={[{ required: true, message: 'Выберите тип данных' }]}>
            <Select options={types} placeholder='Выберите тип данных'/>
          </Form.Item>
          <Form.Item label='Значение по умолчанию' name='defaultValue'>
            <Input placeholder='100'/>
          </Form.Item>
          <Button type='primary' htmlType='submit'>
            Создать
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default EntityKeysCreateModal;
