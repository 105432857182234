import React, { useEffect, useRef } from 'react';
import { Form, Input, InputNumber } from 'antd';

const SoloInputComponent = ({ type, lable, placeholder, accessData, onChange }) => {
  const [form] = Form.useForm();
  const inputRef = useRef(null);

  useEffect(() => {
    form.setFieldsValue({ [accessData.id]: accessData.value });
  }, [accessData]);

  const onFinish = (values) => {
    onChange(values);
  }

  const handleBlur = () => {
    form.submit();
  }

  return (
    <>
      {accessData.visible && (
        <Form
          form={form}
          layout='vertical'
          name="kitchen_name_form"
          initialValues={{value: accessData.value}}
          onFinish={onFinish}
          disabled={!accessData.editable}
        >
          <Form.Item name={accessData.id} label={lable} rules={[{ required: true, message: 'Обязательное поле!' }]}>
            {type === 'inputNumber' ?
              <InputNumber
                ref={inputRef}
                min={(lable === 'Рейтинг' || lable === 'Наша оценка') ? 1 : undefined}
                max={(lable === 'Рейтинг' || lable === 'Наша оценка') ? 5 : undefined}
                placeholder={placeholder}
                onBlur={handleBlur}
                onPressEnter={() => inputRef.current.blur()}
              />
            :
              <Input
                ref={inputRef}
                placeholder={placeholder}
                onBlur={handleBlur}
                onPressEnter={() => inputRef.current.blur()}
              />
            }
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default SoloInputComponent;
