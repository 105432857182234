import React, { useState } from 'react';
import { Flex, Input, Checkbox, InputNumber, Button } from 'antd';

const AssortmentSearchComponent = ({ type='default', onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [moderationFilter, setModerationFilter] = useState(false);
  const [priceValues, setPriceValues] = useState({ priceFrom: 0, priceTo: 1000 });

  const handleMinPriceChange = priceFrom => {
    const newValue = { ...priceValues, priceFrom };
    setPriceValues(newValue);
  };

  const handleMaxPriceChange = priceTo => {
    const newValue = { ...priceValues, priceTo };
    setPriceValues(newValue);
  };

  const handleSearch = () => {
    onSearch(searchTerm, moderationFilter ? false : null, priceValues.priceFrom, priceValues.priceTo);
  };

  return (
    <Flex gap={10} align='center'>
      <Input
        style={{width: '100%'}}
        placeholder='Поиск'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Checkbox
        style={{ display: 'flex', alignItems: 'center' }}
        checked={moderationFilter}
        onChange={(e) => setModerationFilter(e.target.checked)}
      >
        На модерации
      </Checkbox>
      <Flex gap={10}>
        <InputNumber
          style={{width: '100%'}}
          placeholder="Цена от:"
          min={0}
          formatter={value => `${value}₽`}
          parser={value => value.replace('₽', '')}
          value={priceValues.priceFrom}
          onChange={handleMinPriceChange}
        />
        <span>-</span>
        <InputNumber
          style={{width: '100%'}}
          placeholder="Цена до:"
          min={0}
          formatter={value => `${value}₽`}
          parser={value => value.replace('₽', '')}
          value={priceValues.priceTo}
          onChange={handleMaxPriceChange}
        />
      </Flex>
      <Flex justify='flex-end'>
        <Button type='primary' onClick={handleSearch}>Поиск</Button>
      </Flex>
    </Flex>
  );
};

export default AssortmentSearchComponent;