import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestNewsGet,
  sendRequestNewsCreate,
  sendRequestNewsUpdate,
  sendRequestNewsDelete,
} from '../../../redux/reducers/utils/mainReducers/mainReducer';
import { Flex, Typography, Button, Modal, Form, Input, Popconfirm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { SitiyCard, ScrolledCol } from '../../../core/styles';
import styled from 'styled-components';
import { checkAccess, formatDate } from '../../../core/utils';

const { Title, Text, Link } = Typography;
const { TextArea } = Input;

function NewsComponent() {
  const { isAuth } = useSelector((store) => store.auth);
  const { accessComponentsForUser } = useSelector((store) => store.services);
  const {
    news,
    newsCreateRequestStatus,
    newsUpdateRequestStatus,
    newsDeleteRequestStatus,
  } = useSelector((store) => store.main);
  const dispatch = useDispatch();
  const [ isCreate, setIsCreate] = useState(false);
  const [ currentPost, setCurrentPost] = useState(null);
  const [form] = Form.useForm();
  const isAdmin = checkAccess(accessComponentsForUser, 'moderation');

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestNewsGet({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  useEffect(() => {
    if (
      newsCreateRequestStatus === 'resolved' ||
      newsUpdateRequestStatus === 'resolved' ||
      newsDeleteRequestStatus === 'resolved'
    ) {
      dispatch(sendRequestNewsGet({ token: localStorage.getItem('token') }));
    }
  }, [
    newsCreateRequestStatus,
    newsUpdateRequestStatus,
    newsDeleteRequestStatus,
  ]);

  const handleCancel = () => {
    setIsCreate(false);
    setCurrentPost(null);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      title: values.title,
      body: values.body,
    }

    if (currentPost) {
      dispatch(sendRequestNewsUpdate({...requestData, newsAdminId: currentPost.id}));
    } else {
      dispatch(sendRequestNewsCreate(requestData));
    }

    setIsCreate(false);
    form.resetFields();
  };

  const handleCreate = () => {
    setCurrentPost(null);
    setIsCreate(true);
  }

  const handleUpdate = (post) => {
    setCurrentPost(post);
    setIsCreate(true);
    form.setFieldsValue(post);
  }

  const handleDelete = (id) => {
    const requestData = {
      token: localStorage.getItem('token'),
      newsAdminId: id,
    }

    dispatch(sendRequestNewsDelete(requestData));
    setIsCreate(false);
    form.resetFields();
  }

  return (
    <div style={{padding: '30px 0'}}>
      <NewsWrapper>
        <Flex style={{padding: '0 10px'}} justify='space-between' align='center'>
          <Title level={4} style={{margin: '0'}}>Новости</Title>
          {isAdmin && <Button type='primary' shape="circle" icon={<PlusOutlined/>} onClick={handleCreate}/>}
        </Flex>
        <div style={{position: 'relative'}}>
          <ScrolledCol height={'calc(100vh - 250px)'}>
          {news &&
            news
              .slice()
              .sort((a, b) => new Date(b.created) - new Date(a.created))
              .map(post => (
                <NewsCardComponent key={post.id} post={post} isAdmin={isAdmin} onUpdate={handleUpdate} onDelete={handleDelete} />
              ))
          }
          </ScrolledCol>
          <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 90%, rgba(245, 245, 245, 1) 100%)', pointerEvents: 'none'}}></div>
        </div>
      </NewsWrapper>

      <Modal
        title="Создание новости"
        open={isCreate}
        onCancel={handleCancel}
        footer={null}
      >
        <Form style={{ width: '100%' }} form={form} name='deliveryService' layout='vertical' onFinish={handleSubmit}>
          <Form.Item label='Заголовок' name='title' rules={[{ required: true, message: 'Введите название' }]}>
            <Input placeholder='Заголовок'/>
          </Form.Item>
          <Form.Item label='Текст' name='body' rules={[{ required: true, message: 'Введите текст' }]}>
            <TextArea rows={2} placeholder='Текст новости'/>
          </Form.Item>
          <Button type='primary' htmlType='submit' style={{ display: 'flex', marginLeft: 'auto' }}>
            {currentPost !== null ? 'сохранить' : 'Создать'}
          </Button>
        </Form>
      </Modal>
    </div>
  );
}

export default NewsComponent;

const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  padding: 10px 20px;
`

const NewsCardComponent = ({ post, isAdmin, onUpdate, onDelete }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleClickUpdate = () => {
    onUpdate(post);
  };

  const handleClickDelete = () => {
    onDelete(post.id);
  };

  return (
    <SitiyCard>
      <Title level={5}>{formatDate(post.created)}</Title>
      <Title level={5}>{post.title}</Title>
      <Text>
        {post.body.length > 100 ?
          <>
            {expanded ? post.body : `${post.body.slice(0, 100)}...`}
            {expanded ? (
              <Link onClick={toggleExpanded}> cвернуть</Link>
            ) : (
              <Link onClick={toggleExpanded}> читать полностью</Link>
            )}
          </>
          :
          <>
            {post.body}
          </>
        }
      </Text>

      {isAdmin && 
        <Flex
          style={{marginTop: '30px'}}
          gap={10}
          justify='flex-end'
        >
          <Button onClick={handleClickUpdate}>Редактировать</Button>
          <Popconfirm
            title="Удаление поста!"
            description={`Вы действительно хотите удалить пост?`}
            okText="Да"
            cancelText="Нет"
            placement="left"
            onConfirm={handleClickDelete}
          >
            <Button danger>Удалить</Button>
          </Popconfirm>
        </Flex>
      }
    </SitiyCard>
  );
};
