import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestKitchenComplaintGet,
  sendRequestKitchenComplaintCreate,
  sendRequestKitchenComplaintUpdate,
  sendRequestKitchenComplaintDelete,
  sendRequestKitchenComplaintConfirm,
  sendRequestKitchenComplaintTablePretensionsStatusGet,
} from '../../../../redux/reducers/utils/kitchenReducers/kitchenComplaintReducer';
import { Select, Table, Button, Typography, Modal, Divider, DatePicker, Space, Input } from 'antd';
import styled from 'styled-components';
import Loader from '../../../../components/Loader';
import KitchenSelectorComponent from '../../components/KitchenSelectorComponent';
import ComplaintTableComponent from './components/ComplaintTableComponent';
import DateRangePicker from '../../../../components/DateRangePicker';
import moment from 'moment';

const { Title, Text } = Typography;
const { Option } = Select;

const ComplaintPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const { selectKitchen } = useSelector((store) => store.kitchen);
  const {
    kitchenComplaintGetRequestStatus,
    kitchenComplaint,
    kitchenComplaintCreateRequestStatus,
    kitchenComplaintUpdateRequestStatus,
    kitchenComplaintDeleteRequestStatus,
    kitchenComplaintConfirmRequestStatus,
    tablePretensionsStatus,
  } = useSelector((store) => store.kitchenComplaint);
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState({
    dateFrom: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    dateTo: moment().format('YYYY-MM-DD'),
  });
  const [clientPhone, setClientPhone] = useState('');
  const [complaintStatus, setComplaintStatus] = useState(0);

  const getRequestData = {
    token: localStorage.getItem('token'),
    kitchenId: selectKitchen.id,
    ...dateRange,
    status: complaintStatus != 0 ? complaintStatus : undefined,
    phone: clientPhone.length === 12 ? clientPhone : undefined,
  }

  const complaintStatusOptions = [
    { value: 0, label: 'Выберите статус' },
    ...tablePretensionsStatus.map((item) => ({
      ...item,
      value: item.id,
      label: item.name,
    })),
  ];

  useEffect(() => {
    if (selectKitchen) {
      dispatch(sendRequestKitchenComplaintGet(getRequestData));
      dispatch(sendRequestKitchenComplaintTablePretensionsStatusGet({token: localStorage.getItem('token'), kitchenId: selectKitchen.id}));
    }
  }, [selectKitchen])

  useEffect(() => {
    if (
      selectKitchen && 
      kitchenComplaintCreateRequestStatus === 'resolved' ||
      kitchenComplaintUpdateRequestStatus === 'resolved' ||
      kitchenComplaintDeleteRequestStatus === 'resolved' ||
      kitchenComplaintConfirmRequestStatus === 'resolved'
    ) {
      dispatch(sendRequestKitchenComplaintGet(getRequestData));
    }
  }, [
    kitchenComplaintCreateRequestStatus,
    kitchenComplaintUpdateRequestStatus,
    kitchenComplaintDeleteRequestStatus,
    kitchenComplaintConfirmRequestStatus,
  ]);

  const handleDateChange = (range) => {
    setDateRange(range);
    dispatch(sendRequestKitchenComplaintGet({ ...getRequestData, ...range}));
  };

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    if (value.startsWith('7')) {
      value = '+' + value;
    } else {
      value = '+7' + value;
    }

    if (value.length <= 12) {
      setClientPhone(value);
    }

    if (value.length === 12) {
      dispatch(sendRequestKitchenComplaintGet({ ...getRequestData, phone: value}));
    }
  };

  const handleStatusChange = (value) => {
    setComplaintStatus(value);
    dispatch(sendRequestKitchenComplaintGet({ ...getRequestData, status: value != 0 ? value : undefined }));
  };

  const handleConfirm = (id, confirmed) => {
    const requestData = {
      token: localStorage.getItem('token'),
      id: id,
      confirmed: confirmed,
    }

    console.log(requestData);
    dispatch(sendRequestKitchenComplaintConfirm(requestData));
  };

  return (
    <>
      {(
        kitchenComplaintGetRequestStatus === 'pending' ||
        kitchenComplaintCreateRequestStatus === 'pending' ||
        kitchenComplaintUpdateRequestStatus === 'pending' ||
        kitchenComplaintDeleteRequestStatus === 'pending'
      ) && <Loader />}
      <Title level={2}>Претензии</Title>
      <Space size={20} style={{marginBottom: '20px'}}>
        <KitchenSelectorComponent/>
        <DateRangePicker
          initialDateFrom={moment().subtract(30, 'days').toDate()}
          initialDateTo={moment().toDate()}
          onChange={handleDateChange}
        />
        <Input
          placeholder="+7 --- --- -- --"
          value={clientPhone}
          maxLength={12}
          onChange={handlePhoneChange}
        />
        <Select
          defaultValue={0}
          options={complaintStatusOptions}
          onChange={handleStatusChange}
        />
      </Space>

      <ComplaintTableComponent
        complaints={kitchenComplaint}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default ComplaintPage;
