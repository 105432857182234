import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchFormFilters } from '../../../../redux/reducers/utils/deliveryReducers/deliveryMapReducer';
import Loader from '../../../../components/Loader';
import DeliveryLeftPanel from './components/DeliveryLeftPanel';
import DeliveryRightPanel from './components/DeliveryRightPanel';
import DeliveryMap from './components/DeliveryMap';

const DeliveryMapPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const {
    assignCourierToAddressesStatus,
    addressChangeGeopointStatus,
    updateAddressCommentStatus,
    updateOrderCommentStatus,
  } = useSelector((store) => store.deliveryMap);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth) {
      dispatch(fetchFormFilters({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  return (
    <>
      {(
        assignCourierToAddressesStatus === 'pending' ||
        addressChangeGeopointStatus === 'pending' ||
        updateAddressCommentStatus === 'pending' ||
        updateOrderCommentStatus === 'pending'
      ) && <Loader />}
      <DeliveryLeftPanel/>
      <DeliveryRightPanel/>
      <DeliveryMap/>
    </>
  );
};

export default DeliveryMapPage;
