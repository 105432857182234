import apiInstance from '../../../../../api';
import {call, put, select} from 'redux-saga/effects';
import {
  //Categories
  sendRequestCategoriesGetSuccess,
  sendRequestCategoriesGetError,
  setCategories,
  sendRequestCategoriesSortSuccess,
  sendRequestCategoriesSortError,

  //Products
  sendRequestProductsGetSuccess,
  sendRequestProductsGetError,
  setProducts,
  sendRequestSearchProductsGetSuccess,
  sendRequestSearchProductsGetError,
  setSearchData,
  updateSearchDataProducts,
  setCurrentProductId,
  sendRequestProductsCreateSuccess,
  sendRequestProductsCreateError,
  sendRequestProductsUpdateSuccess,
  sendRequestProductsUpdateError,
  sendRequestProductsDeleteSuccess,
  sendRequestProductsDeleteError,
  sendRequestProductChangeModerationSuccess,
  sendRequestProductChangeModerationError,
  sendRequestProductsDeleteImageSuccess,
  sendRequestProductsDeleteImageError,
} from '../../../../reducers/utils/kitchenReducers/kitchenAssortmentReducer';
import { setMessagePopupContent, selectCommonState } from '../../../../reducers/utils/commonReducer';

//Categories
export function* handleGetCategories(action) {
  const api = '/products/categories/getBykitchenId?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestCategoriesGetSuccess());
      const categories = response.data.data.categories;
      const sortedCategories = categories.sort((a, b) => {
        if (a.countProducts === 0 && b.countProducts !== 0) {
          return 1;
        } else if (b.countProducts === 0 && a.countProducts !== 0) {
          return -1;
        } else {
          return a.sort - b.sort;
        }
      });

      yield put(setCategories(sortedCategories));
    } else {
      yield put(sendRequestCategoriesGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestCategoriesGetError());
  }
}

export function* handleSortCategories(action) {
  const api = '/products/categories/sort/add?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestCategoriesSortSuccess());
    } else {
      yield put(sendRequestCategoriesSortError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestCategoriesSortError());
  }
}

//Products
export function* handleGetProducts(action) {
  const api = '/products/getProductsByKitcenIdAndCategoryId?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestProductsGetSuccess());
      yield put(setProducts(response.data.data.products));
    } else {
      yield put(sendRequestProductsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestProductsGetError());
  }
}

export function* handleGetSearchProducts(action) {
  const api = '/products/search?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestSearchProductsGetSuccess());
      if (response.data.data.offset === 0) {
        console.log('новые продукты')
        yield put(setSearchData(response.data.data.search));
      } else {
        console.log('подзагрузка')
        yield put(updateSearchDataProducts(response.data.data.search.products.map((product, index) => ({ ...product, key: `${product.id}_${index}` }))));
      }
    } else {
      yield put(sendRequestSearchProductsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestSearchProductsGetError());
  }
}

export function* handleCreateProducts(action) {
  const api = '/products/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  let formData = new FormData();
  formData.append('file', action.payload.image);
  formData.append('data', JSON.stringify( data ));

  try {
    const response = yield call(() => apiInstance.post(fullApi, formData));
    

    if (response.data.result) {
      yield put(sendRequestProductsCreateSuccess());
      yield put(setCurrentProductId(response.data.data.product.id));
    } else {
      yield put(sendRequestProductsCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestProductsCreateError());
  }
}

export function* handleUpdateProducts(action) {
  const api = '/products/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  let formData = new FormData();
  formData.append('file', action.payload.image);
  formData.append('data', JSON.stringify( data ));

  try {
    const response = yield call(() => apiInstance.post(fullApi, formData));
    

    if (response.data.result) {
      yield put(sendRequestProductsUpdateSuccess());
    } else {
      yield put(sendRequestProductsUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestProductsUpdateError());
  }
}

export function* handleDeleteProducts(action) {
  const api = '/products/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestProductsDeleteSuccess());
    } else {
      yield put(sendRequestProductsDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestProductsDeleteError());
  }
}

export function* handleProductChangeModeration(action) {
  const api = '/products/enabledByAdmin?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestProductChangeModerationSuccess());
    } else {
      yield put(sendRequestProductChangeModerationError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestProductChangeModerationError());
  }
}

export function* handleDeleteProductsImage(action) {
  const api = '/products/deleteImg?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestProductsDeleteImageSuccess());
    } else {
      yield put(sendRequestProductsDeleteImageError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestProductsDeleteImageError());
  }
}
