import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCurrentDeliveryService,
  sendRequestDeliveryServicesUpdate,
  sendRequestDeliveryServicesDelete
} from '../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Row, Col, Flex, Modal, Typography, Select, Table, Form, DatePicker, Popconfirm, Input, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import DeliveryServicesCreateModalComponent from './DeliveryServicesCreateModalComponent';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PrintTable from '../../../../../components/PrintTable';

const { Title } = Typography;
const { Search } = Input;

const DeliveryServicesTableComponent = () => {
  const navigate = useNavigate();
  const { deliveryServices } = useSelector((store) => store.deliveryServices);
  const { organizations } = useSelector((store) => store.settingsCatalogs);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const modifyDeliveryServices = deliveryServices?.map(item => {
    const organization = organizations?.find(org => org.id === item.organizationId);
    const urName = organization ? organization?.urName : '';
  
    return { ...item, key: item.id, organizationUrName: urName };
  });

  const filteredDeliveryServices = modifyDeliveryServices?.filter((item) =>
    item.name?.toString().toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleCellClick = (record) => {
    dispatch(setCurrentDeliveryService(record));
    navigate(`${record.id}`);
  };

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Зона доставки',
      dataIndex: 'countDeliveryZones',
      key: 'countDeliveryZones',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Количество курьеров',
      dataIndex: 'countCouriers',
      key: 'countCouriers',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Организация',
      dataIndex: 'organizationUrName',
      key: 'organizationUrName',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: '№ договора',
      dataIndex: 'contract_number',
      key: 'contract_number',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Дата договора',
      dataIndex: 'contract_date',
      key: 'contract_date',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: '',
      key: 'actions',
      render: (_, record) => (
        <AccessAction
          deliveryService={record}
        />
      ),
    },
  ];

  return (
    <>
      <Flex gap={20} wrap='wrap' style={{ margin: '20px 0' }}>
        <Search
          placeholder="Поиск по названию или описанию"
          onChange={(e) => handleSearch(e.target.value)}
          style={{ maxWidth: '500px' }}
        />
        <DeliveryServicesCreateModalComponent/>
      </Flex>
      <PrintTable>
        <Table
          dataSource={filteredDeliveryServices}
          columns={columns}
          size="small"
          pagination={{
            defaultPageSize: 50,
          }}
        />
      </PrintTable>
    </>
  );
};

export default DeliveryServicesTableComponent;

const AccessAction = ({deliveryService}) => {
  const { organizations } = useSelector((store) => store.settingsCatalogs);
  const dispatch = useDispatch();
  const [ isCreate, setIsCreate] = useState(false);
  const [form] = Form.useForm();

  dayjs.extend(customParseFormat);
  const dateFormat = 'YYYY-MM-DD';

  const handleOpen = () => {
    setIsCreate(true);
  };

  const handleCancel = () => {
    setIsCreate(false);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      deliveryServiceId: deliveryService.id,
      name: values.name,
      description: values.description,
      contract_number: values.contractNumber,
      contract_date: moment(new Date(values.contractDate)).format('YYYY-MM-DD'),
      organizationId: values.id,
    }

    dispatch(sendRequestDeliveryServicesUpdate(requestData));
    setIsCreate(false);
    form.resetFields();
  }

  const organizationOptions = organizations?.map((item) => ({
    ...item,
    value: item?.id,
    label: item?.urName,
  }));

  const handleDelete = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      deliveryServiceId: deliveryService.id,
    }

    dispatch(sendRequestDeliveryServicesDelete(requestData));
  };

  return (
    <Action>
      <Button
        icon={<EditOutlined />}
        onClick={handleOpen}
      >
      </Button>
      <Modal
        title="Редактирование службы доставки"
        open={isCreate}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          style={{ width: '100%' }}
          form={form}
          name='deliveryService'
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={{
            name: deliveryService.name,
            description: deliveryService.description,
            contractNumber: deliveryService.contract_number,
            contractDate: deliveryService.contract_date ? dayjs(deliveryService.contract_date, dateFormat) : '',
            id: {value: deliveryService.organizationId, label: organizations?.find(organization => organization.id.toString() === deliveryService.organizationId.toString())?.urName,}.value,
          }}
        >
          <Form.Item label='Название' name='name' rules={[{ required: true, message: 'Введите название' }]}>
            <Input placeholder='Название службы доставки'/>
          </Form.Item>
          <Form.Item label='Описание' name='description' rules={[{ required: true, message: 'Введите описание' }]}>
            <Input placeholder='Название службы доставки'/>
          </Form.Item>
          <Form.Item label='Номер договора' name='contractNumber' rules={[{ required: true, message: 'Введите номер договора' }]}>
            <Input placeholder='Название службы доставки'/>
          </Form.Item>
          <Form.Item label='Дата заключения договора' name='contractDate' rules={[{ required: true, message: 'Выберите дату' }]}>
            <DatePicker style={{ width:'100%' }} placeholder='Выберите дату'/>
          </Form.Item>
          <Form.Item label='Организация' name='id' rules={[{ required: true, message: 'Выберите организацию' }]}>
            <Select
              showSearch
              placeholder="Выберите организацию"
              optionFilterProp="children"
              options={organizationOptions}
              filterOption={(input, option) =>
                (option?.urName?.toString() ?? '').toLowerCase().includes(input.toLowerCase()) ||
                (option?.inn?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
              }
              optionRender={(option) => (
                <Flex
                  vertical
                  style={{ margin: '5px 0', height: '80px' }}
                >
                  <div>ID: {option.data.id}</div>
                  <Title level={5}>{option.data.urName}</Title>
                  <div>ИНН: {option.data.inn}</div>
                </Flex>
              )}
            />
          </Form.Item>
          <Button type='primary' htmlType='submit' style={{ display: 'flex', marginLeft: 'auto' }}>Сохранить</Button>
        </Form>
      </Modal>
      <Popconfirm
        title="Удаление службы доставки!"
        description={`Вы действительно хотите удалить компонента доступа ${deliveryService.name}`}
        okText="Да"
        cancelText="Нет"
        placement="left"
        onConfirm={handleDelete}
      >
        <Button danger>Удалить</Button>
      </Popconfirm>
    </Action>
  );
};
const Action = styled.div`
  position: relative;
  z-index: 600;
  display: flex;
  gap: 5px;
  align-items: center;
`