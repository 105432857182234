import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setIsShowRaceKitchenDrawer,
  setCurrentRaceKitchen,
  sendRequestDeliveryServiceRaceKitchenAdd,
  sendRequestDeliveryServiceRaceKitchenUpdate,
} from '../../../../../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Flex, Table, Input, Switch } from 'antd';
import PrintTable from '../../../../../../../../../components/PrintTable';

const { Search } = Input;

const KitchensTableComponent = () => {
  const { deliveryServiceRaceKitchens, currentRaceKitchen } = useSelector((store) => store.deliveryServices);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const modifyRaceKitchens = deliveryServiceRaceKitchens?.map(item => ({ ...item, key: item.id }));

  const filteredRaceKitchens = modifyRaceKitchens?.filter((item) =>
    item.name?.toString().toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleCellClick = (record) => {
    dispatch(setIsShowRaceKitchenDrawer(true));
    dispatch(setCurrentRaceKitchen(record));
  };

  const columns = [
    {
      title: 'Кухня',
      key: 'name',
      dataIndex: 'name',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Зона доставки',
      key: 'actions',
      render: (_, record) => (
        <Flex gap='small'>
          <AccessActionSwitch record={record} />
          {record.polygonName}
        </Flex>
      ),
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Стоп приёма заказов (отсечка)',
      dataIndex: 'stopAcceptingOrders',
      key: 'stopAcceptingOrders',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Время передачи заказов',
      dataIndex: 'timeTransferringOrderToCourier',
      key: 'timeTransferringOrderToCourier',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Мин. сумма заказа',
      dataIndex: 'minimumOrderAmount',
      key: 'minimumOrderAmount',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Условия доставки',
      key: 'termsDelivery',
      render: (_, { termsDelivery }) => (
        <Flex vertical gap='small'>
          {termsDelivery.map((term, index) => (
            <Flex vertical gap='0' key={index} style={{padding: '4px 8px', fontSize: '10px', lineHeight: 1, border: 'solid 1px #f1f1f1', borderRadius: '5px'}}>
              <div><b>Заказ от {term.minPrice} р.</b></div>
              <div>Цена доставки: {term.deliveryPrice} р.</div>
              <div>Сервисный сбор: {term.serviceFee} р.</div>
            </Flex>
          ))}
        </Flex>
      ),
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
  ];

  console.log(currentRaceKitchen)

  return (
    <>
      <Flex gap={20} wrap='wrap' style={{ margin: '20px 0' }}>
        <Search
          placeholder="Поиск по имени"
          onChange={(e) => handleSearch(e.target.value)}
          style={{ maxWidth: '500px' }}
        />
      </Flex>
      <PrintTable>
        <Table
          dataSource={filteredRaceKitchens}
          columns={columns}
          bordered
          size="small"
          pagination={{
            defaultPageSize: 50,
          }}
          onRow={(record) => ({
            style: { background: record.kitchenId === currentRaceKitchen?.kitchenId && record.racePolygonId === currentRaceKitchen?.racePolygonId ? '#e6f7ff' : '' },
          })}
        />
      </PrintTable>
    </>
  );
};

export default KitchensTableComponent;

const AccessActionSwitch = ({record}) => {
  const dispatch = useDispatch();

  const onChange = (record, checked) => {
    const createData = {
      token: localStorage.getItem('token'),
      kitchenId: record.kitchenId,
      racePolygonId: record.racePolygonId,
      shutoff: record.shutoff,
      serviceFee: record.serviceFee,
      minimumOrderAmount: record.minimumOrderAmount,
      timeTransferringOrderToCourier: record.timeTransferringOrderToCourier,
    }
    const updateData = {
      token: localStorage.getItem('token'),
      kitchenRacePolygonId: record.kitchenRacePolygonId,
      kitchenId: record.kitchenId,
      racePolygonId: record.racePolygonId,
      shutoff: record.shutoff,
      serviceFee: record.serviceFee,
      minimumOrderAmount: record.minimumOrderAmount,
      timeTransferringOrderToCourier: record.timeTransferringOrderToCourier,
      isEnabled: checked,
    }

    if (record.kitchenRacePolygonId === 0) {
      console.log('add kitchenRacePolygonId', record.kitchenRacePolygonId, createData);
      dispatch(sendRequestDeliveryServiceRaceKitchenAdd(createData));
    } else {
      console.log('update kitchenRacePolygonId', record.kitchenRacePolygonId, updateData);
      dispatch(sendRequestDeliveryServiceRaceKitchenUpdate(updateData));
    }
  };

  return (
    <Switch checked={record.isEnabled} onChange={(checked) => onChange(record, checked)}/>
  );
};
