import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";
import moment from 'moment';

const initialState = {
  isAuth: false,

  //Code To Phone
  sendCodeStatus: null,

  //Token By Session
  sendCodeTime: null,

  //Token By Session
  createTokenStatus: null,

  //Logout
  logoutStatus: null,

  //Give Role To New User
  isNewUser: false,
  giveRoleToNewUserRequestStatus: null,

  //User Profile
  userProfileRequestStatus: null,
  userProfile: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    setAuth: (state, action) => { state.isAuth = action.payload; },

    //Code To Phone
    sendCodeToPhone: (state) => { state.sendCodeStatus = 'pending'; },
    sendCodeToPhoneSuccess: (state) => {
      state.sendCodeStatus = 'resolved';
      state.sendCodeTime = moment().unix();
    },
    sendCodeToPhoneError: (state) => { state.sendCodeStatus = 'rejected'; },
    sendCodeToPhoneStatusClear: (state) => { state.sendCodeStatus = null; },

    //Token By Session
    createTokenBySession: (state) => { state.createTokenStatus = 'pending'; },
    createTokenBySessionSuccess: (state, action) => {
      state.createTokenStatus = 'resolved';
      state.isAuth = true;
      state.isNewUser = action.payload;
    },
    createTokenBySessionError: (state) => { state.createTokenStatus = 'rejected'; },
    clearCreateTokenStatus: (state) => { state.createTokenStatus = null; },

    //Logout
    logout: (state) => { state.logoutStatus = 'pending'; },
    logoutSuccess: (state) => {
      state.logoutStatus = 'resolved';
      state.isAuth = false;
      state.sendCodeTime = null;
    },
    logoutError: (state) => { state.logoutStatus = 'rejected'; },
    clearLogoutStatus: (state) => { state.logoutStatus = null; },

    //Give Role To New User
    sendRequestGiveRoleToNewUser: (state) => { state.giveRoleToNewUserRequestStatus = 'pending'; },
    sendRequestGiveRoleToNewUserSuccess: (state) => {
      state.giveRoleToNewUserRequestStatus = 'resolved';
      state.isNewUser = false;
    },
    sendRequestGiveRoleToNewUserError: (state) => { state.giveRoleToNewUserRequestStatus = 'rejected'; },
    clearGiveRoleToNewUserStatus: (state) => { state.giveRoleToNewUserRequestStatus = null; },

    //User Profile
    sendRequestUserProfile: (state) => { state.userProfileRequestStatus = 'pending'; },
    sendRequestUserProfileSuccess: (state) => { state.userProfileRequestStatus = 'resolved'; },
    sendRequestUserProfileError: (state) => { state.userProfileRequestStatus = 'rejected'; },
    clearUserProfileStatus: (state) => { state.userProfileRequestStatus = null; },
    setUserProfile: (state, action) => { state.userProfile = action.payload; },
  },
});

export const {
  setAuth,

  //Code To Phone
  sendCodeToPhone,
  sendCodeToPhoneSuccess,
  sendCodeToPhoneError,
  sendCodeToPhoneStatusClear,

  //Token By Session
  createTokenBySession,
  createTokenBySessionSuccess,
  createTokenBySessionError,
  clearCreateTokenStatus,

  //Logout
  logout,
  logoutSuccess,
  logoutError,
  clearLogoutStatus,

  //Give Role To New User
  sendRequestGiveRoleToNewUser,
  sendRequestGiveRoleToNewUserSuccess,
  sendRequestGiveRoleToNewUserError,
  clearGiveRoleToNewUserStatus,

  //User Profile
  sendRequestUserProfile,
  sendRequestUserProfileSuccess,
  sendRequestUserProfileError,
  clearUserProfileStatus,
  setUserProfile,
} = authSlice.actions;

export default authSlice.reducer;
