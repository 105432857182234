import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestCitiesGet} from '../../../../redux/reducers/utils/settingsReducers/settingsCatalogsReducer';
import { sendRequestDeliveryServicesGet} from '../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import {
  sendRequestNotificationsGet,
  sendRequestNotificationsPush,
  setCurrentPage,
  clearDevices,
} from '../../../../redux/reducers/utils/clientsReducers/clientsNotificationsReducer';
import { Row, Col, Typography } from 'antd';
import Loader from '../../../../components/Loader';
import ScrolledContainer from "../../../../components/ScrolledContainer";
import NotificationsTableComponent from "./components/NotificationsTableComponent";
import NotificationCreateModalComponent from "./components/NotificationCreateModalComponent";
import { Container, Page, ScrolledCol } from "../../../../core/styles";

const { Title } = Typography;

const ClientsNotificationsPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const { cities } = useSelector((store) => store.settingsCatalogs);
  const { deliveryServices } = useSelector((store) => store.deliveryServices);
  const {
    notificationsGetRequestStatus,
    notifications,
    currentPage,
    totalNotifications,
    notificationPushRequestStatus,
    devices,
  } = useSelector((store) => store.clientsNotifications);
  const dispatch = useDispatch();

  const requestDataNotificationsGet = {
    token: localStorage.getItem('token'),
    offset: (currentPage - 1) * 20,
    limit: 20,
  }

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestCitiesGet({ token: localStorage.getItem('token') }));
      dispatch(sendRequestDeliveryServicesGet({ token: localStorage.getItem('token') }));
      dispatch(sendRequestNotificationsGet(requestDataNotificationsGet));
    }
  }, [isAuth]);

  useEffect(() => {
    if (notificationPushRequestStatus === 'resolved') {
      dispatch(sendRequestNotificationsGet({...requestDataNotificationsGet, offset: 0}));
    }
  }, [notificationPushRequestStatus]);

  const citiesOptions = cities?.map((item) => ({
    ...item,
    value: item.id,
    label: item.name,
  }));

  const deliveryServicesOptions = deliveryServices?.map((item) => ({
    ...item,
    value: item.id,
    label: item.name,
  }));

  const handlePushNotification = (data) => {
    const requestData = {
      token: localStorage.getItem('token'),
      ...data,
    }

    console.log(requestData);
    dispatch(sendRequestNotificationsPush(requestData));
    dispatch(clearDevices());
  }

  const handlePageChange = (page) => {
    console.log(page);

    dispatch(setCurrentPage(page));
    dispatch(sendRequestNotificationsGet({...requestDataNotificationsGet, offset: (page - 1) * 20 }));
  }

  return (
    <Page>
      {(
        notificationsGetRequestStatus === 'pending'
      ) && <Loader />}

      <ScrolledCol>
        <Container>
          <Title>Уведомления клиентов</Title>
          <NotificationCreateModalComponent
            devices={devices}
            citiesOptions={citiesOptions}
            deliveryServicesOptions={deliveryServicesOptions}
            onSubmit={handlePushNotification}
          />
          <NotificationsTableComponent
            notifications={notifications}
            currentPage={currentPage}
            totalNotifications={totalNotifications}
            onChange={handlePageChange}
          />
        </Container>
      </ScrolledCol>
    </Page>
  )
}

export default ClientsNotificationsPage;