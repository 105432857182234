import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  //Categories
  categoriesGetRequestStatus: null,
  categories: [],
  currentKitchen: null,
  currentCategory: null,
  categoriesSortRequestStatus: null,

  //Products
  productsGetRequestStatus: null,
  products: [],
  searchProductsGetRequestStatus: null,
  searchCategories: [],
  searchCategoriesCount: [],
  searchCategoriesSorting: [],
  searchProducts: [],
  searchKitchens: [],
  groupedCategories: [],
  currentProductId: null,
  productsCreateRequestStatus: null,
  productsUpdateRequestStatus: null,
  productsDeleteRequestStatus: null,
  productChangeModerationRequestStatus: null,
  productDeleteImageRequestStatus: null,

  //Drawer
  isShowAssortmentDrawer: false,
};

const kitchenAssortmentSlice = createSlice({
  name: 'kitchenAssortment',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    //Categories
    sendRequestCategoriesGet: (state) => { state.categoriesGetRequestStatus = 'pending'; },
    sendRequestCategoriesGetSuccess: (state) => { state.categoriesGetRequestStatus = 'resolved'; },
    sendRequestCategoriesGetError: (state) => { state.categoriesGetRequestStatus = 'rejected'; },
    clearCategoriesGetStatus: (state) => { state.categoriesGetRequestStatus = null; },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setCurrentKitchen: (state, action) => {
      state.currentKitchen = action.payload;
    },
    setCurrentCategory: (state, action) => {
      state.currentCategory = action.payload;
    },
    sendRequestCategoriesSort: (state) => { state.categoriesSortRequestStatus = 'pending'; },
    sendRequestCategoriesSortSuccess: (state) => { state.categoriesSortRequestStatus = 'resolved'; },
    sendRequestCategoriesSortError: (state) => { state.categoriesSortRequestStatus = 'rejected'; },
    clearCategoriesSortStatus: (state) => { state.categoriesSortRequestStatus = null; },

    //Products
    sendRequestProductsGet: (state) => { state.productsGetRequestStatus = 'pending'; },
    sendRequestProductsGetSuccess: (state) => { state.productsGetRequestStatus = 'resolved'; },
    sendRequestProductsGetError: (state) => { state.productsGetRequestStatus = 'rejected'; },
    clearProductsGetStatus: (state) => { state.productsGetRequestStatus = null; },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    sendRequestSearchProductsGet: (state) => { state.searchProductsGetRequestStatus = 'pending'; },
    sendRequestSearchProductsGetSuccess: (state) => { state.searchProductsGetRequestStatus = 'resolved'; },
    sendRequestSearchProductsGetError: (state) => { state.searchProductsGetRequestStatus = 'rejected'; },
    clearSearchProductsGetStatus: (state) => { state.searchProductsGetRequestStatus = null; },
    setSearchData: (state, action) => {
      const data = action.payload;

      if (data.products) {
        const searchProducts = data?.products.map((product, index) => ({ ...product, key: `${product.id}_${index}` }));
        state.searchProducts = searchProducts;
      }

      if (data.categories && data.kitchens) {
        const searchCategories = data?.categories?.categories;
        const searchCategoriesCount = data?.categories?.categoriesCount;
        const searchCategoriesSorting = data?.categories?.categoriesSorting;
        const searchKitchens = data?.kitchens;
        state.searchCategories = searchCategories;
        state.searchCategoriesCount = searchCategoriesCount;
        state.searchCategoriesSorting = searchCategoriesSorting;
        state.searchKitchens = searchKitchens;

        const categoriesCount = searchCategoriesCount.reduce((acc, obj) => {
          const { kitchenId } = obj;
          if (!acc[kitchenId]) {
            acc[kitchenId] = [];
          }
          acc[kitchenId].push(obj);
          return acc;
        }, {});

        const categoriesSorting = searchCategoriesSorting.reduce((acc, obj) => {
          const { kitchenId } = obj;
          if (!acc[kitchenId]) {
            acc[kitchenId] = [];
          }
          acc[kitchenId].push(obj);
          return acc;
        }, {});

        const modifySearchCategories = searchCategories.map(category => {
          return { ...category, countProducts: 0, countModered: 0 };
        });

        const kitchensWithCategories = searchKitchens.map(kitchen => {
          return {
            ...kitchen,
            categories: modifySearchCategories.map(category => ({ ...category, kitchenId: kitchen.id }))
          };
        });

        const updatedKitchens = kitchensWithCategories.map(kitchen => {
          const kitchenId = kitchen.id;
          const updatedCategoriesCount = categoriesCount[kitchenId];

          if (updatedCategoriesCount) {
            return {
              ...kitchen,
              categories: kitchen.categories.map(category => {
                const updatedCategory = updatedCategoriesCount.find(cat => cat.categoryId === category.id);
                if (updatedCategory) {
                  return {
                    ...category,
                    countProducts: updatedCategory.countProducts,
                    countModered: updatedCategory.countModered,
                  };
                } else {
                  return category;
                }
              })
            };
          } else {
            return kitchen;
          }
        });

        const updatedKitchens2 = updatedKitchens.map(kitchen => {
          const kitchenId = kitchen.id;
          const updatedCategoriesSorting = categoriesSorting[kitchenId];

          if (updatedCategoriesSorting) {
            return {
              ...kitchen,
              categories: kitchen.categories.map(category => {
                const updatedCategory = updatedCategoriesSorting.find(cat => cat.categoryId === category.id);
                if (updatedCategory) {
                  return {
                    ...category,
                    sort: updatedCategory.sort,
                  };
                } else {
                  return category;
                }
              })
            };
          } else {
            return kitchen;
          }
        });

        const updatedKitchensSorted = updatedKitchens2.map(kitchen => {
          const sortedCategories = kitchen.categories
            .sort((a, b) => a.sort - b.sort)
            .sort((a, b) => {
              if (a.countProducts === 0 && b.countProducts !== 0) {
                return 1;
              } else if (a.countProducts !== 0 && b.countProducts === 0) {
                return -1;
              } else {
                return 0;
              }
            });

          return {
            ...kitchen,
            categories: sortedCategories
          };
        });

        state.groupedCategories = updatedKitchensSorted;
      }
    },
    updateSearchDataProducts: (state, action) => {
      return {
        ...state,
        searchProducts: [...state.searchProducts, ...action.payload]
      };
    },
    setCurrentProductId: (state, action) => {
      state.currentProductId = action.payload;
    },
    setSearchProducts: (state, action) => {
      state.searchProducts = action.payload;
    },
    sendRequestProductsCreate: (state) => { state.productsCreateRequestStatus = 'pending'; },
    sendRequestProductsCreateSuccess: (state) => { state.productsCreateRequestStatus = 'resolved'; },
    sendRequestProductsCreateError: (state) => { state.productsCreateRequestStatus = 'rejected'; },
    clearProductsCreateStatus: (state) => { state.productsCreateRequestStatus = null; },
    sendRequestProductsUpdate: (state) => { state.productsUpdateRequestStatus = 'pending'; },
    sendRequestProductsUpdateSuccess: (state) => { state.productsUpdateRequestStatus = 'resolved'; },
    sendRequestProductsUpdateError: (state) => { state.productsUpdateRequestStatus = 'rejected'; },
    clearProductsUpdateStatus: (state) => { state.productsUpdateRequestStatus = null; },
    sendRequestProductsDelete: (state) => { state.productsDeleteRequestStatus = 'pending'; },
    sendRequestProductsDeleteSuccess: (state) => { state.productsDeleteRequestStatus = 'resolved'; },
    sendRequestProductsDeleteError: (state) => { state.productsDeleteRequestStatus = 'rejected'; },
    clearProductsDeleteStatus: (state) => { state.productsDeleteRequestStatus = null; },
    sendRequestProductChangeModeration: (state) => { state.productChangeModerationRequestStatus = 'pending'; },
    sendRequestProductChangeModerationSuccess: (state) => { state.productChangeModerationRequestStatus = 'resolved'; },
    sendRequestProductChangeModerationError: (state) => { state.productChangeModerationRequestStatus = 'rejected'; },
    clearProductChangeModerationStatus: (state) => { state.productChangeModerationRequestStatus = null; },
    sendRequestProductsDeleteImage: (state) => { state.productDeleteImageRequestStatus = 'pending'; },
    sendRequestProductsDeleteImageSuccess: (state) => { state.productDeleteImageRequestStatus = 'resolved'; },
    sendRequestProductsDeleteImageError: (state) => { state.productDeleteImageRequestStatus = 'rejected'; },
    clearProductsDeleteImageStatus: (state) => { state.productDeleteImageRequestStatus = null; },
    //Drawer
    setIsShowAssortmentDrawer: (state, action) => {
      state.isShowAssortmentDrawer = action.payload;
    },
  },
});

export const {
  //Categories
  sendRequestCategoriesGet,
  sendRequestCategoriesGetSuccess,
  sendRequestCategoriesGetError,
  clearCategoriesGetStatus,
  setCategories,
  setCurrentKitchen,
  setCurrentCategory,
  sendRequestCategoriesSort,
  sendRequestCategoriesSortSuccess,
  sendRequestCategoriesSortError,
  clearCategoriesSortStatus,

  //Products
  sendRequestProductsGet,
  sendRequestProductsGetSuccess,
  sendRequestProductsGetError,
  clearProductsGetStatus,
  setProducts,
  sendRequestSearchProductsGet,
  sendRequestSearchProductsGetSuccess,
  sendRequestSearchProductsGetError,
  clearSearchProductsGetStatus,
  setSearchData,
  updateSearchDataProducts,
  setSearchProducts,
  setCurrentProductId,
  sendRequestProductsCreate,
  sendRequestProductsCreateSuccess,
  sendRequestProductsCreateError,
  clearProductsCreateStatus,
  sendRequestProductsUpdate,
  sendRequestProductsUpdateSuccess,
  sendRequestProductsUpdateError,
  clearProductsUpdateStatus,
  sendRequestProductsDelete,
  sendRequestProductsDeleteSuccess,
  sendRequestProductsDeleteError,
  clearProductsDeleteStatus,
  sendRequestProductChangeModeration,
  sendRequestProductChangeModerationSuccess,
  sendRequestProductChangeModerationError,
  clearProductChangeModerationStatus,
  sendRequestProductsDeleteImage,
  sendRequestProductsDeleteImageSuccess,
  sendRequestProductsDeleteImageError,
  clearProductsDeleteImageStatus,

  //Drawer
  setIsShowAssortmentDrawer,
} = kitchenAssortmentSlice.actions;

export default kitchenAssortmentSlice.reducer;
