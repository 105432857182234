import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setIsShowOrganizationDrawer, setCurrentOrganization } from '../../../../../redux/reducers/utils/settingsReducers/settingsCatalogsReducer';
import { theme, Flex, Table, Dropdown, Checkbox, Input, Button } from 'antd';
import { PlusOutlined, SettingOutlined } from '@ant-design/icons';

const { Search } = Input;

const OrganizationsTableComponent = ({ organizations }) => {
  const { currentOrganization } = useSelector((store) => store.settingsCatalogs);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const organizationsWithKey = organizations.map(item => ({...item, key: item.id}))

  const filteredOrganization = organizationsWithKey?.filter((item) =>
    item.name?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.desc?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.urName?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.inn?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.kpp?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.urAddres?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.factAddres?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.fio?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.email?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.phone?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.bank_name?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.bank_accAt?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.bank_corrAcc?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.bank_rcbic?.toString().toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Описание',
      dataIndex: 'desc',
      key: 'desc',
    },
    {
      title: 'Юридическая информация',
      key: 'info',
      children: [
        {
          title: 'Юр.Название',
          dataIndex: 'urName',
          key: 'urName',
        },
        {
          title: 'ИНН',
          dataIndex: 'inn',
          key: 'inn',
        },
        {
          title: 'КПП',
          dataIndex: 'kpp',
          key: 'kpp',
        },
        {
          title: 'Юр. Адрес',
          dataIndex: 'urAddres',
          key: 'urAddres',
        },
        {
          title: 'Факт. Адрес',
          dataIndex: 'factAddres',
          key: 'factAddres',
        },
        {
          title: 'ФИО',
          dataIndex: 'fio',
          key: 'fio',
        },
        {
          title: 'email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Телефон',
          dataIndex: 'phone',
          key: 'phone',
        },
      ],
    },
    {
      title: 'Банк',
      key: 'bank',
      children: [
        {
          title: 'Наименование',
          dataIndex: 'bank_name',
          key: 'bank_name',
        },
        {
          title: 'р/с',
          dataIndex: 'bank_accAt',
          key: 'bank_accAt',
        },
        {
          title: 'к/с',
          dataIndex: 'bank_corrAcc',
          key: 'bank_corrAcc',
        },
        {
          title: 'БИК',
          dataIndex: 'bank_rcbic',
          key: 'bank_rcbic',
        },
      ],
    },
    {
      title: 'Сущности',
      dataIndex: 'entities',
      key: 'entities',
    },
  ];

  const defaultCheckedList = columns.map((item) => item.key);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const options = columns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));
  const newColumns = columns.map((item) => ({
    ...item,
    hidden: !checkedList.includes(item.key),
  }));

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleRowClick = (record) => {
    dispatch(setCurrentOrganization(record));
    dispatch(setIsShowOrganizationDrawer(true));
  };

  const handleCheckChange = (checkedValues) => {
    setCheckedList(checkedValues);
  };

  const handleOpenCreateDrawer = () => {
    dispatch(setIsShowOrganizationDrawer(true));
  };

  return (
    <>
      <Flex gap={20} wrap='wrap' style={{ margin: '20px 0' }}>
        <Search
          placeholder="Поиск по названию или описанию"
          onChange={(e) => handleSearch(e.target.value)}
          style={{ maxWidth: '500px' }}
        />
        <CheckboxDropdown options={options} checkedList={checkedList} onCheckChange={handleCheckChange} />
        <Button type='primary' shape="round" icon={<PlusOutlined />} style={{marginLeft: 'auto'}} onClick={handleOpenCreateDrawer}>Добавить организацию</Button>
      </Flex>
      <Table
        dataSource={filteredOrganization}
        columns={newColumns}
        scroll={{ x: 'auto' }}
        bordered
        size="small"
        pagination={{
          defaultPageSize: 50,
        }}
        onRow={(record) => ({
          style: { cursor: 'pointer', background: record.key === currentOrganization?.key ? '#e6f7ff' : '' },
          onClick: () => handleRowClick(record),
        })}
      />
    </>
  );
};

export default OrganizationsTableComponent;

const CheckboxDropdown = ({ options, checkedList, onCheckChange }) => {
  const { useToken } = theme;
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  
  return (
    <Dropdown
      trigger={['click']}
      dropdownRender={() => (
        <div style={contentStyle}>
          <Checkbox.Group style={{display: 'flex', flexDirection: 'column', padding: '5px 10px'}} options={options} value={checkedList} onChange={onCheckChange} />
        </div>
      )}
    >
      <Button type='text'><SettingOutlined /> настроить таблицу</Button>
    </Dropdown>
  );
};
