import React, {useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrder, setMapAddressLocation } from '../../../../../redux/reducers/utils/deliveryReducers/deliveryMapReducer';
import styled from 'styled-components';
import { ReactComponent as IconStar } from '../../../../../assets/icons/icon-star.svg';
import { ReactComponent as IconUser } from '../../../../../assets/icons/icon-user.svg';
import { ReactComponent as IconCash } from '../../../../../assets/icons/icon-сash.svg';
import { ReactComponent as IconArrowForward } from '../../../../../assets/icons/icon-arrow-forward.svg';
import { ReactComponent as IconPlacemarkAppointed } from '../../../../../assets/icons/icon-order-status-appointed.svg';
import { ReactComponent as IconPlacemarkDelivered } from '../../../../../assets/icons/icon-order-status-complited.svg';
import { Flex, Tag } from 'antd';

const AddressCard = ({ point }) => {
  const {
    selectedOrder,
    selectedCourierToAppoint
  } = useSelector((store) => store.deliveryMap);
  const dispatch = useDispatch();
  const cardRef = useRef(null);

  useEffect(() => {
    if (selectedOrder && selectedOrder.addressId === point.addressId && cardRef.current) {
      cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [selectedOrder, point.addressId]);

  const handleOrderToggle = () => {
    dispatch(setMapAddressLocation(point.coordinates));
    dispatch(selectOrder(selectedOrder && selectedOrder.addressId === point.addressId ? null : point));
  };

  return (
    <StyledAddressCard ref={cardRef} onClick={handleOrderToggle} selected={!!selectedOrder && selectedOrder.addressId === point.addressId}>
      <StyledHeader>
        <StyledId>ID {point.addressId}</StyledId>
        <StyledNewUser>
          {point.isNewAddress && <><IconStar /> <StyledNewUserCount>{point.countUsersNeverOrdered}</StyledNewUserCount></>}
        </StyledNewUser>
        {point.orders &&
          <>
            <StyledOrders>
              <IconUser /> {point.orders.length}
            </StyledOrders>
            <StyledPayments>
              <IconCash /> {point.orders.reduce((total, order) => total + order.payment.finalPrice, 0)}
            </StyledPayments>
          </>
        }
      </StyledHeader>
      <StyledAddress>{point.address}</StyledAddress>
      <StyledName>{point.addressName}</StyledName>
      <Flex vertical>
        {point.meetingPoints && point.meetingPoints.length > 0 && point.locationMidpoints && <div>Мидпоинт: <Tag color='green'>{point.locationMidpoints.find(midpoint => midpoint.id === point.meetingPoints[0].meetingPointId)?.name}</Tag></div>}
        {point.entrances && point.entrances.length > 0 && point.locationEntrance && <div>Вход: <Tag color='green'>{point.locationEntrance.find(entrance => entrance.id === point.entrances[0].locationEntranceId)?.name}</Tag></div>}
      </Flex>
      <StyledStatus>
        {point.courierId && (
          <>
            <StyledCourier style={{ borderColor: point }}>{point.courierName}</StyledCourier>
            {selectedCourierToAppoint ? (
              <StyledAppoint>
                <IconPlacemarkAppointed style={{ stroke: point.courierColor }} />
                <IconArrowForward />
                <IconPlacemarkAppointed style={{ stroke: selectedCourierToAppoint.color }} />
              </StyledAppoint>
            ) : (
              <>
                {point.orders.every(order => order.status.id === 50) ?
                  <IconPlacemarkDelivered style={{ fill: point.courierColor }} />
                :
                  <IconPlacemarkAppointed style={{ stroke: point.courierColor }} />
                }
              </>
            )}
          </>
        )}
      </StyledStatus>
    </StyledAddressCard>
  );
};

export default AddressCard;

const StyledAddressCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: ${({ selected }) => (selected ? '#f1f1f1' : '#ffffff')};
  border: 1px solid #f1f1f1;
  padding: 10px;
  width: 100%;
  height: auto;
  font-size: 13px;
  transition: all .3s;
  cursor: pointer;
  &:hover {
    background: #f1f1f1;
  }
`;
const StyledHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;
const StyledId = styled.div`
  font-size: 20px;
`;
const StyledNewUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;
const StyledNewUserCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  background-color: #ffd954;
`;
const StyledOrders = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;
const StyledPayments = styled.div`
  display: flex;
  gap: 5px;
`;
const StyledAddress = styled.div`
  font-size: 13px;
  color: #3498DB;
`;
const StyledName = styled.div`
  font-size: 11px;
  color: #999999;
`;
const StyledStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledCourier = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  width: auto;
  height: 20px;
  font-size: 13px;
  border: 1px solid;
  border-radius: 20px;
`;
const StyledAppoint = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;