import { takeLatest } from 'redux-saga/effects';
import {
  handleGetNotifications,
  handlePushNotification,
} from './clientsNotificationsHandler';
import {
  sendRequestNotificationsGet,
  sendRequestNotificationsPush,
} from '../../../../reducers/utils/clientsReducers/clientsNotificationsReducer';

export function* clientsNotificationsWatcherSaga() {
  yield takeLatest(sendRequestNotificationsGet.type, handleGetNotifications);
  yield takeLatest(sendRequestNotificationsPush.type, handlePushNotification);
}