import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import combineReducer from './reducers/combineReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'common', 'nav'],
  timeout: 10000,
};

const persistedReducer = persistReducer(persistConfig, combineReducer);

export { persistedReducer };