import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setShow,
  updateFormFilterData,
  sendRequestForMapData,
  selectOrder,
  setMapAddressLocation,
  resetState,
  sendRequestForUpdateOrderComment,
} from '../../../../../redux/reducers/utils/deliveryReducers/deliveryMapReducer';
import {
  setCurrentOrdersPage,
} from '../../../../../redux/reducers/utils/clientsReducers/clientsSearchReducer';
import { Flex, Table, Input, Checkbox, Typography, Tag, Divider, ColorPicker, Button } from 'antd';
import Overlay from '../../../../../components/Overlay';
import DeliveryMapPage from '../../../../deliveryModule/pages/deliveryMapPage/DeliveryMapPage'
import { formatDate } from '../../../../../core/utils';
import OrderCardComponent from './OrderCardComponent';
import PrintTable from '../../../../../components/PrintTable';
const moment = require('moment');

const { Search } = Input;
const { Title, Text } = Typography;

const OrdersTableComponent = ({ onUpdateComment, onCreateComplaint }) => {
  const { clientOrders, totalOrders } = useSelector((store) => store.clientsSearch);
  const { mapDataRequestStatus, mapDataPoints } = useSelector((store) => store.deliveryMap);
  const dispatch = useDispatch();
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    total: 0,
  });
  
  const ordersWithKey = clientOrders.map((item, index) => ({...item, key: index}))

  const columns = [
    {
      title: '',
      key: 'orderGroupId',
      render: (record) => (
        <OrderCardComponent
          record={record}
          onUpdateComment={onUpdateComment}
          onCreateComplaint={onCreateComplaint}
        />
      )
    },
  ];

  const closeWindow = () => {
    setIsWindowOpen(false);
    dispatch(resetState());
  };

  useEffect(() => {
    if (mapDataRequestStatus === 'resolved' && currentOrder) {
      const currentPoint = mapDataPoints.find(point => point.addressId === currentOrder.addressId);
      dispatch(selectOrder(currentPoint));
      dispatch(updateFormFilterData({cityCoordinates: currentPoint.coordinates}));
    }
  }, [mapDataRequestStatus])

  useEffect(() => {
    setPagination({...pagination, total: totalOrders})
  }, [totalOrders]);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
    dispatch(setCurrentOrdersPage(pagination.current))
  };

  return (
    <>
      <PrintTable>
        <Table
          dataSource={ordersWithKey}
          columns={columns}
          bordered
          size="small"
          pagination={{
            ...pagination,
            showSizeChanger: false,
            showQuickJumper: true,
          }}
          onChange={handleTableChange}
        />
      </PrintTable>

      {isWindowOpen && (
        <Overlay onClose={closeWindow}>
          <DeliveryMapPage/>
        </Overlay>
      )}
    </>
  );
};

export default OrdersTableComponent;




























const Countdown = ({ record }) => {
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment();
      const timestamp = record.orderExpDateTimestamp;
      const timeZoneOffset = record.timeZone;
      const targetTime = moment.unix(timestamp).utcOffset(timeZoneOffset * 60);
      const diff = targetTime.diff(now);
      
      // Если время истекло, остановить счетчик
      if (diff <= 0) {
        setCountdown(null);
        clearInterval(interval);
      } else {
        const duration = moment.duration(diff);
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        setCountdown(`${hours}:${minutes}:${seconds}`);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [record]);

  return (
    <>
      {countdown ?
        <Tag color='green'>Можно отменить: {countdown}</Tag>
      :
        <Tag color='red'>Отменить нельзя</Tag>
      }
    </>
  );
};

const CommentComponent = ({ record, onUpdateComment }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [comment, setComment] = useState(record.comment);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleChange = (event) => {
    setComment(event.target.value);
  };

  const handleSave = () => {
    setIsEditing(false);
    onUpdateComment(record.orderId, comment); // Передаем обновленный комментарий обратно в родительский компонент
  };

  return (
    <div
      style={{width: '100%', minHeight: '35px', cursor: 'pointer'}}
      onClick={handleEdit}
    >
      {isEditing ? (
        <Input
          type="text"
          value={comment}
          onChange={handleChange}
          onBlur={handleSave}
          autoFocus
        />
      ) : (
        <div
          style={{padding: '4px 10px', background: '#ffffff', border: 'solid 1px #cccccc', borderRadius: '5px'}}
        >
          {comment}
        </div>
      )}
    </div>
  );
};

// import React, { useState, useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import {
//   setShow,
//   updateFormFilterData,
//   sendRequestForMapData,
//   selectOrder,
//   setMapAddressLocation,
//   resetState,
//   sendRequestForUpdateOrderComment,
// } from '../../../../../redux/reducers/utils/deliveryReducers/deliveryMapReducer';
// import {
//   setCurrentOrdersPage,
// } from '../../../../../redux/reducers/utils/clientsReducers/clientsSearchReducer';
// import { Flex, Table, Input, Checkbox, Typography, Tag, Divider, ColorPicker, Button } from 'antd';
// import Overlay from '../../../../../components/Overlay';
// import DeliveryMapPage from '../../../../deliveryModule/pages/deliveryMapPage/DeliveryMapPage'
// import { formatDate } from '../../../../../core/utils';
// const moment = require('moment');

// const { Search } = Input;
// const { Title, Text } = Typography;

// const OrdersTableComponent = () => {
//   const { clientOrders, totalOrders } = useSelector((store) => store.clientsSearch);
//   const { mapDataRequestStatus, mapDataPoints, selectedOrder, mapAddressLocation } = useSelector((store) => store.deliveryMap);
//   const dispatch = useDispatch();
//   const [isWindowOpen, setIsWindowOpen] = useState(false);
//   const [currentOrder, setCurrentOrder] = useState(null);
//   const [pagination, setPagination] = useState({
//     current: 1,
//     pageSize: 50,
//     total: 0,
//   });
  
//   const ordersWithKey = clientOrders.map((item, index) => ({...item, key: index}))

//   const columns = [
//     {
//       title: 'Номер группы заказов',
//       key: 'orderGroupId',
//       render: (record) => (
//         <div>  
//           <div>ID: {record.orderGroupId}</div>
//           <div>{formatDate(record.deliveryDay)}</div>
//         </div>
//       )
//     },
//     {
//       title: 'Информация о рейсе',
//       key: 'delivery',
//       render: ((record) => (
//         <div>СД: {record.deliveryServiceName}</div>
//       ))
//     },
//     {
//       title: 'Заказы',
//       key: 'Orders',
//       render: (record) => {
//         const orders = record.orders.map(order => order)

//         return (
//           <>
//             {orders.map(order => (
//               <Tag key={order.orderId}>{order.orderId}</Tag>
//             ))}
//           </>
//         )
//       }
//     },
//     {
//       title: 'Оплата',
//       key: 'payment',
//       render: (record) => {
//         const transactions = record.orderTransactions.map(transaction => transaction)

//         return (
//           <>
//             {transactions.map(transaction => (
//               <div style={{fontSize: '0.8em'}}>
//                 <div>{formatDate(transaction.created)}</div>
//                 <div>Сумма: {transaction.amount}р.</div>
//                 <div>Статус: <Tag color={transaction.paymentStatus === 2 ? 'green' : 'red'}>{transaction.paymentStatusName}</Tag></div>
//                 <div>Оплачен: "{transaction.paySystemName}"</div>
//                 <Divider style={{margin: '0 0 15px 0'}}/>
//               </div>
//             ))}
//             <Text strong>Итого: {record.finalPrice}р.</Text>
//           </>
//         )
//       }
//     },
//   ];

//   const subColumns = [
//     {
//       title: 'Номер заказа',
//       key: 'orderId',
//       render: (record) => (
//         <div>
//           <div>ID: {record.orderId}</div>
//           <div>Дата создания: {formatDate(record.created)}</div>
//           <Countdown record={record}/>
//         </div>
//       )
//     },
//     {
//       title: 'Кухня',
//       dataIndex: 'kitchenName',
//       key: 'kitchenName',
//     },
//     {
//       title: 'Адрес заказа',
//       key: 'address',
//       render: (record) => (
//         <div>
//           {record.addressId && <Text>ID: {record.addressId} </Text>} 
//           {record.citiName && <Text>г.{record.citiName}, </Text>} 
//           {record.street && <Text>ул.{record.street}, </Text>} 
//           {record.house && <Text>д.{record.house}, </Text>} 
//           {record.flat && <Text>кв.{record.flat}, </Text>} 
//           {record.floor && <Text>э.{record.floor}, </Text>} 
//           {record.entrance && <Text>вх.{record.entrance}, </Text>} 
//           {record.structure && <Text>корп.{record.structure}, </Text>} 
//         </div>
//       ),
//       onCell: (record) => ({
//         onClick: () => handleCellClick(record),
//         style: {cursor: 'pointer'}
//       })
//     },
//     {
//       title: 'Курьер заказа',
//       key: 'courier',
//       render: (record) => (
//         <Flex align='center'>
//           <ColorPicker size='small' value={record.courierColor} disabled style={{marginRight: '10px', cursor: 'pointer'}}/>
//           <Tag>{record.courierPrefix}</Tag>
//           <Text>{record.courierName}</Text>
//         </Flex>
//       )
//     },
//     {
//       title: 'Комментарий',
//       key: 'comment',
//       render: (record) => (
//         <>
//           <CommentComponent record={record} onUpdateComment={onUpdateComment}/>
//         </>
//       )
//     },
//     {
//       title: 'Состав заказа',
//       key: 'menu',
//       render: (record) => (
//         <Flex vertical>
//           {record.orderPoducts.map(item => (
//             <Flex>
//               {item.name}
//             </Flex>
//           ))}
//         </Flex>
//       )
//     },
//   ]

//   const handleCellClick = (record) => {
//     console.log('Clicked row:', record);
//     const requestData = {
//       token: localStorage.getItem('token'),
//       raceId: record.raceId,
//       racePolygonIds: [record.racePolygonId],
//       day: record.deliveryDay,
//     };

//     setIsWindowOpen(true);
//     setCurrentOrder(record);

//     dispatch(updateFormFilterData({
//       city: record.city,
//       delivery: null,
//       timePeriod: record.raceId,
//       date: record.deliveryDay,
//       polygons: [record.racePolygonId],
//     }));
//     dispatch(sendRequestForMapData(requestData));
//   };

//   const onUpdateComment = (orderId, comment) => {
//     const requestData = {
//       token: localStorage.getItem('token'),
//       orderId: orderId,
//       comment: comment || '',
//     }

//     dispatch(sendRequestForUpdateOrderComment(requestData));
//   }

//   const closeWindow = () => {
//     setIsWindowOpen(false);
//     dispatch(resetState());
//   };

//   useEffect(() => {
//     if (mapDataRequestStatus === 'resolved' && currentOrder) {
//       const currentPoint = mapDataPoints.find(point => point.addressId === currentOrder.addressId);
//       dispatch(selectOrder(currentPoint));
//       dispatch(updateFormFilterData({cityCoordinates: currentPoint.coordinates}));
//     }
//   }, [mapDataRequestStatus])

//   useEffect(() => {
//     setPagination({...pagination, total: totalOrders})
//   }, [totalOrders]);

//   const handleTableChange = (pagination) => {
//     setPagination(pagination);
//     dispatch(setCurrentOrdersPage(pagination.current))
//   };

//   return (
//     <>
//       <Table
//         dataSource={ordersWithKey}
//         columns={columns}
//         bordered
//         size="small"
//         pagination={{
//           ...pagination,
//           showSizeChanger: false,
//           showQuickJumper: true,
//         }}
//         onChange={handleTableChange}
//         expandable={{
//           expandedRowRender: (record) => {
//             const modifyOrders = record.orders.map(item => ({...item, timeZone: record.timeZone}))

//             return (
//               <Table
//                 dataSource={modifyOrders}
//                 columns={subColumns}
//                 bordered
//                 size="small"
//                 pagination={false}
//               />
//             )
//           },
//           rowExpandable: (record) => record.orderGroupId !== 0,
//         }}
//       />

//       {isWindowOpen && (
//         <Overlay onClose={closeWindow}>
//           <DeliveryMapPage/>
//         </Overlay>
//       )}
//     </>
//   );
// };

// export default OrdersTableComponent;

// const Countdown = ({ record }) => {
//   const [countdown, setCountdown] = useState('');

//   useEffect(() => {
//     const interval = setInterval(() => {
//       const now = moment();
//       const timestamp = record.orderExpDateTimestamp;
//       const timeZoneOffset = record.timeZone;
//       const targetTime = moment.unix(timestamp).utcOffset(timeZoneOffset * 60);
//       const diff = targetTime.diff(now);
      
//       // Если время истекло, остановить счетчик
//       if (diff <= 0) {
//         setCountdown(null);
//         clearInterval(interval);
//       } else {
//         const duration = moment.duration(diff);
//         const hours = duration.hours();
//         const minutes = duration.minutes();
//         const seconds = duration.seconds();
//         setCountdown(`${hours}:${minutes}:${seconds}`);
//       }
//     }, 1000);

//     return () => clearInterval(interval);
//   }, [record]);

//   return (
//     <>
//       {countdown ?
//         <Tag color='green'>Можно отменить: {countdown}</Tag>
//       :
//         <Tag color='red'>Отменить нельзя</Tag>
//       }
//     </>
//   );
// };

// const CommentComponent = ({ record, onUpdateComment }) => {
//   const [isEditing, setIsEditing] = useState(false);
//   const [comment, setComment] = useState(record.comment);

//   const handleEdit = () => {
//     setIsEditing(true);
//   };

//   const handleChange = (event) => {
//     setComment(event.target.value);
//   };

//   const handleSave = () => {
//     setIsEditing(false);
//     onUpdateComment(record.orderId, comment); // Передаем обновленный комментарий обратно в родительский компонент
//   };

//   return (
//     <div
//       style={{width: '100%', minHeight: '35px', cursor: 'pointer'}}
//       onClick={handleEdit}
//     >
//       {isEditing ? (
//         <Input
//           type="text"
//           value={comment}
//           onChange={handleChange}
//           onBlur={handleSave}
//           autoFocus
//         />
//       ) : (
//         <div
//           style={{padding: '4px 10px', background: '#ffffff', border: 'solid 1px #cccccc', borderRadius: '5px'}}
//         >
//           {comment}
//         </div>
//       )}
//     </div>
//   );
// };
