import { takeLatest } from 'redux-saga/effects';
import {
  handleGetKitchenActWorks,
  handleCreateKitchenActWorks,
  handleUpdateKitchenActWorks,
  handleDeleteKitchenActWorks,
  handleSignedStaffSOActWorks,
  handleSignedStaffKitchenActWorks,
  handleSetStatusPaymentKitchenActWorks,
} from './kitchenFinanceHandler';
import {
  sendRequestKitchenActWorksGet,
  sendRequestKitchenActWorksCreate,
  sendRequestKitchenActWorksUpdate,
  sendRequestKitchenActWorksDelete,
  sendRequestKitchenActWorksSignedStaffSO,
  sendRequestKitchenActWorksSignedStaffKitchen,
  sendRequestKitchenActWorksSetStatusPaymentKitchen,
} from '../../../../reducers/utils/kitchenReducers/kitchenFinanceReducer';

export function* kitchenFinanceWatcherSaga() {
  yield takeLatest(sendRequestKitchenActWorksGet.type, handleGetKitchenActWorks);
  yield takeLatest(sendRequestKitchenActWorksCreate.type, handleCreateKitchenActWorks);
  yield takeLatest(sendRequestKitchenActWorksUpdate.type, handleUpdateKitchenActWorks);
  yield takeLatest(sendRequestKitchenActWorksDelete.type, handleDeleteKitchenActWorks);
  yield takeLatest(sendRequestKitchenActWorksSignedStaffSO.type, handleSignedStaffSOActWorks);
  yield takeLatest(sendRequestKitchenActWorksSignedStaffKitchen.type, handleSignedStaffKitchenActWorks);
  yield takeLatest(sendRequestKitchenActWorksSetStatusPaymentKitchen.type, handleSetStatusPaymentKitchenActWorks);
}