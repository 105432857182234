import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  setIsShowAssortmentDrawer,
  setCurrentProductId,
  sendRequestProductsCreate,
  sendRequestProductsUpdate,
  sendRequestProductsDelete,
  sendRequestProductChangeModeration,
} from '../../../../../redux/reducers/utils/kitchenReducers/kitchenAssortmentReducer';
import { checkAccess } from '../../../../../core/utils'
import { Flex, Drawer, Tabs, Typography, Popconfirm, Button, Form, Input, InputNumber, Checkbox, Switch, Radio, Divider } from 'antd';
import ImageUploadAndCrop from "./ImageUploadAndCrop";
import styled from 'styled-components';

const { TabPane } = Tabs;
const { Title, Text } = Typography;
const { TextArea } = Input;

const AssortmentDrawerComponent = ({ product, modifierGroups }) => {
  const { accessComponentsForUser } = useSelector((store) => store.services);
  const {
    isShowAssortmentDrawer,
    currentKitchen,
    currentCategory,
  } = useSelector((store) => store.kitchenAssortment);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [modifiers, setModifiers] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (product) {
      setIsFormChanged(false);
      setModifiers(product.modifiersGroup);

      let productType = ''
      if (product.isProduct && product.isModifier) {
        productType = 'isAll';
      } else if (product.isProduct) {
        productType = 'isProduct';
      } else if (product.isModifier) {
        productType = 'isModifier';
      }

      form.setFieldsValue({...product, productType});
    } else {
      setModifiers(modifierGroups);
      form.resetFields();
    }
  }, [product, modifierGroups, form]);

  const handleCreateProduct = () => {
    form.validateFields()
      .then((values) => {
        const requestData = {
          token: localStorage.getItem('token'),
          kitchenId: currentKitchen,
          categoryId: currentCategory,
          image: values.image,
          name: values.name,
          description: values.description,
          price: values.price === null ? 0 : values.price,
          weight: values.weight === null ? 0 : values.weight,
          calories: values.calories === null ? 0 : values.calories,
          proteins: values.proteins === null ? 0 : values.proteins,
          fats: values.fats === null ? 0 : values.fats,
          carbs: values.carbs === null ? 0 : values.carbs,
          isProduct: (values.productType === 'isProduct' || values.productType === 'isAll') ? true : false,
          isModifier: (values.productType === 'isModifier' || values.productType === 'isAll') ? true : false,
          alwaysPublish: values.alwaysPublish,
          modifiersGroup: modifiers,
          isEnabled: true,
        }

        console.log(requestData);
        dispatch(sendRequestProductsCreate(requestData));
      })
      .catch((errorInfo) => {
        console.error('Ошибка при валидации формы:', errorInfo);
      });
  };

  const handleUpdateProduct = () => {
    form.validateFields()
      .then((values) => {
        const requestData = {
          token: localStorage.getItem('token'),
          productId: product.id,
          kitchenId: product.kitchenId,
          categoryId: currentCategory,
          image: values.image,
          name: values.name,
          description: values.description,
          price: values.price === null ? 0 : values.price,
          weight: values.weight === null ? 0 : values.weight,
          calories: values.calories === null ? 0 : values.calories,
          proteins: values.proteins === null ? 0 : values.proteins,
          fats: values.fats === null ? 0 : values.fats,
          carbs: values.carbs === null ? 0 : values.carbs,
          isEnabled: values.isEnabled,
          isProduct: (values.productType === 'isProduct' || values.productType === 'isAll') ? true : false,
          isModifier: (values.productType === 'isModifier' || values.productType === 'isAll') ? true : false,
          alwaysPublish: values.alwaysPublish,
          modifiersGroup: modifiers,
        }

        console.log(requestData);
        dispatch(sendRequestProductsUpdate(requestData));
      })
      .catch((errorInfo) => {
        console.error('Ошибка при валидации формы:', errorInfo);
      });
  };

  const handleDeleteProduct = () => {
    dispatch(sendRequestProductsDelete({ token: localStorage.getItem('token'), productId: product.id }));
    handleCloseDrawer();
  }

  const handleChangeModeration = (checked) => {
    const requestData = {
      token: localStorage.getItem('token'),
      productId: product.id,
      enabledByAdmin: checked,
    }

    console.log(requestData);
    dispatch(sendRequestProductChangeModeration(requestData));
  }
  
  const handleCloseDrawer = () => {
    form.resetFields();
    setIsFormChanged(false);
    dispatch(setIsShowAssortmentDrawer(false));
    dispatch(setCurrentProductId(null));
  };

  const handleFormChange = () => {
    setIsFormChanged(true);
  };

  const onFileUpload = (file) => {
    form.setFieldsValue({ image: file });
    handleFormChange();
  };

  const onSwitchChange = (id, checked) => {
    setModifiers(prevModifiers => {
      return prevModifiers.map(modifier => {
        if (modifier.id === id) {
          return { ...modifier, isEnabled: checked };
        } else {
          return modifier;
        }
      });
    });
    handleFormChange();
  };

  return (
    <Drawer
      title={
        <>
          {product ?
            <Flex gap={20}>
              { isFormChanged &&
                <>
                  <Button type="primary" onClick={handleUpdateProduct}>Сохранить</Button>
                  <Button onClick={handleCloseDrawer}>Отменить</Button>
                </>
              }
              <Popconfirm
                title="Удаление продукта!"
                description={`Вы действительно хотите удалить продукт ${product.name}`}
                okText="Да"
                cancelText="Нет"
                placement="left"
                onConfirm={handleDeleteProduct}
              >
                <Button danger style={{marginLeft: 'auto'}}>Удалить продукт</Button>
              </Popconfirm>
            </Flex>
            :
            <Button type="primary" onClick={handleCreateProduct}>Создать</Button>
          }
        </>
      }
      placement="right"
      onClose={handleCloseDrawer}
      open={isShowAssortmentDrawer}
      width='500px'
      mask={false}
    >
      <Tabs defaultActiveKey="1" tabPosition="top">
        <TabPane tab="Основная" key="1">
          <Form
            form={form}
            layout='vertical'
            onValuesChange={handleFormChange}
          >
            <Flex gap={15} justify='space-between'>
              <StyledFormItem label="Изображение" name="image"><ImageUploadAndCrop productImage={product?.big_filePathPublic} productId={product?.id} onFileUpload={onFileUpload}/></StyledFormItem>
              <Flex gap={15} vertical>
                {checkAccess(accessComponentsForUser, 'moderation') && product && <StyledFormItem label="Проверен"><Switch checked={product?.enabledByAdmin} onChange={(checked) => handleChangeModeration(checked)}/></StyledFormItem>}
                <StyledFormItem inline label="Доступен в меню" name="isEnabled"><Switch/></StyledFormItem>
              </Flex>
            </Flex>
            <StyledFormItem label="Название" name="name" rules={[{ required: true, message: 'Обязательное поле!' }]}><Input placeholder='Название' ref={inputRef}/></StyledFormItem>
            <StyledFormItem label="Описание" name="description"><TextArea rows={2} placeholder='Описание'/></StyledFormItem>
            <Flex gap={15}>
              <StyledFormItem label="Цена, руб" name="price"><InputNumber placeholder='Цена'/></StyledFormItem>
              <StyledFormItem label="Вес, грамм" name="weight"><InputNumber placeholder='Вес'/></StyledFormItem>
            </Flex>
            <Flex gap={15}>
              <StyledFormItem label="К" name="calories"><InputNumber placeholder='Калории'/></StyledFormItem>
              <StyledFormItem label="Б" name="proteins"><InputNumber placeholder='Белки'/></StyledFormItem>
              <StyledFormItem label="Ж" name="fats"><InputNumber placeholder='Жиры'/></StyledFormItem>
              <StyledFormItem label="У" name="carbs"><InputNumber placeholder='Углеводы'/></StyledFormItem>
            </Flex>
            <StyledFormItem name="productType" label="Тип продукта" initialValue="isProduct" style={{marginTop: '20px'}}>
              <Radio.Group defaultValue="isProduct" style={{display: 'flex', flexDirection: 'column'}}>
                <Radio value="isProduct">Самостоятельное блюдо в меню</Radio>
                <Radio value="isModifier">Модификатор</Radio>
                <Radio value="isAll">И самостоятельное блюдо в меню и модификатор</Radio>
              </Radio.Group>
            </StyledFormItem>
            <StyledFormItem name="alwaysPublish" valuePropName="checked" initialValue={false} style={{marginTop: '20px'}}>
              <Checkbox>Авто-публикация</Checkbox>
            </StyledFormItem>
          </Form>
          <Divider/>
          <Title level={3}>Группы модификаторов</Title>
          <Flex vertical gap={'small'}>
          {modifiers && modifiers.map((modifier) => (
            <Flex gap={'small'} align="center">
              <Switch onChange={(checked) => onSwitchChange(modifier.id, checked)} checked={modifier.isEnabled}/>
              <span>{modifier.name}</span>
              <span>{modifier.public_name}</span>
            </Flex>
          ))}
          </Flex>

        </TabPane>
        <TabPane tab="Дополнительно" key="2">
          <Title level={3}>Дополнительно</Title>
        </TabPane>
        <TabPane tab="Состав" key="3">
          <Title level={3}>Состав</Title>
        </TabPane>
        <TabPane tab="Статистика" key="4">
          <Title level={3}>Статистика</Title>
        </TabPane>
      </Tabs>
    </Drawer>
  );
}

export default AssortmentDrawerComponent;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 5px;

  & .ant-form-item-label {
    padding: 0;
  }
`;