import React, { useState } from 'react';
import { Modal, Button, Form, DatePicker } from 'antd';

const KitchenActWorksCreateModalComponent = ({ onSubmit }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        form.resetFields();
        setIsModalVisible(false);
        onSubmit(values);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Сформировать отчет
      </Button>
      <Modal
        title="Сформировать отчет"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical" name="report_form">
          <Form.Item
            name="dateFrom"
            label="С какой даты сформировать отчет"
            rules={[{ required: true, message: 'Пожалуйста, выберите дату начала!' }]}
          >
            <DatePicker style={{ width: '100%' }} format="DD-MM-YYYY"/>
          </Form.Item>
          <Form.Item
            name="dateTo"
            label="По какую дату сформировать отчет"
            rules={[{ required: true, message: 'Пожалуйста, выберите дату конца!' }]}
          >
            <DatePicker style={{ width: '100%' }} format="DD-MM-YYYY"/>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default KitchenActWorksCreateModalComponent;