import { takeLatest } from 'redux-saga/effects';
import {
  //Kitchen Main Settings
  handleRenameKitchen,
  handleCreateKitchenPlashka,
  handleDeleteKitchenPlashka,
  handleChangeCityKitchen,
  handleGetAllKitchenStatuses,
  handleChangeStatusKitchen,

  //Kitchen Settings Not Working
  handleKitchenNotWorkingGet,
  handleKitchenNotWorkingCreate,
  handleKitchenNotWorkingUpdate,
  handleKitchenNotWorkingDelete,

  //Kitchen Settings Access
  handleGetKitchenSettingsAccess,
  handleSetKitchenSettingsAccess,
  handleDeleteKitchenSettingsAccess,

  //Kitchen Contracts
  handleGetKitchenContracts,
  handleCreateKitchenContracts,
  handleUpdateKitchenContracts,
  handleDeleteKitchenContracts,

  //Kitchen Contract Commissions
  handleGetKitchenContractCommissions,
  handleCreateKitchenContractCommissions,
  handleUpdateKitchenContractCommissions,
  handleDeleteKitchenContractCommissions,
} from './kitchenSettingsHandler';
import {
  //Kitchen Main Settings
  sendRequestRenameKitchen,
  sendRequestCreateKitchenPlashka,
  sendRequestDeleteKitchenPlashka,
  sendRequestChangeCityKitchen,
  sendRequestGetAllKitchenStatuses,
  sendRequestChangeStatusKitchen,

  //Kitchen Settings Not Working
  sendRequestKitchenNotWorkingGet,
  sendRequestKitchenNotWorkingCreate,
  sendRequestKitchenNotWorkingUpdate,
  sendRequestKitchenNotWorkingDelete,

  //Kitchen Settings Access
  sendRequestkitchenSettingsAccessGet,
  sendRequestkitchenSettingsAccessSet,
  sendRequestkitchenSettingsAccessDelete,

  //Kitchen Contracts
  sendRequestKitchenContractsGet,
  sendRequestKitchenContractsCreate,
  sendRequestKitchenContractsUpdate,
  sendRequestKitchenContractsDelete,

  //Kitchen Contract Commissions
  sendRequestKitchenContractCommissionsGet,
  sendRequestKitchenContractCommissionsCreate,
  sendRequestKitchenContractCommissionsUpdate,
  sendRequestKitchenContractCommissionsDelete,
} from '../../../../reducers/utils/kitchenReducers/kitchenSettingsReducer';

export function* kitchenSettingsWatcherSaga() {
  //Kitchen Main Settings
  yield takeLatest(sendRequestRenameKitchen.type, handleRenameKitchen);
  yield takeLatest(sendRequestCreateKitchenPlashka.type, handleCreateKitchenPlashka);
  yield takeLatest(sendRequestDeleteKitchenPlashka.type, handleDeleteKitchenPlashka);
  yield takeLatest(sendRequestChangeCityKitchen.type, handleChangeCityKitchen);
  yield takeLatest(sendRequestGetAllKitchenStatuses.type, handleGetAllKitchenStatuses);
  yield takeLatest(sendRequestChangeStatusKitchen.type, handleChangeStatusKitchen);

  //Kitchen Settings Not Working
  yield takeLatest(sendRequestKitchenNotWorkingGet.type, handleKitchenNotWorkingGet);
  yield takeLatest(sendRequestKitchenNotWorkingCreate.type, handleKitchenNotWorkingCreate);
  yield takeLatest(sendRequestKitchenNotWorkingUpdate.type, handleKitchenNotWorkingUpdate);
  yield takeLatest(sendRequestKitchenNotWorkingDelete.type, handleKitchenNotWorkingDelete);

  //Kitchen Settings Access
  yield takeLatest(sendRequestkitchenSettingsAccessGet.type, handleGetKitchenSettingsAccess);
  yield takeLatest(sendRequestkitchenSettingsAccessSet.type, handleSetKitchenSettingsAccess);
  yield takeLatest(sendRequestkitchenSettingsAccessDelete.type, handleDeleteKitchenSettingsAccess);

  //Kitchen Contracts
  yield takeLatest(sendRequestKitchenContractsGet.type, handleGetKitchenContracts);
  yield takeLatest(sendRequestKitchenContractsCreate.type, handleCreateKitchenContracts);
  yield takeLatest(sendRequestKitchenContractsUpdate.type, handleUpdateKitchenContracts);
  yield takeLatest(sendRequestKitchenContractsDelete.type, handleDeleteKitchenContracts);

  //Kitchen Contract Commissions
  yield takeLatest(sendRequestKitchenContractCommissionsGet.type, handleGetKitchenContractCommissions);
  yield takeLatest(sendRequestKitchenContractCommissionsCreate.type, handleCreateKitchenContractCommissions);
  yield takeLatest(sendRequestKitchenContractCommissionsUpdate.type, handleUpdateKitchenContractCommissions);
  yield takeLatest(sendRequestKitchenContractCommissionsDelete.type, handleDeleteKitchenContractCommissions);
}