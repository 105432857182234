import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentRoute } from '../../../redux/reducers/utils/navReducer';
import { Typography, Flex, Button } from 'antd';
import KitchenSelectorComponent from './KitchenSelectorComponent';

const { Title, Text } = Typography;

const KitchenNOTselectedComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    dispatch(setCurrentRoute('main'));
    navigate('/main');
  }

  return (
    <Flex
      style={{height: '100%'}}
      vertical
      justify='center'
      align='center'
    >
      <Title style={{marginBottom: '20px'}}>Вы не выбрали кухню!</Title>
      <Text style={{margin: '10px 0', textAlign: 'center'}}>Выберите кухню из списка</Text>
      <KitchenSelectorComponent/>
      <Text style={{margin: '10px 0', textAlign: 'center'}}>или вернитесь</Text>
      <Button type='primary' size='large' onClick={handleButtonClick}>На главную</Button>
    </Flex>
  );
};

export default KitchenNOTselectedComponent;
