import React, { useState } from 'react';
import { Flex, Upload, Image, Button } from 'antd';
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import styled from 'styled-components';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const ImageUploadAndCrop = ({ isAdmin, onFileUpload }) => {
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState('');

  // const onChange = ({ fileList: newFileList }) => {
  //   setFileList(newFileList);

  //   if (newFileList.length > 0 && newFileList.length !== fileList.length) {
  //     const newFile = newFileList[0];
  //     if (newFile && !newFile.preview) {
  //       getBase64(newFile.originFileObj).then((imageUrl) => {
  //         setPreviewImage(imageUrl);
  //       });
  //     }
  //   }

  //   if (newFileList.length > fileList.length) {
  //     // onFileUpload(newFileList[newFileList.length - 1].originFileObj);
  //   }
  // };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    if (newFileList.length > 0) {
      console.log('отправка')
      // onFileUpload(newFileList[0].originFileObj);
      const file = newFileList[0];
      if (file && !file.preview) {
        console.log('превью' , newFileList)
        getBase64(file.originFileObj).then((imageUrl) => {
          setPreviewImage(imageUrl);
        });
      }
    }
  };

  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
  };

  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess(null, file);
    }, 0);
  };


  const handleSave = () => {
    console.log('Сохранить');
    onFileUpload(fileList[0].originFileObj);
  };

  const handleDelete = () => {
    console.log('Удалить');
    setFileList([])
    setPreviewImage('')
  };

  return (
    <StyledWrapper>
      {previewImage ? 
        <div style={{position: 'relative', maxWidth: '500px', borderRadius: '25px', overflow: 'hidden'}}>
          <Image
            alt="example"
            src={previewImage}
            mask='false'
            preview={{
              toolbarRender: (_,{}) => (<Flex size={12} className="toolbar-wrapper"></Flex>),
            }}
          />
          <Button style={{ position: 'absolute', bottom: '10px', right: '10px' }} type="primary" icon={<SaveOutlined />} onClick={handleSave} />
          <Button style={{ position: 'absolute', top: '10px', right: '10px' }} type="default" icon={<DeleteOutlined />} onClick={handleDelete} />
        </div>
      :
        <ImgCrop rotationSlider aspect={ 2 / 1 }>
          <Upload.Dragger
            style={{ maxWidth: '500px', flex: '1', minHeight: '250px', borderRadius: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            customRequest={customRequest}
            accept="image/*"
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
            disabled={!isAdmin}
          >
            {fileList.length < 1 && '+ Upload'}
          </Upload.Dragger>
        </ImgCrop>
      }
    </StyledWrapper>
  );
};

export default ImageUploadAndCrop;

const StyledWrapper = styled.div`
  margin-bottom: 20px;
  width: 100%;
  & .ant-upload-wrapper {
    display: flex !important;
  }
`