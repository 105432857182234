import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCurrentDeliveryServiceRace,
  sendRequestDeliveryServiceRacesUpdate,
  sendRequestDeliveryServiceRacesDelete,
} from '../../../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Flex, Modal, Select, Table, Form, TimePicker, Popconfirm, Input, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import RaceCreateModalComponent from './RaceCreateModalComponent';
import PrintTable from '../../../../../../../components/PrintTable';

const { Search } = Input;

const RacesTableComponent = () => {
  const navigate = useNavigate();
  const { deliveryServiceRaces } = useSelector((store) => store.deliveryServices);
  const { cities } = useSelector((store) => store.settingsCatalogs);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const modifyCurrentRaces = deliveryServiceRaces?.map(item => {  
    const city = cities?.find(city => city.id === item.cityId);
    const cityName = city ? city?.name : '';
  
    return { ...item, key: item.id, cityName: cityName };
  });

  const filteredCurrentRaces = modifyCurrentRaces?.filter((item) =>
    item.cityId?.toString().toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleCellClick = (record) => {
    dispatch(setCurrentDeliveryServiceRace(record));
    navigate(`races/${record.id}`);
  };

  const columns = [
    {
      title: 'Город',
      dataIndex: 'cityName',
      key: 'cityName',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Начало\u00A0доставки',
      dataIndex: 'timeStart',
      key: 'timeStart',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Время\u00A0доставки',
      dataIndex: 'timeDelivery',
      key: 'timeDelivery',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Интервал',
      key: 'CourierCutoff',
      render: (_, record) => (
        <div>{record.initialCourierCutoff} - {record.finalCourierCutoff}</div>
      ),
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: '',
      key: 'actions',
      render: (_, record) => (
        <AccessAction
          deliveryServiceRace={record}
        />
      ),
    },
  ];

  return (
    <>
      <Flex gap={20} wrap='wrap' style={{ margin: '20px 0' }}>
        <Search
          placeholder="Поиск по названию или описанию"
          onChange={(e) => handleSearch(e.target.value)}
          style={{ maxWidth: '500px' }}
        />
        <RaceCreateModalComponent/>
      </Flex>
      <PrintTable>
        <Table
          dataSource={filteredCurrentRaces}
          columns={columns}
          size="small"
          pagination={{
            defaultPageSize: 50,
          }}
        />
      </PrintTable>
    </>
  );
};

export default RacesTableComponent;

const AccessAction = ({deliveryServiceRace}) => {
  const { cities } = useSelector((store) => store.settingsCatalogs);
  const { currentDeliveryService } = useSelector((store) => store.deliveryServices);
  const dispatch = useDispatch();
  const [ isCreate, setIsCreate] = useState(false);
  const [form] = Form.useForm();

  dayjs.extend(customParseFormat);
  const timeFormat = 'HH:mm';

  const handleOpen = () => {
    setIsCreate(true);
  };

  const handleCancel = () => {
    setIsCreate(false);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      raceId: deliveryServiceRace.id,
      cityId: values.city,
      deliveryServiceId: currentDeliveryService.id,
      timeStart: moment(new Date(values.timeStart)).format('HH:mm'),
      timeDelivery: values.timeDelivery,
      initialCourierCutoff: moment(new Date(values.initialCourierCutoff)).format('HH:mm'),
      finalCourierCutoff: moment(new Date(values.finalCourierCutoff)).format('HH:mm'),
    }

    dispatch(sendRequestDeliveryServiceRacesUpdate(requestData));
    setIsCreate(false);
    form.resetFields();
  }

  const cityOptions = cities?.map((item) => ({
    ...item,
    value: item?.id,
    label: item?.name,
  }));

  const handleDelete = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      raceId: deliveryServiceRace.id,
    }

    dispatch(sendRequestDeliveryServiceRacesDelete(requestData));
  };

  return (
    <Action>
      <Button
        icon={<EditOutlined />}
        onClick={handleOpen}
      >
      </Button>
      <Modal
        title="Редактирование службы доставки"
        open={isCreate}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          style={{ width: '100%' }}
          form={form}
          name='deliveryService'
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={{
            city: {value: deliveryServiceRace.cityId, label: cities?.find(city => city.id.toString() === deliveryServiceRace.cityId.toString())?.name}.value,
            timeStart: dayjs(deliveryServiceRace.timeStart, timeFormat),
            timeDelivery: deliveryServiceRace.timeDelivery,
            initialCourierCutoff: dayjs(deliveryServiceRace.initialCourierCutoff, timeFormat),
            finalCourierCutoff: dayjs(deliveryServiceRace.finalCourierCutoff, timeFormat),
          }}
        >
          <Form.Item label='Город' name='city' rules={[{ required: true, message: 'Выберите город' }]}>
            <Select
              showSearch
              placeholder="Выберите город"
              optionFilterProp="children"
              options={cityOptions}
              filterOption={(input, option) =>
                (option?.name?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Flex gap='large'>
            <Form.Item label="Начало доставки" name="timeStart" rules={[{ required: true, message: 'Выберите время' }]}>
              <TimePicker format="HH:mm"/>
            </Form.Item>
            <Form.Item label="Период доставки" name="timeDelivery" rules={[{ required: true, message: 'Укажите период' }]}>
              <Input placeholder='60'/>
            </Form.Item>
          </Flex>
          <Flex gap='large'>
            <Form.Item label="Крайнее абсолютное время начала работы курьера" name="initialCourierCutoff">
              <TimePicker format="HH:mm"/>
            </Form.Item>
            <Form.Item label="Крайнее абсолютное время завершения работы курьера" name="finalCourierCutoff">
              <TimePicker format="HH:mm"/>
            </Form.Item>
          </Flex>
          <Button type='primary' htmlType='submit' style={{ display: 'flex', marginLeft: 'auto' }}>Сохранить</Button>
        </Form>
      </Modal>
      <Popconfirm
        title="Удаление службы доставки!"
        description={`Вы действительно хотите удалить рейс?`}
        okText="Да"
        cancelText="Нет"
        placement="left"
        onConfirm={handleDelete}
      >
        <Button danger>Удалить</Button>
      </Popconfirm>
    </Action>
  );
};
const Action = styled.div`
  position: relative;
  z-index: 600;
  display: flex;
  gap: 5px;
  align-items: center;
`