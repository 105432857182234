import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestAddProductInMenu,
  sendRequestEnableProductInMenu,
  sendRequestDisableProductInMenu,
  sendRequestUpdateProductInMenu,
  updateMenuProductDataById,
} from '../../../../../../redux/reducers/utils/kitchenReducers/kitchenMenuReducer';
import { Flex, Typography, Tabs, Table, Switch, Input, Image } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import PrintTable from '../../../../../../components/PrintTable';

const { Title, Text } = Typography;
const { Search } = Input;
const { TabPane } = Tabs;

const MenuProductListComponent = () => {
  const { menuAllProductsData, selectedMenuDate, selectedMenuRace } = useSelector((store) => store.kitchenMenu);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [isFilter, setIsFilter] = useState(false);

  const memoMenuAllProductsData = useMemo(() => {
    return menuAllProductsData;
  }, [menuAllProductsData]);

  const isChecked = (inMenu, isStatus) => {
    if (inMenu === true && isStatus === 'on') {
      return true;
    }
    return false;
  };

  const onChange = () => {
    setIsFilter(!isFilter);
  };

  const handleSwitchProductInMenu = (menuProduct) => {
    const addProductData = {
      token: localStorage.getItem('token'),
      products: [
        {
          productId: menuProduct.productId,
          price: menuProduct.price,
          amountForOrder: menuProduct.amountForOrder || 0,
          amoutForOrderAlonePerson: 0,
          status: "on",
        }
      ],
      menuId: selectedMenuRace.menuId,
    }
  
    const enableDisableData = {
      token: localStorage.getItem('token'),
      menuId: selectedMenuRace.menuId,
      productId: menuProduct.productId,
    }
  
    if (menuProduct.inMenu === true) {
      if (menuProduct.status === 'on') {
        dispatch(updateMenuProductDataById({productId: menuProduct.productId, status: 'off'}));
        dispatch(sendRequestDisableProductInMenu(enableDisableData));
        
      } else {
        dispatch(updateMenuProductDataById({productId: menuProduct.productId, status: 'on'}));
        dispatch(sendRequestEnableProductInMenu(enableDisableData));
        
      }
    } else {
      dispatch(sendRequestAddProductInMenu(addProductData));
    }
  };

  const filteredMenuAllProductsData = memoMenuAllProductsData?.filter((item) =>
    item.name?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.description?.toString().toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredData = isFilter
  ? filteredMenuAllProductsData.filter(item => item.status === 'on')
  : filteredMenuAllProductsData;

  const handleSearch = (value) => {
    if (value.length > 2) {
      setSearchTerm(value);
    } else {
      setSearchTerm('');
    }
  };

  const groupedProducts = filteredData.reduce((acc, product) => {
    const categoryName = product.categoryName;
    if (!acc[categoryName]) {
      acc[categoryName] = [];
    }
    acc[categoryName].push(product);
    return acc;
  }, {});

  const columns = [
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Switch
          checked={isChecked(record.inMenu, record.status)}
          onChange={() => handleSwitchProductInMenu(record)}
        />
      ),
      width: '55px',
    },
    {
      title: 'В\u00A0меню',
      key: 'amountForOrder',
      render: (_, record) => (
        <EditableCell
          menuProduct={record}
          selectedMenuRace={selectedMenuRace}
        />
      ),
      width: '65px',
    },
    {
      title: 'Заказано',
      dataIndex: 'amountOrdered',
      key: 'amountOrdered',
      responsive: ['md'],
      width: '70px',
    },
    {
      title: 'Название, состав',
      key: 'name',
      render: (_, record) => (
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div><b>{record.name}</b></div>
          <div 
            style={{
              msTextOverflow: 'ellipsis',
              OTextOverflow: 'ellipsis',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              msLineClamp: 1,
              WebkitLineClamp: 1,
              lineClamp: 1,
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              boxOrient: 'vertical',
              wordWrap: 'break-word',
            }}
          >
            {record.description}
          </div>
        </div>
      ),
      width: '100%',
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
      responsive: ['md'],
      sorter: (a, b) => a.price - b.price,
      width: '60px',
    },
    {
      title: 'Фото',
      key: 'filePathPublic',
      dataIndex: 'filePathPublic',
      responsive: ['md'],
      render: (filePathPublic) => (
        <Image
          src={filePathPublic}
          width={50}
          mask='false'
          preview={{
            toolbarRender: (_,{}) => (<Flex size={12} className="toolbar-wrapper"></Flex>),
          }}
        />
      ),
      width: '60px',
    },
    {
      title: 'Вес',
      dataIndex: 'weight',
      key: 'weight',
      responsive: ['md'],
      sorter: (a, b) => a.weight - b.weight,
      width: '60px',
    },
  ];

  const withoutLimit = memoMenuAllProductsData.filter(menuProduct => {
    return menuProduct.status === 'on' && (menuProduct.amountForOrder === '' || menuProduct.amountForOrder === 0);
  })

  const withoutImage = memoMenuAllProductsData.filter(menuProduct => {
    return menuProduct.status === 'on' && menuProduct.filePathPublic === '';
  })

  const withoutBread = memoMenuAllProductsData.filter(menuProduct => {
    return menuProduct.status === 'off' && menuProduct.name === 'Хлеб';
  })

  const withoutСutlery = memoMenuAllProductsData.filter(menuProduct => {
    return menuProduct.status === 'off' && menuProduct.name === 'Комплект приборов';
  })

  return (
    <>
      {selectedMenuDate !== null ? (
        <>
          <Flex gap='large' style={{padding: '10px 0 30px'}}>
            {withoutLimit.length > 0 && (<Text><ExclamationCircleFilled style={{ color: '#FFA800' }} /> без лимита количества: {withoutLimit.length}</Text>)}
            {withoutImage.length > 0 && (<Text><ExclamationCircleFilled style={{ color: '#FFA800' }} /> без изображения: {withoutImage.length}</Text>)}
            {withoutBread.length > 0 && (<Text><ExclamationCircleFilled style={{ color: '#FFA800' }} /> без хлеба!</Text>)}
            {withoutСutlery.length > 0 && (<Text><ExclamationCircleFilled style={{ color: '#FFA800' }} /> без приборов!</Text>)}
          </Flex>
          <Search
            placeholder="Поиск по названию или описанию"
            onChange={(e) => handleSearch(e.target.value)}
            style={{ maxWidth: '500px' }}
          />
          <Flex gap={20} align='center' style={{margin: '10px 0'}}>Только опубликованные <Switch onChange={onChange}/></Flex>
          <Tabs
            defaultActiveKey='1'
            tabPosition='top'
          >
            {Object.keys(groupedProducts).map((categoryName, index) => (
              <TabPane tab={categoryName} key={index + 1}>
                <PrintTable>
                  <Table
                    dataSource={groupedProducts[categoryName]}
                    columns={columns}
                    pagination={false}
                    scroll={{ y: 'calc(100vh - 235px)' }}
                    size="small"
                  />
                </PrintTable>
              </TabPane>
            ))}
          </Tabs>
        </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>Выберите дату и время</div>
      )}
    </>
  );  
};

export default MenuProductListComponent;

const EditableCell = ({ menuProduct, selectedMenuRace }) => {
  const dispatch = useDispatch();
  const [inMenuValue, setInMenuValue] = useState(menuProduct.amountForOrder);
  const [hasChanges, setHasChanges] = useState(false);
  const inputRef = React.createRef();

  useEffect(() => {
    setInMenuValue(menuProduct.amountForOrder);
    setHasChanges(false);
  }, [menuProduct]);

  const handleChangeInMenuValue = (event) => {
    setInMenuValue(event.target.value);
    setHasChanges(true);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      inputRef.current.blur();
    }
  };

  const handleBlur = () => {
    if (hasChanges) {
      const requestData = {
        token: localStorage.getItem('token'),
        products: [
          {
            productId: menuProduct.productId,
            price: menuProduct.price,
            amountForOrder: Number(inMenuValue),
            amoutForOrderAlonePerson: 0,
            status: 'on',
          },
        ],
        menuId: selectedMenuRace.menuId,
      };

      dispatch(sendRequestUpdateProductInMenu(requestData));
      setHasChanges(false);
    }
  };

  const hasError = (menuProduct) => {
    return menuProduct.status === 'on' && (menuProduct.amountForOrder === 0 || menuProduct.amountForOrder === '');
  };

  return (
    <Input
      ref={inputRef}
      value={inMenuValue}
      maxLength={3}
      disabled={menuProduct.status === 'off'}
      onChange={handleChangeInMenuValue}
      onBlur={handleBlur}
      onKeyDown={handleKeyPress}
      style={{ borderColor: hasError(menuProduct) ? 'red' : '' }}
    />
  );
};
