import React from 'react';
import { Form, Input, Select, Checkbox, DatePicker, Button, Flex, Typography } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Title, Text } = Typography;

const serverFormat = 'YYYY-MM-DD';
const clientFormat = 'DD-MM-YYYY';

const PromocodesFiltersComponent = ({ totalPromocodes, kitchenOptions, onSearch }) => {
  const handleSearch = (values) => {
    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(([_, v]) => v !== undefined && v !== null && v !== '' && v !== false)
    );
  
    if (filteredValues.valid) {
      const [validFrom, validTo] = filteredValues.valid;
      filteredValues.validFrom = validFrom.format(serverFormat);
      filteredValues.validTo = validTo.format(serverFormat);
      delete filteredValues.valid;
    }

    if (filteredValues.kitchens) {
      filteredValues.kitchens = filteredValues.kitchens.join();
    }

    onSearch(filteredValues);
  };

  return (
    <Form
      layout='vertical'
      onFinish={handleSearch}
      initialValues={{
        isActive: false,
        isPersonal: false,
      }}
    >
      <Flex gap='0 16px' align='center' wrap='wrap'>
        <Form.Item style={{flex: '0 0 auto', margin: '5px'}} name='isActive' valuePropName='checked'>
          <Checkbox>Действующие</Checkbox>
        </Form.Item>

        <Form.Item style={{flex: '0 0 auto', margin: '5px'}} name='isUserPromocode' valuePropName='checked'>
          <Checkbox>Личные</Checkbox>
        </Form.Item>

        <Form.Item style={{flex: '1 1 auto', margin: '5px'}} name='promocodId' label='ID промокода'>
          <Input placeholder='Введите ID промокода'/>
        </Form.Item>

        <Form.Item style={{flex: '3 1 auto', margin: '5px'}} name='name' label='Промокод'>
          <Input placeholder='Введите код промокода'/>
        </Form.Item>
      </Flex>

      <Flex gap={16} align='center'>
        <Form.Item style={{flex: 1, margin: '5px'}} name='valid' label='Активны в период'>
          <RangePicker style={{width: '100%'}} format={clientFormat}/>
        </Form.Item>

        <Form.Item style={{flex: 1, margin: '5px'}} name='kitchens' label='Применяется в кухнях'>
          <Select
            style={{minWidth: '100%'}}
            mode="multiple"
            showSearch
            placeholder="Выберите кухни"
            optionFilterProp="children"
            options={kitchenOptions}
            filterOption={(input, option) =>
              (option?.kitchenId?.toString() ?? '').toLowerCase().includes(input.toLowerCase()) ||
              (option?.name?.toString() ?? '').toLowerCase().includes(input.toLowerCase()) ||
              (option?.inn?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
            }
            optionRender={(option) => (
              <Flex vertical >
                <Title level={5}>{option.data.name}</Title>
                <Flex gap='small'>
                  <Text type="secondary" size="small">ID: {option.data.id}</Text>
                  <Text type="secondary" size="small">{option.data.cityName}</Text>
                </Flex>
              </Flex>
            )}
          />
        </Form.Item>
      </Flex>

      <Flex style={{marginTop: '20px'}} gap='large' justify='space-between'>
        <Text type='secondary'>Найдено промокодов: {new Intl.NumberFormat().format(totalPromocodes)}</Text>
        <Flex justify='flex-end' gap={16}>
          <Button type='primary' htmlType='submit'>Поиск</Button>
          <Button htmlType='reset'>Сбросить</Button>
        </Flex>
      </Flex>
    </Form>
  );
};

export default PromocodesFiltersComponent;
