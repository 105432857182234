import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentRoute } from '../../redux/reducers/utils/navReducer';

const DocumentationModule = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  dispatch(setCurrentRoute('documentation/adminpaneldocs'));
  navigate('adminpaneldocs');
}

export default DocumentationModule;
