import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  // Corpclients
  corpclientsGetRequestStatus: null,
  corpclients: [],
  totalCorpclients: 0,
  currentCorpclientsPage: 1,
  corpclientsCreateRequestStatus: null,
  corpclientsUpdateRequestStatus: null,
  corpclientsDeleteRequestStatus: null,

  // Corpclients contracts
  corpclientsContractsGetRequestStatus: null,
  corpclientsContracts: [],
  totalCorpclientsContracts: 0,
  currentCorpclientsContarctsPage: 1,
  corpclientsContractsCreateRequestStatus: null,
  corpclientsContractsUpdateRequestStatus: null,
  corpclientsContractsDeleteRequestStatus: null,
};

const financeCorpclientsSlice = createSlice({
  name: 'financeCorpclients',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    // Corpclients
    sendRequestCorpclientsGet: (state) => { state.corpclientsGetRequestStatus = 'pending'; },
    sendRequestCorpclientsGetSuccess: (state) => { state.corpclientsGetRequestStatus = 'resolved'; },
    sendRequestCorpclientsGetError: (state) => { state.corpclientsGetRequestStatus = 'rejected'; },
    clearCorpclientsGetStatus: (state) => { state.corpclientsGetRequestStatus = null; },
    setCorpclients: (state, action) => {
      const data = action.payload;

      state.corpclients = data.corporates;
      state.totalCorpclients = data.countCorporates;
    },
    setCurrentCorpclientsPage: (state, action) => {
      state.currentCorpclientsPage = action.payload;
    },

    sendRequestCorpclientsCreate: (state) => { state.corpclientsCreateRequestStatus = 'pending'; },
    sendRequestCorpclientsCreateSuccess: (state) => { state.corpclientsCreateRequestStatus = 'resolved'; },
    sendRequestCorpclientsCreateError: (state) => { state.corpclientsCreateRequestStatus = 'rejected'; },
    clearCorpclientsCreateStatus: (state) => { state.corpclientsCreateRequestStatus = null; },

    sendRequestCorpclientsUpdate: (state) => { state.corpclientsUpdateRequestStatus = 'pending'; },
    sendRequestCorpclientsUpdateSuccess: (state) => { state.corpclientsUpdateRequestStatus = 'resolved'; },
    sendRequestCorpclientsUpdateError: (state) => { state.corpclientsUpdateRequestStatus = 'rejected'; },
    clearCorpclientsUpdateStatus: (state) => { state.corpclientsUpdateRequestStatus = null; },

    sendRequestCorpclientsDelete: (state) => { state.corpclientsDeleteRequestStatus = 'pending'; },
    sendRequestCorpclientsDeleteSuccess: (state) => { state.corpclientsDeleteRequestStatus = 'resolved'; },
    sendRequestCorpclientsDeleteError: (state) => { state.corpclientsDeleteRequestStatus = 'rejected'; },
    clearCorpclientsDeleteStatus: (state) => { state.corpclientsDeleteRequestStatus = null; },

    // Corpclients contracts
    sendRequestCorpclientsContractsGet: (state) => { state.corpclientsContractsGetRequestStatus = 'pending'; },
    sendRequestCorpclientsContractsGetSuccess: (state) => { state.corpclientsContractsGetRequestStatus = 'resolved'; },
    sendRequestCorpclientsContractsGetError: (state) => { state.corpclientsContractsGetRequestStatus = 'rejected'; },
    clearCorpclientsContractsGetStatus: (state) => { state.corpclientsContractsGetRequestStatus = null; },
    setCorpclientsContracts: (state, action) => {
      const data = action.payload;

      state.corpclientsContracts = data.corporatsContracts;
      state.totalCorpclientsContracts = data.countCorporatesContracts;
    },
    setCurrentCorpclientsContractsPage: (state, action) => {
      state.currentCorpclientsContarctsPage = action.payload;
    },
    mergeCorpclientsWithContracts: (state) => {
      state.corpclients = state.corpclients.map(client => {
        const contracts = state.corpclientsContracts.filter(contract => contract.corporatId === client.corporatId);
        return { ...client, contracts };
      });
    },

    sendRequestCorpclientsContractsCreate: (state) => { state.corpclientsContractsCreateRequestStatus = 'pending'; },
    sendRequestCorpclientsContractsCreateSuccess: (state) => { state.corpclientsContractsCreateRequestStatus = 'resolved'; },
    sendRequestCorpclientsContractsCreateError: (state) => { state.corpclientsContractsCreateRequestStatus = 'rejected'; },
    clearCorpclientsContractsCreateStatus: (state) => { state.corpclientsContractsCreateRequestStatus = null; },

    sendRequestCorpclientsContractsUpdate: (state) => { state.corpclientsContractsUpdateRequestStatus = 'pending'; },
    sendRequestCorpclientsContractsUpdateSuccess: (state) => { state.corpclientsContractsUpdateRequestStatus = 'resolved'; },
    sendRequestCorpclientsContractsUpdateError: (state) => { state.corpclientsContractsUpdateRequestStatus = 'rejected'; },
    clearCorpclientsContractsUpdateStatus: (state) => { state.corpclientsContractsUpdateRequestStatus = null; },

    sendRequestCorpclientsContractsDelete: (state) => { state.corpclientsContractsDeleteRequestStatus = 'pending'; },
    sendRequestCorpclientsContractsDeleteSuccess: (state) => { state.corpclientsContractsDeleteRequestStatus = 'resolved'; },
    sendRequestCorpclientsContractsDeleteError: (state) => { state.corpclientsContractsDeleteRequestStatus = 'rejected'; },
    clearCorpclientsContractsDeleteStatus: (state) => { state.corpclientsContractsDeleteRequestStatus = null; },
  },
});

export const {
  // Corpclients
  sendRequestCorpclientsGet,
  sendRequestCorpclientsGetSuccess,
  sendRequestCorpclientsGetError,
  clearCorpclientsGetStatus,
  setCorpclients,
  setCurrentCorpclientsPage,

  sendRequestCorpclientsCreate,
  sendRequestCorpclientsCreateSuccess,
  sendRequestCorpclientsCreateError,
  clearCorpclientsCreateStatus,

  sendRequestCorpclientsUpdate,
  sendRequestCorpclientsUpdateSuccess,
  sendRequestCorpclientsUpdateError,
  clearCorpclientsUpdateStatus,

  sendRequestCorpclientsDelete,
  sendRequestCorpclientsDeleteSuccess,
  sendRequestCorpclientsDeleteError,
  clearCorpclientsDeleteStatus,

  // Corpclients contracts
  sendRequestCorpclientsContractsGet,
  sendRequestCorpclientsContractsGetSuccess,
  sendRequestCorpclientsContractsGetError,
  clearCorpclientsContractsGetStatus,
  setCorpclientsContracts,
  setCurrentCorpclientsContractsPage,
  mergeCorpclientsWithContracts,

  sendRequestCorpclientsContractsCreate,
  sendRequestCorpclientsContractsCreateSuccess,
  sendRequestCorpclientsContractsCreateError,
  clearCorpclientsContractsCreateStatus,

  sendRequestCorpclientsContractsUpdate,
  sendRequestCorpclientsContractsUpdateSuccess,
  sendRequestCorpclientsContractsUpdateError,
  clearCorpclientsContractsUpdateStatus,

  sendRequestCorpclientsContractsDelete,
  sendRequestCorpclientsContractsDeleteSuccess,
  sendRequestCorpclientsContractsDeleteError,
  clearCorpclientsContractsDeleteStatus,
} = financeCorpclientsSlice.actions;

export default financeCorpclientsSlice.reducer;