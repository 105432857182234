import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  promocodesSearchRequestStatus: null,
  promocodes: [],
  totalPromocodes: 0,
  currentPromocodesPage: 1,
  promocodeCreateRequestStatus: null,
  promocodeUpdateRequestStatus: null,
  promocodeDeleteRequestStatus: null,
};

const financePromocodesSlice = createSlice({
  name: 'financePromocodes',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    sendRequestPromocodesSearch: (state) => { state.promocodesSearchRequestStatus = 'pending'; },
    sendRequestPromocodesSearchSuccess: (state) => { state.promocodesSearchRequestStatus = 'resolved'; },
    sendRequestPromocodesSearchError: (state) => { state.promocodesSearchRequestStatus = 'rejected'; },
    clearPromocodesSearchStatus: (state) => { state.promocodesSearchRequestStatus = null; },
    setPromocodes: (state, action) => {
      const data = action.payload;

      state.promocodes = data.promocodes;
      state.totalPromocodes = data.countPromocodes;
    },
    setCurrentPromocodesPage: (state, action) => {
      state.currentPromocodesPage = action.payload;
    },

    sendRequestPromocodeCreate: (state) => { state.promocodeCreateRequestStatus = 'pending'; },
    sendRequestPromocodeCreateSuccess: (state) => { state.promocodeCreateRequestStatus = 'resolved'; },
    sendRequestPromocodeCreateError: (state) => { state.promocodeCreateRequestStatus = 'rejected'; },
    clearPromocodeCreateStatus: (state) => { state.promocodeCreateRequestStatus = null; },

    sendRequestPromocodeUpdate: (state) => { state.promocodeUpdateRequestStatus = 'pending'; },
    sendRequestPromocodeUpdateSuccess: (state) => { state.promocodeUpdateRequestStatus = 'resolved'; },
    sendRequestPromocodeUpdateError: (state) => { state.promocodeUpdateRequestStatus = 'rejected'; },
    clearPromocodeUpdateStatus: (state) => { state.promocodeUpdateRequestStatus = null; },

    sendRequestPromocodeDelete: (state) => { state.promocodeDeleteRequestStatus = 'pending'; },
    sendRequestPromocodeDeleteSuccess: (state) => { state.promocodeDeleteRequestStatus = 'resolved'; },
    sendRequestPromocodeDeleteError: (state) => { state.promocodeDeleteRequestStatus = 'rejected'; },
    clearPromocodeDeleteStatus: (state) => { state.promocodeDeleteRequestStatus = null; },
  },
});

export const {
  sendRequestPromocodesSearch,
  sendRequestPromocodesSearchSuccess,
  sendRequestPromocodesSearchError,
  clearPromocodesSearchStatus,
  setPromocodes,
  setCurrentPromocodesPage,
  sendRequestPromocodeCreate,
  sendRequestPromocodeCreateSuccess,
  sendRequestPromocodeCreateError,
  clearPromocodeCreateStatus,
  sendRequestPromocodeUpdate,
  sendRequestPromocodeUpdateSuccess,
  sendRequestPromocodeUpdateError,
  clearPromocodeUpdateStatus,
  sendRequestPromocodeDelete,
  sendRequestPromocodeDeleteSuccess,
  sendRequestPromocodeDeleteError,
  clearPromocodeDeleteStatus,
} = financePromocodesSlice.actions;

export default financePromocodesSlice.reducer;