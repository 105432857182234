import React from 'react';
import SwaggerUIcomponent from '../components/SwagerUIcomponent';

function SiteDocsPage() {
  const siteApi = 'https://api-test.menuforme.online/documentation/fooddelivery/v2/website/api';
  return (
    <SwaggerUIcomponent api={siteApi}/>
  );
}

export default SiteDocsPage;
