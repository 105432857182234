import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestKitchens,
} from '../../../../../redux/reducers/utils/kitchenReducers/kitchenReducer';
import {
  setIsOpenRacesDrawer,
  sendRequestRacesForDays,
  sendRequestCreateMenu,
  sendRequestMenuTemplates,
  sendRequestMenuAllProducts,
  sendRequestCopyMenuTemplate,
} from '../../../../../redux/reducers/utils/kitchenReducers/kitchenMenuReducer';
import { Flex, Typography, Button, Drawer } from 'antd';
import { RightOutlined, CloseOutlined } from '@ant-design/icons';
import Loader from '../../../../../components/Loader';
import MenuDateListComponent from './components/MenuDateListComponent';
import MenuProductListComponent from './components/MenuProductListComponent';
import MenuTopRightDropdown from './components/MenuTopRightDropdown';
import KitchenSelectorComponent from '../../../components/KitchenSelectorComponent';
import { Container, Page, ScrolledCol } from '../../../../../core/styles';

const { Title, Text } = Typography;

const MenuPublicationPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const {
    selectKitchen,
  } = useSelector((store) => store.kitchen);
  const {
    isOpenRacesDrawer,
    selectedMenuDate,
    selectedMenuRace,
    createMenuRequestStatus,
    menuAllProductsData,
    racesForDaysRequestStatus,
    menuAllProductsRequestStatus,
    addProductInMenuStatus,
    enableProductInMenuStatus,
    disableProductInMenuStatus,
    updateProductInMenuStatus, 
    copyMenuTemplateStatus,
    transferMenuTemplateStatus,
    createMenuTemplateStatus,
    lastCreatedTemplateId,
    enableMenuStatus,
    disableMenuStatus,
    racesForDaysData,
  } = useSelector((store) => store.kitchenMenu);
  const dispatch = useDispatch();

  const formatMenuDate = (menuDate) => {
    const options = { month: 'long', day: 'numeric' };
    const formattedDate = new Date(menuDate).toLocaleDateString('ru-RU', options);
    return formattedDate;
  };

  const isPublished = data => data.some(item => item && item.statusMenu === 'publish');
  const published = isPublished(menuAllProductsData);

  const handleCreateMenu = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      day: selectedMenuDate.day,
      kitchenId: selectKitchen.id,
      raceId: selectedMenuRace.raceId,
    }

    dispatch(sendRequestCreateMenu(requestData));
  }

  useEffect(() => {
    if (selectKitchen !== null) {
      const requestData = {
        token: localStorage.getItem('token'),
        kitchenId: selectKitchen.id,
      }
  
      dispatch(sendRequestRacesForDays(requestData));
      dispatch(sendRequestMenuTemplates(requestData));
    }
  }, [selectKitchen])

  useEffect(() => {
    if (transferMenuTemplateStatus === 'resolved') {
      const requestData = {
        token: localStorage.getItem('token'),
        day: selectedMenuDate?.day,
        kitchenId: selectKitchen.id,
        raceId: selectedMenuRace?.raceId,
      }
  
      dispatch(sendRequestMenuAllProducts(requestData));
    }
  }, [transferMenuTemplateStatus])

  useEffect(() => {
    if (selectedMenuRace && selectedMenuRace.menuId !== 0) {
      const requestData = {
        token: localStorage.getItem('token'),
        day: selectedMenuDate?.day,
        kitchenId: selectKitchen.id,
        raceId: selectedMenuRace?.raceId,
      }
  
      dispatch(sendRequestMenuAllProducts(requestData));
    }
  }, [selectedMenuRace])

  useEffect(() => {
    if (createMenuTemplateStatus === 'resolved') {
      const requestData = {
        token: localStorage.getItem('token'),
        kitchenId: selectKitchen.id,
        menuId: selectedMenuRace?.menuId,
        menuTemplateId: lastCreatedTemplateId,
      }
  
      dispatch(sendRequestCopyMenuTemplate(requestData))
    }
  }, [createMenuTemplateStatus])

  const showDrawer = () => {
    dispatch(setIsOpenRacesDrawer(true));
  };

  const onClose = () => {
    dispatch(setIsOpenRacesDrawer(false));
  };

  console.log(racesForDaysData)

  return (
    <>
      {(
        racesForDaysRequestStatus === 'pending' ||
        menuAllProductsRequestStatus === 'pending' ||
        addProductInMenuStatus === 'pending' ||
        // enableProductInMenuStatus === 'pending' ||
        // disableProductInMenuStatus === 'pending' ||
        updateProductInMenuStatus === 'pending' ||
        copyMenuTemplateStatus === 'pending' ||
        createMenuRequestStatus === 'pending' ||
        transferMenuTemplateStatus === 'pending' ||
        enableMenuStatus === 'pending' ||
        disableMenuStatus === 'pending'
      ) && <Loader />}
      
      <Button
        onClick={showDrawer}
        icon={<RightOutlined />}
        style={{
          position: 'absolute',
          top: '50%',
          left: 0,
          transform: 'translate(0, -50%)',
          zIndex: 100,
          width: '24px',
          height: '70px',
          borderRadius: '0 15px 15px 0',
        }}
      />
      <Drawer
        title="Рейсы"
        placement="left"
        open={isOpenRacesDrawer}
        mask={false}
        width='fit-content'
        getContainer={false}
        closeIcon={
          <Button
            type="text"
            icon={<CloseOutlined />}
            onClick={onClose}
            style={{ position: 'absolute', top: '12px', right: '6px', zIndex: 1 }}
          />
        }
        style={{ height: 'calc(100vh - 44px)', left: '-15px',}}
      >
        <MenuDateListComponent/>
      </Drawer>

      <ScrolledCol>
        <Title level={2}>Публикация меню</Title>
        <Flex justify='space-between' align='center' gap={16}>
          <KitchenSelectorComponent/>
          {selectedMenuRace && <MenuTopRightDropdown published={published}/>}
        </Flex>

        {racesForDaysData.some(item => item.races.length > 0) ?
          <>
            {selectedMenuRace ? (
              <>
                {selectedMenuRace && selectedMenuRace.menuId !== 0 ? (
                  <>
                    <Title level={3} style={{margin: '10px 0'}}>
                      Меню на {formatMenuDate(selectedMenuDate?.day)},
                      на {selectedMenuRace?.timeStart} - {selectedMenuRace?.timeEnd}
                      <span style={{color: published ? 'green' : 'red'}}> {published ? 'Опубликовано' : 'Не опубликовано'}</span>
                    </Title>
                    <MenuProductListComponent/>
                  </>
                ) : (
                  <Flex vertical align='center' justify='center' style={{height: '100%'}}>
                    <Text style={{marginBottom: '20px'}}>Нет меню на этот рейс!</Text>
                    <Button type='primary' size='large' onClick={handleCreateMenu}>Создать меню</Button>
                  </Flex>
                )}
              </>
            ):(
              <Flex vertical align='center' justify='center' style={{height: '100%'}}>
                <Text>Выберите рейс в меню слева</Text>
              </Flex>
            )}
          </>
        :
          <Flex vertical align='center' justify='center' style={{height: '100%'}}>
            <Text>Вы пока не можете публиковать меню, ваша кухня не настроена. Обратитесь к вашему менеджеру</Text>
          </Flex>
        }
        
      </ScrolledCol>
    </>
  );
};

export default MenuPublicationPage;
