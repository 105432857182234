import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Form, Select, Input, Button } from 'antd';
import styled from 'styled-components';

const RoleUsersCreateComponent = ({dataForTypeObjects, onCreateRoleUsers}) => {
  const { accessRoleComponents } = useSelector((store) => store.services);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [phoneValue, setPhoneValue] = useState('+7');

  const roles = accessRoleComponents.roles;
  const [curentValueObject, setCurentValueObject] = useState(dataForTypeObjects[0]);

  const roleOptions = roles?.map((role) => ({
    value: role.id,
    label: role.description,
  }));

  const typeObjectOptions = dataForTypeObjects.map((typeObject) => ({
    value: typeObject.id,
    label: typeObject.name,
  }));

  const handleTypeObjectChange = (value) => {
    const curentValueObject = dataForTypeObjects.find(typeObject => value === typeObject.id)
    setCurentValueObject(curentValueObject);
  }

  const objectOptions = curentValueObject?.valueObject.map((object) => ({
    value: object.id,
    label: object.name,
  }));

  const onFinish = (value) => {
    onCreateRoleUsers(value);
    form.resetFields();
    setPhoneValue('+7');
    setIsModalVisible(false);
  }

  const handleCreate = () => {
    setIsModalVisible(true)
  };

  const handleModalCancel = () => {
    setIsModalVisible(false)
  };

  const onChangePhoneValue = (event) => {
    const val = event.target.value;
    if (val.length < 2) {
      setPhoneValue('+7');
    } else {
      setPhoneValue(val);
    }
  };

  return (
    <>
      <Button type={'primary'} style={{width:'fit-content', marginBottom: '20px'}} onClick={handleCreate}>Добавить пользователя</Button>
      <Modal
        title="Создание пользователя"
        open={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        <Form
          form={form}
          name='MapFilter'
          onFinish={onFinish}
        >
          <Form.Item name='phone' rules={[{ required: true, message: 'Введите номер телефона' }]}>
            <Input placeholder='Введите номер телефона' value={phoneValue} maxLength={12} onChange={onChangePhoneValue}/>
          </Form.Item>

          <Form.Item name='role' rules={[{ required: true, message: 'Выберите новую роль' }]}>
            <Select options={roleOptions} placeholder='Выберите роль'/>
          </Form.Item>

          <Form.Item name='typeObject' rules={[{ required: true, message: 'Выберите новый тип объекта' }]}>
            <Select options={typeObjectOptions} placeholder='Выберите тип объекта' onChange={handleTypeObjectChange}/>
          </Form.Item>

          <Form.Item name='object' rules={[{ required: true, message: 'Выберите новый объект' }]}>
            <Select
              showSearch
              placeholder="Выберите организацию"
              optionFilterProp="children"
              options={objectOptions}
              filterOption={(input, option) =>
                (option?.label.toString() ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item>
            <Button type='primary' htmlType='submit'>Сохранить</Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default RoleUsersCreateComponent;
