import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DatePicker, Select, Typography, Row, Col, Steps, Divider, Card } from 'antd';
import { LoadingOutlined, SmileOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import ReactECharts from 'echarts-for-react';
import styled from 'styled-components';
import KitchenSelectorComponent from '../../components/KitchenSelectorComponent';
import { SitiyCard } from '../../../../core/styles';
import { formatDate } from '../../../../core/utils';

const { Title, Text } = Typography;
const { Meta } = Card;

const StatisticsPage = () => {
  const [ratingData, setRatingData] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [ordersData, setOrdersData] = useState([]);
  const [popularProducts, setPopularProducts] = useState([]);
  const [highRatedProducts, setHighRatedProducts] = useState([]);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    // Фейковые данные для графиков
    setRatingData([
      { date: '2024-05-01', rating: 4 },
      { date: '2024-05-02', rating: 4 },
      { date: '2024-05-03', rating: 4.2 },
      { date: '2024-05-04', rating: 4.3 },
      { date: '2024-05-05', rating: 4.7 },
      { date: '2024-05-06', rating: 4.5 },
    ]);

    setSalesData([
      { date: '2024-05-01', sales: 100 },
      { date: '2024-05-02', sales: 150 },
      { date: '2024-05-03', sales: 250 },
      { date: '2024-05-04', sales: 180 },
      { date: '2024-05-05', sales: 220 },
      { date: '2024-05-06', sales: 300 },
    ]);

    setOrdersData([
      { date: '2024-05-01', orders: 5 },
      { date: '2024-05-02', orders: 10 },
      { date: '2024-05-03', orders: 15 },
      { date: '2024-05-04', orders: 18 },
      { date: '2024-05-05', orders: 21 },
      { date: '2024-05-06', orders: 40 },
    ]);

    // Фейковые данные для товаров и отзывов
    setPopularProducts([
      { id: 1, name: 'Продукт 1', sales: 300, imageUrl: 'https://via.placeholder.com/150' },
      { id: 2, name: 'Продукт 2', sales: 250, imageUrl: 'https://via.placeholder.com/150' },
      { id: 3, name: 'Продукт 3', sales: 200, imageUrl: 'https://via.placeholder.com/150' },
    ]);

    setHighRatedProducts([
      { id: 1, name: 'Продукт 4', rating: 4.8, imageUrl: 'https://via.placeholder.com/150' },
      { id: 2, name: 'Продукт 5', rating: 4.7, imageUrl: 'https://via.placeholder.com/150' },
      { id: 3, name: 'Продукт 6', rating: 4.6, imageUrl: 'https://via.placeholder.com/150' },
    ]);

    setReviews([
      { id: 1, reviewer: 'Клиент 1', comment: 'Отличный продукт', rating: 4.5 },
      { id: 2, reviewer: 'Клиент 2', comment: 'Все хорошо', rating: 4.0 },
      { id: 3, reviewer: 'Клиент 3', comment: 'Можно было и получше', rating: 3.5 },
    ]);
  }, []);

  const getRatingOption = () => ({
    title: {
      text: 'Рейтинг',
    },
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: ratingData.map((data) => formatDate(data.date)),
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'Рейтинг',
        type: 'line',
        data: ratingData.map((data) => data.rating),
      },
    ],
  });

  const getSalesOption = () => ({
    title: {
      text: 'Продажи за период',
    },
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: salesData.map((data) => formatDate(data.date)),
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'Продажи',
        type: 'line',
        data: salesData.map((data) => data.sales),
      },
    ],
  });

  const getOrdersOption = () => ({
    title: {
      text: 'Заказы за период',
    },
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: ordersData.map((data) => formatDate(data.date)),
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'Заказы',
        type: 'bar',
        data: ordersData.map((data) => data.orders),
      },
    ],
  });

  return (
    <>
      <Title level={2}>Дашборд</Title>
      <FiltersWrapper>
        <KitchenSelectorComponent/>
      </FiltersWrapper>
      <Row gutter={16}>
        <Col span={8}>
          <SitiyCard>
            <Title>Сегодня</Title>
            <Title level={4}>Заказов 21 на сумму 7297 р.</Title>
            <Text>(из них наличными 0 р.)</Text>
            <Divider/>
            <div>На <b>8</b> заказов больше, чем в этот день неделю назад</div>
            <div>Заказывают первый раз: <b>1</b></div>
            <div>Заказывали вчера: <b>18</b></div>
          </SitiyCard>
        </Col>
        <Col span={8}>
          <SitiyCard>
            <Title>Завтра</Title>
            <Title level={4}>Заказов 2 на сумму 568 р.</Title>
            <Text>(из них наличными 0 р.)</Text>
            <Divider/>
            <div>На <b>17</b> заказов меньше, чем в этот день неделю назад</div>
            <div>Заказывают первый раз: <b>0</b></div>
            <div>Заказывали вчера: <b>18</b></div>
          </SitiyCard>
        </Col>
        <Col span={8}>
          <SitiyCard>
            <ReactECharts option={getRatingOption()}/>
          </SitiyCard>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <SitiyCard>
            <ReactECharts option={getSalesOption()}/>
          </SitiyCard>
        </Col>
        <Col span={16}>
          <SitiyCard>
            <ReactECharts option={getOrdersOption()}/>
          </SitiyCard>
        </Col>
      </Row>
      <Row gutter={16} style={{marginTop: '50px'}}>
        <Col span={16}>
          <Row gutter={16}>
            <Col span={12} gutter={16}>
              <Title level={4}>Популярные товары</Title>
              <Row gutter={16}>
                <Col span={24}>
                  {popularProducts.map(product => (
                    <StyledCard hoverable key={product.id}>
                      <CardContent>
                        <img alt={product.name} src={product.imageUrl} style={{ width: 70, height: 70, marginRight: 16 }} />
                        <Meta title={product.name} description={`Продано: ${product.sales}`} />
                      </CardContent>
                    </StyledCard>
                  ))}
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Title level={4}>Товары с высоким рейтингом</Title>
              <Row gutter={16}>
                <Col span={24}>
                  {highRatedProducts.map(product => (
                    <StyledCard hoverable key={product.id}>
                      <CardContent>
                        <img alt={product.name} src={product.imageUrl} style={{ width: 70, height: 70, marginRight: 16 }} />
                        <Meta title={product.name} description={`Рейтинг: ${product.rating}`} />
                      </CardContent>
                    </StyledCard>
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <SitiyCard>
            <Title level={4}>Отзывы</Title>
            {reviews.map(review => (
              <div key={review.id}>
                <Text strong>{review.reviewer}</Text>
                <Text> {review.comment}</Text>
                <Text> {`Рейтинг: ${review.rating}`}</Text>
                <Divider />
              </div>
            ))}
          </SitiyCard>
        </Col>
      </Row>
    </>
  );
};

export default StatisticsPage;

const FiltersWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 16px;
`;
const StyledCard = styled(Card)`
  margin-bottom: 10px;

  & .ant-card-body {
    padding: 10px;
  }
`
const CardContent = styled.div`
  display: flex;
  align-items: center;
`;