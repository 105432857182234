import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestDeliveryServiceCouriersCreate } from '../../../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Flex, Modal, Form, Select, Input, ColorPicker, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const CourierCreateModalComponent = () => {
  const { currentDeliveryService } = useSelector((store) => store.deliveryServices);
  const dispatch = useDispatch();
  const [ isCreate, setIsCreate] = useState(false);
  const [phoneValue, setPhoneValue] = useState('+7');
  const [form] = Form.useForm();

  const handleOpen = () => {
    setIsCreate(true);
  };

  const handleCancel = () => {
    setIsCreate(false);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      prefix: values.prefix,
      name: values.name,
      color: values.color.toHexString(),
      phone: values.phone,
      deliveryServiceId: currentDeliveryService.id,
    }

    dispatch(sendRequestDeliveryServiceCouriersCreate(requestData));
    setIsCreate(false);
    form.resetFields();
  }
  
  const handleLiterChange = (e) => {
    const upperCaseValue = e.target.value.toUpperCase();
    form.setFieldsValue({ prefix: upperCaseValue });
  };

  const onChangePhoneValue = (event) => {
    const val = event.target.value;
    if (val.length < 2) {
      setPhoneValue('+7');
    } else {
      setPhoneValue(val);
    }
  };

  useEffect(() => {
    form.setFieldsValue({ phone: phoneValue }); 
  }, [phoneValue])

  return (
    <>
      <Button
        type='primary'
        shape="round"
        icon={<PlusOutlined />}
        onClick={handleOpen}
        style={{marginLeft: 'auto'}}
      >
        Добавить курьера
      </Button>
      <Modal
        title="Добавление нового курьера"
        open={isCreate}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          style={{ width: '100%' }}
          form={form}
          name='deliveryService'
          layout='vertical'
          onFinish={handleSubmit}
        >
          <Flex gap='large'>
            <Form.Item label='Цвет' name='color' rules={[{ required: true, message: 'Выберите цвет' }]}><ColorPicker/></Form.Item>
            <Form.Item label='Литера' name='prefix' rules={[{ required: true, message: 'Введите литеру' }]}><Input maxLength={3} placeholder='Введите литеру' onChange={handleLiterChange}/></Form.Item>
          </Flex>
          <Form.Item label='Имя' name='name' rules={[{ required: true, message: 'Введите имя' }]}><Input placeholder='Введите имя'/></Form.Item>
          <Form.Item label='Телефон' name='phone' rules={[{ required: true, message: 'Введите телефон' }]}><Input maxLength={12} value={phoneValue} onChange={onChangePhoneValue}/></Form.Item>
          <Button type='primary' htmlType='submit' style={{ display: 'flex', marginLeft: 'auto' }}>Добавить</Button>
        </Form>
      </Modal>
    </>
  );
};

export default CourierCreateModalComponent;
