import React, { useState } from 'react';
import { Button, Modal, message, Upload, Typography, Flex, Select, Switch } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { Dragger } = Upload;
const { Title, Text } = Typography;

const WalletsUploadComponent = ({ corpclientsOptions, uploadInfo, uploadStatus, onSubmit }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [csvFile, setCsvFile] = useState([]);
  const [corporatContractId, setCorporatContractId] = useState(null);
  const [isDefaultWalets, setIsDefaultWalets] = useState(false);

  const handleModalOpen = () => {
    setIsModalVisible(true);
  }

  const handleModalClose = () => {
    setIsModalVisible(false);
    setCsvFile([]);
  }

  const beforeUpload = (file) => {
    const isCsv = file?.type.startsWith('text/csv');
    if (isCsv) {
      return true;
    } else {
      message.error('Не является файлом .csv!');
      return false;
    }
  };

  const handleCsvChange = ({ fileList: newFileList }) => {
    const isCsv = newFileList[0]?.originFileObj ? beforeUpload(newFileList[0]?.originFileObj) : false;

    if (isCsv) {
      newFileList = newFileList.slice(-1);

      setCsvFile(newFileList);
    } else {
      setCsvFile([]);
    }
  }

  const handleSelectChange = (value) => {
    setCorporatContractId(value);
  }

  const handleSwitchChange = () => {
    setIsDefaultWalets(!isDefaultWalets);
  }

  const handleSendFile = () => {
    const data = {
      walletsFile: csvFile[0].originFileObj,
      corporatContractId: corporatContractId ? corporatContractId : undefined,
      isDefaultWalets: isDefaultWalets,
      isCreate: false,
    }

    onSubmit(data);
  };

  const handleSubmit = () => {
    const data = {
      walletsFile: csvFile[0].originFileObj,
      corporatContractId: corporatContractId ? corporatContractId : undefined,
      isDefaultWalets: isDefaultWalets,
      isCreate: true,
    }

    onSubmit(data);
    setIsModalVisible(false);
    setCsvFile([]);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setCsvFile([]);
  }

  return (
    <>
      <Button
        icon={<UploadOutlined />}
        onClick={handleModalOpen}
      >
        Загрузить .csv
      </Button>
      <Modal
        title="Добавить .csv"
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <Dragger
          customRequest={({ file, onSuccess }) => {
            setTimeout(() => {
              onSuccess(null, file);
            }, 0);
          }}
          name='file'
          fileList={csvFile}
          onChange={handleCsvChange}
        >
          <Text>Перетащите сюда</Text>
          <Title level={3} style={{margin: '10px 0'}}>Файл .csv</Title>
        </Dragger>

        <Flex style={{marginTop: '20px'}}>
          <Select
            style={{width: '100%'}}
            showSearch
            placeholder="Выберите договор"
            optionFilterProp="children"
            options={corpclientsOptions}
            filterOption={(input, option) =>
              (option?.contractNumber?.toString() ?? '').toLowerCase().includes(input.toLowerCase()) ||
              (option?.contractDate?.toString() ?? '').toLowerCase().includes(input.toLowerCase()) ||
              (option?.corporatContractId?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
            }
            onChange={handleSelectChange}
            // optionRender={(option) => (
            //   <Flex vertical >
            //     <Title level={5}>{option.data.contractNumber} от {option.data.contractDate}</Title>
            //     <Flex gap='small'>
            //       <Text type="secondary" size="small">ID: {option.data.corporatContractId}</Text>
            //     </Flex>
            //   </Flex>
            // )}
          />
        </Flex>

        <Flex style={{marginTop: '20px'}} gap={16}>
          <Text>Пополнение дефолтных кошельков</Text>
          <Switch
            value={isDefaultWalets}
            onChange={handleSwitchChange}
          />
        </Flex>

        <Flex style={{marginTop: '20px'}} justify='flex-end'>
          <Button
            type='primary'
            disabled={csvFile.length === 0}
            onClick={handleSendFile}
          >
            Отправить
          </Button>
        </Flex>

        { uploadInfo && <Title level={5}>{uploadInfo}</Title>}
        { uploadStatus === 1 && 
          <>
            <Text>Создать кошельки?</Text>
            <Flex justify='flex-end' gap={16}>
              <Button
                type='primary'
                onClick={handleSubmit}
              >
                Создать
              </Button>
              <Button
                type='primary'
                onClick={handleCancel}
              >
                Отменить
              </Button>
            </Flex>
          </>
        }
      </Modal>
    </>
  );
};

export default WalletsUploadComponent;
