import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setMessagePopupContent } from '../redux/reducers/utils/commonReducer';
import { message } from 'antd';

const MessagePopup = ({ messagePopup }) => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (messagePopup && messagePopup.type && messagePopup.content) {
      messageApi.open({
        type: messagePopup.type,
        content: messagePopup.content,
        duration: 3,
      });
      setTimeout(() => {
        dispatch(setMessagePopupContent({ type: '', content: '' }));
      }, 3000);
    }
  }, [messagePopup, messageApi, dispatch]);

  return <>{contextHolder}</>;
};

export default MessagePopup;
