import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestKitchens } from '../../../redux/reducers/utils/kitchenReducers/kitchenReducer';
import { Flex, Typography, List, Button } from 'antd';
import { Link } from 'react-router-dom';
import welcomePackImage from '../../../assets/img/packs/welcomePack.png';
import NewKitchenCreateModalComponent from './NewKitchenCreateModalComponent';

const { Title, Paragraph, Text } = Typography;
const steps = [
  'Добавить кухню',
  'Внести меню',
  'Заполнить реквизиты и подписать договор',
  'Провести тестовый запуск совместно с представителем Сытого',
  'Получайте заказы от новых клиентов 🤩'
];
const faq = [
  { url: '#', label: 'Как заполнить меню?' },
  { url: '#', label: 'Как подготовить фотографии блюд?' },
  { url: '#', label: 'Какую одноразовую посуду использовать?' },
  { url: '#', label: 'Как быть если у нас меню каждый день разное?' },
  { url: '#', label: 'Как происходит выплата и какая комиссия сервиса?' },
];

function WelcomeComponent() {
  const { userKitchenCreateRequestStatus } = useSelector((store) => store.kitchen);
  const dispatch = useDispatch();
  const [createModalVisible, setCreateModalVisible] = useState(false);


  useEffect(() => {
    if (userKitchenCreateRequestStatus === 'resolved') {
      dispatch(sendRequestKitchens({ token: localStorage.getItem('token'), offset: 0, limit: 10 }));
    }
  }, [userKitchenCreateRequestStatus]);

  const handleCreate = () => {
    setCreateModalVisible(true);
  }

  return (
    <div style={{padding: '30px 0'}}>
      <Flex gap={20}>
        <img src={welcomePackImage} alt=''/>
        <Title>Добро пожаловать<br/>в проект “Сытый”</Title>
      </Flex>

      <Paragraph style={{marginTop: '60px'}}>
        Добавьте свою кухню и принимайте новые заказы!<br/>
        Курьеры Сытого доставят их в любую точку города!
      </Paragraph>

      <Text>
        Несколько простых шагов для подключению к проекту:
      </Text>

      <List
        style={{marginTop: '20px'}}
        size="large"
        dataSource={steps}
        renderItem={(item, index) => (
          <List.Item style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '5px 0', border: 0}}>
            <span style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px',  borderRadius: '50px', background: '#dddddd', fontWeight: 'bold' }}>{index + 1}</span>
            {index === 0 ? <Button type='link' onClick={handleCreate}>{item}</Button> : <span style={{padding: '4px 15px'}}>{item}</span>}
          </List.Item>
        )}
      />

      <Title level={3} style={{marginTop: '60px'}}>Часто задаваемые вопросы</Title>

      <List
        size="large"
        dataSource={faq}
        renderItem={(item) => (
          <List.Item style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '10px 0', border: 0}}>
            <Link to={item.url}>{item.label}</Link>
          </List.Item>
        )}
      />

      <NewKitchenCreateModalComponent
        createModalVisible={createModalVisible}
        setCreateModalVisible={setCreateModalVisible}
      />
    </div>
  );
}

export default WelcomeComponent;
