import apiInstance from '../../../../../api';
import {call, put, select} from 'redux-saga/effects';
import {
  sendRequestKitchenActWorksGetSuccess,
  sendRequestKitchenActWorksGetError,
  setKitchenActWorks,
  sendRequestKitchenActWorksCreateSuccess,
  sendRequestKitchenActWorksCreateError,
  sendRequestKitchenActWorksUpdateSuccess,
  sendRequestKitchenActWorksUpdateError,
  sendRequestKitchenActWorksDeleteSuccess,
  sendRequestKitchenActWorksDeleteError,

  sendRequestKitchenActWorksSignedStaffSOSuccess,
  sendRequestKitchenActWorksSignedStaffSOError,

  sendRequestKitchenActWorksSignedStaffKitchenSuccess,
  sendRequestKitchenActWorksSignedStaffKitchenError,

  sendRequestKitchenActWorksSetStatusPaymentKitchenSuccess,
  sendRequestKitchenActWorksSetStatusPaymentKitchenError,
} from '../../../../reducers/utils/kitchenReducers/kitchenFinanceReducer';
import { setMessagePopupContent, selectCommonState } from '../../../../reducers/utils/commonReducer';

export function* handleGetKitchenActWorks(action) {
  const api = '/kitchen/actWorks/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload;
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenActWorksGetSuccess());
      yield put(setKitchenActWorks(response.data.data.actWorks));
    } else {
      yield put(sendRequestKitchenActWorksGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenActWorksGetError());
  }
}

export function* handleCreateKitchenActWorks(action) {
  const api = '/kitchen/actWorks/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload;
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenActWorksCreateSuccess());
    } else {
      yield put(sendRequestKitchenActWorksCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenActWorksCreateError());
  }
}

export function* handleUpdateKitchenActWorks(action) {
  const api = '/kitchen/actWorks/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload;
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenActWorksUpdateSuccess());
    } else {
      yield put(sendRequestKitchenActWorksUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenActWorksUpdateError());
  }
}

export function* handleDeleteKitchenActWorks(action) {
  const api = '/kitchen/actWorks/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload;
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenActWorksDeleteSuccess());
    } else {
      yield put(sendRequestKitchenActWorksDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenActWorksDeleteError());
  }
}

export function* handleSignedStaffSOActWorks(action) {
  const api = '/kitchen/actWorks/signedStaffSO?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload;
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenActWorksSignedStaffSOSuccess());
    } else {
      yield put(sendRequestKitchenActWorksSignedStaffSOError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenActWorksSignedStaffSOError());
  }
}

export function* handleSignedStaffKitchenActWorks(action) {
  const api = '/kitchen/actWorks/signedStaffKitchen?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload;
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenActWorksSignedStaffKitchenSuccess());
    } else {
      yield put(sendRequestKitchenActWorksSignedStaffKitchenError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenActWorksSignedStaffKitchenError());
  }
}

export function* handleSetStatusPaymentKitchenActWorks(action) {
  const api = '/kitchen/actWorks/setStatusPaymentKitchen?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload;
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchenActWorksSetStatusPaymentKitchenSuccess());
    } else {
      yield put(sendRequestKitchenActWorksSetStatusPaymentKitchenError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchenActWorksSetStatusPaymentKitchenError());
  }
}
