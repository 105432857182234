import React, { useState } from 'react';
import { Button, Modal, Form, Input, Select, Flex, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const CorpclientCreateComponent = ({ organizationOptions, onSubmit }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const handleModalOpen = () => {
    setIsModalVisible(true);
  }

  const handleModalClose = () => {
    setIsModalVisible(false);
    form.resetFields();
  }

  const handleSubmit = (values) => {
    onSubmit(values);
    setIsModalVisible(false);
    form.resetFields();
  };

  return (
    <>
      <Button
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
        onClick={handleModalOpen}
      >
        Добавить корп.клиента
      </Button>
      <Modal
        title="Добавить корп.клиента"
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item style={{ margin: '5px', flex: 1 }} label="Коммерческое название" name="commercialName" rules={[{ required: true, message: 'Введите название' }]}>
            <Input placeholder='Введите название'/>
          </Form.Item>
          <Form.Item style={{ margin: '5px', flex: 1 }} label='Организация' name='organizationId' rules={[{ required: true, message: 'Выберите организацию' }]}>
            <Select
              showSearch
              placeholder="Выберите организацию"
              optionFilterProp="children"
              options={organizationOptions}
              filterOption={(input, option) =>
                (option?.urName?.toString() ?? '').toLowerCase().includes(input.toLowerCase()) ||
                (option?.inn?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
              }
              optionRender={(option) => (
                <Flex
                  vertical
                  style={{ margin: '5px 0', height: '80px' }}
                >
                  <Text type='secondary'>ID: {option.data.id}</Text>
                  <Title level={5} style={{margin: '0'}}>{option.data.urName}</Title>
                  <Text type='secondary'>ИНН: {option.data.inn}</Text>
                </Flex>
              )}
            />
          </Form.Item>
          <Form.Item style={{ margin: '5px', flex: 1 }} label="Ответственное лицо (ФИО)" name="nameResponsiblePerson" rules={[{ required: true, message: 'Укажите ответственное лицо' }]}>
            <Input placeholder='Укажите ответственное лицо'/>
          </Form.Item>
          <Form.Item style={{ margin: '5px', flex: 1 }} label="Телефон контактного лица" name="phoneResponsiblePerson" rules={[{ required: true, message: 'Укажите телефон' }]}>
            <Input placeholder='Укажите телефон' maxLength={13}/>
          </Form.Item>
          <Form.Item style={{ margin: '5px', flex: 1 }} label="Адрес доставки" name="address" rules={[{ required: true, message: 'Выберите адрес доставки' }]}>
            <Input placeholder='Выберите адрес доставки'/>
          </Form.Item>
          <Flex style={{marginTop: '20px'}} justify='flex-end'>
            <Button type="primary" htmlType="submit">Добавить</Button>
          </Flex>
        </Form>
      </Modal>
    </>
  );
};

export default CorpclientCreateComponent;
