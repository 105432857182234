import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestKitchens, setSelectKitchen } from '../../../redux/reducers/utils/kitchenReducers/kitchenReducer';
import { setSelectedTemplate, setIsOpenRacesDrawer } from '../../../redux/reducers/utils/kitchenReducers/kitchenMenuReducer';
import { Select, Flex, Typography } from 'antd';
import styled from 'styled-components';

const { Title, Text } = Typography;

const KitchenSelectorComponent = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const {kitchens, selectKitchen} = useSelector((store) => store.kitchen);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestKitchens({ token: localStorage.getItem('token'), offset: 0, limit: 1000 }));
    }
  }, [isAuth]);

  const handleChange = (value) => {
    const kitchen = kitchens.find(kitchen => kitchen.id === value);
    
    dispatch(setSelectKitchen(kitchen))
    dispatch(setSelectedTemplate(null));
    dispatch(setIsOpenRacesDrawer(true));
  };

  const groupedKitchens = kitchens?.reduce((groups, kitchen) => {
    const { cityId } = kitchen;
    if (!groups[cityId]) {
      groups[cityId] = [];
    }
    groups[cityId].push(kitchen);
    return groups;
  }, {});

  const kitchenOptions = Object.keys(groupedKitchens).map(cityId => {
    const cityKitchens = groupedKitchens[cityId];
    const cityName = cityKitchens[0].cityName;

    const options = cityKitchens.map(kitchen => ({
      ...kitchen,
      label: kitchen.name,
      value: kitchen.id,
    }));

    return {
      label: cityName,
      title: cityName,
      options: options,
    };
  });

  return (
    <Select
      style={{minWidth: '250px'}}
      showSearch
      placeholder="Выберите кухню"
      optionFilterProp="children"
      options={kitchenOptions}
      onChange={handleChange}
      defaultValue={selectKitchen?.id}
      filterOption={(input, option) =>
        (option?.id?.toString() ?? '').toLowerCase().includes(input.toLowerCase()) ||
        (option?.name?.toString() ?? '').toLowerCase().includes(input.toLowerCase()) ||
        (option?.inn?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
      }
      optionRender={(option) => (
        <Flex vertical >
          <Title level={5}>{option.data.name}</Title>
          <Flex gap='small'>
            <Text type="secondary" size="small">ID: {option.data.id}</Text>
            <Text type="secondary" size="small">{option.data.cityName}</Text>
          </Flex>
        </Flex>
      )}
    />
  );
};

export default KitchenSelectorComponent;
