import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestDeliveryServiceRacesCreate } from '../../../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Flex, Modal, Form, Select, Input, TimePicker, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

const RaceCreateModalComponent = () => {
  const { cities } = useSelector((store) => store.settingsCatalogs);
  const { currentDeliveryService } = useSelector((store) => store.deliveryServices);
  const dispatch = useDispatch();
  const [ isCreate, setIsCreate] = useState(false);
  const [form] = Form.useForm();

  const handleOpen = () => {
    setIsCreate(true);
  };

  const handleCancel = () => {
    setIsCreate(false);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      cityId: values.city,
      deliveryServiceId: currentDeliveryService.id,
      timeStart: moment(new Date(values.timeStart)).format('HH:mm'),
      timeDelivery: values.timeDelivery,
      initialCourierCutoff: moment(new Date(values.initialCourierCutoff)).format('HH:mm'),
      finalCourierCutoff: moment(new Date(values.finalCourierCutoff)).format('HH:mm'),
    }

    console.log(requestData)
    dispatch(sendRequestDeliveryServiceRacesCreate(requestData));
    setIsCreate(false);
    form.resetFields();
  }

  const cityOptions = cities?.map((item) => ({
    ...item,
    value: item.id,
    label: item.name,
  }));
  
  return (
    <>
      <Button
        type='primary'
        shape="round"
        icon={<PlusOutlined />}
        onClick={handleOpen}
        style={{marginLeft: 'auto'}}
      >
        Добавить рейс
      </Button>
      <Modal
        title="Создание рейса доставки"
        open={isCreate}
        onCancel={handleCancel}
        footer={null}
      >
        <Form style={{ width: '100%' }} form={form} name='deliveryService' layout='vertical' onFinish={handleSubmit}>
          <Form.Item label='Город' name='city' rules={[{ required: true, message: 'Выберите город' }]}>
            <Select
              showSearch
              placeholder="Выберите город"
              optionFilterProp="children"
              options={cityOptions}
              filterOption={(input, option) =>
                (option?.name?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Flex gap='large'>
            <Form.Item label="Time Start" name="timeStart" rules={[{ required: true, message: 'Please select Time Start!' }]}>
              <TimePicker format="HH:mm"/>
            </Form.Item>
            <Form.Item label="Time Delivery" name="timeDelivery" rules={[{ required: true, message: 'Please input Time Delivery!' }]}>
              <Input placeholder='60'/>
            </Form.Item>
          </Flex>
          <Flex gap='large'>
            <Form.Item label="Initial Courier Cutoff" name="initialCourierCutoff">
              <TimePicker format="HH:mm"/>
            </Form.Item>
            <Form.Item label="Final Courier Cutoff" name="finalCourierCutoff">
              <TimePicker format="HH:mm"/>
            </Form.Item>
          </Flex>
          <Button type='primary' htmlType='submit' style={{ display: 'flex', marginLeft: 'auto' }}>Создать</Button>
        </Form>
      </Modal>
    </>
  );
};

export default RaceCreateModalComponent;
