import React, { useRef, forwardRef } from 'react';
import { Flex, Button } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';

const PrintTable = forwardRef(({ children }) => {
  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <Flex vertical>
      <Button
        style={{marginLeft: 'auto', width: 'fit-content'}}
        type='text'
        size='small'
        icon={<PrinterOutlined />}
        onClick={handlePrint}
      >
        распечатать
      </Button>
      <div ref={printRef}>
        {children}
      </div>
    </Flex>
  );
});

export default PrintTable;
