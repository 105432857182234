import React from 'react';
import { useDispatch } from 'react-redux';
import { setIsShowAssortmentDrawer, setCurrentProductId } from '../../../../../redux/reducers/utils/kitchenReducers/kitchenAssortmentReducer';
import { Flex, Typography, Table, Image, Tag } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import PrintTable from '../../../../../components/PrintTable';

const { Text } = Typography;

const AssortmentTableComponent = ({ products, currentProductId }) => {
  const dispatch = useDispatch();
  
  const columns = [
    {
      title: 'Название',
      key: 'name',
      render: (record) => (
        <Flex gap='large'>{!record.enabledByAdmin && <Text><ExclamationCircleFilled style={{ fontSize: '24px', color: '#FFA800' }}/></Text>}{record.name}</Flex>
      ),
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
      sorter: (a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        if (!a.name && b.name) return 1;
        if (a.name && !b.name) return -1;
        return 0;
      }
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
      sorter: (a, b) => {
        if (a.description && b.description) {
          return a.description.localeCompare(b.description);
        }
        if (!a.description && b.description) return 1;
        if (a.description && !b.description) return -1;
        return 0;
      }
    },
    {
      title: 'Цена, руб',
      dataIndex: 'price',
      key: 'price',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: 'Вес, грамм',
      dataIndex: 'weight',
      key: 'weight',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
      sorter: (a, b) => a.weight - b.weight,
    },
    {
      title: 'КБЖУ',
      key: 'energyValue',
      render: (record) => (
        <Flex>{+record.calories}/{+record.proteins}/{+record.fats}/{+record.carbs}</Flex>
      ),
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Изображение',
      dataIndex: 'small_filePathPublic',
      key: 'small_filePathPublic',
      render: (_, record) => (
        <Image.PreviewGroup
          items={[record.big_filePathPublic]}
          mask='false'
          preview={{
            toolbarRender: (_,{}) => (<Flex size={12} className="toolbar-wrapper"></Flex>),
          }}
        >
          <Image
            width={70}
            src={_}
            mask='false'
            preview={{
              toolbarRender: (_,{}) => (<Flex size={12} className="toolbar-wrapper"></Flex>),
            }}
          />
        </Image.PreviewGroup>
      ),
    },
    {
      title: 'Модерация',
      key: 'enabledByAdmin',
      render: (record) => {
        let color = '';
        let status = ''
        if (!record.enabledByAdmin) {
          color = 'volcano';
          status = 'На модерации'
        } else {
          color = 'green';
          status = 'Проверен'
        }
        return (
          <Tag color={color}>
            {status.toUpperCase()}
          </Tag>
        );
      },
      filters: [
        {
          text: 'На модерации',
          value: true,
        },
        {
          text: 'Проверен',
          value: false,
        },
      ],
      onFilter: (value, record) => record.enabledByAdmin === value,
    },
    {
      title: 'Статус',
      key: 'isEnabled',
      render: (record) => {
        let color = '';
        let status = ''
        if (record.isEnabled) {
          color = 'green';
          status = 'Включен'
        } else {
          color = 'volcano';
          status = 'Выключен'
        }
        return (
          <Tag color={color}>
            {status.toUpperCase()}
          </Tag>
        );
      },
      filters: [
        {
          text: 'Включен',
          value: true,
        },
        {
          text: 'Выключен',
          value: false,
        },
      ],
      onFilter: (value, record) => record.isEnabled === value,
    },
  ];

  const handleCellClick = (record) => {
    dispatch(setCurrentProductId(record.id));
    dispatch(setIsShowAssortmentDrawer(true));
  };

  const handleRowClick = (record) => {
    console.log(record);
  };

  return (
    <PrintTable>
      <Table
        dataSource={products}
        columns={columns}
        scroll={{ x: 'auto' }}
        bordered
        size="small"
        pagination={false}
        onRow={(record) => ({
          style: { background: record.id === currentProductId ? '#e6f7ff' : '' },
          onClick: () => handleRowClick(record),
        })}
      />
    </PrintTable>
  );
};

export default AssortmentTableComponent;
