import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestDeliveryServiceRacesUpdate,
  sendRequestDeliveryServiceRacesDelete,
  setCurrentDeliveryServiceRace,
  sendRequestDeliveryServiceNotWorkingGet,
  sendRequestDeliveryServiceNotWorkingCreate,
  sendRequestDeliveryServiceNotWorkingDelete,
} from '../../../../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Flex, Select, Form, TimePicker, Popconfirm, Input, Button } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import YearCalendar from '../../../../../../../../components/YearCalendar';

const EditableForm = () => {
  const navigate = useNavigate();
  const { isAuth } = useSelector((store) => store.auth);
  const { cities } = useSelector((store) => store.settingsCatalogs);
  const {
    currentDeliveryService,
    currentDeliveryServiceRace,
    deliveryServiceNotWorking,
    deliveryServiceNotWorkingCreateRequestStatus,
    deliveryServiceNotWorkingDeleteRequestStatus,
  } = useSelector((store) => store.deliveryServices);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestDeliveryServiceNotWorkingGet({ token: localStorage.getItem('token'), deliveryServiceId: currentDeliveryService.id, raceId: currentDeliveryServiceRace.id}));
    }
  }, [isAuth]);

  useEffect(() => {
    if (
      deliveryServiceNotWorkingCreateRequestStatus === 'resolved' ||
      deliveryServiceNotWorkingDeleteRequestStatus === 'resolved'
    ) {
      dispatch(sendRequestDeliveryServiceNotWorkingGet({ token: localStorage.getItem('token'), deliveryServiceId: currentDeliveryService.id, raceId: currentDeliveryServiceRace.id}));
    }
  }, [
    deliveryServiceNotWorkingCreateRequestStatus,
    deliveryServiceNotWorkingDeleteRequestStatus,
  ]);

  useEffect(() => {
    if (currentDeliveryServiceRace) {
      form.setFieldsValue({
        city: {value: currentDeliveryServiceRace.cityId, label: cities?.find(city => city.id.toString() === currentDeliveryServiceRace.cityId.toString())?.name}.value,
        timeStart: dayjs(currentDeliveryServiceRace.timeStart, timeFormat),
        timeDelivery: currentDeliveryServiceRace.timeDelivery,
        initialCourierCutoff: dayjs(currentDeliveryServiceRace.initialCourierCutoff, timeFormat),
        finalCourierCutoff: dayjs(currentDeliveryServiceRace.finalCourierCutoff, timeFormat),
      });
    }
  }, [currentDeliveryServiceRace, form]);

  dayjs.extend(customParseFormat);
  const timeFormat = 'HH:mm';

  const handleSubmit = (values) => {
    form.validateFields()
    .then((values) => {
      const requestData = {
        token: localStorage.getItem('token'),
        raceId: currentDeliveryServiceRace.id,
        cityId: values.city,
        deliveryServiceId: currentDeliveryService.id,
        timeStart: moment(new Date(values.timeStart)).format('HH:mm'),
        timeDelivery: values.timeDelivery,
        initialCourierCutoff: moment(new Date(values.initialCourierCutoff)).format('HH:mm'),
        finalCourierCutoff: moment(new Date(values.finalCourierCutoff)).format('HH:mm'),
      }
      console.log(requestData)
      dispatch(sendRequestDeliveryServiceRacesUpdate(requestData));
      form.resetFields();
    })
    .catch((errorInfo) => {
      console.error('Ошибка при валидации формы:', errorInfo);
    });
  }

  const cityOptions = cities?.map((item) => ({
    ...item,
    value: item?.id,
    label: item?.name,
  }));

  const handleDelete = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      raceId: currentDeliveryServiceRace.id,
    }
    
    dispatch(sendRequestDeliveryServiceRacesDelete(requestData));
    navigate(`/delivery/services/${currentDeliveryService.id}`);
    setCurrentDeliveryServiceRace(null);
  };

  const handleAddNotWorkingDay = (day) => {
    const requestData = {
      token: localStorage.getItem('token'),
      deliveryServiceId: currentDeliveryService.id,
      raceId: currentDeliveryServiceRace.id,
      days: [day]
    }
    
    dispatch(sendRequestDeliveryServiceNotWorkingCreate(requestData));
  };

  const handleRemoveNotWorkingDay = (day) => {
    const requestData = {
      token: localStorage.getItem('token'),
      deliveryServiceId: currentDeliveryService.id,
      raceId: currentDeliveryServiceRace.id,
      days: [day]
    }
    
    dispatch(sendRequestDeliveryServiceNotWorkingDelete(requestData));
  };

  return (
    <Flex vertical>
      <Form
        style={{ width: '100%' }}
        form={form}
        name='deliveryService'
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={{
          city: {value: currentDeliveryServiceRace.cityId, label: cities?.find(city => city.id.toString() === currentDeliveryServiceRace.cityId.toString())?.name}.value,
          timeStart: dayjs(currentDeliveryServiceRace.timeStart, timeFormat),
          timeDelivery: currentDeliveryServiceRace.timeDelivery,
          initialCourierCutoff: dayjs(currentDeliveryServiceRace.initialCourierCutoff, timeFormat),
          finalCourierCutoff: dayjs(currentDeliveryServiceRace.finalCourierCutoff, timeFormat),
        }}
      >
        <Form.Item label='Город' name='city' rules={[{ required: true, message: 'Выберите город' }]}>
          <Select
            showSearch
            placeholder="Выберите город"
            optionFilterProp="children"
            options={cityOptions}
            filterOption={(input, option) =>
              (option?.name?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Flex gap='large'>
          <Form.Item label="Time Start" name="timeStart" rules={[{ required: true, message: 'Please select Time Start!' }]}>
            <TimePicker format="HH:mm"/>
          </Form.Item>
          <Form.Item label="Time Delivery" name="timeDelivery" rules={[{ required: true, message: 'Please input Time Delivery!' }]}>
            <Input placeholder='60'/>
          </Form.Item>
        </Flex>
        <Flex gap='large'>
          <Form.Item label="Initial Courier Cutoff" name="initialCourierCutoff">
            <TimePicker format="HH:mm"/>
          </Form.Item>
          <Form.Item label="Final Courier Cutoff" name="finalCourierCutoff">
            <TimePicker format="HH:mm"/>
          </Form.Item>
        </Flex>
      </Form>
      <Flex gap='large'>
        <Button type='primary' htmlType='submit' onClick={handleSubmit} style={{ display: 'flex', marginLeft: 'auto' }}>Сохранить</Button>
        <Popconfirm
          title="Удаление рейса!"
          description={`Вы действительно хотите удалить рейс?`}
          okText="Да"
          cancelText="Нет"
          placement="left"
          onConfirm={handleDelete}
        >
          <Button danger>Удалить</Button>
        </Popconfirm>
      </Flex>

      <div style={{marginTop: '60px'}}>
        <YearCalendar
          data={deliveryServiceNotWorking}
          onCreate={handleAddNotWorkingDay}
          onDelete={handleRemoveNotWorkingDay}
        />
      </div>

    </Flex>
  );
};

export default EditableForm;
