import { takeLatest } from 'redux-saga/effects';
import {
  //Search
  handleGetClients,

  //Detail
  handleGetClientProfile,
  handleGetClientOrdersStatistics,
  handleGetClientOrders,
  handleGetClientAddresses,
  handleMakeAddressActive,
  handleGetClientDevices,
  handleGetPushNotification,
  handleGetMessages,
} from './clientsSearchHandler';
import {
  //Search
  sendRequestClientsGet,

  //Detail
  sendRequestClientProfileGet,
  sendRequestClientOrdersStatisticsGet,
  sendRequestClientOrdersGet,
  sendRequestClientAddressesGet,
  sendRequestMakeAddressActive,
  sendRequestClientDevicesGet,
  sendRequestPushNotificationGet,
  sendRequestMessagesGet,
} from '../../../../reducers/utils/clientsReducers/clientsSearchReducer';

export function* clientsSearchWatcherSaga() {
  //Search
  yield takeLatest(sendRequestClientsGet.type, handleGetClients);

  //Detail
  yield takeLatest(sendRequestClientProfileGet.type, handleGetClientProfile);
  yield takeLatest(sendRequestClientOrdersStatisticsGet.type, handleGetClientOrdersStatistics);
  yield takeLatest(sendRequestClientOrdersGet.type, handleGetClientOrders);
  yield takeLatest(sendRequestClientAddressesGet.type, handleGetClientAddresses);
  yield takeLatest(sendRequestMakeAddressActive.type, handleMakeAddressActive);
  yield takeLatest(sendRequestClientDevicesGet.type, handleGetClientDevices);
  yield takeLatest(sendRequestPushNotificationGet.type, handleGetPushNotification);
  yield takeLatest(sendRequestMessagesGet.type, handleGetMessages);
}