import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestkitchenSettingsAccessSet } from '../../../../../redux/reducers/utils/kitchenReducers/kitchenSettingsReducer';
import { Flex, Switch } from 'antd';

const checkElementAccess = (element, accessData) => {
  const foundKey = accessData.keys.find(key => key.id === element);

  if (foundKey) {
    const keyRoles = Object.values(accessData.keysRoles);
    const roles = keyRoles.filter(role => role?.entitySettingKeyId === element);
    const kitchenValue = accessData?.keysKitchen.find(kitchen => kitchen?.entitySettingKeyId === element);
    const hasReadwrite = roles.some(role => role.action === 'readwrite');
    const action = hasReadwrite ? 'readwrite' : 'read';
    const value = kitchenValue ? kitchenValue.value : '';

    return {
      id: foundKey.id,
      visible: roles.length > 0,
      editable: hasReadwrite,
      action: action,
      value: value
    };
  } else {
    return { id: null, visible: false, editable: false, action: 'read', value: '' };
  }
}

const AdminCheckList = () => {
  const { selectKitchen } = useSelector((store) => store.kitchen);
  const { kitchenSettingsAccess } = useSelector((store) => store.kitchenSettings);
  const dispatch = useDispatch();

  const handleAccessInputChange = (inputValue) => {
    const key = Object.keys(inputValue)[0];
    const value = inputValue[key].toString();

    const requestData = {
      token: localStorage.getItem('token'),
      kitchenId: selectKitchen.id,
      keyValue: [
        {
          entitySettingKeyId: key,
          value: value
        }
      ]
    }

    dispatch(sendRequestkitchenSettingsAccessSet(requestData));
  }

  return (
    <>
      {kitchenSettingsAccess && [...kitchenSettingsAccess.keys]
      .sort((a, b) => a.sort - b.sort)
      .map(key => {

        if ([40, 41, 42, 43, 44].includes(key.id)) {
          const accessData = checkElementAccess(key.id, kitchenSettingsAccess);

          return (
            <>
              {accessData.visible &&
                <Flex>
                  {key.name}
                  <Switch
                    style={{marginBottom: '10px'}}
                    disabled={!accessData.editable}
                    value = {accessData.value === 'false' ? false : Boolean(accessData.value)}
                    onChange={(value) => handleAccessInputChange({[accessData.id]: value})}
                  />
                </Flex>
              }
            </>
          )
        }

      })}
    </>
  );
};

export default AdminCheckList;
