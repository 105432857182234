import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestAddProductMenuTemplate,
  sendRequestRemoveProductMenuTemplate,
  sendRequestUpdateProductMenuTemplate,
} from '../../../../../../redux/reducers/utils/kitchenReducers/kitchenMenuReducer';
import { Flex, Typography, Input, Switch, Image, Table } from 'antd';
import PrintTable from '../../../../../../components/PrintTable';

const { Title } = Typography;
const { Search } = Input;

const MenuTemplateProductListComponent = () => {
  const { menuTemplateProducts, selectedTemplate } = useSelector((store) => store.kitchenMenu);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [isFilter, setIsFilter] = useState(false);

  const isChecked = (inMenu, isStatus) => {
    if (inMenu === true && isStatus === 'on') {
      return true;
    }
    return false;
  };

  const onChange = () => {
    setIsFilter(!isFilter);
  };

  const handleSwitchProductInMenu = (menuProduct) => {
    const addProductData = {
      token: localStorage.getItem('token'),
      products: [
        {
          productId: menuProduct.productId,
          price: menuProduct.price,
          amountForOrder: menuProduct.amountForOrder || 0,
          amoutForOrderAlonePerson: 0,
          status: 'on',
        }
      ],
      menuTemplateId: selectedTemplate.id,
    }
  
    const enableDisableData = {
      token: localStorage.getItem('token'),
      menuTemplateId: selectedTemplate.id,
      productId: menuProduct.productId,
    }

    const updateData = {
      token: localStorage.getItem('token'),
      menuTemplateId: selectedTemplate.id,
      productId: menuProduct.productId,
      price: menuProduct.price,
      amountForOrder: menuProduct.amountForOrder || 0,
      amoutForOrderAlonePerson: 0,
      status: 'on',
    }
  
    if (menuProduct.inMenu === true) {
      if (menuProduct.status === 'on') {
        dispatch(sendRequestUpdateProductMenuTemplate({...updateData, status: 'off'}))
      } else {
        dispatch(sendRequestUpdateProductMenuTemplate(updateData))
      }
    } else {
      dispatch(sendRequestAddProductMenuTemplate(addProductData));
    }
  };

  const filteredMenuTemplateProducts = menuTemplateProducts?.filter((item) =>
    item.name?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.description?.toString().toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredData = isFilter
  ? filteredMenuTemplateProducts.filter(item => item.status === 'on')
  : filteredMenuTemplateProducts;

  const handleSearch = (value) => {
    if (value.length > 2) {
      setSearchTerm(value);
    } else {
      setSearchTerm('');
    }
  };

  const groupedProducts = filteredData.reduce((acc, product) => {
    const categoryName = product.categoryName;
    if (!acc[categoryName]) {
      acc[categoryName] = [];
    }
    acc[categoryName].push(product);
    return acc;
  }, {});

  const columns = [
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Switch
          checked={isChecked(record.inMenu, record.status)}
          onChange={() => handleSwitchProductInMenu(record)}
        />
      ),
    },
    {
      title: 'В\u00A0меню',
      key: 'amountForOrder',
      render: (_, record) => (
        <EditableCell
          menuProduct={record}
          selectedTemplate={selectedTemplate}
        />
      ),
    },
    {
      title: 'Название, состав',
      key: 'name',
      render: (_, record) => (
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div><b>{record.name}</b></div>
          <div 
            style={{
              msTextOverflow: 'ellipsis',
              OTextOverflow: 'ellipsis',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              msLineClamp: 1,
              WebkitLineClamp: 1,
              lineClamp: 1,
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              boxOrient: 'vertical',
              wordWrap: 'break-word',
            }}
          >
            {record.description}
          </div>
        </div>
      ),
      width: '100%',
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
      responsive: ['md'],
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: 'Фото',
      key: 'filePathPublic',
      dataIndex: 'filePathPublic',
      responsive: ['md'],
      render: (filePathPublic) => (
        <Image
          src={filePathPublic}
          width={50}
          mask={false}
        />
      ),
    },
    {
      title: 'Вес',
      dataIndex: 'weight',
      key: 'weight',
      responsive: ['md'],
      sorter: (a, b) => a.weight - b.weight,
    },
  ];

  return (
    <>
      {selectedTemplate !== null ? (
        <>
          <Search
            placeholder="Поиск по названию или описанию"
            onChange={(e) => handleSearch(e.target.value)}
            style={{ maxWidth: '500px' }}
          />
          <Flex gap={20} align='center' style={{margin: '10px 0'}}>Только опубликованные <Switch onChange={onChange}/></Flex>
          {Object.keys(groupedProducts).map(categoryName => (
            <div key={categoryName}>
              <Title level={3} style={{marginTop: '10px'}}>{categoryName}</Title>
              <PrintTable>
                <Table
                  dataSource={groupedProducts[categoryName]}
                  columns={columns}
                  size="small"
                  pagination={false}
                />
              </PrintTable>
            </div>
          ))}
        </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>Выберите шаблон</div>
      )}
    </>
  );  
};

export default MenuTemplateProductListComponent;

const EditableCell = ({menuProduct, selectedTemplate}) => {
  const dispatch = useDispatch();
  const [inMenuValue, setInMenuValue] = useState(menuProduct.amountForOrder);

  useEffect(() => {
    setInMenuValue(menuProduct.amountForOrder);
  }, [menuProduct])

  const handleChangeInMenuValue = (event) => {
    setInMenuValue(event.target.value);
  };
  
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      const requestData = {
        token: localStorage.getItem('token'),
        menuTemplateId: selectedTemplate.id,
        productId: menuProduct.productId,
        price: menuProduct.price,
        amountForOrder: inMenuValue,
        amoutForOrderAlonePerson: 0,
        status: 'on',
      }
      
      dispatch(sendRequestUpdateProductMenuTemplate(requestData));
    }
  };

  return (
    <Input
      placeholder={menuProduct.amountForOrder}
      value={inMenuValue}
      maxLength={3}
      disabled={menuProduct.status === 'off'}
      onChange={handleChangeInMenuValue}
      onKeyDown={handleEnterKeyPress}
    />
  )
};