import React, { useEffect, useState } from 'react';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;
const serverFormat = 'YYYY-MM-DD';
const clientFormat = 'DD-MM-YYYY';

const DateRangePicker = ({ initialDateFrom, initialDateTo, onChange }) => {
  const [dates, setDates] = useState([dayjs(initialDateFrom), dayjs(initialDateTo)]);

  const handleDateChange = (dates) => {
    if (dates) {
      setDates(dates);
      onChange({
        dateFrom: dates[0].format(serverFormat),
        dateTo: dates[1].format(serverFormat),
      });
    }
  };

  return (
    <Space size={12}>
      <label>Выберите период:</label>
      <RangePicker
        defaultValue={dates}
        onChange={handleDateChange}
        format={clientFormat}
      />
    </Space>
  );
};

export default DateRangePicker;
