import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { setIsShowCityDrawer, setCurrentCity } from '../../../../../redux/reducers/utils/settingsReducers/settingsCatalogsReducer';
import { Flex, Drawer, Popconfirm, Button, Form, Input } from 'antd';

const CityDrawerComponent = ({ isShowCityDrawer, currentCity, onCreateCity, onUpdateCity, onDeleteCity }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isFormChanged, setIsFormChanged] = useState(false);

  const handleCreateCity = () => {
    form.validateFields()
      .then((values) => {
        onCreateCity(values);
        form.resetFields();
        handleCloseDrawer();
      })
      .catch((errorInfo) => {
        console.error('Ошибка при валидации формы:', errorInfo);
      });
  };

  const handleUpdateCity = () => {
    form.validateFields()
      .then((values) => {
        onUpdateCity({ ...values, cityId: currentCity.id });
        handleCloseDrawer();
      })
      .catch((errorInfo) => {
        console.error('Ошибка при валидации формы:', errorInfo);
      });
  };

  const handleDeleteCity = () => {
    onDeleteCity(currentCity.id);
    handleCloseDrawer();
  }

  const handleCloseDrawer = () => {
    form.resetFields();
    setIsFormChanged(false);
    dispatch(setIsShowCityDrawer(false));
    dispatch(setCurrentCity(null));
  };

  const handleFormChange = () => {
    setIsFormChanged(true);
  };

  useEffect(() => {
    if (currentCity) {
      setIsFormChanged(false);
      form.setFieldsValue(currentCity);
    }
  }, [currentCity, form]);

  return (
    <Drawer
      title={
        <>
          {currentCity ?
            <Flex gap={20}>
              { isFormChanged &&
                <>
                  <Button type="primary" onClick={handleUpdateCity}>Сохранить</Button>
                  <Button onClick={handleCloseDrawer}>Отменить</Button>
                </>
              }
              <Popconfirm
                title="Удаление города!"
                description={`Вы действительно хотите удалить город ${currentCity.name}`}
                okText="Да"
                cancelText="Нет"
                placement="left"
                onConfirm={handleDeleteCity}
              >
                <Button danger>Удалить город</Button>
              </Popconfirm>
            </Flex>
            :
            <Button type="primary" onClick={handleCreateCity}>Создать</Button>
          }
        </>
      }
      placement="right"
      onClose={handleCloseDrawer}
      open={isShowCityDrawer}
      width='40%'
      mask={false}
    >
      <Form
        form={form}
        onValuesChange={handleFormChange}
      >
        <Form.Item label="Название" name="name" rules={[{ required: true, message: 'Обязательное поле!' }]}><Input /></Form.Item>
        <Form.Item label="Временная зона" name="timezone" rules={[{ required: true, message: 'Обязательное поле!' }]}><Input /></Form.Item>
        <Form.Item label="Широта" name="latitude" rules={[{ required: true, message: 'Обязательное поле!' }]}><Input /></Form.Item>
        <Form.Item label="Долгота" name="longitude" rules={[{ required: true, message: 'Обязательное поле!' }]}><Input /></Form.Item>
      </Form>
    </Drawer>
  );
}

export default CityDrawerComponent;
