import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  messagePopupContent: { type: '', content: '' },
  screenWidth: window.innerWidth,
  isMobile: window.innerWidth <= 767,
  apiUrl: 'https://api-test.menuforme.online/api/v1/admin',
};

export const selectCommonState = state => state.common;

const commonSlice = createSlice({
  name: 'common',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    setMessagePopupContent: (state, action) => {
      state.messagePopupContent = action.payload;
    },
    setScreenWidth: (state, action) => {
      return {
        ...state,
        screenWidth: action.payload,
        isMobile: action.payload <= 767,
      };
    },

    setSerjApiUrl: (state) => {
      state.apiUrl = 'http://api.menu4me.ru/api/v1/admin';
    },
    setDevApiUrl: (state) => {
      state.apiUrl = 'https://bottest2.menu4me.ru/api/v1/admin';
    },
    setProdApiUrl: (state) => {
      state.apiUrl = 'https://api-test.menuforme.online/api/v1/admin';
    },
  },
});

export const {
  setMessagePopupContent,
  setScreenWidth,

  setSerjApiUrl,
  setDevApiUrl,
  setProdApiUrl,
} = commonSlice.actions;

export default commonSlice.reducer;
