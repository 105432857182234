import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Table, Input, Modal, Form, Select, Popconfirm, Button } from 'antd';
import styled from 'styled-components';
import RoleUsersCreateComponent from './RoleUsersCreateComponent';
import { ReactComponent as IconEdit } from '../../../../../assets/icons/icon-edit.svg';
import { ReactComponent as IconClose } from '../../../../../assets/icons/icon-close.svg';

const { Search } = Input;

const AccessListRoleUsers = ({
  roleUsers,
  dataForTypeObjects,
  onCreateRoleUsers,
  onUpdateRoleUsers,
  onDeleteRoleUsers
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const roleUsersWithKey = roleUsers.map(item => ({...item, key: item.id}))
  const filteredRoleUsers = roleUsersWithKey?.filter((item) =>
    item.phone?.toString().includes(searchTerm.toLowerCase()) ||
    item.roleName?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.typeObjectName?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.objectName?.toString().toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const columns = [
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'РОЛЬ',
      dataIndex: 'roleName',
      key: 'roleName',
    },
    {
      title: 'Тип объекта',
      dataIndex: 'typeObjectName',
      key: 'typeObjectName',
    },
    {
      title: 'Объект',
      dataIndex: 'objectName',
      key: 'objectName',
    },
    {
      title: '',
      key: 'actions',
      render: (_, record) => (
        <ActionComponent
          roleUser={record}
          dataForTypeObjects={dataForTypeObjects}
          onUpdateRoleUsers={onUpdateRoleUsers}
          onDeleteRoleUsers={onDeleteRoleUsers}
        />
      ),
    },
  ];
  
  return (
    <>
      <RoleUsersCreateComponent
        dataForTypeObjects={dataForTypeObjects}
        onCreateRoleUsers={onCreateRoleUsers}
      />
      <Search
        placeholder="Поиск по названию или описанию"
        onChange={(e) => handleSearch(e.target.value)}
        style={{ margin: '20px 0' }}
      />
      <Table
        dataSource={filteredRoleUsers}
        columns={columns}
        size="small"
        pagination={{
          defaultPageSize: 50,
        }}
      />
    </>
  );
};

export default AccessListRoleUsers;

const ActionComponent = ({roleUser, dataForTypeObjects, onUpdateRoleUsers, onDeleteRoleUsers}) => {
  const { accessRoleComponents } = useSelector((store) => store.services);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  const roles = accessRoleComponents.roles;
  const [curentValueObject, setCurentValueObject] = useState(dataForTypeObjects.find(typeObject => roleUser.typeObjectId === typeObject.id));

  const roleOptions = roles?.map((role) => ({
    value: role.id,
    label: role.description,
  }));

  const typeObjectOptions = dataForTypeObjects.map((typeObject) => ({
    value: typeObject.id,
    label: typeObject.name,
  }));

  const handleTypeObjectChange = (value) => {
    const curentValueObject = dataForTypeObjects.find(typeObject => value === typeObject.id)
    setCurentValueObject(curentValueObject);
  }

  const objectOptions = curentValueObject.valueObject.map((object) => ({
    value: object.id,
    label: object.name,
  }));

  const handleEdit = () => {
    setIsModalVisible(true);
  }

  const onFinish = (value) => {
    onUpdateRoleUsers({...value, roleUserId: roleUser.id, userId: roleUser.userId});
    setIsModalVisible(false);
  }

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleDelete = () => {
    onDeleteRoleUsers(roleUser.id);
  };

  return (
    <Action>
      <Button onClick={handleEdit}><IconEdit/></Button>
      <Modal
        title="Редактирование прав пользователя"
        open={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        <Form
          form={form}
          name='MapFilter'
          onFinish={onFinish}
          initialValues={{
            phone: roleUser.phone,
            role: roleOptions?.find(roleOption => roleOption.value === roleUser.roleId)?.value,
            typeObjectId: typeObjectOptions?.find(typeObjectOption => typeObjectOption.value === roleUser.typeObjectId)?.value,
            objectId: objectOptions?.find(objectOption => objectOption.value === roleUser.objectId)?.value
          }}
        >
          <Form.Item name='phone'>
            <div>{roleUser.phone}</div>
          </Form.Item>

          <Form.Item name='role' rules={[{ required: true, message: 'Выберите новую роль' }]} >
            <Select
              options={roleOptions}
              placeholder={roleUser.roleName}
            />
          </Form.Item>

          <Form.Item name='typeObjectId' rules={[{ required: true, message: 'Выберите новый тип объекта' }]} >
            <Select
              options={typeObjectOptions}
              placeholder={roleUser.typeObjectName}
              onChange={handleTypeObjectChange}
            />
          </Form.Item>

          <Form.Item name='objectId' rules={[{ required: true, message: 'Выберите новый объект' }]} >
            <Select
              options={objectOptions}
              placeholder={roleUser.objectName}
            />
          </Form.Item>

          <Form.Item>
            <Button type='primary' htmlType='submit'>Сохранить</Button>
          </Form.Item>
        </Form>
      </Modal>
      <Popconfirm
        title="Удаление пользователя!"
        description={`Вы действительно хотите удалить пользователя ${roleUser.phone}`}
        okText="Да"
        cancelText="Нет"
        placement="left"
        onConfirm={handleDelete}
      >
        <Button danger>Удалить</Button>
      </Popconfirm>
    </Action>
  );
};

const Action = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`