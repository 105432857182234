import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Form, Input, Button } from 'antd';

const ModalCreateAccessComponent = ({onCreateComponent}) => {
  const [ isCreate, setIsCreate] = useState(false);
  const [form] = Form.useForm();

  const handleOpenCreateComponentForm = () => {
    setIsCreate(true);
  };

  const handleCancel = () => {
    setIsCreate(false);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    onCreateComponent(values);
    setIsCreate(false);
    form.resetFields();
  }
  
  return (
    <ModalCreateAccess>
      <Button type={'primary'} onClick={handleOpenCreateComponentForm}>Добавить компонент</Button>
      <Modal
        title="Создание компонента"
        open={isCreate}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} name='MapFilter' onFinish={handleSubmit}>
          <Form.Item label='Ключ' name='key' rules={[{ required: true, message: 'Введите ключ' }]}>
            <Input />
          </Form.Item>
          <Form.Item label='Название' name='name' rules={[{ required: true, message: 'Введите название' }]}>
            <Input />
          </Form.Item>
          <Form.Item label='Описание' name='description' rules={[{ required: true, message: 'Введите описание' }]}>
            <Input />
          </Form.Item>
          <Button type='primary' htmlType='submit'>
            Создать
          </Button>
        </Form>
      </Modal>
    </ModalCreateAccess>
  );
};

export default ModalCreateAccessComponent;

const ModalCreateAccess = styled.div`

`
