import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setUploadNewPoligons,
} from '../../../../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const FileUploader = () => {
  const { uploadNewPoligons } = useSelector((store) => store.deliveryServices);
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (uploadNewPoligons === null) {
      setFileList([])
    }
  }, [uploadNewPoligons])

  useEffect(() => {
    if (fileList.length === 0) {
      dispatch(setUploadNewPoligons(null));
    }
  }, [fileList])

    
  const convertARGBtoHex = (colorStr) => {
    var alpha = parseInt(colorStr.substr(0, 2), 16) / 255;
    var blue = parseInt(colorStr.substr(2, 2), 16);
    var green = parseInt(colorStr.substr(4, 2), 16);
    var red = parseInt(colorStr.substr(6, 2), 16);

    var rgbaColor = "rgba(" + red + ", " + green + ", " + blue + ", " + alpha + ")";
    
    return rgbaColor;
  };

  const readKMLFile = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const content = event.target.result;
      const polygons = parseKMLContent(content);
      dispatch(setUploadNewPoligons(polygons));
    };

    reader.readAsText(file);
  };

  const parseKMLContent = (content) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(content, "text/xml");

    const styleMap = {};
    const styleElements = xmlDoc.getElementsByTagName("Style");
    for (let i = 0; i < styleElements.length; i++) {
      const style = styleElements[i];
      const styleId = style.getAttribute("id");
      const lineStyle = style.querySelector("LineStyle");
      const polyStyle = style.querySelector("PolyStyle");
      const fillColor = convertARGBtoHex(polyStyle.querySelector("color").textContent);
      const strokeColor = convertARGBtoHex(lineStyle.querySelector("color").textContent);
      styleMap[styleId] = { fillColor, strokeColor };
    }
  
    const placemarkElements = xmlDoc.getElementsByTagName("Placemark");
    const polygons = [];
  
    for (let i = 0; i < placemarkElements.length; i++) {
      const placemark = placemarkElements[i];
      const name = placemark.querySelector("name").textContent;
      const description = placemark.querySelector("description").textContent;
      const styleUrl = placemark.querySelector("styleUrl").textContent.slice(1); // remove '#'
      const coordinatesElement = placemark.querySelector("coordinates");
      const coordinatesText = coordinatesElement.textContent.trim();
      const coordinates = coordinatesText.split(" ").map(point => {
        const [lng, lat] = point.split(",").map(parseFloat);
        return [lng, lat];
      });
  
      const { fillColor, strokeColor } = styleMap[styleUrl];
    
      polygons.push({
        id: i + 1,
        key: i + 1,
        fillColor: fillColor,
        strokeColor: strokeColor,
        name: name,
        description: description,
        coordinates: [coordinates],
        baseStrCoords: coordinatesText,
      });
    }
  
    return polygons;
  };

  const handleChange = (info) => {
    let newFileList = [...info.fileList];

    newFileList = newFileList.slice(-1);

    newFileList = newFileList.map((file) => {
      if (file.status !== 'uploading') {
        readKMLFile(info.file.originFileObj);
      }
      if (file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
      return file;
    });

    setFileList(newFileList);
  };

  const props = {
    name: 'file',
    multiple: false,
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange: handleChange,
  };

  return (
    <Dragger {...props} fileList={fileList}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Импортировать зоны доставки</p>
    </Dragger>
  );
};

export default FileUploader;