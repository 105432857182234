import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAddLocationAddresses,
  setLocationAddressAddedArray,
  setMapMode,
} from '../../../../../redux/reducers/utils/deliveryReducers/deliveryMapReducer';
import styled from 'styled-components';
import { Flex, Button } from 'antd';
import AddressCard from './AddressCard';

const LocationAddressAddComponent = () => {
  const {
    currentLocation,
    locationAddressAddedArray,
  } = useSelector((store) => store.deliveryMap);
  const dispatch = useDispatch();

  const selectedLocationsArrayIds = [...new Set(
    locationAddressAddedArray.map(item => Number(item.addressId))
  )];

  const onFinish = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      locationId: currentLocation.id,
      addressIds: selectedLocationsArrayIds,
    };
    
    dispatch(fetchAddLocationAddresses(requestData));
    dispatch(setLocationAddressAddedArray([]));
    dispatch(setMapMode('defaultMode'));
  };

  const handleCancel = () => {
    dispatch(setLocationAddressAddedArray([]));
    dispatch(setMapMode('defaultMode'));
  };

  return (
    <Flex
      vertical
      style={{
        width: '280px',
        height: 'calc(100vh - 40px)',
        background: '#ffffff',
        border: '1px solid #f1f1f1',
      }}
    >
      <StyledHeader>
        <Button type="primary" htmlType="submit"  onClick={onFinish}>Добавить</Button>
        <Button type="primary" htmlType="submit"  onClick={handleCancel}>Отменить</Button>
      </StyledHeader>
      <StyledOrderCounter>Выбрано адресов: {locationAddressAddedArray.length}</StyledOrderCounter>
      <StyledContent>
      {locationAddressAddedArray && locationAddressAddedArray.map((address) => (
        <AddressCard key={address.addressId} point={address} />
      ))}
      </StyledContent>
    </Flex>
  );
};

export default LocationAddressAddComponent;

const StyledHeader = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px;
  width: 100%;
`;
const StyledOrderCounter = styled.div`
margin: 0 0 5px 10px;
  color: #999999;
`;
const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
  }
`;
