import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { sendRequestProductsDeleteImage } from '../../../../../redux/reducers/utils/kitchenReducers/kitchenAssortmentReducer';
import { Flex, Upload, Popconfirm, Image, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const ImageUploadAndCrop = ({productImage, productId, onFileUpload}) => {
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState('');

  useEffect(() => {
    if (productImage) {
      setFileList([{
        uid: '-1',
        name: '',
        status: 'done',
        url: productImage,
      }])
      setPreviewImage(productImage);
    } else {
      setFileList([]);
      setPreviewImage('');
    }
  }, [productImage])

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    if (newFileList.length > 0) {
      onFileUpload(newFileList[0].originFileObj);
      const file = newFileList[0];
      if (file && !file.preview) {
        getBase64(file.originFileObj).then((imageUrl) => {
          setPreviewImage(imageUrl);
        });
      }
    }
  };

  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
  };

  const handleRemoveImage = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      productId: productId,
    }

    dispatch(sendRequestProductsDeleteImage(requestData));
    setFileList([]);
    setPreviewImage('');
  };

  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess(null, file);
    }, 0);
  };

  return (
    <>
      {previewImage ? 
        <div style={{position: 'relative'}}>
          <Image
            alt="example"
            width='150px'
            src={previewImage}
            mask='false'
            preview={{
              toolbarRender: (_,{}) => (<Flex size={12} className="toolbar-wrapper"></Flex>),
            }}
          />
          <Popconfirm
            title="Удаление картинки!"
            description={`Вы действительно хотите удалить картинку`}
            okText="Да"
            cancelText="Нет"
            placement="left"
            onConfirm={handleRemoveImage}
          >
            <Button shape="circle" style={{position: 'absolute', top: '0', left: '117px'}} icon={<DeleteOutlined/>}/>
          </Popconfirm>
          
        </div>
      :
        <ImgCrop rotationSlider>
          <Upload
            customRequest={customRequest}
            accept="image/*"
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
          >
            {fileList.length < 1 && '+ Upload'}
          </Upload>
        </ImgCrop>
      }
    </>
  );
};

export default ImageUploadAndCrop;
