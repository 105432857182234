import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Space } from 'antd';
import { MobileOutlined, InfoCircleOutlined } from '@ant-design/icons';

const withMobileDetection = (WrappedComponent) => {
  return function(props) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return isMobile ? <OnlyMobileComponent/> : <WrappedComponent {...props} />;
  }
}

export default withMobileDetection;

const { Title, Text } = Typography;

function OnlyMobileComponent() {
  return (
    <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', textAlign: 'center', padding: '20px'}}>
      <Col>
        <Space direction="vertical" size="large">
          <MobileOutlined style={{ fontSize: '64px', color: '#ff4500' }} />
          <Title level={2}>Страница не адаптирована под мобильные устройства</Title>
          <Text>
            Данная страница лучше просматривается на компьютере. Пожалуйста, используйте настольную версию для лучшего опыта.
          </Text>
          <InfoCircleOutlined style={{ fontSize: '32px', color: '#ff4500' }} />
        </Space>
      </Col>
    </Row>
  );
}
