import React from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const Overlay = ({ children, onClose }) => {
  return (
    <Wrapper>
      <CloseButton onClick={onClose} shape="circle" icon={<CloseOutlined />} />
      <WindowContainer>
        {children}
      </WindowContainer>
    </Wrapper>
  );
};

export default Overlay;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const WindowContainer = styled.div`
  position: relative;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 90vw;
  height: 90vh;
  overflow: hidden;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 20px;
  background-color: transparent;
  color: #ffffff;
`;
