import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestDeliveryServicesGet } from '../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { sendRequestOrganizationsGet, sendRequestCitiesGet } from '../../../../redux/reducers/utils/settingsReducers/settingsCatalogsReducer';
import { Row, Col, Typography } from 'antd';
import Loader from '../../../../components/Loader';
import ScrolledContainer from '../../../../components/ScrolledContainer';
import DeliveryServicesTableComponent from './components/DeliveryServicesTableComponent';

const { Title } = Typography;

const DeliveryServicesPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const {
    deliveryServicesGetRequestStatus,
    deliveryServicesCreateRequestStatus,
    deliveryServicesUpdateRequestStatus,
    deliveryServicesDeleteRequestStatus,
  } = useSelector((store) => store.deliveryServices);
  const { organizationsGetRequestStatus } = useSelector((store) => store.settingsCatalogs);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestDeliveryServicesGet({ token: localStorage.getItem('token') }));
      dispatch(sendRequestOrganizationsGet({ token: localStorage.getItem('token') }));
      dispatch(sendRequestCitiesGet({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  useEffect(() => {
    if (
        deliveryServicesCreateRequestStatus === 'resolved' ||
        deliveryServicesUpdateRequestStatus === 'resolved' ||
        deliveryServicesDeleteRequestStatus === 'resolved'
      ) {
      dispatch(sendRequestDeliveryServicesGet({ token: localStorage.getItem('token') }));
    }
  }, [
      deliveryServicesCreateRequestStatus,
      deliveryServicesUpdateRequestStatus,
      deliveryServicesDeleteRequestStatus
    ]
  );

  return (
    <ScrolledContainer>
      {(
        deliveryServicesGetRequestStatus === 'pending' ||
        deliveryServicesCreateRequestStatus === 'pending' ||
        deliveryServicesUpdateRequestStatus === 'pending' ||
        deliveryServicesDeleteRequestStatus === 'pending' ||
        organizationsGetRequestStatus === 'pending'
      ) && <Loader />}
      <Title>Службы доставки</Title>
      <DeliveryServicesTableComponent/>
    </ScrolledContainer>
  )
}

export default DeliveryServicesPage;