import { takeLatest } from 'redux-saga/effects';
import {
  // handleGetInfoForMap,
  // handleGetDataForMap,
  handleAssignCourierToAddresses,
  handleAddressChangeGeopoint,
  handleUpdateAddressComment,
  handleUpdateOrderComment,
  handleGetWhereCouriersData,
  handleGetCouriersDetail,
  handleCourierRouteSave,
} from './deliveryMapHandler';
import {
  // sendRequestFormFilters,
  // sendRequestForMapData,
  sendRequestForAssignCourierToAddresses,
  sendRequestForAddressChangeGeopoint,
  sendRequestForUpdateAddressComment,
  sendRequestForUpdateOrderComment,
  sendRequestWhereCouriersGet,
  sendRequestCouriersDetailGet,
  sendRequestCourierRouteSave,
} from '../../../../reducers/utils/deliveryReducers/deliveryMapReducer';

export function* deliveryMapWatcherSaga() {
  // yield takeLatest(sendRequestFormFilters.type, handleGetInfoForMap);
  // yield takeLatest(sendRequestForMapData.type, handleGetDataForMap);
  yield takeLatest(sendRequestForAssignCourierToAddresses.type, handleAssignCourierToAddresses);
  yield takeLatest(sendRequestForAddressChangeGeopoint.type, handleAddressChangeGeopoint);
  yield takeLatest(sendRequestForUpdateAddressComment.type, handleUpdateAddressComment);
  yield takeLatest(sendRequestForUpdateOrderComment.type, handleUpdateOrderComment);
  yield takeLatest(sendRequestWhereCouriersGet.type, handleGetWhereCouriersData);
  yield takeLatest(sendRequestCouriersDetailGet.type, handleGetCouriersDetail);
  yield takeLatest(sendRequestCourierRouteSave.type, handleCourierRouteSave);
}