import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCurrentLocation,
  fetchUpdateLocation,
  fetchDeleteLocation,
  fetchRemoveLocationAddresses,
  setMapMode,
  fetchCreateLocationMidpoint,
  fetchDeleteLocationMidpoint,
  fetchDeleteLocationEntrance,
  setLocationAddressAddedArray,
  selectOrder,
} from '../../../../../redux/reducers/utils/deliveryReducers/deliveryMapReducer';
import styled from 'styled-components';
import { Flex, Button, Input, Typography, Tooltip, Popconfirm, Form, Divider, Modal, Tag, Spin, Select } from 'antd';
import {
  EnvironmentOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SelectOutlined,
  CloseOutlined,
  ImportOutlined,
} from '@ant-design/icons';
import { ScrolledCol } from '../../../../../core/styles';
import AddressCard from './AddressCard';

const { Title, Text } = Typography;
const { Option } = Select;

const LocationDetailComponent = () => {
  const {
    mapMode,
    currentLocation,
    currentLocationAddressesLoading,
    currentLocationAddresses,
  } = useSelector((store) => store.deliveryMap);
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [ requestValues, setRequestValues ] = useState({});
  const [form] = Form.useForm();
  const [ midpointModalShow, setMidpointModalShow ] = useState(false);
  const [ midpointName, setMidpointName ] = useState('');

  useEffect(() => {
    if (currentLocation) {
      form.setFieldsValue(currentLocation);
      dispatch(setMapMode('defaultMode'));
    }
  }, [currentLocation])

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleCancelEdit = () => {
    form.setFieldsValue(currentLocation);
    setRequestValues({});
  };

  const handleSubmit = () => {
    dispatch(fetchUpdateLocation({
      token: localStorage.getItem('token'),
      locationId: currentLocation.id,
      coords: [currentLocation.longitude, currentLocation.latitude],
      ...currentLocation,
      ...requestValues
    }));
    setIsEditing(false);
  };

  const handleDelete = (id) => {
    dispatch(fetchDeleteLocation({token: localStorage.getItem('token'), locationId: id}));
  };

  const handleClose = () => {
    dispatch(setCurrentLocation(null));
    dispatch(selectOrder(null));
    dispatch(setLocationAddressAddedArray([]));
    dispatch(setMapMode('defaultMode'));
  };

  const handleAddEnter = () => {
    if (mapMode !== 'addingEnterMode') {
      dispatch(setMapMode('addingEnterMode'));
    } else {
      dispatch(setMapMode('defaultMode'));
    }
  };

  const handleChangePosition = () => {
    if (mapMode !== 'locationDragMode') {
      dispatch(setMapMode('locationDragMode'));
    } else {
      dispatch(setMapMode('defaultMode'));
    }
  };

  const handleAddAddresses = () => {
    if (mapMode !== 'addingAddressesMode') {
      dispatch(setMapMode('addingAddressesMode'));
    } else {
      dispatch(setMapMode('defaultMode'));
    }
  };

  const handleValuesChange = (changedValues, allValues) => {
    const filteredValues = Object.fromEntries(
      Object.entries(requestValues).filter(([_, v]) => v !== undefined && v !== null && v !== '')
    );

    setRequestValues({...filteredValues, ...changedValues});
  };

  const handleAddMidpoint = () => {
    setMidpointModalShow(true);
  };

  const handleOkMidpointName = () => {
    dispatch(fetchCreateLocationMidpoint({ token: localStorage.getItem('token'), locationId: currentLocation.id, name: midpointName }));
    setMidpointModalShow(false);
    setMidpointName('');
  };
  
  const handleCancelMidpointName = () => {
    setMidpointModalShow(false);
    setMidpointName('');
  };

  const handleChangeMidpointName = (e) => {
    setMidpointName(e.target.value);
  };

  const handleDeleteLocationMidpoint = (id) => {
    dispatch(fetchDeleteLocationMidpoint({ token: localStorage.getItem('token'), locationsMeetingPointId: id }));
  }

  const handleDeleteLocationEntrance = (id) => {
    dispatch(fetchDeleteLocationEntrance({ token: localStorage.getItem('token'), locationEntranceId: id }));
  }

  if (!currentLocation) { return; }

  // Сводная информация по всем адресам
  let summaryStats = { totalOrders: 0, deliveredOrders: 0, totalRevenue: 0 };

  if (currentLocation && currentLocation.addresses) {
    summaryStats = Object.values(currentLocation.addresses).reduce(
      (acc, address) => {
        acc.totalOrders += address.orders.length;
        acc.deliveredOrders += address.orders.filter(order => order.status.name === 'Доставлен').length;
        acc.totalRevenue += address.orders.reduce((sum, order) => sum + order.payment.finalPrice, 0);
        return acc;
      },
      { totalOrders: 0, deliveredOrders: 0, totalRevenue: 0 }
    );
  }

  return (
    <Wrapper>
      <Flex vertical>
        <Flex gap='5px' justify='flex-end'>
          <Tooltip title="Добавление мидпоинта к локации">
            <Button type='text' icon={<ImportOutlined/>} onClick={handleAddMidpoint}/>
          </Tooltip>
          <Tooltip title="Добавление входа к локации">
            <Button type={mapMode === 'addingEnterMode' ? 'primary' : 'text'} icon={<SelectOutlined/>} onClick={handleAddEnter}/>
          </Tooltip>
          <Tooltip title="Перемещение локации">
            <Button type={mapMode === 'locationDragMode' ? 'primary' : 'text'} icon={<EnvironmentOutlined/>} onClick={handleChangePosition}/>
          </Tooltip>
          <Tooltip title="Добавить адреса">
            <Button type={mapMode === 'addingAddressesMode' ? 'primary' : 'text'} icon={<PlusOutlined/>} onClick={handleAddAddresses}/>
          </Tooltip>
          <Tooltip title="Редактировать локацию">
            <Button type={isEditing ? 'primary' : 'text'} icon={<EditOutlined />} onClick={handleEdit}/>
          </Tooltip>
          <Tooltip title="Удаление локации">
            <Popconfirm
              title="Удаление локации!"
              description={'Вы уверены что хотите удалить локацию?'}
              okText="Да"
              cancelText="Нет"
              placement="top"
              onConfirm={() => handleDelete(currentLocation.id)}
            >
              <Button type='text' icon={<DeleteOutlined/>} danger/>
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Закрыть">
            <Button type='text' icon={<CloseOutlined/>} onClick={handleClose}></Button>
          </Tooltip>
        </Flex>
        <Title level={4}>{currentLocation.id} - {currentLocation.name || 'Нет названия'}</Title>
      </Flex>

      <ScrolledCol height='calc(100vh - 135px)'>
        {!isEditing ?
          <Flex vertical>
            <Text type='secondary'>
              {currentLocation.country && `${currentLocation.country}, `}
              {currentLocation.administrativeArea && `${currentLocation.administrativeArea}, `}
              {currentLocation.city && `${currentLocation.city}, `}
              {currentLocation.street && `${currentLocation.street}, `}
              {currentLocation.house && `${currentLocation.house} `}
              {currentLocation.postalCodeNumber && `(${currentLocation.postalCodeNumber})`}
            </Text>
      
            {currentLocation && currentLocation.addresses &&
              <>
                <Flex align='center' gap='10px'>
                  <Text>Aдресов: {Object.values(currentLocation.addresses).length}</Text>
                  <Text>Заказов: {summaryStats.deliveredOrders}/{summaryStats.totalOrders}</Text>
                  <Text strong>{summaryStats.totalRevenue} ₽</Text>
                </Flex>

                <Flex align='center' gap='10px'>
                  <Text type='secondary'>ID: {currentLocation.courierId}</Text>
                  <Text>{currentLocation.courierName}</Text>
                  <div style={{width: '20px', height: '20px', background: currentLocation.courierColor, borderRadius: '20px'}}></div>
                </Flex>
              </>
            }

            <Divider style={{margin: '10px 0'}}/>

            <Title level={5}>Митпоинты:</Title>
            <Flex justify='flex-start' gap='10px' wrap='wrap'>
              {currentLocation && currentLocation.meeting_points && currentLocation.meeting_points.length > 0 ? (
                currentLocation.meeting_points.map(item => (
                  <Tag key={item.id} color='red' closeIcon onClose={() => handleDeleteLocationMidpoint(item.id)}>
                    {item.name}
                  </Tag>
                ))
              ) : (
                <Flex>- Пусто -</Flex>
              )}
            </Flex>

            <Divider style={{margin: '10px 0'}}/>

            <Title level={5}>Входы:</Title>
            <Flex justify='flex-start' gap='10px' wrap='wrap'>
              {currentLocation && currentLocation.entrances && currentLocation.entrances.length > 0 ? (
                currentLocation.entrances.map(item => (
                  <Tag key={item.id} color='red' closeIcon onClose={() => handleDeleteLocationEntrance(item.id)}>
                    {item.name}
                  </Tag>
                ))
              ) : (
                <Flex>- Пусто -</Flex>
              )}
            </Flex>

            {currentLocation && currentLocation.addresses &&
              <>
                <Divider style={{margin: '10px 0'}}/>

                <Title level={5}>Заказанные адреса:</Title>
                {Object.values(currentLocation.addresses).map((point) => (
                  <Flex key={point.addressId}>
                    <AddressCard
                      point={{
                        ...point,
                        locationId: currentLocation.id,
                        locationEntrance: currentLocation.entrances,
                        locationMidpoints: currentLocation.meeting_points,
                      }}
                    />
                    <CardAction id={currentLocationAddresses.length > 0 && currentLocationAddresses.find(item => item.id === point.addressId)?.locationAddressId}/>
                  </Flex>
                ))}
              </>
            }

            <Divider style={{margin: '10px 0'}}/>

            <Title level={5}>Адреса:</Title>

            {currentLocationAddressesLoading ?
              <Spin/>
            :
              <>
                {currentLocationAddresses && currentLocationAddresses.length > 0 ?
                  currentLocationAddresses
                  .filter(a => 
                    !currentLocation.addresses || 
                    (Object.values(currentLocation.addresses).length && 
                    !Object.values(currentLocation.addresses).some(b => b.addressId === a.id))
                  ).map(address => {
                    const modifydAddress = {
                      ...address,
                      addressId: address.id,
                      address: `${address.street && address.street} ${address.house && `, ${address.house}`}`,
                      addressName: address.name,
                      meetingPoints: address.meeting_points,
                      locationId: currentLocation.id,
                      locationEntrance: currentLocation.entrances,
                      locationMidpoints: currentLocation.meeting_points
                    };

                    return (
                      <Flex key={modifydAddress.addressId}>
                        <AddressCard
                          point={modifydAddress}
                        />
                        <CardAction id={modifydAddress.locationAddressId}/>
                      </Flex>
                    )
                  })
                  :
                  <Text>- Адреса еще не добавлены -</Text>
                }
              </>
            }
          </Flex>
        :
          <Form
            form={form}
            layout="vertical"
            initialValues={currentLocation}
            onValuesChange={handleValuesChange}
            onFinish={handleSubmit}
          >
            <Form.Item style={{marginBottom: '10px'}} label='Страна' name='country'>
              <Input placeholder='Страна' disabled={!isEditing}/>
            </Form.Item>
            <Form.Item style={{marginBottom: '10px'}} label='Регион' name='administrativeArea'>
              <Input placeholder='Регион' disabled={!isEditing}/>
            </Form.Item>
            <Form.Item style={{marginBottom: '10px'}} label='Город' name='city'>
              <Input placeholder='Город' disabled={!isEditing}/>
            </Form.Item>
            <Form.Item style={{marginBottom: '10px'}} label='Улица' name='street'>
              <Input placeholder='Улица' disabled={!isEditing}/>
            </Form.Item>
            <Form.Item style={{marginBottom: '10px'}} label='Дом' name='house'>
              <Input placeholder='Дом' disabled={!isEditing}/>
            </Form.Item>
            <Form.Item style={{marginBottom: '10px'}} label='Почтовый индекс' name='postalCodeNumber'>
              <Input placeholder='Почтовый индекс' disabled={!isEditing}/>
            </Form.Item>
            <Form.Item style={{marginBottom: '10px'}} label='Название' name='name'>
              <Input placeholder='Название'/>
            </Form.Item>
            <Form.Item style={{marginBottom: '10px'}} label='Тип объекта' name='locationTypeObjectId'>
              <Select>
                <Option key={0} value={0}>Без типа объекта</Option>
                <Option key={1} value={1}>Офисное здание</Option>
                <Option key={2} value={2}>Частный дом</Option>
                <Option key={3} value={3}>Жилой многоквартирный дом</Option>
                <Option key={4} value={4}>Предприятие</Option>
              </Select>
            </Form.Item>

            {Object.keys(requestValues).length > 0 &&
              <Flex justify='flex-end' gap='10px'>
                <Button htmlType='submit' type="primary">
                  Сохранить
                </Button>
                <Button onClick={handleCancelEdit}>
                  Отменить
                </Button>
              </Flex>
            }
          </Form>
        }
      </ScrolledCol>

      <Modal
        title="Название мидпоинта"
        open={midpointModalShow}
        onOk={handleOkMidpointName}
        onCancel={handleCancelMidpointName}
        okText="Сохранить"
        cancelText="Отменить"
      >
        <Input
          placeholder="Название мидпоинта"
          value={midpointName}
          onChange={handleChangeMidpointName}
        />
      </Modal>
    </Wrapper>
  );
};

export default LocationDetailComponent;

const Wrapper = styled.div`
  padding: 10px 20px;
  max-width: 350px;
  height: fit-content;
  background: #ffffff;
`;
const StyledAddressCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: ${({ selected }) => (selected ? '#f1f1f1' : '#ffffff')};
  border: 1px solid #f1f1f1;
  padding: 10px;
  width: 100%;
  height: auto;
  font-size: 13px;
  transition: all .3s;
  cursor: pointer;
  &:hover {
    background: #f1f1f1;
  }
`;
const StyledId = styled.div`
  font-size: 20px;
`;
const StyledAddress = styled.div`
  font-size: 13px;
  color: #3498DB;
`;
const StyledName = styled.div`
  font-size: 11px;
  color: #999999;
`;

const CardAction = ({ id }) => {
  const dispatch = useDispatch();

  const handleDeleteAddressFromLocation = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      locationAddressIds: [id],
    }

    dispatch(fetchRemoveLocationAddresses(requestData));
  }

  return (
    <Flex style={{paddingBottom: '20px', border: 'solid 1px #f1f1f1'}} vertical>
      <Tooltip title="Удаление адреса">
        <Popconfirm
          title="Удаление адреса!"
          description={'Вы уверены что хотите удалить адрес с локации?'}
          okText="Да"
          cancelText="Нет"
          placement="top"
          onConfirm={handleDeleteAddressFromLocation}
        >
          <Button type='text' icon={<CloseOutlined/>} danger/>
        </Popconfirm>
      </Tooltip>
    </Flex>
  )
}