import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentRoute } from '../../redux/reducers/utils/navReducer';
import { sendRequestCitiesGet } from '../../redux/reducers/utils/settingsReducers/settingsCatalogsReducer';
import { sendRequestKitchens, setSelectKitchen } from '../../redux/reducers/utils/kitchenReducers/kitchenReducer';
import { Container, Page, ScrolledCol } from '../../core/styles';
import KitchenNOTselectedComponent from './components/KitchenNOTselectedComponent';

function KitchenModule({children}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuth } = useSelector((store) => store.auth);
  const { selectKitchen, kitchens } = useSelector((store) => store.kitchen);
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === '/kitchens') {
      dispatch(setCurrentRoute('kitchens/statistics'));
      navigate('/kitchens/statistics');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (kitchens.length === 1) {
      dispatch(setSelectKitchen(kitchens[0]));
    }
  }, [kitchens]);

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestKitchens({ token: localStorage.getItem('token'), offset: 0, limit: 1000 }));
      dispatch(sendRequestCitiesGet({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  console.log(location.pathname);

  return(
    <Page>
      <ScrolledCol>
        <Container>
          {selectKitchen || location.pathname === '/kitchens/assortment' ?
            <>
              {children}
            </>
          :
            <KitchenNOTselectedComponent/>
          }
        </Container>
      </ScrolledCol>
    </Page>
  );
}

export default KitchenModule;
