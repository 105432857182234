import React, { useState, useEffect, useRef } from 'react';
import { Typography, Tag, Form, Input, message } from 'antd';
import styled from 'styled-components';

const { Title, Text } = Typography;

const TestingSettingsComponent = ({ type, title, description, accessData, onChange }) => {
  const [form] = Form.useForm();
  const phonePattern = /^\+7[0-9]{10}$/;
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [items, setItems] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    if (accessData && accessData.value) {
      const phones = accessData.value.includes('|') 
        ? accessData.value.split('|').filter(item => item.trim() !== '') 
        : [accessData.value.trim()];
        setItems(phones);
    }
  }, [accessData]);

  const handleInputConfirm = (values) => {
    const newItem = values.item.trim();
    if (items.includes(newItem)) {
      message.error('Значение уже сохранено');
      return;
    }

    if (type === 'phone') {
      if (phonePattern.test(newItem)) {
        const newItems = [...items, newItem];
        onChange({ [accessData.id]: newItems.join('|') });
        form.resetFields();
      } else {
        message.error('Некорректный номер телефона');
      }
    } else if (type === 'email') {
      if (emailPattern.test(newItem)) {
        const newItems = [...items, newItem];
        onChange({ [accessData.id]: newItems.join('|') });
        form.resetFields();
      } else {
        message.error('Некорректная электронная почта');
      }
    } else {
      const newItems = [...items, newItem];
      onChange({ [accessData.id]: newItems.join('|') });
      form.resetFields();
    }
  };

  const handleItemDelete = (itemToDelete) => {
    const newTestPhones = items.filter(item => item !== itemToDelete);
    onChange({ [accessData.id]: newTestPhones.join('|') });
  };
  
  return (
    <>
      {accessData.visible && (
        <Wrapper>
          <Title level={4} style={{ margin: 0 }}>{title}</Title>
          <Text style={{ marginBottom: '20px' }}>{description}</Text>
          {items.length > 0 && (
            <TagsWrapper>
              {items.map(item => (
                <Tag
                  key={item}
                  closable
                  onClose={() => handleItemDelete(item)}
                >
                  {item}
                </Tag>
              ))}
            </TagsWrapper>
          )}
          <Form
            style={{ marginTop: 'auto' }}
            form={form}
            layout='vertical'
            name="form"
            onFinish={handleInputConfirm}
          >
            <Form.Item name='item'>
              <Input
                ref={inputRef}
                placeholder={type === 'phone' ? "+7 --- --- -- --" : (type === 'email' ? "email@mail.ru" : 'Введите данные')}
                maxLength={ type === 'phone' && 12}
                onBlur={() => form.submit()}
                onPressEnter={() => inputRef.current.blur()}
              />
            </Form.Item>
          </Form>
        </Wrapper>
      )}
    </>
  );
};

export default TestingSettingsComponent;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 20px 15px;
  background: #eeeeee;
  border-radius: 24px;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
`;
