import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestTransactionsSearch,
  setCurrentTransactionsPage,
} from '../../../../redux/reducers/utils/financeReducers/financeWalletsReducer';
import {
  sendRequestPromocodesSearch,
  setCurrentPromocodesPage,
  sendRequestPromocodeCreate,
  sendRequestPromocodeUpdate,
} from '../../../../redux/reducers/utils/financeReducers/financePromocodesReducer';
import { Typography, Flex, Divider } from 'antd';
import Loader from '../../../../components/Loader';
import PromocodeCreateComponent from './components/PromocodeCreateComponent';
import PromocodesFiltersComponent from './components/PromocodesFiltersComponent';
import PromocodeDrawerComonent from './components/PromocodeDrawerComponent';
import PromocodesTableComponent from './components/PromocodesTableComponent';

const { Title } = Typography;

const PromocodesPage = () => {
  const dispatch = useDispatch();
  const { isAuth } = useSelector(state => state.auth);
  const { kitchens, categoriesCatalog } = useSelector((store) => store.kitchen);
  const {
    transactions,
    totalTransactions,
    currentTransactionsPage,
  } = useSelector(state => state.financeWallets);
  const {
    promocodesSearchRequestStatus,
    promocodes,
    totalPromocodes,
    currentPromocodesPage,
    promocodeCreateRequestStatus,
    promocodeUpdateRequestStatus,
    promocodeDeleteRequestStatus,
  } = useSelector((store) => store.financePromocodes);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentPromocode, setCurrentPromocode] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [searchTransactionData, setSearchTransactionData] = useState({});

  const requestData = {
    token: localStorage.getItem('token'),
    offset: (currentPromocodesPage - 1) * 20,
    limit: 20,
  }

  const requestTransactionData = {
    token: localStorage.getItem('token'),
    offset: (currentTransactionsPage - 1) * 20,
    limit: 20,
  }

  const groupedKitchens = kitchens?.reduce((groups, kitchen) => {
    const { cityId } = kitchen;
    if (!groups[cityId]) {
      groups[cityId] = [];
    }
    groups[cityId].push(kitchen);
    return groups;
  }, {});

  const kitchenOptions = Object.keys(groupedKitchens).map(cityId => {
    const cityKitchens = groupedKitchens[cityId];
    const cityName = cityKitchens[0].cityName;

    const options = cityKitchens.map(kitchen => ({
      ...kitchen,
      label: kitchen.name,
      value: kitchen.id,
    }));

    return {
      label: cityName,
      title: cityName,
      options: options,
    };
  });

  const categoriesOptions = categoriesCatalog.map(category => ({
    ...category,
    label: category.name,
    value: category.id,
  }));

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestPromocodesSearch(requestData))
    }
  }, [isAuth]);

  useEffect(() => {
    if (
      promocodeCreateRequestStatus === 'resolved' ||
      promocodeUpdateRequestStatus === 'resolved' ||
      promocodeDeleteRequestStatus === 'resolved'
    ) {
      dispatch(sendRequestPromocodesSearch(requestData))
    }
  }, [
    promocodeCreateRequestStatus,
    promocodeUpdateRequestStatus,
    promocodeDeleteRequestStatus,
  ]);

  const handleSearch = (data) => {
    console.log(data);
    setSearchData(data)
    dispatch(setCurrentPromocodesPage(1));
    dispatch(sendRequestPromocodesSearch({...requestData, ...data, offset: 0 }))
  }

  const handleCreatePromocode = (data) => {
    dispatch(sendRequestPromocodeCreate({token: localStorage.getItem('token'), ...data}));
  }

  const handleOpenDrawer = (record) => {
    setIsDrawerOpen(true);
    setCurrentPromocode(record);
    dispatch(sendRequestTransactionsSearch({...requestTransactionData, offset: 0, walletId: record?.wallet?.id }));
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setCurrentPromocode(null);
  };

  const handleUpdatePromocode = (data) => {
    console.log(data);
    dispatch(sendRequestPromocodeUpdate({token: localStorage.getItem('token'), ...data}));
    setIsDrawerOpen(false);
    setCurrentPromocode(null);
  }

  const handlePromocodesPageChange = (page) => {
    dispatch(setCurrentPromocodesPage(page));
    dispatch(sendRequestPromocodesSearch({...requestData, ...searchData, offset: (page - 1) * 20 }));
  }
  
  const handleSearchTransaction = (data) => {
    console.log(data);
    setSearchTransactionData(data)
    dispatch(setCurrentTransactionsPage(1));
    dispatch(sendRequestTransactionsSearch({...requestTransactionData, ...data, offset: 0 }));
  }

  const handleTransactionsPageChange = (page) => {
    dispatch(setCurrentTransactionsPage(page));
    dispatch(sendRequestTransactionsSearch({...requestTransactionData, ...searchTransactionData, offset: (page - 1) * 20, walletId: currentPromocode.wallet.id }));
  }

  return (
    <>
      {(
        promocodesSearchRequestStatus === 'pending' ||
        promocodeCreateRequestStatus === 'pending' ||
        promocodeUpdateRequestStatus === 'pending' ||
        promocodeDeleteRequestStatus === 'pending'
      ) && <Loader />}

      <Title level={2}>Промокоды</Title>

      <PromocodesFiltersComponent
        totalPromocodes={totalPromocodes}
        kitchenOptions={kitchenOptions}
        onSearch={handleSearch}
      />

      <Divider/>

      <Flex justify='flex-end' style={{marginBottom: '20px'}}>
        <PromocodeCreateComponent
          onSubmit={handleCreatePromocode}
        />
      </Flex>
      
      <PromocodesTableComponent
        dataSource={promocodes}
        kitchenOptions={kitchenOptions}
        currentPromocode={currentPromocode}
        currentPromocodesPage={currentPromocodesPage}
        totalPromocodes={totalPromocodes}
        onRowClick={handleOpenDrawer}
        onChange={handlePromocodesPageChange}
      />

      <PromocodeDrawerComonent
        isDrawerOpen={isDrawerOpen}
        onClose={handleCloseDrawer}
        record={currentPromocode}
        kitchenOptions={kitchenOptions}
        categoriesOptions={categoriesOptions}
        onUpdatePromocode={handleUpdatePromocode}
        transactions={transactions}
        totalTransactions={totalTransactions}
        currentTransactionsPage={currentTransactionsPage}
        onSearchTransaction={handleSearchTransaction}
        onChange={handleTransactionsPageChange}
      />
    </>
  );
};

export default PromocodesPage;
