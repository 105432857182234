import React from 'react';
import { Table, Button, Typography, Divider, Flex, Image } from 'antd';
import emptyImage from '../../../../../assets/img/empty-kitchen-image.png';
import PrintTable from '../../../../../components/PrintTable';
const moment = require('moment');

const { Title, Text } = Typography;

const ComplaintTableComponent = ({ complaints, onConfirm }) => {
  const columns = [
    {
      title: 'Номер, дата и время создания претензии',
      key: 'complaintNumber',
      align: 'top',
      render: (record) => <Text>Претензия №{record.id}, от {moment(record.created_at).format('DD-MM-YYYY HH:mm:ss')}</Text>,
    },
    {
      title: 'Номер, дата и время доставки заказа',
      key: 'orderNumber',
      render: (record) => <Text>Заказ №{record.orderId}, от {moment(record.deliveryDay).format('DD-MM-YYYY')}. Доставлен: {moment(record.deliveryDay).format('DD-MM-YYYY')}</Text>,
    },
    {
      title: 'Клиент',
      key: 'client',
      render: (record) => (
        <Flex vertical>
          <Title level={5}>{record.name}</Title>
          <Text type='secondary'>{record.phone}</Text>
          <div><a href={record.url_receipt_back}>Ссылка на чек заказа</a></div>
        </Flex>
      ),
      width: '130px'
    },
    {
      title: 'Описание претензии',
      key: 'description',
      render: (record) => (
        <Card
          description={record.description}
          image={record.pictures}
        />
      ),
    },
    {
      title: 'Состав всего заказа и оценка',
      key: 'orderDetails',
      render: (record) => {
        return record.products && record.products.length > 0 && record.products.map((product, productIndex) => (
          <>
            <div key={productIndex} style={{ display: 'flex', gap: '20px', fontSize: '12px' }}>
              {product.productSeparated && product.productSeparated.length > 0 && product.productSeparated.map((item, itemIndex) => (
                <>
                  <div key={itemIndex} style={{ display: 'grid', gridTemplateColumns: '1fr auto auto', gap: '5px 20px', textDecoration: item.isPretension ? 'line-through' : 'none' }}>
                    <div>{item.name}</div>
                    <div>{item.amount} шт.</div>
                    <div>{item.price} р.</div>
                  </div>
                  {itemIndex < product.productSeparated.length - 1 && <Divider style={{ gridColumn: 'span 3', margin: '5px 0' }} />}
                </>
              ))}
              <div>
                {product.review && (
                  <div>
                    <Text type='secondary' style={{fontSize: 'inherit'}}><b>{product.review.rating ? product.review.rating : 0}</b> </Text>
                    {product.review.comment ? <Text>{product.review.comment}</Text> : <Text type='secondary' style={{fontSize: 'inherit'}}>Без комментария</Text>}
                  </div>
                )}
              </div>
            </div>
            {productIndex < record.products.length - 1 && <Divider style={{ gridColumn: 'span 3', margin: '5px 0' }} />}
          </>
        ))
      },
      width: '30%',
    },
    {
      title: 'Статус претензии',
      dataIndex: 'pretensionsStatusName',
      key: 'pretensionsStatusName',
      render: (text, record) => (
        <Flex vertical gap={16}>
          {text}
          {record.url_receipt_back && <div><a href={record.url_receipt_back}>Ссылка на чек возврата</a></div>}
          {text === 'ожидает решения' && (
            <Flex align='center' gap={16}>
              <Button type="primary" onClick={() => onConfirm(record.id, true)}>
                Принять
              </Button>
              <Button onClick={() => onConfirm(record.id, false)}>
                Отклонить
              </Button>
            </Flex>
          )}
        </Flex>
      ),
    },
  ];

  return (
    <PrintTable>
      <Table
        columns={columns}
        dataSource={complaints}
        bordered
        rowKey="key"
      />
    </PrintTable>
  );
};

export default ComplaintTableComponent;

const Card = ({ description, image }) => {
  return(
    <>
      <Flex flex='0 0 215px' gap={5} style={{minWidth: '50px', height: '50px'}}>
        {image && image.length > 0 ? (
          image.map((url, index) => (
            <Image
              key={index}
              style={{
                width: '50px',
                height: '50px',
                objectFit: 'cover',
                background: '#eeeeee',
                borderRadius: '10px',
                overflow: 'hidden',
              }}
              src={url}
              fallback={emptyImage}
            />
          ))
        ) : (
          <Image
            style={{
              width: '50px',
              height: '50px',
              objectFit: 'cover',
              background: '#eeeeee',
              borderRadius: '10px',
              overflow: 'hidden',
            }}
            src={emptyImage}
          />
        )}
      </Flex>
      <Flex flex='1 1 100%' style={{minWidth: '200px'}}>
        <Text>{description}</Text>
      </Flex>
    </>
  )
}