import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  setDetailCouriersShow,
  setCurrentCourierId,
  sendRequestCouriersDetailGet,
  selectOrder,
  setMapAddressLocation,
  sendRequestCourierRouteSave,
  // setCourierDeliveryTrack,
  setCouriersDetail,
} from '../../../../../redux/reducers/utils/deliveryReducers/deliveryMapReducer';
import { Flex, Space, Button, Typography, Divider, List } from 'antd';
import { CloseOutlined, WifiOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import CourierProgressBar from "./CourierProgressBar";
import { ReactComponent as IconStar } from '../../../../../assets/icons/icon-star.svg';
import { ReactComponent as IconUser } from '../../../../../assets/icons/icon-user.svg';
import { ReactComponent as IconCash } from '../../../../../assets/icons/icon-сash.svg';
import moment from 'moment';

const { Text } = Typography;

const DetailCourierComponent = () => {
  const {
    points,
    couriers,
    currentCourierId,
    selectedFilters,
    couriersDetail,
    courierRouteSaveStatus,
  } = useSelector((store) => store.deliveryMap);
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [list2, setList2] = useState([]);

  console.log(couriersDetail)
  useEffect(() => {
    const splitCouriers = () => {
      const list = [];
      const list2 = [];
      couriersDetail.forEach(courier => {
        if (courier.indexNum && courier.indexNum !== null) {
          list.push(courier);
        } else if (!courier.indexNum) {
          list2.push(courier);
        }
      });

      const sortList = list.sort((a, b) => a.indexNum - b.indexNum);
      setList(sortList);
      setList2(list2);
    };

    splitCouriers();
  }, [couriersDetail, currentCourierId]);

  useEffect(() => {
    if (courierRouteSaveStatus === 'resolved') {
      const requestData = {
        token: localStorage.getItem('token'),
        courierId: courier.courierId,
        deliveryDay: selectedFilters.date,
        raceId: selectedFilters.delivery,
      }
  
      dispatch(sendRequestCouriersDetailGet(requestData));
    }
  }, [courierRouteSaveStatus])

  const courier = couriers.find(courier => courier.courierId === currentCourierId)

  const handleClose = () => {
    dispatch(setDetailCouriersShow(false));
    dispatch(setCurrentCourierId(null));
    // dispatch(setCourierDeliveryTrack([]));
    dispatch(setCouriersDetail([]));
  };

  const handleCardClick = (id) => {
    const point = points.find(point => point.addressId === id)
    console.log(point)
    dispatch(selectOrder(point));
    dispatch(setMapAddressLocation(point.coordinates));
  }

  const onDragEnd = (result) => {
    const { source, destination } = result;
  
    if (!destination) return;
  
    if (source.droppableId === destination.droppableId) {
      // Если перемещаем внутри одного списка
      const newList = reorder(list, source.index, destination.index);
      if (source.droppableId === 'list') {
        setList(newList.map((item, index) => ({ ...item, indexNum: index + 1 })));
        courierRouteSave(newList.map((item, index) => ({ ...item, indexNum: index + 1 })));
        // dispatch(setCourierDeliveryTrack(newList.map((item, index) => ({ ...item, indexNum: index + 1 }))));
      } else {
        setList2(newList.map((item) => ({ ...item, indexNum: null })));
        courierRouteSave(newList.map((item) => ({ ...item, indexNum: null })));
        // dispatch(setCourierDeliveryTrack(newList.map((item) => ({ ...item, indexNum: null }))));
      }
    } else {
      // Если перемещаем между списками
      const sourceList = source.droppableId === 'list' ? list : list2;
      const destList = destination.droppableId === 'list' ? list : list2;
      const [removed] = sourceList.splice(source.index, 1);
      if (destination.droppableId === 'list') {
        destList.splice(destination.index, 0, { ...removed, indexNum: destination.index + 1 });
      } else {
        destList.splice(destination.index, 0, { ...removed, indexNum: null });
      }
      setList([...list]);
      setList2([...list2]);
      courierRouteSave([...list]);
      // dispatch(setCourierDeliveryTrack([...list]));
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const courierRouteSave = (list) => {
    const requestData = {
      token: localStorage.getItem('token'),
      courierId: currentCourierId,
      deliveryDay: selectedFilters.date,
      raceId: selectedFilters.delivery,
      routes: list,
    }

    console.log(requestData);
    dispatch(sendRequestCourierRouteSave(requestData));
  }

  return (
    <Flex
      vertical
      style={{
        minWidth: '300px',
        height: 'calc(100vh - 40px)',
        background: '#ffffff',
        borderRight: '1px solid #dddddd'
      }}
    >
      <Button
        style={{ position: 'absolute', top: '5px', right: '5px', zIndex: 800 }}
        type='text'
        icon={<CloseOutlined/>}
        onClick={handleClose}
      />
      <Flex 
        Style={{width: '100%'}}
        vertical
        justify="space-between"
        align="center"
      >
        <Flex
          style={{ width: '100%', padding: '30px 10px 0'}}
          justify="space-between"
          align="center"
        >
          <StyledMarkWrapper couriercolor={courier.color}>
            <StyledMark couriercolor={courier.color}></StyledMark>
          </StyledMarkWrapper>
          <Text style={{fontSize: '14px', color: '#3498DB'}}><b>{courier.prefix}</b>-{courier.name}</Text>
          <Text style={{fontSize: '14px'}} type="secondary">{courier.phone}</Text>
        </Flex>
        <Flex
          style={{ width: '100%', padding: '15px 10px 10px'}}
          justify="space-between"
          align="center"
        >
          <Space>
            <span style={{color: courier.isOnline ? '#2bff00' : '#ff0000'}}><WifiOutlined/></span>
            <span style={{color: courier.GPS ? '#2bff00' : '#ff0000'}}>GPS</span>
          </Space>
          <CourierProgressBar
            addresses={courier.numberAddresses}
            addressesDelivered={courier.numberAddressesDelivered}
            timeStart={courier.firstDeliveryTimeTimestamp ? moment(courier.firstDeliveryTimeTimestamp * 1000).format('HH:mm') : null}
            timeFinish={courier.lastDeliveryTimeTimestamp ? moment(courier.lastDeliveryTimeTimestamp * 1000).format('HH:mm') : null}
          />
        </Flex>
      </Flex>

      <StyledContent>
        <DragDropContext onDragEnd={onDragEnd}>
          <DraggbleList items={list} droppableId={'list'} handleCardClick={handleCardClick}/>
          <Divider orientation="left">Маршрут не назначен</Divider>
          <DraggbleList items={list2} droppableId={'list2'} handleCardClick={handleCardClick}/>
        </DragDropContext>
      </StyledContent>
    </Flex>
  );
};

export default DetailCourierComponent;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 320px;
  height: auto;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
  }
`;
const StyledMarkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border: ${({ selected }) => (selected ? '3px solid' : '1px solid')};
  border-radius: 40%;
  border-color: ${({ selected, couriercolor }) => (selected ? '#000000' : couriercolor)};
  overflow: hidden;
  transition: all .3s;
  cursor: pointer;
`;
const StyledMark = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ selected }) => (selected ? '25px' : '17px')};
  height: ${({ selected }) => (selected ? '25px' : '17px')};
  border-radius: ${({ selected }) => (selected ? '0' : '17px')};
  background: ${({ couriercolor }) => (couriercolor)};
  transition: all .3s;
`;

const DraggbleList = ({ items, droppableId, handleCardClick }) => {
  return(
    <Droppable droppableId={droppableId}>
      {(provided) => (
        <div style={{width: '100%'}} {...provided.droppableProps} ref={provided.innerRef}>
          <List
            dataSource={items}
            size="small"
            renderItem={(address, index) => (
              <Draggable draggableId={address.addressId.toString()} index={index} key={address.addressId}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <List.Item style={{padding: '4px 8px'}}>
                      <OrderCard address={address} onClick={() => handleCardClick(address.addressId)} />
                    </List.Item>
                  </div>
                )}
              </Draggable>
            )}
          />
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

const OrderCard = ({ address, onClick }) => {
  return (
    <Flex
      style={{
        width: '100%',
        padding: '15px',
        background: '#F7F7F7',
        borderRadius: '20px',
        cursor: 'pointer',
      }}
      vertical
      gap={0}
      onClick={onClick}
    >
      <StyledHeader>
        <StyledOrderQueue isNull={address.indexNum === null} isDelivered={address.isDelivered}>{address.indexNum === null ? '?' : address.indexNum}</StyledOrderQueue>
        <StyledId>ID {address.addressId}</StyledId>
        <StyledNewUser>
          {address.isNewAddress && <><IconStar /> <StyledNewUserCount>{address.countUsersNeverOrdered}</StyledNewUserCount></>}
        </StyledNewUser>
        <StyledOrders>
          <IconUser /> {address.orders.length}
        </StyledOrders>
        <StyledPayments>
          <IconCash /> {address.summOrders}
        </StyledPayments>
      </StyledHeader>

      <Flex wrap>
        <Text style={{color: '#3498DB'}}>{address.street}-{address.house} {address.flat ? `кв.${address.flat}` : ''} {address.floor ? `э.${address.floor}` : ''}</Text>
      </Flex>

    </Flex>
  );
};

const StyledOrderQueue = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 25px;
  height: 25px;
  border-radius: 25px;
  background: ${({ isNull, isDelivered }) => (isNull ? '#000000' : (isDelivered ? '#55DB34' : '#3490db'))};
  color: ${({ isNull }) => (isNull ? '#ffffff' : '#000000')};
`
const StyledHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;
const StyledId = styled.div`
  font-size: 20px;
`;
const StyledNewUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;
const StyledNewUserCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  background-color: #ffd954;
`;
const StyledOrders = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;
const StyledPayments = styled.div`
  display: flex;
  gap: 5px;
`;
