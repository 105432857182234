import { takeLatest } from 'redux-saga/effects';
import {
  handleSendCodeToPhone,
  handleCreateTokenBySession,
  handleLogout,
  handleGiveRoleToNewUser,
  handleCreateUserProfile,
} from './authHandler';
import {
  sendCodeToPhone,
  createTokenBySession,
  logout,
  sendRequestGiveRoleToNewUser,
  sendRequestUserProfile,
} from '../../../reducers/utils/authReducer';

export function* authWatcherSaga() {
  yield takeLatest(sendCodeToPhone.type, handleSendCodeToPhone);
  yield takeLatest(createTokenBySession.type, handleCreateTokenBySession);
  yield takeLatest(logout.type, handleLogout);
  yield takeLatest(sendRequestGiveRoleToNewUser.type, handleGiveRoleToNewUser);
  yield takeLatest(sendRequestUserProfile.type, handleCreateUserProfile);
}