import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedMenuDate, setSelectedMenuRace, setIsOpenRacesDrawer } from '../../../../../../redux/reducers/utils/kitchenReducers/kitchenMenuReducer';
import { Tooltip } from 'antd';
import { BookOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const MenuDateComponent = ({dayData}) => {
  const { selectedMenuDate, selectedMenuRace } = useSelector((store) => store.kitchenMenu);
  const dispatch = useDispatch();
  
  const formatMenuDate = (menuDate) => {
    const options = { month: 'long', day: 'numeric' };
    const formattedDate = new Date(menuDate).toLocaleDateString('ru-RU', options);
    return formattedDate;
  };

  const handleSelectRace = (date, race) => {
    dispatch(setSelectedMenuDate(date));
    dispatch(setSelectedMenuRace(race));
    dispatch(setIsOpenRacesDrawer(false));
  }
  
  return (
    <MenuDate>
      <MenuDateItemDate>
        {formatMenuDate(dayData.day)}
        
      </MenuDateItemDate>
      {dayData?.races.length > 0 && dayData?.races.map((race) => {
        return (
          <MenuDateItemRace
            key={race.raceId}
            selected={selectedMenuDate?.day === dayData.day && selectedMenuRace?.raceId === race.raceId}
            onClick={() => handleSelectRace(dayData, race)}
          >
            Time: {race.timeStart} - {race.timeEnd}
            {race.menuId !== 0 && 
              <Tooltip title={'Созданное меню'}>
                <BookOutlined/>
              </Tooltip>
            }
          </MenuDateItemRace>
        );
      })}
    </MenuDate>
  );
};

export default MenuDateComponent;

const MenuDate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background: var(--color-light);
  border-radius: var(--radius-medium);
`
const MenuDateItemDate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-dark);
`
const MenuDateItemRace = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: ${({ selected }) => (selected ? 'var(--color-accent);' : 'var(--color-deep-light);')};
  border-radius: var(--radius-small);
  transition: all .3s;
  cursor: pointer;
  &:hover {
    background: var(--color-very-deep-light);
  }
`
