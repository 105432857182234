import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestModifierGroupsGet } from '../../../../../redux/reducers/utils/kitchenReducers/kitchenMenuReducer';
import {
  sendRequestCategoriesSort,
  setCurrentKitchen,
  setCurrentCategory,
  setIsShowAssortmentDrawer,
} from '../../../../../redux/reducers/utils/kitchenReducers/kitchenAssortmentReducer';
import { List, Badge, Button, Collapse } from 'antd';
import { DragOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const { Panel } = Collapse;

const CategoryComponent = ({ kitchens, onChangeCategory }) => {
  const [activeKey, setActiveKey] = useState([0]);

  const handlePanelChange = key => {
    setActiveKey(key);
  };

  return (
    <Collapse accordion activeKey={activeKey} onChange={handlePanelChange}>
      {kitchens.map((kitchen, index) => (
        <Panel header={kitchen.name} key={index}>
          <SubCategoryComponent categories={kitchen.categories} onChangeCategory={onChangeCategory}/>
        </Panel>
      ))}
    </Collapse>
  );
};

export default CategoryComponent;

const SubCategoryComponent = ({ type='default', categories, onChangeCategory }) => {
  const { currentCategory } = useSelector((store) => store.kitchenAssortment);
  const dispatch = useDispatch();
  const [list, setList] = useState(categories);
  const [showAll, setShowAll] = useState(type === 'admin' ? false : true);
  const [originalList, setOriginalList] = useState(categories);

  useEffect(() => {
    setList(categories);
    setOriginalList(categories);
  }, [categories]);

  useEffect(() => {
    const anyCategoryHasProducts = categories.some(category => category.countProducts > 0);
    if (!anyCategoryHasProducts) {
      setShowAll(true);
    }
  }, [categories]);

  const filteredList = showAll ? list : list.filter(category => category.countProducts > 0);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedList = Array.from(list);
    const [removed] = reorderedList.splice(result.source.index, 1);
    reorderedList.splice(result.destination.index, 0, removed);

    setList(reorderedList);
    setOriginalList(reorderedList);

    const isSortingChanged = JSON.stringify(reorderedList) !== JSON.stringify(originalList);

    if (isSortingChanged) {
      const sortingCategories = reorderedList.map((item, index) => ({
        categoryId: item.id,
        sort: index + 1,
      }));

      const requestData = {
        token: localStorage.getItem('token'),
        kitchenId: categories[0].kitchenId,
        sortingCategories: sortingCategories,
      };

      console.log(categories, requestData)
      dispatch(sendRequestCategoriesSort(requestData));
    }
  };

  const onClickCategory = (kitchenId, id, offsetLimit) => {
    onChangeCategory(kitchenId, id, offsetLimit);
    dispatch(setCurrentKitchen(kitchenId));
    dispatch(setCurrentCategory(id));
    dispatch(sendRequestModifierGroupsGet({ token: localStorage.getItem('token'), kitchenId: kitchenId }));
    dispatch(setIsShowAssortmentDrawer(false))
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="categories">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <List
              dataSource={filteredList}
              size='small'
              renderItem={(category, index) => (
                <Draggable draggableId={category.id?.toString()} index={index} key={category.id}>
                  {(provided) => (
                    <StyledListItem
                      key={category.id}
                      onClick={() => onClickCategory(category.kitchenId, category.id, category.countProducts)}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      
                      currentCategory={currentCategory}
                      categoryId={category.id}
                    >
                      <StyledButton {...provided.dragHandleProps}><DragOutlined /></StyledButton>
                      <span style={{ flex: '1' }}>{category.name}</span>
                      <Badge count={category.countProducts} style={{ backgroundColor: '#D3D3D3', color: 'black', marginLeft: '10px' }}/>
                      <Badge count={category.countModered} style={{ backgroundColor: '#FFA500', color: 'black', marginLeft: '10px' }}/>
                    </StyledListItem>
                  )}
                </Draggable>
              )}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Button style={{margin: '0 auto 20px', width: '100%'}} type='text' icon={showAll ? <UpOutlined/> : <DownOutlined/>} onClick={toggleShowAll}/>
    </DragDropContext>
  );
};

const StyledListItem = styled(List.Item)`
  background: ${(props) => props.currentCategory === props.categoryId ? '#e6f7ff' : 'inherit'};
  cursor: pointer;
`;
const StyledButton = styled('div')`
  margin: 0 10px;
`;