import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  //Access Roles
  accessRolesGetRequestStatus: null,
  accessRoles: [],

  //Access Get Components For User
  accessGetComponentsForUserRequestStatus: null,
  accessComponentsForUser: [],

  //Access Components
  accessComponentsGetRequestStatus: null,
  accessComponents: [],
  accessComponentsCreateRequestStatus: null,
  accessComponentsUpdateRequestStatus: null,
  accessComponentsDeleteRequestStatus: null,

  //Access Role Components
  accessRoleComponentsGetRequestStatus: null,
  accessRoleComponents: [],
  accessRoleComponentsCreateRequestStatus: null,
  accessRoleComponentsUpdateRequestStatus: null,
  accessRoleComponentsDeleteRequestStatus: null,

  //Access Public Methods
  accessPublicMethodsGetRequestStatus: null,
  accessPublicMethods: [],
  accessPublicMethodsCreateRequestStatus: null,
  accessPublicMethodsUpdateRequestStatus: null,
  accessPublicMethodsDeleteRequestStatus: null,

  //Access Role Public Methods
  accessRolePublicMethodsGetRequestStatus: null,
  accessRolePublicMethods: [],
  accessRolePublicMethodsCreateRequestStatus: null,
  accessRolePublicMethodsUpdateRequestStatus: null,
  accessRolePublicMethodsDeleteRequestStatus: null,

  //Access Role Users
  accessRoleUsersGetRequestStatus: null,
  accessRoleUsers: [],
  accessRoleUsersCreateRequestStatus: null,
  accessRoleUsersUpdateRequestStatus: null,
  accessRoleUsersDeleteRequestStatus: null,

  //Entity
  entityGetRequestStatus: null,
  entity: [],
  entityCreateRequestStatus: null,
  entityUpdateRequestStatus: null,
  entityDeleteRequestStatus: null,

  //Entity Keys
  entityKeysGetRequestStatus: null,
  entityKeys: [],
  entityKeysCreateRequestStatus: null,
  entityKeysUpdateRequestStatus: null,
  entityKeysDeleteRequestStatus: null,

  //Entity Key Roles
  entityKeyRolesGetRequestStatus: null,
  entityKeyRoles: [],
  entityKeyRolesCreateRequestStatus: null,
  entityKeyRolesUpdateRequestStatus: null,
  entityKeyRolesDeleteRequestStatus: null,
};

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    //Access Roles
    sendRequestAccessRolesGet: (state) => { state.accessRolesGetRequestStatus = 'pending'; },
    sendRequestAccessRolesGetSuccess: (state) => { state.accessRolesGetRequestStatus = 'resolved'; },
    sendRequestAccessRolesGetError: (state) => { state.accessRolesGetRequestStatus = 'rejected'; },
    clearAccessRolesGetStatus: (state) => { state.accessRolesGetRequestStatus = null; },
    setAccessRoles: (state, action) => {
      state.accessRoles = action.payload;
    },

    //Access Get Components For User
    sendRequestAccessGetComponentsForUser: (state) => { state.accessGetComponentsForUserRequestStatus = 'pending'; },
    sendRequestAccessGetComponentsForUserSuccess: (state) => { state.accessGetComponentsForUserRequestStatus = 'resolved'; },
    sendRequestAccessGetComponentsForUserError: (state) => { state.accessGetComponentsForUserRequestStatus = 'rejected'; },
    clearAccessGetComponentsForUserStatus: (state) => { state.accessGetComponentsForUserRequestStatus = null; },
    setAccessComponentsForUser: (state, action) => {
      state.accessComponentsForUser = action.payload;
    },

    //Access Components
    sendRequestAccessComponentsGet: (state) => { state.accessComponentsGetRequestStatus = 'pending'; },
    sendRequestAccessComponentsGetSuccess: (state) => { state.accessComponentsGetRequestStatus = 'resolved'; },
    sendRequestAccessComponentsGetError: (state) => { state.accessComponentsGetRequestStatus = 'rejected'; },
    clearAccessComponentsGetStatus: (state) => { state.accessComponentsGetRequestStatus = null; },
    setAccessComponents: (state, action) => {
      state.accessComponents = action.payload;
    },
    sendRequestAccessComponentsCreate: (state) => { state.accessComponentsCreateRequestStatus = 'pending'; },
    sendRequestAccessComponentsCreateSuccess: (state) => { state.accessComponentsCreateRequestStatus = 'resolved'; },
    sendRequestAccessComponentsCreateError: (state) => { state.accessComponentsCreateRequestStatus = 'rejected'; },
    clearAccessComponentsCreateStatus: (state) => { state.accessComponentsCreateRequestStatus = null; },
    sendRequestAccessComponentsUpdate: (state) => { state.accessComponentsUpdateRequestStatus = 'pending'; },
    sendRequestAccessComponentsUpdateSuccess: (state) => { state.accessComponentsUpdateRequestStatus = 'resolved'; },
    sendRequestAccessComponentsUpdateError: (state) => { state.accessComponentsUpdateRequestStatus = 'rejected'; },
    clearAccessComponentsUpdateStatus: (state) => { state.accessComponentsUpdateRequestStatus = null; },
    sendRequestAccessComponentsDelete: (state) => { state.accessComponentsDeleteRequestStatus = 'pending'; },
    sendRequestAccessComponentsDeleteSuccess: (state) => { state.accessComponentsDeleteRequestStatus = 'resolved'; },
    sendRequestAccessComponentsDeleteError: (state) => { state.accessComponentsDeleteRequestStatus = 'rejected'; },
    clearAccessComponentsDeleteStatus: (state) => { state.accessComponentsDeleteRequestStatus = null; },

    //Access Role Components
    sendRequestAccessRoleComponentsGet: (state) => { state.accessRoleComponentsGetRequestStatus = 'pending'; },
    sendRequestAccessRoleComponentsGetSuccess: (state) => { state.accessRoleComponentsGetRequestStatus = 'resolved'; },
    sendRequestAccessRoleComponentsGetError: (state) => { state.accessRoleComponentsGetRequestStatus = 'rejected'; },
    clearAccessRoleComponentsGetStatus: (state) => { state.accessRoleComponentsGetRequestStatus = null; },
    setAccessRoleComponents: (state, action) => {
      state.accessRoleComponents = action.payload;
    },
    sendRequestAccessRoleComponentsCreate: (state) => { state.accessRoleComponentsCreateRequestStatus = 'pending'; },
    sendRequestAccessRoleComponentsCreateSuccess: (state) => { state.accessRoleComponentsCreateRequestStatus = 'resolved'; },
    sendRequestAccessRoleComponentsCreateError: (state) => { state.accessRoleComponentsCreateRequestStatus = 'rejected'; },
    clearAccessRoleComponentsCreateStatus: (state) => { state.accessRoleComponentsCreateRequestStatus = null; },
    sendRequestAccessRoleComponentsUpdate: (state) => { state.accessRoleComponentsUpdateRequestStatus = 'pending'; },
    sendRequestAccessRoleComponentsUpdateSuccess: (state) => { state.accessRoleComponentsUpdateRequestStatus = 'resolved'; },
    sendRequestAccessRoleComponentsUpdateError: (state) => { state.accessRoleComponentsUpdateRequestStatus = 'rejected'; },
    clearAccessRoleComponentsUpdateStatus: (state) => { state.accessRoleComponentsUpdateRequestStatus = null; },
    sendRequestAccessRoleComponentsDelete: (state) => { state.accessRoleComponentsDeleteRequestStatus = 'pending'; },
    sendRequestAccessRoleComponentsDeleteSuccess: (state) => { state.accessRoleComponentsDeleteRequestStatus = 'resolved'; },
    sendRequestAccessRoleComponentsDeleteError: (state) => { state.accessRoleComponentsDeleteRequestStatus = 'rejected'; },
    clearAccessRoleComponentsDeleteStatus: (state) => { state.accessRoleComponentsDeleteRequestStatus = null; },

    //Access Public Methods
    sendRequestAccessPublicMethodsGet: (state) => { state.accessPublicMethodsGetRequestStatus = 'pending'; },
    sendRequestAccessPublicMethodsGetSuccess: (state) => { state.accessPublicMethodsGetRequestStatus = 'resolved'; },
    sendRequestAccessPublicMethodsGetError: (state) => { state.accessPublicMethodsGetRequestStatus = 'rejected'; },
    clearAccessPublicMethodsGetStatus: (state) => { state.accessPublicMethodsGetRequestStatus = null; },
    setAccessPublicMethods: (state, action) => {
      state.accessPublicMethods = action.payload;
    },
    sendRequestAccessPublicMethodsCreate: (state) => { state.accessPublicMethodsCreateRequestStatus = 'pending'; },
    sendRequestAccessPublicMethodsCreateSuccess: (state) => { state.accessPublicMethodsCreateRequestStatus = 'resolved'; },
    sendRequestAccessPublicMethodsCreateError: (state) => { state.accessPublicMethodsCreateRequestStatus = 'rejected'; },
    clearAccessPublicMethodsCreateStatus: (state) => { state.accessPublicMethodsCreateRequestStatus = null; },
    sendRequestAccessPublicMethodsUpdate: (state) => { state.accessPublicMethodsUpdateRequestStatus = 'pending'; },
    sendRequestAccessPublicMethodsUpdateSuccess: (state) => { state.accessPublicMethodsUpdateRequestStatus = 'resolved'; },
    sendRequestAccessPublicMethodsUpdateError: (state) => { state.accessPublicMethodsUpdateRequestStatus = 'rejected'; },
    clearAccessPublicMethodsUpdateStatus: (state) => { state.accessPublicMethodsUpdateRequestStatus = null; },
    sendRequestAccessPublicMethodsDelete: (state) => { state.accessPublicMethodsDeleteRequestStatus = 'pending'; },
    sendRequestAccessPublicMethodsDeleteSuccess: (state) => { state.accessPublicMethodsDeleteRequestStatus = 'resolved'; },
    sendRequestAccessPublicMethodsDeleteError: (state) => { state.accessPublicMethodsDeleteRequestStatus = 'rejected'; },
    clearAccessPublicMethodsDeleteStatus: (state) => { state.accessPublicMethodsDeleteRequestStatus = null; },

    //Access Role Public Methods
    sendRequestAccessRolePublicMethodsGet: (state) => { state.accessPublicMethodsGetRequestStatus = 'pending'; },
    sendRequestAccessRolePublicMethodsGetSuccess: (state) => { state.accessPublicMethodsGetRequestStatus = 'resolved'; },
    sendRequestAccessRolePublicMethodsGetError: (state) => { state.accessPublicMethodsGetRequestStatus = 'rejected'; },
    clearAccessRolePublicMethodsGetStatus: (state) => { state.accessPublicMethodsGetRequestStatus = null; },
    setAccessRolePublicMethods: (state, action) => {
      state.accessRolePublicMethods = action.payload;
    },
    sendRequestAccessRolePublicMethodsCreate: (state) => { state.accessRolePublicMethodsCreateRequestStatus = 'pending'; },
    sendRequestAccessRolePublicMethodsCreateSuccess: (state) => { state.accessRolePublicMethodsCreateRequestStatus = 'resolved'; },
    sendRequestAccessRolePublicMethodsCreateError: (state) => { state.accessRolePublicMethodsCreateRequestStatus = 'rejected'; },
    clearAccessRolePublicMethodsCreateStatus: (state) => { state.accessRolePublicMethodsCreateRequestStatus = null; },
    sendRequestAccessRolePublicMethodsUpdate: (state) => { state.accessRolePublicMethodsUpdateRequestStatus = 'pending'; },
    sendRequestAccessRolePublicMethodsUpdateSuccess: (state) => { state.accessRolePublicMethodsUpdateRequestStatus = 'resolved'; },
    sendRequestAccessRolePublicMethodsUpdateError: (state) => { state.accessRolePublicMethodsUpdateRequestStatus = 'rejected'; },
    clearAccessRolePublicMethodsUpdateStatus: (state) => { state.accessRolePublicMethodsUpdateRequestStatus = null; },
    sendRequestAccessRolePublicMethodsDelete: (state) => { state.accessRolePublicMethodsDeleteRequestStatus = 'pending'; },
    sendRequestAccessRolePublicMethodsDeleteSuccess: (state) => { state.accessRolePublicMethodsDeleteRequestStatus = 'resolved'; },
    sendRequestAccessRolePublicMethodsDeleteError: (state) => { state.accessRolePublicMethodsDeleteRequestStatus = 'rejected'; },
    clearAccessRolePublicMethodsDeleteStatus: (state) => { state.accessRolePublicMethodsDeleteRequestStatus = null; },

    //Access Role Users
    sendRequestAccessRoleUsersGet: (state) => { state.accessRoleUsersGetRequestStatus = 'pending'; },
    sendRequestAccessRoleUsersGetSuccess: (state) => { state.accessRoleUsersGetRequestStatus = 'resolved'; },
    sendRequestAccessRoleUsersGetError: (state) => { state.accessRoleUsersGetRequestStatus = 'rejected'; },
    clearAccessRoleUsersGetStatus: (state) => { state.accessRoleUsersGetRequestStatus = null; },
    setAccessRoleUsers: (state, action) => {
      state.accessRoleUsers = action.payload;
    },
    sendRequestAccessRoleUsersCreate: (state) => { state.accessRoleUsersCreateRequestStatus = 'pending'; },
    sendRequestAccessRoleUsersCreateSuccess: (state) => { state.accessRoleUsersCreateRequestStatus = 'resolved'; },
    sendRequestAccessRoleUsersCreateError: (state) => { state.accessRoleUsersCreateRequestStatus = 'rejected'; },
    clearAccessRoleUsersCreateStatus: (state) => { state.accessRoleUsersCreateRequestStatus = null; },
    sendRequestAccessRoleUsersUpdate: (state) => { state.accessRoleUsersUpdateRequestStatus = 'pending'; },
    sendRequestAccessRoleUsersUpdateSuccess: (state) => { state.accessRoleUsersUpdateRequestStatus = 'resolved'; },
    sendRequestAccessRoleUsersUpdateError: (state) => { state.accessRoleUsersUpdateRequestStatus = 'rejected'; },
    clearAccessRoleUsersUpdateStatus: (state) => { state.accessRoleUsersUpdateRequestStatus = null; },
    sendRequestAccessRoleUsersDelete: (state) => { state.accessRoleUsersDeleteRequestStatus = 'pending'; },
    sendRequestAccessRoleUsersDeleteSuccess: (state) => { state.accessRoleUsersDeleteRequestStatus = 'resolved'; },
    sendRequestAccessRoleUsersDeleteError: (state) => { state.accessRoleUsersDeleteRequestStatus = 'rejected'; },
    clearAccessRoleUsersDeleteStatus: (state) => { state.accessRoleUsersDeleteRequestStatus = null; },

    //Entity
    sendRequestEntityGet: (state) => { state.entityGetRequestStatus = 'pending'; },
    sendRequestEntityGetSuccess: (state) => { state.entityGetRequestStatus = 'resolved'; },
    sendRequestEntityGetError: (state) => { state.entityGetRequestStatus = 'rejected'; },
    clearEntityGetStatus: (state) => { state.entityGetRequestStatus = null; },
    setEntity: (state, action) => {
      state.entity = action.payload;
    },
    sendRequestEntityCreate: (state) => { state.entityCreateRequestStatus = 'pending'; },
    sendRequestEntityCreateSuccess: (state) => { state.entityCreateRequestStatus = 'resolved'; },
    sendRequestEntityCreateError: (state) => { state.entityCreateRequestStatus = 'rejected'; },
    clearEntityCreateStatus: (state) => { state.entityCreateRequestStatus = null; },
    sendRequestEntityUpdate: (state) => { state.entityUpdateRequestStatus = 'pending'; },
    sendRequestEntityUpdateSuccess: (state) => { state.entityUpdateRequestStatus = 'resolved'; },
    sendRequestEntityUpdateError: (state) => { state.entityUpdateRequestStatus = 'rejected'; },
    clearEntityUpdateStatus: (state) => { state.entityUpdateRequestStatus = null; },
    sendRequestEntityDelete: (state) => { state.entityDeleteRequestStatus = 'pending'; },
    sendRequestEntityDeleteSuccess: (state) => { state.entityDeleteRequestStatus = 'resolved'; },
    sendRequestEntityDeleteError: (state) => { state.entityDeleteRequestStatus = 'rejected'; },
    clearEntityDeleteStatus: (state) => { state.entityDeleteRequestStatus = null; },

    //Entity Keys
    sendRequestEntityKeysGet: (state) => { state.entityKeysGetRequestStatus = 'pending'; },
    sendRequestEntityKeysGetSuccess: (state) => { state.entityKeysGetRequestStatus = 'resolved'; },
    sendRequestEntityKeysGetError: (state) => { state.entityKeysGetRequestStatus = 'rejected'; },
    clearEntityKeysGetStatus: (state) => { state.entityKeysGetRequestStatus = null; },
    setEntityKeys: (state, action) => {
      state.entityKeys = action.payload;
    },
    sendRequestEntityKeysCreate: (state) => { state.entityKeysCreateRequestStatus = 'pending'; },
    sendRequestEntityKeysCreateSuccess: (state) => { state.entityKeysCreateRequestStatus = 'resolved'; },
    sendRequestEntityKeysCreateError: (state) => { state.entityKeysCreateRequestStatus = 'rejected'; },
    clearEntityKeysCreateStatus: (state) => { state.entityKeysCreateRequestStatus = null; },
    sendRequestEntityKeysUpdate: (state) => { state.entityKeysUpdateRequestStatus = 'pending'; },
    sendRequestEntityKeysUpdateSuccess: (state) => { state.entityKeysUpdateRequestStatus = 'resolved'; },
    sendRequestEntityKeysUpdateError: (state) => { state.entityKeysUpdateRequestStatus = 'rejected'; },
    clearEntityKeysUpdateStatus: (state) => { state.entityKeysUpdateRequestStatus = null; },
    sendRequestEntityKeysDelete: (state) => { state.entityKeysDeleteRequestStatus = 'pending'; },
    sendRequestEntityKeysDeleteSuccess: (state) => { state.entityKeysDeleteRequestStatus = 'resolved'; },
    sendRequestEntityKeysDeleteError: (state) => { state.entityKeysDeleteRequestStatus = 'rejected'; },
    clearEntityKeysDeleteStatus: (state) => { state.entityKeysDeleteRequestStatus = null; },

    //Entity Key Roles
    sendRequestEntityKeyRolesGet: (state) => { state.entityKeyRolesGetRequestStatus = 'pending'; },
    sendRequestEntityKeyRolesGetSuccess: (state) => { state.entityKeyRolesGetRequestStatus = 'resolved'; },
    sendRequestEntityKeyRolesGetError: (state) => { state.entityKeyRolesGetRequestStatus = 'rejected'; },
    clearEntityKeyRolesGetStatus: (state) => { state.entityKeyRolesGetRequestStatus = null; },
    setEntityKeyRoles: (state, action) => {
      state.entityKeyRoles = action.payload;
    },
    sendRequestEntityKeyRolesCreate: (state) => { state.entityKeyRolesCreateRequestStatus = 'pending'; },
    sendRequestEntityKeyRolesCreateSuccess: (state) => { state.entityKeyRolesCreateRequestStatus = 'resolved'; },
    sendRequestEntityKeyRolesCreateError: (state) => { state.entityKeyRolesCreateRequestStatus = 'rejected'; },
    clearEntityKeyRolesCreateStatus: (state) => { state.entityKeyRolesCreateRequestStatus = null; },
    sendRequestEntityKeyRolesUpdate: (state) => { state.entityKeyRolesUpdateRequestStatus = 'pending'; },
    sendRequestEntityKeyRolesUpdateSuccess: (state) => { state.entityKeyRolesUpdateRequestStatus = 'resolved'; },
    sendRequestEntityKeyRolesUpdateError: (state) => { state.entityKeyRolesUpdateRequestStatus = 'rejected'; },
    clearEntityKeyRolesUpdateStatus: (state) => { state.entityKeyRolesUpdateRequestStatus = null; },
    sendRequestEntityKeyRolesDelete: (state) => { state.entityKeyRolesDeleteRequestStatus = 'pending'; },
    sendRequestEntityKeyRolesDeleteSuccess: (state) => { state.entityKeyRolesDeleteRequestStatus = 'resolved'; },
    sendRequestEntityKeyRolesDeleteError: (state) => { state.entityKeyRolesDeleteRequestStatus = 'rejected'; },
    clearEntityKeyRolesDeleteStatus: (state) => { state.entityKeyRolesDeleteRequestStatus = null; },
  },
});

export const {
  //Access Roles
  sendRequestAccessRolesGet,
  sendRequestAccessRolesGetSuccess,
  sendRequestAccessRolesGetError,
  clearAccessRolesGetStatus,
  setAccessRoles,

  //Access Get Components For User
  sendRequestAccessGetComponentsForUser,
  sendRequestAccessGetComponentsForUserSuccess,
  sendRequestAccessGetComponentsForUserError,
  clearAccessGetComponentsForUserStatus,
  setAccessComponentsForUser,

  //Access Components
  sendRequestAccessComponentsGet,
  sendRequestAccessComponentsGetSuccess,
  sendRequestAccessComponentsGetError,
  clearAccessComponentsGetStatus,
  setAccessComponents,
  sendRequestAccessComponentsCreate,
  sendRequestAccessComponentsCreateSuccess,
  sendRequestAccessComponentsCreateError,
  clearAccessComponentsCreateStatus,
  sendRequestAccessComponentsUpdate,
  sendRequestAccessComponentsUpdateSuccess,
  sendRequestAccessComponentsUpdateError,
  clearAccessComponentsUpdateStatus,
  sendRequestAccessComponentsDelete,
  sendRequestAccessComponentsDeleteSuccess,
  sendRequestAccessComponentsDeleteError,
  clearAccessComponentsDeleteStatus,

  //Access Role Components
  sendRequestAccessRoleComponentsGet,
  sendRequestAccessRoleComponentsGetSuccess,
  sendRequestAccessRoleComponentsGetError,
  clearAccessRoleComponentsGetStatus,
  setAccessRoleComponents,
  sendRequestAccessRoleComponentsCreate,
  sendRequestAccessRoleComponentsCreateSuccess,
  sendRequestAccessRoleComponentsCreateError,
  clearAccessRoleComponentsCreateStatus,
  sendRequestAccessRoleComponentsUpdate,
  sendRequestAccessRoleComponentsUpdateSuccess,
  sendRequestAccessRoleComponentsUpdateError,
  clearAccessRoleComponentsUpdateStatus,
  sendRequestAccessRoleComponentsDelete,
  sendRequestAccessRoleComponentsDeleteSuccess,
  sendRequestAccessRoleComponentsDeleteError,
  clearAccessRoleComponentsDeleteStatus,

  //Access Public Methods
  sendRequestAccessPublicMethodsGet,
  sendRequestAccessPublicMethodsGetSuccess,
  sendRequestAccessPublicMethodsGetError,
  clearAccessPublicMethodsGetStatus,
  setAccessPublicMethods,
  sendRequestAccessPublicMethodsCreate,
  sendRequestAccessPublicMethodsCreateSuccess,
  sendRequestAccessPublicMethodsCreateError,
  clearAccessPublicMethodsCreateStatus,
  sendRequestAccessPublicMethodsUpdate,
  sendRequestAccessPublicMethodsUpdateSuccess,
  sendRequestAccessPublicMethodsUpdateError,
  clearAccessPublicMethodsUpdateStatus,
  sendRequestAccessPublicMethodsDelete,
  sendRequestAccessPublicMethodsDeleteSuccess,
  sendRequestAccessPublicMethodsDeleteError,
  clearAccessPublicMethodsDeleteStatus,

  //Access Role Public Methods
  sendRequestAccessRolePublicMethodsGet,
  sendRequestAccessRolePublicMethodsGetSuccess,
  sendRequestAccessRolePublicMethodsGetError,
  clearAccessRolePublicMethodsGetStatus,
  setAccessRolePublicMethods,
  sendRequestAccessRolePublicMethodsCreate,
  sendRequestAccessRolePublicMethodsCreateSuccess,
  sendRequestAccessRolePublicMethodsCreateError,
  clearAccessRolePublicMethodsCreateStatus,
  sendRequestAccessRolePublicMethodsUpdate,
  sendRequestAccessRolePublicMethodsUpdateSuccess,
  sendRequestAccessRolePublicMethodsUpdateError,
  clearAccessRolePublicMethodsUpdateStatus,
  sendRequestAccessRolePublicMethodsDelete,
  sendRequestAccessRolePublicMethodsDeleteSuccess,
  sendRequestAccessRolePublicMethodsDeleteError,
  clearAccessRolePublicMethodsDeleteStatus,

  //Access Role Users
  sendRequestAccessRoleUsersGet,
  sendRequestAccessRoleUsersGetSuccess,
  sendRequestAccessRoleUsersGetError,
  clearAccessRoleUsersGetStatus,
  setAccessRoleUsers,
  sendRequestAccessRoleUsersCreate,
  sendRequestAccessRoleUsersCreateSuccess,
  sendRequestAccessRoleUsersCreateError,
  clearAccessRoleUsersCreateStatus,
  sendRequestAccessRoleUsersUpdate,
  sendRequestAccessRoleUsersUpdateSuccess,
  sendRequestAccessRoleUsersUpdateError,
  clearAccessRoleUsersUpdateStatus,
  sendRequestAccessRoleUsersDelete,
  sendRequestAccessRoleUsersDeleteSuccess,
  sendRequestAccessRoleUsersDeleteError,
  clearAccessRoleUsersDeleteStatus,

  //Entity
  sendRequestEntityGet,
  sendRequestEntityGetSuccess,
  sendRequestEntityGetError,
  clearEntityGetStatus,
  setEntity,
  sendRequestEntityCreate,
  sendRequestEntityCreateSuccess,
  sendRequestEntityCreateError,
  clearEntityCreateStatus,
  sendRequestEntityUpdate,
  sendRequestEntityUpdateSuccess,
  sendRequestEntityUpdateError,
  clearEntityUpdateStatus,
  sendRequestEntityDelete,
  sendRequestEntityDeleteSuccess,
  sendRequestEntityDeleteError,
  clearEntityDeleteStatus,

  //Entity Keys
  sendRequestEntityKeysGet,
  sendRequestEntityKeysGetSuccess,
  sendRequestEntityKeysGetError,
  clearEntityKeysGetStatus,
  setEntityKeys,
  sendRequestEntityKeysCreate,
  sendRequestEntityKeysCreateSuccess,
  sendRequestEntityKeysCreateError,
  clearEntityKeysCreateStatus,
  sendRequestEntityKeysUpdate,
  sendRequestEntityKeysUpdateSuccess,
  sendRequestEntityKeysUpdateError,
  clearEntityKeysUpdateStatus,
  sendRequestEntityKeysDelete,
  sendRequestEntityKeysDeleteSuccess,
  sendRequestEntityKeysDeleteError,
  clearEntityKeysDeleteStatus,

  //Entity Key Roles
  sendRequestEntityKeyRolesGet,
  sendRequestEntityKeyRolesGetSuccess,
  sendRequestEntityKeyRolesGetError,
  clearEntityKeyRolesGetStatus,
  setEntityKeyRoles,
  sendRequestEntityKeyRolesCreate,
  sendRequestEntityKeyRolesCreateSuccess,
  sendRequestEntityKeyRolesCreateError,
  clearEntityKeyRolesCreateStatus,
  sendRequestEntityKeyRolesUpdate,
  sendRequestEntityKeyRolesUpdateSuccess,
  sendRequestEntityKeyRolesUpdateError,
  clearEntityKeyRolesUpdateStatus,
  sendRequestEntityKeyRolesDelete,
  sendRequestEntityKeyRolesDeleteSuccess,
  sendRequestEntityKeyRolesDeleteError,
  clearEntityKeyRolesDeleteStatus,
} = servicesSlice.actions;

export default servicesSlice.reducer;
