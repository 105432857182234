import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  //Search
  financialSettlementGetRequestStatus: null,
  financialSettlement: [],
};

const financialSettlementSlice = createSlice({
  name: 'financialSettlement',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    //Search
    sendRequestFinancialSettlementGet: (state) => { state.financialSettlementGetRequestStatus = 'pending'; },
    sendRequestFinancialSettlementGetSuccess: (state) => { state.financialSettlementGetRequestStatus = 'resolved'; },
    sendRequestFinancialSettlementGetError: (state) => { state.financialSettlementGetRequestStatus = 'rejected'; },
    clearFinancialSettlementGetStatus: (state) => { state.financialSettlementGetRequestStatus = null; },
    setFinancialSettlement: (state, action) => {
      state.financialSettlement = action.payload;
    },
  },
});

export const {
  //Search
  sendRequestFinancialSettlementGet,
  sendRequestFinancialSettlementGetSuccess,
  sendRequestFinancialSettlementGetError,
  clearFinancialSettlementGetStatus,
  setFinancialSettlement,
} = financialSettlementSlice.actions;

export default financialSettlementSlice.reducer;