import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestCitiesGet } from '../../../../../redux/reducers/utils/settingsReducers/settingsCatalogsReducer';
import {
  sendRequestNotificationLogsGet,
  setCurrentNotificationPage,
 } from '../../../../../redux/reducers/utils/servicesLogsReducer';
import { Typography, Divider } from 'antd';
import { Container, Page, ScrolledCol } from '../../../../../core/styles';
import Loader from '../../../../../components/Loader'
import NotificationlogsFiltersComponent from './components/NotificationlogsFiltersComponent';
import NotificationlogsTableComponent from './components/NotificationlogsTableComponent';

const { Title, Text } = Typography;

const NotificationlogsPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const { cities } = useSelector((store) => store.settingsCatalogs)
  const {
    getNotificationsLogsRequestStatus,
    notificationsLogs,
    totalNotifications,
    currentNotificationsPage,
    projectsOptions,
    statusesOptions,
  } = useSelector(state => state.servicesLogs);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({})

  const citiesOptions = cities?.map((item) => ({
    ...item,
    value: item.id,
    label: item.name,
  }));

  const requestDataNotificationsGet = {
    token: localStorage.getItem('token'),
    offset: (currentNotificationsPage - 1) * 10,
    limit: 10,
  }

  useEffect(() => {
    if(isAuth) {
      dispatch(sendRequestCitiesGet({ token: localStorage.getItem('token') }));
      dispatch(sendRequestNotificationLogsGet({ ...requestDataNotificationsGet}));
    }
  }, [isAuth]);

  const handleSearch = (filters) => {
    setFilters(filters);
    dispatch(sendRequestNotificationLogsGet({ ...requestDataNotificationsGet, ...filters}));
  }
  
  const handlePageChange = (page) => {
    console.log(page);

    dispatch(setCurrentNotificationPage(page));
    dispatch(sendRequestNotificationLogsGet({...requestDataNotificationsGet, ...filters, offset: (page - 1) * 10 }));
  }

  return (
    <Page>
      <ScrolledCol>
        <Container>
          {(
            getNotificationsLogsRequestStatus === 'pending'
          ) && <Loader />}
          <Title level={2}>Логи уведомлений клиентов</Title>

          <NotificationlogsFiltersComponent
            projectsOptions={projectsOptions}
            statusesOptions={statusesOptions}
            citiesOptions={citiesOptions}
            total={totalNotifications}
            onSearch={handleSearch}
          />

          <Divider/>

          <NotificationlogsTableComponent
            dataSource={notificationsLogs}
            currentPage={currentNotificationsPage}
            total={totalNotifications}
            onChange={handlePageChange}
          />
        </Container>
      </ScrolledCol>
    </Page>
  );
};

export default NotificationlogsPage;
