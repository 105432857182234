import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

function SwagerUIcomponent({api}) {
  return (
    <div style={{height: '100vh', overflow: 'auto'}}>
      <SwaggerUI url={api}/>
    </div>
  );
}

export default SwagerUIcomponent;