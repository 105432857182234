import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestCitiesGet } from '../../redux/reducers/utils/settingsReducers/settingsCatalogsReducer';
import { Flex } from 'antd';
import { Container, Page, ScrolledCol } from '../../core/styles';
import NewsComponent from './components/NewsComponent';
import KitchensComponent from './components/KitchensComponent';

function MainModule() {
  const { isAuth } = useSelector((store) => store.auth);
  const { currentPage } = useSelector((store) => store.kitchen);
  const dispatch = useDispatch();
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestCitiesGet({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo(0, 0);
    }
  }, [currentPage]);

  return (
    <Page>
      <Container>
        <Flex>
          <ScrolledCol ref={scrollContainerRef} height={'calc(100vh - 85px)'}>
            <KitchensComponent/>
          </ScrolledCol>
          <ScrolledCol width={'350px'} height={'calc(100vh - 45px)'}>
            <NewsComponent/>
          </ScrolledCol>
        </Flex>
      </Container>
    </Page>
  );
}

export default MainModule;
