import React from "react";
import { Row, Col, Typography } from 'antd';

const { Title, Text } = Typography;

function DevComponent({page}) {
  return (
    <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
      <Col>
        <Title level={3}>Страница находится в разработке</Title>
        <Text>Скоро тут появится:</Text>
        <Title>{page}</Title>
      </Col>
    </Row>
  );
}

export default DevComponent;