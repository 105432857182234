import React, { useState, useEffect } from 'react';
import { Flex, Modal, Input, Checkbox, Typography, Form, Divider, ColorPicker, Button, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import styled from 'styled-components';
import { formatDate } from '../../../../../core/utils';
import ImgUploader from '../../../../../components/ImgUploader';

const { Title, Text } = Typography;

// {
//   "orderGroupId": 5674297,
//   "finalPrice": 350,
//   "deliveryDay": "2024-05-28",
//   "deliveryPrice": 0,
//   "serviceFeePrice": 0,
//   "timeZone": 4,
//   "timeServer": "2024-06-17 11:54:39",
//   "timeServerTimestamp": 1718625279,
//   "walletTransactions": [
//       {
//           "orderGroupId": 5674297,
//           "amount": 300,
//           "comment": "Заказ 5674297",
//           "created": "2024-05-28 07:28:00",
//           "walletFrom": 27911,
//           "walletTo": 0
//       },
//       {
//           "orderGroupId": 5674297,
//           "amount": 262,
//           "comment": "262.5 рублей по заказу 5674297",
//           "created": "2024-05-28 11:42:23",
//           "walletFrom": 0,
//           "walletTo": 77010
//       }
//   ],
//   "orderTransactions": [
//       {
//           "amount": 50,
//           "orderGroupId": 5674297,
//           "paymentStatus": 2,
//           "paymentStatusName": "Оплачен",
//           "paySystemId": 2,
//           "paySystemName": "Тинькофф",
//           "created": "2024-05-28 07:28:00",
//           "updated": "2024-05-30 13:08:03"
//       },
//       {
//           "amount": 300,
//           "orderGroupId": 5674297,
//           "paymentStatus": 2,
//           "paymentStatusName": "Оплачен",
//           "paySystemId": 32,
//           "paySystemName": "Баллами",
//           "created": "2024-05-28 07:28:00",
//           "updated": "2024-05-30 13:08:03"
//       }
//   ],
//   "raceId": 1,
//   "racePolygonId": 1,
//   "deliveryServiceName": "Доставка Владимира",
//   "orders": [
//       {
//           "orderId": 5674297,
//           "timeStart": "07:30",
//           "timeStartTimestamp": 1718609400,
//           "timeEnd": "08:30",
//           "timeEndTimestamp": 1718613000,
//           "orderExpDate": "2024-05-28 05:30:00.000000",
//           "orderExpDateTimestamp": 1716874200,
//           "orderGroupId": 5674297,
//           "ordersId": null,
//           "orderStatus": 50,
//           "orderStatusName": "Доставлен",
//           "kitchenName": "Комбо-ланч",
//           "kitchenId": 239665,
//           "addressId": 9854,
//           "flat": "305",
//           "house": "161",
//           "street": "Южное шоссе",
//           "floor": 3,
//           "structure": "2.1",
//           "city": 18,
//           "cityName": "Тольятти",
//           "entrance": "1",
//           "addressName": "© Сытый ",
//           "created": "2024-05-28 03:28:00",
//           "updated": "2024-05-28 07:42:23",
//           "orderPrice": 350,
//           "deliveryDay": "2024-05-28",
//           "comment": "Не класть маойнез\n",
//           "courierId": 771849,
//           "courierName": "Олег",
//           "courierPrefix": "Т",
//           "courierColor": "#4D7B09",
//           "orderPoducts": [
//               {
//                   "orderId": 5674297,
//                   "productId": 1055189,
//                   "kitchenId": 239665,
//                   "created": "2024-05-28 07:28:00",
//                   "name": "Премиум №3. ВТ 1",
//                   "description": "1. Суп рисовый с индейкой (250 гр) Филе индейки, картофель, рис, лук репчатый, морковь, соль, специи, масло растительное\n2. Картофель жареный с вешенками (150 гр) Картофель, грибы вешенки,  лук репчатый, масло растительное, соль, пряности\n3. Люля-кебаб из",
//                   "price": 350,
//                   "orderProductPrice": 350,
//                   "orderProductId": 16611147,
//                   "amount": 1,
//                   "weight": 536,
//                   "proteins": 7,
//                   "calories": 113,
//                   "carbs": 12,
//                   "fats": 4,
//                   "isModifier": 0,
//                   "isProduct": 1
//               }
//           ],
//           "orderReviews": [],
//           "raceId": 1,
//           "racePolygonId": 1
//       }
//   ]
// }

const OrderCardComponent = ({ record, onUpdateComment, onCreateComplaint }) => {

  return (
    <OrderCard>
      <OrderCardGrid>
        <GridCol>
          <Text strong>ID: {record.orderGroupId}</Text>
          <Text>{formatDate(record.deliveryDay)}</Text>
        </GridCol>

        <GridCol>
          <Text>Доставка "{record.deliveryServiceName}"</Text>
        </GridCol>

        <GridCol>
          {record.orders.map(order => (
            <OrderItem
              key={order.orderId}
              order={order}
              onUpdateComment={onUpdateComment}
              onCreateComplaint={onCreateComplaint}
            />
          ))}
        </GridCol>
      </OrderCardGrid>
      <Flex vertical style={{width: 'fit-content', marginLeft: 'auto'}}>
        <Text strong>Итого: {record.finalPrice}р.</Text>
      </Flex>
    </OrderCard>
  );
};

export default OrderCardComponent;

const OrderCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`
const OrderCardGrid = styled.div`
  display: grid;
  grid-template-columns: 150px 200px 1fr;
  gap: 10px;
  border-bottom: solid 1px #eeeeee;
`
const GridCol = styled.div`
  display: flex;
  flex-direction: column;
`

const OrderItem = ({ order, onUpdateComment, onCreateComplaint }) => {
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [comment, setComment] = useState(order.comment || '');
  const [isComplaintModalVisible, setIsComplaintModalVisible] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [complaintProducts, setComplaintProducts] = useState([]);
  const [complaint, setComplaint] = useState('');
  const [fileList, setFileList] = useState([]);

  const showCommentModal = () => {
    setIsCommentModalVisible(true);
  };

  const handleCommentOk = () => {
    onUpdateComment(order.orderId, comment);
    setIsCommentModalVisible(false);
  };

  const handleCommentCancel = () => {
    setIsCommentModalVisible(false);
  };

  const handleCheckboxChange = (productId) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(productId)
        ? prevSelected.filter((id) => id !== productId)
        : [...prevSelected, productId]
    );
  };

  const showComplaintModal = () => {
    const complaintProductsArr = order.orderPoducts.filter(product => selectedProducts.includes(product.productId))
    setComplaintProducts(complaintProductsArr);
    setIsComplaintModalVisible(true);
  };

  const handleComplaintOk = () => {
    const requestData = {
      orderId: order.orderId,
      description: complaint,
      pretensions: {
        products: complaintProducts.map(product => {return {orderProductId: product.orderProductId, amount: product.amount}}),
        services: [],
      }
    }

    if (fileList.length > 0) {
      fileList.forEach((file, index) => {
        requestData[`image${index + 1}`] = file.originFileObj;
      });
    }

    onCreateComplaint(requestData);
    setIsComplaintModalVisible(false);
    setComplaint('');
    setFileList([]);
    setComplaintProducts([]);
  };

  const handleComplaintCancel = () => {
    setIsComplaintModalVisible(false);
    setComplaint('');
    setFileList([]);
    setComplaintProducts([]);
  };

  return (
    <>
      <Flex key={order.orderId} vertical>
        <Title level={5}>
          Заказ {order.orderId} <b>{order.kitchenName}</b> - {comment ? comment : <Text type='secondary'>Добавить комментарий</Text>}
          <Button type="text" icon={<EditOutlined />} onClick={showCommentModal} />
        </Title>
        <div style={{marginBottom: '10px'}}>
          {order.orderPoducts.map(product => (
            <div
              key={product.productId}
              style={{
                display: 'grid',
                gridTemplateColumns: '20px 1fr 60px',
                gap: '10px',
                textDecoration: selectedProducts.includes(product.productId) ? 'line-through' : 'none',
                color: selectedProducts.includes(product.productId) ? 'gray' : 'inherit'
              }}
            >
              <Checkbox
                checked={selectedProducts.includes(product.productId)}
                onChange={() => handleCheckboxChange(product.productId)}
              />
              <div>{product.name}</div>
              <div style={{ textAlign: 'right' }}>{product.price}р.</div>
            </div>
          ))}
        </div>
        {selectedProducts.length > 0 && <Button
          type="primary"
          style={{marginBottom: '10px', width: 'fit-content'}}
          onClick={showComplaintModal}
        >
          Создать претензию
        </Button>}
      </Flex>

      <Modal
        title="Добавить комментарий"
        open={isCommentModalVisible}
        onOk={handleCommentOk}
        onCancel={handleCommentCancel}
      >
        <Input.TextArea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Введите ваш комментарий"
        />
      </Modal>

      <Modal
        title="Добавить претензию"
        open={isComplaintModalVisible}
        onOk={handleComplaintOk}
        onCancel={handleComplaintCancel}
        okText="Создать"
        okButtonProps={{ disabled: complaint === '' }}
      > 
        <ul style={{marginBottom: '10px'}}>
          {complaintProducts.map(product => (
            <li>{product.name} - {product.amount}шт. - {product.price}р.</li>
          ))}
        </ul>
        <Input.TextArea
          style={{marginBottom: '10px'}}
          value={complaint}
          onChange={(e) => setComplaint(e.target.value)}
          placeholder="Текст претензии"
        />
        <Text>Загрузить изображения:</Text>
        <ImgUploader
          multiple
          fileList={fileList}
          onUpload={setFileList}
        />
      </Modal>
    </>
  );
};