import React from 'react';
import SwaggerUIcomponent from '../components/SwagerUIcomponent';

function AdminPanelDocsPage() {
  const adminPanelApi = 'https://api-test.menuforme.online/documentation/admin/v1/api';
  return (
    <SwaggerUIcomponent api={adminPanelApi}/>
  );
}

export default AdminPanelDocsPage;