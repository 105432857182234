import { takeLatest } from 'redux-saga/effects';
import {
  //Races For Days
  handleGetRacesForDays,

  //Menu
  handleCreateMenu,
  handleEnableMenu,
  handleDisableMenu,

  //Products
  handleGetMenuAllProducts,
  handleMenuAddProductInMenu,
  handleMenuEnableProductInMenu,
  handleMenuDisableProductInMenu,
  handleMenuUpdateProductInMenu,

  //Menu Template
  handleGetMenuTemplates,
  handleCreateMenuTemplate,
  handleUpdateMenuTemplate,
  handleDeleteMenuTemplate,
  handleCopyMenuTemplate,
  handleTransferMenuTemplate,

  //Menu Template Products
  handleGetMenuTemplateProducts,
  handleAddMenuTemplateProduct,
  handleUpdateMenuTemplateProduct,
  handleRemoveMenuTemplateProduct,

  //Modifier Groups
  handleGetModifierGroups,
  handleCreateModifierGroups,
  handleUpdateModifierGroups,
  handleDeleteModifierGroups,

  //Modifier Groups Products
  handleGetModifierGroupsProducts,
  handleCreateModifierGroupsProduct,
  handleUpdateModifierGroupsProduct,
  handleDeleteModifierGroupsProduct,
} from './kitchenMenuHandler';
import {
  //Races For Days
  sendRequestRacesForDays,

  //Menu
  sendRequestCreateMenu,
  sendRequestEnableMenu,
  sendRequestDisableMenu,

  //Products
  sendRequestMenuAllProducts,
  sendRequestAddProductInMenu,
  sendRequestEnableProductInMenu,
  sendRequestDisableProductInMenu,
  sendRequestUpdateProductInMenu,

  //Menu Template
  sendRequestMenuTemplates,
  sendRequestCreateMenuTemplate,
  sendRequestUpdateMenuTemplate,
  sendRequestDeleteMenuTemplate,
  sendRequestCopyMenuTemplate,
  sendRequestTransferMenuTemplate,

  //Menu Template Products
  sendRequestMenuTemplateProducts,
  sendRequestAddProductMenuTemplate,
  sendRequestUpdateProductMenuTemplate,
  sendRequestRemoveProductMenuTemplate,

  //Modifier Groups
  sendRequestModifierGroupsGet,
  sendRequestModifierGroupsCreate,
  sendRequestModifierGroupsUpdate,
  sendRequestModifierGroupsDelete,

  //Modifier Groups Products
  sendRequestModifierGroupsProductsGet,
  sendRequestModifierGroupsProductsCreate,
  sendRequestModifierGroupsProductUpdate,
  sendRequestModifierGroupsProductDelete,
} from '../../../../reducers/utils/kitchenReducers/kitchenMenuReducer';

export function* kitchenMenuWatcherSaga() {
  //Races For Days
  yield takeLatest(sendRequestRacesForDays.type, handleGetRacesForDays);

  //Menu
  yield takeLatest(sendRequestCreateMenu.type, handleCreateMenu);
  yield takeLatest(sendRequestEnableMenu.type, handleEnableMenu);
  yield takeLatest(sendRequestDisableMenu.type, handleDisableMenu);

  //Products
  yield takeLatest(sendRequestMenuAllProducts.type, handleGetMenuAllProducts);
  yield takeLatest(sendRequestAddProductInMenu.type, handleMenuAddProductInMenu);
  yield takeLatest(sendRequestEnableProductInMenu.type, handleMenuEnableProductInMenu);
  yield takeLatest(sendRequestDisableProductInMenu.type, handleMenuDisableProductInMenu);
  yield takeLatest(sendRequestUpdateProductInMenu.type, handleMenuUpdateProductInMenu);

  //Menu Template
  yield takeLatest(sendRequestMenuTemplates.type, handleGetMenuTemplates);
  yield takeLatest(sendRequestCreateMenuTemplate.type, handleCreateMenuTemplate);
  yield takeLatest(sendRequestUpdateMenuTemplate.type, handleUpdateMenuTemplate);
  yield takeLatest(sendRequestDeleteMenuTemplate.type, handleDeleteMenuTemplate);
  yield takeLatest(sendRequestCopyMenuTemplate.type, handleCopyMenuTemplate);
  yield takeLatest(sendRequestTransferMenuTemplate.type, handleTransferMenuTemplate);

  //Menu Template Products
  yield takeLatest(sendRequestMenuTemplateProducts.type, handleGetMenuTemplateProducts);
  yield takeLatest(sendRequestAddProductMenuTemplate.type, handleAddMenuTemplateProduct);
  yield takeLatest(sendRequestUpdateProductMenuTemplate.type, handleUpdateMenuTemplateProduct);
  yield takeLatest(sendRequestRemoveProductMenuTemplate.type, handleRemoveMenuTemplateProduct);

  //Modifier Groups
  yield takeLatest(sendRequestModifierGroupsGet.type, handleGetModifierGroups);
  yield takeLatest(sendRequestModifierGroupsCreate.type, handleCreateModifierGroups);
  yield takeLatest(sendRequestModifierGroupsUpdate.type, handleUpdateModifierGroups);
  yield takeLatest(sendRequestModifierGroupsDelete.type, handleDeleteModifierGroups);

  //Modifier Groups Products
  yield takeLatest(sendRequestModifierGroupsProductsGet.type, handleGetModifierGroupsProducts);
  yield takeLatest(sendRequestModifierGroupsProductsCreate.type, handleCreateModifierGroupsProduct);
  yield takeLatest(sendRequestModifierGroupsProductUpdate.type, handleUpdateModifierGroupsProduct);
  yield takeLatest(sendRequestModifierGroupsProductDelete.type, handleDeleteModifierGroupsProduct);
}