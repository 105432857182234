import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  //Kitchen
  kitchensRequestStatus: null,
  kitchens: [],
  isKitchenOwner: false,
  totalKitchens: 0,
  currentPage: 1,
  selectKitchen: null,

  //UserKitchenCreate
  userKitchenCreateRequestStatus: null,

  //CategoriesCatalog
  categoriesCatalogGetRequestStatus: null,
  categoriesCatalog: [],
};

const kitchenSlice = createSlice({
  name: 'kitchen',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    //Kitchen
    sendRequestKitchens: (state) => { state.kitchensRequestStatus = 'pending'; },
    sendRequestKitchensSuccess: (state) => { state.kitchensRequestStatus = 'resolved'; },
    sendRequestKitchensError: (state) => { state.kitchensRequestStatus = 'rejected'; },
    clearKitchensStatus: (state) => { state.kitchensRequestStatus = null; },
    setKitchensData: (state, action) => {
      const data = action.payload;
      state.kitchens = data.kitchens;
      state.totalKitchens = data.countkitchens;
      state.isKitchenOwner = data.isKitchenOwner;

      if (data.length === 1) {
        state.selectKitchen = data.kitchens[0];
      }
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setSelectKitchen: (state, action) => {
      state.selectKitchen = action.payload;
      state.menuAllProductsData = [];
      state.selectedMenuDate = null;
      state.selectedMenuRace = null;
    },
    updateSelectKitchen: (state, action) => {
      const kitchens = action.payload;
      
      if (state.selectKitchen !== null) {
        const selectedKitchen = kitchens.find(kitchen => kitchen.id === state.selectKitchen.id);
        state.selectKitchen = selectedKitchen;
      } else {
        return;
      }
    },

    //UserKitchenCreate
    sendRequestUserKitchenCreate: (state) => { state.userKitchenCreateRequestStatus = 'pending'; },
    sendRequestUserKitchenCreateSuccess: (state) => { state.userKitchenCreateRequestStatus = 'resolved'; },
    sendRequestUserKitchenCreateError: (state) => { state.userKitchenCreateRequestStatus = 'rejected'; },
    clearUserKitchenCreateStatus: (state) => { state.userKitchenCreateRequestStatus = null; },

    //CategoriesCatalog
    sendRequestCategoriesCatalogGet: (state) => { state.categoriesCatalogGetRequestStatus = 'pending'; },
    sendRequestCategoriesCatalogGetSuccess: (state) => { state.categoriesCatalogGetRequestStatus = 'resolved'; },
    sendRequestCategoriesCatalogGetError: (state) => { state.categoriesCatalogGetRequestStatus = 'rejected'; },
    clearCategoriesCatalogGetStatus: (state) => { state.categoriesCatalogGetRequestStatus = null; },
    setCategoriesCatalog: (state, action) => {
      state.categoriesCatalog = action.payload;
    },
  },
});

export const {
  //Kitchen
  sendRequestKitchens,
  sendRequestKitchensSuccess,
  sendRequestKitchensError,
  clearKitchensStatus,
  setKitchensData,
  setCurrentPage,
  setSelectKitchen,
  updateSelectKitchen,

  //UserKitchenCreate
  sendRequestUserKitchenCreate,
  sendRequestUserKitchenCreateSuccess,
  sendRequestUserKitchenCreateError,
  clearUserKitchenCreateStatus,

  //CategoriesCatalog
  sendRequestCategoriesCatalogGet,
  sendRequestCategoriesCatalogGetSuccess,
  sendRequestCategoriesCatalogGetError,
  clearCategoriesCatalogGetStatus,
  setCategoriesCatalog,
} = kitchenSlice.actions;

export default kitchenSlice.reducer;
