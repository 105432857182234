import React from 'react';
import { Select } from 'antd';

const AccessSelectRoleComponent = ({roles, selectedRole, setSelectedRole}) => {
  const rolesOptions = roles.map((role) => ({
    id: role.id,
    value: role.name,
    label: role.description,
  }));

  const handleChange = (value) => {
    const selectedRole = rolesOptions.find((role) => role.value === value);
    console.log(selectedRole);
    setSelectedRole(selectedRole);
  };

  return (
    <Select
      defaultValue={selectedRole.label}
      style={{ width: 300 }}
      onChange={handleChange}
      options={rolesOptions}
    />
  );
};

export default AccessSelectRoleComponent;
