import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  notificationsGetRequestStatus: null,
  notifications: [],
  totalNotifications: 0,
  currentPage: 1,
  notificationPushRequestStatus: null,
  devices: 0,
};

const clientsNotificationsSlice = createSlice({
  name: 'clientsNotifications',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    sendRequestNotificationsGet: (state) => { state.notificationsGetRequestStatus = 'pending'; },
    sendRequestNotificationsGetSuccess: (state) => { state.notificationsGetRequestStatus = 'resolved'; },
    sendRequestNotificationsGetError: (state) => { state.notificationsGetRequestStatus = 'rejected'; },
    clearNotificationsGetStatus: (state) => { state.notificationsGetRequestStatus = null; },
    setNotifications: (state, action) => {
      const data = action.payload;

      state.notifications = data.customerNotifications;
      state.totalNotifications = data.customerNotificationsCount;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    sendRequestNotificationsPush: (state) => { state.notificationPushRequestStatus = 'pending'; },
    sendRequestNotificationsPushSuccess: (state) => { state.notificationPushRequestStatus = 'resolved'; },
    sendRequestNotificationsPushError: (state) => { state.notificationPushRequestStatus = 'rejected'; },
    clearNotificationsPushStatus: (state) => { state.notificationPushRequestStatus = null; },
    setDevices: (state, action) => {
      state.devices = action.payload;
    },
    clearDevices: (state) => {
      state.devices = 0;
    },
  },
});

export const {
  sendRequestNotificationsGet,
  sendRequestNotificationsGetSuccess,
  sendRequestNotificationsGetError,
  clearNotificationsGetStatus,
  setNotifications,
  setCurrentPage,
  sendRequestNotificationsPush,
  sendRequestNotificationsPushSuccess,
  sendRequestNotificationsPushError,
  clearNotificationsPushStatus,
  setDevices,
  clearDevices,
} = clientsNotificationsSlice.actions;

export default clientsNotificationsSlice.reducer;