import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentRoute } from '../../redux/reducers/utils/navReducer';

const SettingsModule = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  dispatch(setCurrentRoute('settings/main'));
  navigate('main');
}

export default SettingsModule;