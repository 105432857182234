import { combineReducers } from '@reduxjs/toolkit';
import commonReducer from './utils/commonReducer';
import authReducer from './utils/authReducer';
import mainReducer from './utils/mainReducers/mainReducer';
import navReducer from './utils/navReducer';
import kitchenReducer from './utils/kitchenReducers/kitchenReducer';
import kitchenFinanceReducer from './utils/kitchenReducers/kitchenFinanceReducer';
import kitchenComplaintReducer from './utils/kitchenReducers/kitchenComplaintReducer';
import kitchenOrdersReducer from './utils/kitchenReducers/kitchenOrdersReducer';
import kitchenAssortmentReducer from './utils/kitchenReducers/kitchenAssortmentReducer';
import kitchenMenuReducer from './utils/kitchenReducers/kitchenMenuReducer';
import kitchenSettingsReducer from './utils/kitchenReducers/kitchenSettingsReducer';
import deliveryServicesReducer from './utils/deliveryReducers/deliveryServicesReducer';
import deliveryMapReducer from './utils/deliveryReducers/deliveryMapReducer';
import clientsSearchReducer from './utils/clientsReducers/clientsSearchReducer';
import clientsNotificationsReducer from './utils/clientsReducers/clientsNotificationsReducer';
import addressesSearchReducer from './utils/clientsReducers/addressesSearchReducer';
import financeWalletsReducer from './utils/financeReducers/financeWalletsReducer';
import financePromocodesReducer from './utils/financeReducers/financePromocodesReducer';
import financeCorpclientsReducer from './utils/financeReducers/financeCorpclientsReducer';
import settingsCatalogsReducer from './utils/settingsReducers/settingsCatalogsReducer';
import financialSettlementReducer from './utils/moderationReducers/financialSettlementReducer';
import servicesReducer from './utils/servicesReducer';
import servicesLogsReducer from './utils/servicesLogsReducer';

const combineReducer = combineReducers({
  common: commonReducer,
  auth: authReducer,
  main: mainReducer,
  nav: navReducer,
  kitchen: kitchenReducer,
  kitchenFinance: kitchenFinanceReducer,
  kitchenComplaint: kitchenComplaintReducer,
  kitchenOrders: kitchenOrdersReducer,
  kitchenMenu: kitchenMenuReducer,
  kitchenAssortment: kitchenAssortmentReducer,
  kitchenSettings: kitchenSettingsReducer,
  deliveryServices: deliveryServicesReducer,
  deliveryMap: deliveryMapReducer,
  clientsSearch: clientsSearchReducer,
  clientsNotifications: clientsNotificationsReducer,
  addressesSearch: addressesSearchReducer,
  financeWallets: financeWalletsReducer,
  financePromocodes: financePromocodesReducer,
  financeCorpclients: financeCorpclientsReducer,
  settingsCatalogs: settingsCatalogsReducer,
  financialSettlement: financialSettlementReducer,
  services: servicesReducer,
  servicesLogs: servicesLogsReducer,
});

export default combineReducer;
