import React from 'react';
import { Table, Typography, Button, Space, Tag } from 'antd';
import { CheckOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import PrintTable from '../../../../../components/PrintTable';
import { formatDate } from '../../../../../core/utils';

const { Title, Text } = Typography;

const DevicesTableComponent = ({ dataSource }) => {
  const columns = [
    {
      title: 'ID Пользователя',
      dataIndex: 'userId',
      key: 'userId',
    },
    {
      title: 'FCM Token',
      dataIndex: 'fcmToken',
      key: 'fcmToken',
      render: text => <Text copyable>{text}</Text>,
    },
    {
      title: 'Устройство',
      key: 'device',
      render: (record) => (<Text>{record.device}</Text>),
    },
    {
      title: 'Статус',
      dataIndex: 'statusName',
      key: 'statusName',
      render: status => (
        <Tag color={status === 'Активный' ? 'green' : 'red'}>
          {status}
        </Tag>
      ),
    },
    {
      title: 'Создано',
      key: 'created',
      render: (record) => (<Text>{formatDate(record.created)}</Text>),
      width: '100px'
    },
    {
      title: 'Обновлено',
      key: 'updated',
      render: (record) => (<Text>{formatDate(record.updated)}</Text>),
      width: '100px'
    },
    {
      title: 'Город',
      dataIndex: 'cityName',
      key: 'cityName',
    },
    {
      title: 'Версия Приложения',
      dataIndex: 'appVersion',
      key: 'appVersion',
    },
    {
      title: 'Модель',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'Бренд',
      dataIndex: 'brand',
      key: 'brand',
    },
    {
      title: 'Версия ОС',
      dataIndex: 'osVersion',
      key: 'osVersion',
    },
    {
      title: 'ОС',
      dataIndex: 'os',
      key: 'os',
    },
    // {
    //   title: 'Действия',
    //   key: 'actions',
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <Button type="primary" icon={<CheckOutlined />} />
    //       <Button type="default" icon={<EditOutlined />} />
    //       <Button type="danger" icon={<DeleteOutlined />} />
    //     </Space>
    //   ),
    // },
  ];

  return (
    <PrintTable>
      <Table
        dataSource={dataSource}
        columns={columns}
        size="small"
        pagination={false}
        rowKey="userId"
      />
    </PrintTable>
  );
};

export default DevicesTableComponent;