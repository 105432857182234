import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestAccessRolesGet,

  //Entity
  sendRequestEntityGet,
  sendRequestEntityCreate,
  sendRequestEntityUpdate,
  sendRequestEntityDelete,

  //Entity Keys
  sendRequestEntityKeysGet,
  sendRequestEntityKeysCreate,
  sendRequestEntityKeysUpdate,
  sendRequestEntityKeysDelete,

  //Entity Key Roles
  sendRequestEntityKeyRolesGet,
  sendRequestEntityKeyRolesCreate,
  sendRequestEntityKeyRolesUpdate,
  sendRequestEntityKeyRolesDelete,
} from '../../../../redux/reducers/utils/servicesReducer';
import { Flex, Typography, Input } from 'antd';
import { Container, Page, ScrolledCol } from '../../../../core/styles';
import EntityCreateModal from './components/EntityCreateModal';
import EntityTable from './components/EntityTable';
import EntityKeysCreateModal from './components/EntityKeysCreateModal';
import EntityKeysTable from './components/EntityKeysTable';
import EntityKeyRolesCreateModal from './components/EntityKeyRolesCreateModal';
import EntityKeyRolesTable from './components/EntityKeyRolesTable';

const { Title, Text } = Typography;
const { Search } = Input;

const EntitySettingsPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const {
    accessRoles,

    //Entity
    entityCreateRequestStatus,
    entityUpdateRequestStatus,
    entityDeleteRequestStatus,
    entity,

    //Entity Keys
    entityKeysCreateRequestStatus,
    entityKeysUpdateRequestStatus,
    entityKeysDeleteRequestStatus,
    entityKeys,

    //Entity Key Roles
    entityKeyRolesCreateRequestStatus,
    entityKeyRolesUpdateRequestStatus,
    entityKeyRolesDeleteRequestStatus,
    entityKeyRoles,
  } = useSelector((store) => store.services);
  const dispatch = useDispatch();

  //Entity
  const [searchEntityTerm, setSearchEntityTerm] = useState('');
  const entityWithKey = entity.map(item => ({...item, key: item.id}))
  const filteredEntity = entityWithKey.filter((item) =>
    item.name.toLowerCase().includes(searchEntityTerm.toLowerCase()) ||
    item.description.toLowerCase().includes(searchEntityTerm.toLowerCase())
  );
  const [currentEntity, setCurrentEntity] = useState(null);

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestEntityGet({ token: localStorage.getItem('token') }));
      dispatch(sendRequestAccessRolesGet({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  useEffect(() => {
    if (
      entityCreateRequestStatus === 'resolved' ||
      entityUpdateRequestStatus === 'resolved' ||
      entityDeleteRequestStatus === 'resolved'
    ) {
      dispatch(sendRequestEntityGet({ token: localStorage.getItem('token') }));
    }
  }, [
    entityCreateRequestStatus,
    entityUpdateRequestStatus,
    entityDeleteRequestStatus,
  ]);

  const handleSearchEntity = (value) => {
    setSearchEntityTerm(value);
  };

  const handleCreateEntity = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      name: values.name,
      description: values.description,
    }

    dispatch(sendRequestEntityCreate(requestData))
  };

  const handleUpdateEntity = (values, id) => {
    const requestData = {
      token: localStorage.getItem('token'),
      entityId: id,
      name: values.name,
      description: values.description,
    }

    dispatch(sendRequestEntityUpdate(requestData))
  };

  const handleDeleteEntity = (id) => {
    const requestData = {
      token: localStorage.getItem('token'),
      entityId: id,
    }

    dispatch(sendRequestEntityDelete(requestData))
  };

  const handleEntityClick = (record) => {
    const requestData = {
      token: localStorage.getItem('token'),
      entityId: record.id,
    }

    setCurrentEntity(record)
    setCurrentEntityKey(null)
    setCurrentEntityKeyRole(null)
    dispatch(sendRequestEntityKeysGet(requestData))
  }

  //Entity Keys
  const [searchEntityKeysTerm, setSearchEntityKeysTerm] = useState('');

  const elementOptions = [
    { value: 'input', label: 'Тестовое поле ввода' },
    { value: 'inputNumber', label: 'Числовое поле ввода' },
    // { value: 'select', label: 'выпадающий список' },
    { value: 'arrayPhone', label: 'Массив телефонов' },
    { value: 'arrayEmail', label: 'Массив электронных адресов' },
    { value: 'switch', label: 'Переключатель' },
  ];

  const typeOptions = [
    { value: 'boolean', label: 'boolean' },
    { value: 'number', label: 'number' },
    { value: 'string', label: 'string' },
    { value: 'array', label: 'array' },
  ];

  const entityKeysWithKey = entityKeys.map(item => ({...item, key: item.id}))
  const filteredEntityKeys = entityKeysWithKey.filter((item) =>
    item.name.toLowerCase().includes(searchEntityKeysTerm.toLowerCase()) ||
    item.type.toLowerCase().includes(searchEntityKeysTerm.toLowerCase()) ||
    item.description.toLowerCase().includes(searchEntityKeysTerm.toLowerCase())
  );
  const [currentEntityKey, setCurrentEntityKey] = useState(null);

  useEffect(() => {
    if (
      currentEntity &&
      entityKeysCreateRequestStatus === 'resolved' ||
      entityKeysUpdateRequestStatus === 'resolved' ||
      entityKeysDeleteRequestStatus === 'resolved'
    ) {
      dispatch(sendRequestEntityKeysGet({ token: localStorage.getItem('token'), entityId: currentEntity?.id, }));
    }
  }, [
    entityKeysCreateRequestStatus,
    entityKeysUpdateRequestStatus,
    entityKeysDeleteRequestStatus,
  ]);

  const handleSearchEntityKeys = (value) => {
    setSearchEntityKeysTerm(value);
  };

  const handleCreateEntityKeys = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      entityId: currentEntity.id,
      sort: values.sort || 0,
      element: values.element,
      name: values.name,
      description: values.description,
      type: values.type,
      defaultValue: values.defaultValue || '',
    }

    dispatch(sendRequestEntityKeysCreate(requestData))
  };

  const handleUpdateEntityKeys = (values, id) => {
    const requestData = {
      token: localStorage.getItem('token'),
      entitySettingKeyId: id,
      entityId: currentEntity.id,
      sort: values.sort || 0,
      element: values.element,
      name: values.name,
      description: values.description,
      type: values.type,
      defaultValue: values.defaultValue || '',
    }

    dispatch(sendRequestEntityKeysUpdate(requestData))
  };

  const handleDeleteEntityKeys = (id) => {
    const requestData = {
      token: localStorage.getItem('token'),
      entitySettingKeyId: id,
    }

    dispatch(sendRequestEntityKeysDelete(requestData))
  };

  const handleEntityKeysClick = (record) => {
    const requestData = {
      token: localStorage.getItem('token'),
      entitySettingKeyId: record.id,
    }

    setCurrentEntityKey(record)
    setCurrentEntityKeyRole(null)
    dispatch(sendRequestEntityKeyRolesGet(requestData))
  }

  //Entity Key Roles
  const [searchEntityKeyRolesTerm, setSearchEntityKeyRolesTerm] = useState('');

  const roleOptions = accessRoles?.map((role) => ({
    value: role.id,
    label: role.description,
  }));

  const actionOptions = [
    {
      value: 'read',
      label: 'Читать',
    },
    {
      value: 'readwrite',
      label: 'Читать и править',
    },
  ];

  const entityKeyRolesWithKey = entityKeyRoles.map(item => ({
    ...item,
    key: item.id,
    roleName: accessRoles.find(role => role.id === item.roleId)?.description,
    actionName: actionOptions.find(action => action.value === item.action)?.label
  }))
  const filteredEntityKeyRoles = entityKeyRolesWithKey.filter((item) =>
    item.roleName.toLowerCase().includes(searchEntityKeyRolesTerm.toLowerCase()) ||
    item.actionName.toLowerCase().includes(searchEntityKeyRolesTerm.toLowerCase())
  );
  const [currentEntityKeyRole, setCurrentEntityKeyRole] = useState(null);

  useEffect(() => {
    if (
      currentEntityKey && (
      entityKeyRolesCreateRequestStatus === 'resolved' ||
      entityKeyRolesUpdateRequestStatus === 'resolved' ||
      entityKeyRolesDeleteRequestStatus === 'resolved'
    )) {
      dispatch(sendRequestEntityKeyRolesGet({ token: localStorage.getItem('token'), entitySettingKeyId: currentEntityKey.id, }));
    }
  }, [
    entityKeyRolesCreateRequestStatus,
    entityKeyRolesUpdateRequestStatus,
    entityKeyRolesDeleteRequestStatus,
  ]);

  const handleSearchEntityKeyRoles = (value) => {
    setSearchEntityKeyRolesTerm(value);
  };

  const handleCreateEntityKeyRoles = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      entitySettingKeyId: currentEntityKey.id,
      roleId: values.roleId,
      action: values.action,
    }

    dispatch(sendRequestEntityKeyRolesCreate(requestData))
  };

  const handleUpdateEntityKeyRoles = (values, id) => {
    const requestData = {
      token: localStorage.getItem('token'),
      entitySettingKeyRoleId: id,
      entitySettingKeyId: currentEntityKey.id,
      roleId: values.roleId,
      action: values.action,
    }

    dispatch(sendRequestEntityKeyRolesUpdate(requestData))
  };

  const handleDeleteEntityKeyRoles = (id) => {
    const requestData = {
      token: localStorage.getItem('token'),
      entitySettingKeyRoleId: id,
    }

    dispatch(sendRequestEntityKeyRolesDelete(requestData))
  };

  const handleEntityKeyRolesClick = (record) => {
    const requestData = {
      token: localStorage.getItem('token'),
      entitySettingKeysIds: [record.id],
    }

    console.log(record)
    setCurrentEntityKeyRole(record)
  }

  return(
    <Page>
      <ScrolledCol>
        <Container>
          <Flex vertical style={{padding: '10px 15px', background: '#eeeeee'}}>
            <Title>Сущности</Title>
            <Flex style={{marginBottom: '20px'}} gap='large' justify='space-between' wrap='wrap'>
              <Search
                style={{ maxWidth: '500px' }}
                placeholder="Поиск по названию или описанию"
                onChange={(e) => handleSearchEntity(e.target.value)}
              />
              <EntityCreateModal
                onCreate={handleCreateEntity}
              />
            </Flex>
            <EntityTable
              entityData={filteredEntity}
              currentRecord={currentEntity}
              onCellClick={handleEntityClick}
              onUpdate={handleUpdateEntity}
              onDelete={handleDeleteEntity}
            />
          </Flex>

          {currentEntity && <Flex vertical style={{padding: '10px 15px', background: '#dddddd'}}>
            <Title>Ключи для сущности {currentEntity.name}</Title>
            <Flex style={{marginBottom: '20px'}} gap='large' justify='space-between' wrap='wrap'>
              <Search
                style={{ maxWidth: '500px' }}
                placeholder="Поиск по названию или описанию"
                onChange={(e) => handleSearchEntityKeys(e.target.value)}
              />
              <EntityKeysCreateModal
                elements={elementOptions}
                types={typeOptions}
                onCreate={handleCreateEntityKeys}
              />
            </Flex>
            <EntityKeysTable
              entityData={filteredEntityKeys}
              currentRecord={currentEntityKey}
              elements={elementOptions}
              types={typeOptions}
              onCellClick={handleEntityKeysClick}
              onUpdate={handleUpdateEntityKeys}
              onDelete={handleDeleteEntityKeys}
            />
          </Flex>}

          {currentEntityKey && <Flex vertical style={{padding: '10px 15px', background: '#cccccc'}}>
            <Title>Роли для ключа {currentEntityKey.name}</Title>
            <Flex style={{marginBottom: '20px'}} gap='large' justify='space-between' wrap='wrap'>
              <Search
                style={{ maxWidth: '500px' }}
                placeholder="Поиск по роли и действию"
                onChange={(e) => handleSearchEntityKeyRoles(e.target.value)}
              />
              <EntityKeyRolesCreateModal
                roles={roleOptions}
                actions={actionOptions}
                onCreate={handleCreateEntityKeyRoles}
              />
            </Flex>
            <EntityKeyRolesTable
              entityData={filteredEntityKeyRoles}
              roles={roleOptions}
              actions={actionOptions}
              onCellClick={handleEntityKeyRolesClick}
              onUpdate={handleUpdateEntityKeyRoles}
              onDelete={handleDeleteEntityKeyRoles}
            />
          </Flex>}
          
        </Container>
      </ScrolledCol>
    </Page>
  )
}

export default EntitySettingsPage;