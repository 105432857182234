import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestAccessComponentsGet,
  sendRequestAccessComponentsCreate,
  clearAccessComponentsCreateStatus,
  sendRequestAccessComponentsUpdate,
  clearAccessComponentsUpdateStatus,
  sendRequestAccessComponentsDelete,
  clearAccessComponentsDeleteStatus,
} from '../../../../redux/reducers/utils/servicesReducer';
import { Row, Col, Typography } from 'antd'
import Loader from '../../../../components/Loader';
import ModalCreateAccessComponent from './components/ModalCreateAccessComponent';
import AccessListComponent from './components/AccessListComponent';
import ScrolledContainer from '../../../../components/ScrolledContainer';

const { Title } = Typography;

const ComponentsDirectoryPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const {
    accessComponents,
    accessComponentsGetRequestStatus,
    accessComponentsCreateRequestStatus,
    accessComponentsUpdateRequestStatus,
    accessComponentsDeleteRequestStatus,
  } = useSelector((store) => store.services);
  const dispatch = useDispatch();

  const onCreateComponent = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      components: [
        {
          key: values.key,
          name: values.name,
          description: values.description,
        },
      ],
    };

    dispatch(sendRequestAccessComponentsCreate(requestData));
  };

  useEffect(() => {
    if (accessComponentsCreateRequestStatus === 'resolved') {
      const requestData = {
        token: localStorage.getItem('token')
      }
      
      dispatch(sendRequestAccessComponentsGet(requestData));
      dispatch(clearAccessComponentsCreateStatus());
    }
  }, [accessComponentsCreateRequestStatus])

  const onUpdateComponent = (id, key, name, description) => {
    const requestData = {
      token: localStorage.getItem('token'),
      components: [
        {
          componentId: id,
          key: key,
          name: name,
          description: description,
        },
      ],
    };

    dispatch(sendRequestAccessComponentsUpdate(requestData));
  };

  useEffect(() => {
    if (accessComponentsUpdateRequestStatus === 'resolved') {
      const requestData = {
        token: localStorage.getItem('token')
      }
      
      dispatch(sendRequestAccessComponentsGet(requestData));
      dispatch(clearAccessComponentsUpdateStatus());
    }
  }, [accessComponentsUpdateRequestStatus])

  const onDeleteComponent = (id) => {
    const requestData = {
      token: localStorage.getItem('token'),
      components: [
        {
          componentId: id,
        },
      ],
    };
    
    dispatch(sendRequestAccessComponentsDelete(requestData));
  };

  useEffect(() => {
    if (accessComponentsDeleteRequestStatus === 'resolved') {
      const requestData = {
        token: localStorage.getItem('token')
      }
      
      dispatch(sendRequestAccessComponentsGet(requestData));
      dispatch(clearAccessComponentsDeleteStatus());
    }
  }, [accessComponentsDeleteRequestStatus]);

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestAccessComponentsGet({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  return (
    <ScrolledContainer>
      {(
        accessComponentsGetRequestStatus === 'pending' ||
        accessComponentsCreateRequestStatus === 'pending' ||
        accessComponentsUpdateRequestStatus === 'pending' ||
        accessComponentsDeleteRequestStatus === 'pending'
      ) && <Loader />}
      <Title>Справочник компонентов админ-панели</Title>
      <ModalCreateAccessComponent onCreateComponent={onCreateComponent}/>
      <AccessListComponent
        accessComponents={accessComponents}
        onUpdateComponent={onUpdateComponent}
        onDeleteComponent={onDeleteComponent}
      />
    </ScrolledContainer>
  );
};

export default ComponentsDirectoryPage;
