import React from "react";
import ReactDOM from "react-dom/client";
import {Provider} from 'react-redux';
import { store, persistor } from './redux/configureStore';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from "antd"
import { BrowserRouter } from "react-router-dom";
import locale from 'antd/locale/ru_RU';
import dayjs from 'dayjs';
import App from './core/App'
import { antdToken } from "./core/styles";
import 'dayjs/locale/ru';

dayjs.locale('ru');

console.info('CLIENT PROJECTS VERSION: ', process.env.REACT_APP_CLIENT_PROJECTS_VERSION);

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider
        locale={locale}
        theme={{
          token: antdToken,
          cssVar: true,
        }}
      >
        <BrowserRouter>
          <App/>
        </BrowserRouter>
      </ConfigProvider>
    </PersistGate>
  </Provider>
);
