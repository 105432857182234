import { takeLatest } from 'redux-saga/effects';
import {
  //Access Roles
  handleGetAccessRoles,

  //Access Get Components For User
  handleGetComponentsForUser,

  //Access Components
  handleGetAccessComponents,
  handleCreateAccessComponents,
  handleUpdateAccessComponents,
  handleDeleteAccessComponents,

  //Access Role Components
  handleGetAccessRoleComponents,
  handleCreateAccessRoleComponents,
  handleUpdateAccessRoleComponents,
  handleDeleteAccessRoleComponents,

  //Access Public Methods
  handleGetAccessPublicMethods,
  handleCreateAccessPublicMethods,
  handleUpdateAccessPublicMethods,
  handleDeleteAccessPublicMethods,

  //Access Role Public Methods
  handleGetAccessRolePublicMethods,
  handleCreateAccessRolePublicMethods,
  handleUpdateAccessRolePublicMethods,
  handleDeleteAccessRolePublicMethods,

  //Access Role Users
  handleGetAccessRoleUsers,
  handleCreateAccessRoleUsers,
  handleUpdateAccessRoleUsers,
  handleDeleteAccessRoleUsers,

  //Entity
  handleGetEntity,
  handleCreateEntity,
  handleUpdateEntity,
  handleDeleteEntity,

  //Entity Keys
  handleGetEntityKeys,
  handleCreateEntityKeys,
  handleUpdateEntityKeys,
  handleDeleteEntityKeys,

  //Entity Key Roles
  handleGetEntityKeyRoles,
  handleCreateEntityKeyRoles,
  handleUpdateEntityKeyRoles,
  handleDeleteEntityKeyRoles,
} from './servicesHandler';
import {
  //Access Roles
  sendRequestAccessRolesGet,

  //Access Get Components For User
  sendRequestAccessGetComponentsForUser,

  //Access Components
  sendRequestAccessComponentsGet,
  sendRequestAccessComponentsCreate,
  sendRequestAccessComponentsUpdate,
  sendRequestAccessComponentsDelete,

  //Access Role Components
  sendRequestAccessRoleComponentsGet,
  sendRequestAccessRoleComponentsCreate,
  sendRequestAccessRoleComponentsUpdate,
  sendRequestAccessRoleComponentsDelete,

  //Access Public Methods
  sendRequestAccessPublicMethodsGet,
  sendRequestAccessPublicMethodsCreate,
  sendRequestAccessPublicMethodsUpdate,
  sendRequestAccessPublicMethodsDelete,

  //Access Role Public Methods
  sendRequestAccessRolePublicMethodsGet,
  sendRequestAccessRolePublicMethodsCreate,
  sendRequestAccessRolePublicMethodsUpdate,
  sendRequestAccessRolePublicMethodsDelete,

  //Access Role Users
  sendRequestAccessRoleUsersGet,
  sendRequestAccessRoleUsersCreate,
  sendRequestAccessRoleUsersUpdate,
  sendRequestAccessRoleUsersDelete,

  //Entity
  sendRequestEntityGet,
  sendRequestEntityCreate,
  sendRequestEntityUpdate,
  sendRequestEntityDelete,

  //Entity Keys
  sendRequestEntityKeysGet,
  sendRequestEntityKeysCreate,
  sendRequestEntityKeysUpdate,
  sendRequestEntityKeysDelete,

  //Entity Key Roles
  sendRequestEntityKeyRolesGet,
  sendRequestEntityKeyRolesCreate,
  sendRequestEntityKeyRolesUpdate,
  sendRequestEntityKeyRolesDelete,
} from '../../../reducers/utils/servicesReducer';

export function* servicesWatcherSaga() {
  //Access Roles
  yield takeLatest(sendRequestAccessRolesGet.type, handleGetAccessRoles);

  //Access Get Components For User
  yield takeLatest(sendRequestAccessGetComponentsForUser.type, handleGetComponentsForUser);

  //Access Components
  yield takeLatest(sendRequestAccessComponentsGet.type, handleGetAccessComponents);
  yield takeLatest(sendRequestAccessComponentsCreate.type, handleCreateAccessComponents);
  yield takeLatest(sendRequestAccessComponentsUpdate.type, handleUpdateAccessComponents);
  yield takeLatest(sendRequestAccessComponentsDelete.type, handleDeleteAccessComponents);

  //Access Role Components
  yield takeLatest(sendRequestAccessRoleComponentsGet.type, handleGetAccessRoleComponents);
  yield takeLatest(sendRequestAccessRoleComponentsCreate.type, handleCreateAccessRoleComponents);
  yield takeLatest(sendRequestAccessRoleComponentsUpdate.type, handleUpdateAccessRoleComponents);
  yield takeLatest(sendRequestAccessRoleComponentsDelete.type, handleDeleteAccessRoleComponents);

  //Access Public Methods
  yield takeLatest(sendRequestAccessPublicMethodsGet.type, handleGetAccessPublicMethods);
  yield takeLatest(sendRequestAccessPublicMethodsCreate.type, handleCreateAccessPublicMethods);
  yield takeLatest(sendRequestAccessPublicMethodsUpdate.type, handleUpdateAccessPublicMethods);
  yield takeLatest(sendRequestAccessPublicMethodsDelete.type, handleDeleteAccessPublicMethods);

  //Access Role Public Methods
  yield takeLatest(sendRequestAccessRolePublicMethodsGet.type, handleGetAccessRolePublicMethods);
  yield takeLatest(sendRequestAccessRolePublicMethodsCreate.type, handleCreateAccessRolePublicMethods);
  yield takeLatest(sendRequestAccessRolePublicMethodsUpdate.type, handleUpdateAccessRolePublicMethods);
  yield takeLatest(sendRequestAccessRolePublicMethodsDelete.type, handleDeleteAccessRolePublicMethods);

  //Access Role Users
  yield takeLatest(sendRequestAccessRoleUsersGet.type, handleGetAccessRoleUsers);
  yield takeLatest(sendRequestAccessRoleUsersCreate.type, handleCreateAccessRoleUsers);
  yield takeLatest(sendRequestAccessRoleUsersUpdate.type, handleUpdateAccessRoleUsers);
  yield takeLatest(sendRequestAccessRoleUsersDelete.type, handleDeleteAccessRoleUsers);

  //Entity
  yield takeLatest(sendRequestEntityGet.type, handleGetEntity);
  yield takeLatest(sendRequestEntityCreate.type, handleCreateEntity);
  yield takeLatest(sendRequestEntityUpdate.type, handleUpdateEntity);
  yield takeLatest(sendRequestEntityDelete.type, handleDeleteEntity);

  //Entity Keys
  yield takeLatest(sendRequestEntityKeysGet.type, handleGetEntityKeys);
  yield takeLatest(sendRequestEntityKeysCreate.type, handleCreateEntityKeys);
  yield takeLatest(sendRequestEntityKeysUpdate.type, handleUpdateEntityKeys);
  yield takeLatest(sendRequestEntityKeysDelete.type, handleDeleteEntityKeys);

  //Entity Key Roles
  yield takeLatest(sendRequestEntityKeyRolesGet.type, handleGetEntityKeyRoles);
  yield takeLatest(sendRequestEntityKeyRolesCreate.type, handleCreateEntityKeyRoles);
  yield takeLatest(sendRequestEntityKeyRolesUpdate.type, handleUpdateEntityKeyRoles);
  yield takeLatest(sendRequestEntityKeyRolesDelete.type, handleDeleteEntityKeyRoles);
}
