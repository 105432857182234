import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendCodeToPhone, sendCodeToPhoneStatusClear, createTokenBySession } from '../../../redux/reducers/utils/authReducer';
import { Typography, Input, Button, Flex, Form } from 'antd';
import styled from 'styled-components';
import { ReactComponent as IconLogo } from '../../../assets/icons/icon-logo.svg';
import { ReactComponent as IconPhone } from '../../../assets/icons/icon-phone.svg';
import { ReactComponent as IconSocialWhatsup } from '../../../assets/icons/social/icon-social-whatsup.svg';
import { ReactComponent as IconSocialTelegram } from '../../../assets/icons/social/icon-social-telegram.svg';
import { ReactComponent as IconSocialViber } from '../../../assets/icons/social/icon-social-viber.svg';
import { ReactComponent as IconSocialVk } from '../../../assets/icons/social/icon-social-vk.svg';

const { Title, Text, Link } = Typography;

const LoginForm = () => {
  const { sendCodeStatus } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const phonePattern = /^\+7[0-9]{10}$/;
  const [isPhoneEntered, setIsPhoneEntered] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [lastCodeRequestTime, setLastCodeRequestTime] = useState(null);
  const [remainingCooldown, setRemainingCooldown] = useState(0);
  const [isShowCode, setIsShowCode] = useState(false);
  const [code, setCode] = useState(['', '', '', '']);
  const inputs = useRef([]);

  const getMachineId = () => {
    let machineId = localStorage.getItem('MachineId');
  
    if (!machineId) {
      machineId = crypto.randomUUID();
      localStorage.setItem('MachineId', machineId);
    }
  
    return machineId;
  };

  const handlePhoneChange = (e) => {
    setIsPhoneEntered(e.target.value.trim().length > 11);
  };

  const onFinishPhone = (values) => {
    const requestData = {
      phone: values.phone,
      device: getMachineId(),
    }

    dispatch(sendCodeToPhone(requestData));
    setPhoneNumber(values.phone);
    setLastCodeRequestTime(Date.now());
  };

  useEffect(() => {
    if (sendCodeStatus === 'resolved') {
      setIsShowCode(true);
      dispatch(sendCodeToPhoneStatusClear());
    }
  }, [sendCodeStatus])

  const handleResendPhone = () => {
    const requestData = {
      phone: phoneNumber,
      device: getMachineId(),
    }

    dispatch(sendCodeToPhone(requestData));
    setLastCodeRequestTime(Date.now());
  };

  const sendCode = (code) => {
    const requestData = {
      session: localStorage.getItem('session') || '',
      code: code,
    }

    dispatch(createTokenBySession(requestData));
  };

  const isCooldownExpired = () => {
    if (!lastCodeRequestTime) return true;
    const currentTime = Date.now();
    return currentTime - lastCodeRequestTime > 60000;
  };

  useEffect(() => {
    const updateRemainingCooldown = () => {
      if (!lastCodeRequestTime || isCooldownExpired()) {
        setRemainingCooldown(0);
      } else {
        const currentTime = Date.now();
        const cooldownDuration = 60000;
        const timeSinceLastRequest = currentTime - lastCodeRequestTime;
        const remainingTime = cooldownDuration - timeSinceLastRequest;
        setRemainingCooldown(Math.ceil(remainingTime / 1000));
      }
    };

    const intervalId = setInterval(updateRemainingCooldown, 1000);

    return () => clearInterval(intervalId);
  }, [lastCodeRequestTime]);

  const handleChange = (index, value) => {
    const newCode = [...code];
    newCode[index] = value;
  
    if (value !== '' && index < code.length - 1) {
      inputs.current[index + 1].focus();
    }
  
    setCode(newCode);
  
    if (index === code.length - 1 && value !== '') {
      sendCode(code.join('') + value);
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && code[index] === '' & index > 0) {
      e.preventDefault();
      inputs.current[index - 1].focus();
    }
  };

  const handleBackToPhone = () => {
    setIsShowCode(false);
    setCode(['', '', '', '']);
    form.resetFields();
  };

  return (
    <Wrapper>
      <AuthForm>
        <FormHeader>
          <IconLogo/>
          <Text style={{fontSize: '18px'}}>еда на каждый день</Text>
        </FormHeader>
        <FormBody>
          <Title level={2}>Личный кабинет партнёра</Title>
          <Form
            form={form}
            layout='vertical'
            name="phone_auth_form"
            initialValues={{ remember: true }}
            onFinish={isShowCode ? 'onFinishCode' : onFinishPhone}
          >
            {!isShowCode && (
              <>
                <Form.Item
                  name="phone"
                  label="Номер телефона"
                  rules={[
                    { 
                      required: true, 
                      message: 'Пожалуйста, введите номер телефона' 
                    },
                    {
                      pattern: phonePattern,
                      message: 'Некорректный номер телефона'
                    }
                  ]}
                >
                  <Input 
                    style={{padding: '15px 25px', borderRadius: '16px', fontSize: '18px'}}
                    placeholder="+7 --- --- -- --"
                    maxLength={12}
                    onChange={handlePhoneChange}
                  />
                </Form.Item>
                <Button
                  style={{marginBottom: '20px', padding: '15px 25px', width: '100%', height: '60px', borderRadius: '16px', fontSize: '18px'}}
                  type="primary"
                  htmlType="submit"
                  disabled={!isPhoneEntered}
                >
                  Выслать код
                </Button>
              </>
            )}
            {isShowCode && (
              <>
                <Form.Item
                  name="code"
                  label={`Код отправили сообщением на номер ${phoneNumber}`}
                >
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    {code.map((value, index) => (
                      <Input
                        key={index}
                        ref={(input) => (inputs.current[index] = input)}
                        value={value}
                        maxLength={1}
                        onChange={(e) => handleChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        style={{padding: '10px 15px', borderRadius: '16px', fontSize: '24px', fontWeight: 'bold', width: '50px', marginRight: '10px' }}
                      />
                    ))}
                  </div>
                </Form.Item>
                <Button
                  style={{marginBottom: '20px', padding: '15px 25px', width: '100%', height: '60px', borderRadius: '16px', fontSize: '14px'}}
                  type="primary"
                  disabled={!isCooldownExpired()}
                  onClick={handleResendPhone}
                >
                  {isCooldownExpired() ? 'Получить новый код' : `Получить новый код через ${remainingCooldown} секунд`}
                </Button>
                <Button
                  style={{marginBottom: '20px', padding: '15px 25px', width: '100%', height: '60px', borderRadius: '16px', fontSize: '14px'}}
                  type="default"
                  onClick={handleBackToPhone}
                >
                  Назад к вводу телефона
                </Button>
              </>
            )}
          </Form>
          <div><Text>Продолжая, вы принимаете условия</Text></div>
          <div><Link style={{color: '#6F9462'}}>пользовательского соглашения</Link></div>
        </FormBody>
        <FormFooter>
          <Title level={5}>Техническая поддержка</Title>
          <StyledLink href={'tel:+7-800-200-69-24'}><IconPhone/><Text style={{fontSize: '14px'}}>+7-800-200-69-24</Text></StyledLink>
          <Flex gap='8px'>
            <Link href={'https://wa.me/79914590879'} target="_blank" rel="noopener nofollow"><IconSocialWhatsup/></Link>
            <Link href={'https://telegram.me/sitiy_support_bot'} target="_blank" rel="noopener nofollow"><IconSocialTelegram/></Link>
            <Link href={'viber://pa?chatURI=sitiy'} target="_blank" rel="noopener nofollow"><IconSocialViber/></Link>
            <Link href={'https://vk.com/sytofis'} target="_blank" rel="noopener nofollow"><IconSocialVk/></Link>
          </Flex>
        </FormFooter>
      </AuthForm>
    </Wrapper>
  );
};

export default LoginForm;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, .75);
`
const AuthForm = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  max-width: 924px;
  min-height: 400px;
  margin: 0 20px;
  padding: 30px 0;
  border-radius: 24px;
  background: #FFFFFF;
  @media (max-width: 998px) {
    grid-template-columns: 1fr;
  }
`
const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  padding: 10px 50px;
  width: 320px;
  & svg {
    width: 100%;
    fill: #EF0A36;
  }
  @media (max-width: 998px) {
    width: 240px;
    padding: 10px;
  }
`
const FormBody = styled.div`
  grid-row: 1 / 3;
  grid-column: 2 / 3;
  padding: 10px 50px;
  border-left: solid 1px black;
  @media (max-width: 998px) {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    padding: 10px;
    border-left: none;
  }
`
const FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 auto;
  padding: 10px 50px;
  width: 320px;
  @media (max-width: 998px) {
    width: 240px;
    padding: 10px;
  }
`
const StyledLink = styled(Link)`
  display: flex;
  gap: 5px;
  margin-bottom: 8px;
  padding: 12px 20px;
  width: 100%;
  background: #f1f1f1;
  border-radius: 16px;
  color: #2C2E32;
`