import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentRoute } from '../../redux/reducers/utils/navReducer';
import { sendRequestCitiesGet } from '../../redux/reducers/utils/settingsReducers/settingsCatalogsReducer';

const DeliveryModule = ({children}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuth } = useSelector((store) => store.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === '/delivery') {
      dispatch(setCurrentRoute('delivery/map'));
      navigate('/delivery/map');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestCitiesGet({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  return(
    <>
      {children}
    </>
  );
}

export default DeliveryModule;