import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestOrganizationsGet } from '../../../../redux/reducers/utils/settingsReducers/settingsCatalogsReducer';
import { sendRequestKitchenContractsGet } from '../../../../redux/reducers/utils/kitchenReducers/kitchenSettingsReducer';
import { Flex, Typography } from 'antd';
import Loader from '../../../../components/Loader';
import ContractsTableComponent from './components/ContractsTableComponent';
import KitchenSelectorComponent from '../../components/KitchenSelectorComponent';

const { Title } = Typography;

const ContractsPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const { selectKitchen } = useSelector((store) => store.kitchen);
  const {
    kitchenContractsGetRequestStatus,
    kitchenContractsCreateRequestStatus,
    kitchenContractsUpdateRequestStatus,
    kitchenContractsDeleteRequestStatus,
    kitchenContractCommissionsCreateRequestStatus,
    kitchenContractCommissionsDeleteRequestStatus,
  } = useSelector((store) => store.kitchenSettings);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestOrganizationsGet({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  useEffect(() => {
    if (selectKitchen) {
      const requestData = {
        token: localStorage.getItem('token'),
        kitchenId: selectKitchen.id,
      }

      dispatch(sendRequestKitchenContractsGet(requestData));
    }
  }, [selectKitchen]);

  useEffect(() => {
    if (
      kitchenContractsCreateRequestStatus === 'resolved' ||
      kitchenContractsUpdateRequestStatus === 'resolved' ||
      kitchenContractsDeleteRequestStatus === 'resolved' ||
      kitchenContractCommissionsCreateRequestStatus === 'resolved' ||
      kitchenContractCommissionsDeleteRequestStatus === 'resolved'
    ) {
      const requestData = {
        token: localStorage.getItem('token'),
        kitchenId: selectKitchen.id,
      }

      dispatch(sendRequestKitchenContractsGet(requestData));
    }
  }, [
    kitchenContractsCreateRequestStatus,
    kitchenContractsUpdateRequestStatus,
    kitchenContractsDeleteRequestStatus,
    kitchenContractCommissionsCreateRequestStatus,
    kitchenContractCommissionsDeleteRequestStatus,
  ]);

  return (
    <>
      {(
        kitchenContractsGetRequestStatus === 'pending' ||
        kitchenContractsCreateRequestStatus === 'pending' ||
        kitchenContractsUpdateRequestStatus === 'pending' ||
        kitchenContractsDeleteRequestStatus === 'pending'
      ) && <Loader />}
      <Flex gap={16} align='center'>
        <Title style={{margin: '0'}} level={2}>Договоры</Title>
        <KitchenSelectorComponent/>
      </Flex>
      <ContractsTableComponent/>
    </>
  );
};

export default ContractsPage;
