import apiInstance from '../../../../../api';
import {call, put, select} from 'redux-saga/effects';
import {
  sendRequestPromocodesSearchSuccess,
  sendRequestPromocodesSearchError,
  setPromocodes,
  sendRequestPromocodeCreateSuccess,
  sendRequestPromocodeCreateError,
  sendRequestPromocodeUpdateSuccess,
  sendRequestPromocodeUpdateError,
  sendRequestPromocodeDeleteSuccess,
  sendRequestPromocodeDeleteError,
} from '../../../../reducers/utils/financeReducers/financePromocodesReducer';
import { setMessagePopupContent, selectCommonState } from '../../../../reducers/utils/commonReducer';

export function* handleSearchPromocodes(action) {
  const api = '/promocodes/search?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestPromocodesSearchSuccess());
      yield put(setPromocodes(response.data.data));
    } else {
      yield put(sendRequestPromocodesSearchError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestPromocodesSearchError());
  }
}

export function* handleCreatePromocodes(action) {
  const api = '/promocodes/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestPromocodeCreateSuccess());
    } else {
      yield put(sendRequestPromocodeCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestPromocodeCreateError());
  }
}

export function* handleUpdatePromocodes(action) {
  const api = '/promocodes/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestPromocodeUpdateSuccess());
    } else {
      yield put(sendRequestPromocodeUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestPromocodeUpdateError());
  }
}

export function* handleDeletePromocodes(action) {
  const api = '/promocodes/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestPromocodeDeleteSuccess());
    } else {
      yield put(sendRequestPromocodeDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestPromocodeDeleteError());
  }
}
