import { takeLatest } from 'redux-saga/effects';
import {
  //Delivery Statuses Orders
  handleGetDeliveryStatusesOrders,

  //Delivery Service
  handleGetDeliveryServices,
  handleCreateDeliveryServices,
  handleUpdateDeliveryServices,
  handleDeleteDeliveryServices,

  //Delivery Service Races
  handleGetDeliveryServiceRaces,
  handleCreateDeliveryServiceRaces,
  handleUpdateDeliveryServiceRaces,
  handleDeleteDeliveryServiceRaces,

  //Delivery Services Couriers
  handleGetDeliveryServiceCouriers,
  handleCreateDeliveryServiceCourier,
  handleUpdateDeliveryServiceCourier,
  handleDeleteDeliveryServiceCourier,

  //Delivery Services Polygons
  handleGetDeliveryServicePolygons,
  handleCreateDeliveryServicePolygon,
  handleUpdateDeliveryServicePolygon,
  handleDeleteDeliveryServicePolygon,
  handleMassUpdateDeliveryServicePolygons,

  //Delivery Service Race Couriers
  handleGetDeliveryServiceRaceCouriers,
  handleEnableDeliveryServiceRaceCourier,
  handleDisableDeliveryServiceRaceCourier,

  //Delivery Service Race Polygons
  handleGetDeliveryServiceRacePolygons,
  handleEnableDeliveryServiceRacePolygon,
  handleDisableDeliveryServiceRacePolygon,

  //Delivery Service Race Kitchens
  handleGetDeliveryServiceRaceKitchens,
  handleAddDeliveryServiceRaceKitchen,
  handleUpdateDeliveryServiceRaceKitchen,
  handleCreateDeliveryServiceRaceKitchenTerms,
  handleUpdateDeliveryServiceRaceKitchenTerms,
  handleDeleteDeliveryServiceRaceKitchenTerms,

  //Delivery Service Not Working
  handleGetDeliveryServiceNotWorking,
  handleCreateDeliveryServiceNotWorking,
  handleDeleteDeliveryServiceNotWorking,
} from './deliveryServicesHandler';
import {
  //Delivery Statuses Orders
  sendRequestDeliveryStatusesOrdersGet,

  //Delivery Service
  sendRequestDeliveryServicesGet,
  sendRequestDeliveryServicesCreate,
  sendRequestDeliveryServicesUpdate,
  sendRequestDeliveryServicesDelete,

  //Delivery Service Races
  sendRequestDeliveryServiceRacesGet,
  sendRequestDeliveryServiceRacesCreate,
  sendRequestDeliveryServiceRacesUpdate,
  sendRequestDeliveryServiceRacesDelete,

  //Delivery Services Couriers
  sendRequestDeliveryServiceCouriersGet,
  sendRequestDeliveryServiceCouriersCreate,
  sendRequestDeliveryServiceCouriersUpdate,
  sendRequestDeliveryServiceCouriersDelete,

  //Delivery Services Polygons
  sendRequestDeliveryServicePolygonsGet,
  sendRequestDeliveryServicePolygonCreate,
  sendRequestDeliveryServicePolygonUpdate,
  sendRequestDeliveryServicePolygonDelete,
  sendRequestDeliveryServicePolygonsMassUpdate,

  //Delivery Service Race Couriers
  sendRequestDeliveryServiceRaceCouriersGet,
  sendRequestDeliveryServiceRaceCourierEnable,
  sendRequestDeliveryServiceRaceCourierDisable,

  //Delivery Service Race Polygons
  sendRequestDeliveryServiceRacePolygonsGet,
  sendRequestDeliveryServiceRacePolygonEnable,
  sendRequestDeliveryServiceRacePolygonDisable,

  //Delivery Service Race Kitchens
  sendRequestDeliveryServiceRaceKitchensGet,
  sendRequestDeliveryServiceRaceKitchenAdd,
  sendRequestDeliveryServiceRaceKitchenUpdate,
  sendRequestDeliveryServiceRaceKitchenTermsCreate,
  sendRequestDeliveryServiceRaceKitchenTermsUpdate,
  sendRequestDeliveryServiceRaceKitchenTermsDelete,

  //Delivery Service Not Working
  sendRequestDeliveryServiceNotWorkingGet,
  sendRequestDeliveryServiceNotWorkingCreate,
  sendRequestDeliveryServiceNotWorkingDelete,
} from '../../../../reducers/utils/deliveryReducers/deliveryServicesReducer';

export function* deliveryServicesWatcherSaga() {
  //Delivery Statuses Orders
  yield takeLatest(sendRequestDeliveryStatusesOrdersGet.type, handleGetDeliveryStatusesOrders);

  //Delivery Service
  yield takeLatest(sendRequestDeliveryServicesGet.type, handleGetDeliveryServices);
  yield takeLatest(sendRequestDeliveryServicesCreate.type, handleCreateDeliveryServices);
  yield takeLatest(sendRequestDeliveryServicesUpdate.type, handleUpdateDeliveryServices);
  yield takeLatest(sendRequestDeliveryServicesDelete.type, handleDeleteDeliveryServices);

  //Delivery Service Races
  yield takeLatest(sendRequestDeliveryServiceRacesGet.type, handleGetDeliveryServiceRaces);
  yield takeLatest(sendRequestDeliveryServiceRacesCreate.type, handleCreateDeliveryServiceRaces);
  yield takeLatest(sendRequestDeliveryServiceRacesUpdate.type, handleUpdateDeliveryServiceRaces);
  yield takeLatest(sendRequestDeliveryServiceRacesDelete.type, handleDeleteDeliveryServiceRaces);

  //Delivery Service Couriers
  yield takeLatest(sendRequestDeliveryServiceCouriersGet.type, handleGetDeliveryServiceCouriers);
  yield takeLatest(sendRequestDeliveryServiceCouriersCreate.type, handleCreateDeliveryServiceCourier);
  yield takeLatest(sendRequestDeliveryServiceCouriersUpdate.type, handleUpdateDeliveryServiceCourier);
  yield takeLatest(sendRequestDeliveryServiceCouriersDelete.type, handleDeleteDeliveryServiceCourier);

  //Delivery Service Polygons
  yield takeLatest(sendRequestDeliveryServicePolygonsGet.type, handleGetDeliveryServicePolygons);
  yield takeLatest(sendRequestDeliveryServicePolygonCreate.type, handleCreateDeliveryServicePolygon);
  yield takeLatest(sendRequestDeliveryServicePolygonUpdate.type, handleUpdateDeliveryServicePolygon);
  yield takeLatest(sendRequestDeliveryServicePolygonDelete.type, handleDeleteDeliveryServicePolygon);
  yield takeLatest(sendRequestDeliveryServicePolygonsMassUpdate.type, handleMassUpdateDeliveryServicePolygons);

  //Delivery Service Race Couriers
  yield takeLatest(sendRequestDeliveryServiceRaceCouriersGet.type, handleGetDeliveryServiceRaceCouriers);
  yield takeLatest(sendRequestDeliveryServiceRaceCourierEnable.type, handleEnableDeliveryServiceRaceCourier);
  yield takeLatest(sendRequestDeliveryServiceRaceCourierDisable.type, handleDisableDeliveryServiceRaceCourier);

  // //Delivery Service Race Polygons
  yield takeLatest(sendRequestDeliveryServiceRacePolygonsGet.type, handleGetDeliveryServiceRacePolygons);
  yield takeLatest(sendRequestDeliveryServiceRacePolygonEnable.type, handleEnableDeliveryServiceRacePolygon);
  yield takeLatest(sendRequestDeliveryServiceRacePolygonDisable.type, handleDisableDeliveryServiceRacePolygon);

  // //Delivery Service Race Kitchens
  yield takeLatest(sendRequestDeliveryServiceRaceKitchensGet.type, handleGetDeliveryServiceRaceKitchens);
  yield takeLatest(sendRequestDeliveryServiceRaceKitchenAdd.type, handleAddDeliveryServiceRaceKitchen);
  yield takeLatest(sendRequestDeliveryServiceRaceKitchenUpdate.type, handleUpdateDeliveryServiceRaceKitchen);
  yield takeLatest(sendRequestDeliveryServiceRaceKitchenTermsCreate.type, handleCreateDeliveryServiceRaceKitchenTerms);
  yield takeLatest(sendRequestDeliveryServiceRaceKitchenTermsUpdate.type, handleUpdateDeliveryServiceRaceKitchenTerms);
  yield takeLatest(sendRequestDeliveryServiceRaceKitchenTermsDelete.type, handleDeleteDeliveryServiceRaceKitchenTerms);

  //Delivery Service Not Working
  yield takeLatest(sendRequestDeliveryServiceNotWorkingGet.type, handleGetDeliveryServiceNotWorking);
  yield takeLatest(sendRequestDeliveryServiceNotWorkingCreate.type, handleCreateDeliveryServiceNotWorking);
  yield takeLatest(sendRequestDeliveryServiceNotWorkingDelete.type, handleDeleteDeliveryServiceNotWorking);
}