import React from 'react';
import { Table, Typography, Tag, Flex, Image } from 'antd';
import emptyImage from '../../../../../assets/img/empty-kitchen-image.png';
import PrintTable from '../../../../../components/PrintTable';
import { formatDate } from '../../../../../core/utils';

const { Title, Text } = Typography;

const PushNotificationTableComponent = ({ dataSource }) => {
  const columns = [
    {
      title: 'Устройство',
      dataIndex: 'device',
      key: 'device',
    },
    {
      title: 'FCM Token',
      dataIndex: 'fcmToken',
      key: 'fcmToken',
      render: text => <Text copyable>{text}</Text>,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: status => (
        <Tag color={status === 'ok' ? 'green' : 'red'}>
          {status}
        </Tag>
      ),
    },
    {
      title: 'Создано',
      key: 'created',
      render: (record) => (<Text>{formatDate(record.created)}</Text>),
      width: '100px'
    },
    {
      title: 'Обновлено',
      key: 'updated',
      render: (record) => (<Text>{formatDate(record.updated)}</Text>),
      width: '100px'
    },
    {
      title: 'Сообщение',
      key: 'notification',
      render: ((record) => (
        <Card
          title={record.title}
          description={record.body}
          image={record.filePathPublic}
        />
      )),
    },
    // {
    //   title: 'Действия',
    //   key: 'actions',
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <Button type="primary" icon={<CheckOutlined />} />
    //       <Button type="default" icon={<EditOutlined />} />
    //       <Button type="danger" icon={<DeleteOutlined />} />
    //     </Space>
    //   ),
    // },
  ];

  return (
    <PrintTable>
      <Table
        dataSource={dataSource}
        columns={columns}
        size="middle"
        pagination={false}
        rowKey="device"
      />
    </PrintTable>
  );
};

export default PushNotificationTableComponent;

const Card = ({ title, description, image }) => {
  return(
    <Flex gap={5}>
      <Flex vertical flex='1 1 100%' style={{minWidth: '200px'}}>
        <Title level={4}>{title}</Title>
        <Text>{description}</Text>
      </Flex>
      <Flex vertical flex='1 0 100px' style={{minWidth: '100px'}}>
        <Image
          style={{
            width: '100%',
            height: '100px',
            objectFit: 'cover',
            background: '#eeeeee',
            borderRadius: '10px',
            overflow: 'hidden',
          }} 
          src={image}
          fallback={emptyImage}
        />
      </Flex>
    </Flex>
  )
}