import React from 'react';
import { useSelector } from 'react-redux';
import { Select, Table, Button, Typography, Modal, Input, Flex } from 'antd';
import PrintPdfLink from '../../../../../components/PrintPdfLink'
import { checkAccess, formatDate, getCorrectWordForm } from '../../../../../core/utils';
import PrintTable from '../../../../../components/PrintTable';

const { Title, Text } = Typography;
const { Option } = Select;

const KitchenActWorksTableComponent = ({ acts, onSignedStaffSO, onSignedStaffKitchen }) => {
  const { accessComponentsForUser } = useSelector((store) => store.services);
  const columns = [
    {
      title: 'Номер и дата договора',
      dataIndex: 'contract',
      key: 'contract',
    },
    {
      title: 'Дата и номер акта',
      dataIndex: 'act',
      key: 'act',
    },
    {
      title: 'Диапазон дат расчёта',
      key: 'dateRange',
      render: (record) => (<Text>{formatDate(record.dateFrom)} - {formatDate(record.dateTo)}</Text>),
    },
    {
      title: 'Кол-во не закрытых претензий',
      dataIndex: 'complaints',
      key: 'complaints',
      render: (complaints) => (<Text>{complaints ? getCorrectWordForm(complaints, ['претензия', 'претензии', 'претензий']) : 'Нет претензий'}</Text>),
    },
    {
      title: 'Ссылка на PDF',
      dataIndex: 'link',
      key: 'link',
      render: (text) => <PrintPdfLink url={text}>Скачать PDF</PrintPdfLink>,
    },
    // {
    //   title: 'Подпись Сытого',
    //   key: 'sitiySign',
    //   render: (record) => (
    //     <>
    //       {record.statusPayment === 10 &&
    //         <Text>Ожидается подпись кухни</Text>
    //       }
    //       {record.statusPayment === 50 &&
    //         <Button
    //         type='primary'
    //         disabled={!checkAccess(accessComponentsForUser, 'moderation')}
    //         onClick={() => onSignedStaffSO(record.id, true)}
    //         >
    //           Подписать
    //         </Button>
    //       }
    //       {record.statusPayment === 20 &&
    //         <Flex align='center' gap={16}>
    //           <div>
    //             <Text style={{textWrap: 'nowrap'}}>Подписано: {record.signedStaffSOPhone}</Text><br/>
    //             <Text style={{fontSize: '12px'}} type='secondary'>{record.signedStaffSODate}</Text>
    //           </div>
    //           <Button
    //           type='default'
    //           disabled={!checkAccess(accessComponentsForUser, 'moderation')}
    //           onClick={() => onSignedStaffSO(record.id, false)}
    //           >
    //             Отписать
    //           </Button>
    //         </Flex>
    //       }
    //     </>
    //   ),
    // },
    {
      title: 'Подпись Кухни',
      key: 'kitchenSign',
      render: (record) => (
        <>
          {record.statusPayment === 10 &&
            <Button type='primary' onClick={() => onSignedStaffKitchen(record.id, true)}>Подписать</Button>
          }
          {record.statusPayment === 50 &&
            <Flex align='center' gap={16}>
              <div>
                <Text style={{textWrap: 'nowrap'}}>Подписано: {record.signedStaffKitchenPhone}</Text><br/>
                <Text style={{fontSize: '12px'}} type='secondary'>{record.signedStaffKitchenDate}</Text>
              </div>
              <Button type='default' onClick={() => onSignedStaffKitchen(record.id, false)}>Отписать</Button>
            </Flex>
          }
          {record.statusPayment === 20 &&
            <Flex align='center' gap={16}>
              <div>
                <Text style={{textWrap: 'nowrap'}}>Подписано: {record.signedStaffKitchenPhone}</Text><br/>
                <Text style={{fontSize: '12px'}} type='secondary'>{record.signedStaffKitchenDate}</Text>
              </div>
            </Flex>
          }
        </>
      ),
    },
    {
      title: 'Статус оплаты',
      dataIndex: 'statusName',
      key: 'statusName',
    },
  ];

  return (
    <PrintTable>
      <Table columns={columns} dataSource={acts}/>
    </PrintTable>
  );
};

export default KitchenActWorksTableComponent;
