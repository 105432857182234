import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestGetAllKitchenStatuses } from '../../../redux/reducers/utils/kitchenReducers/kitchenSettingsReducer';
import { sendRequestKitchens, setCurrentPage, setSelectKitchen } from '../../../redux/reducers/utils/kitchenReducers/kitchenReducer';
import { setSelectedTemplate } from '../../../redux/reducers/utils/kitchenReducers/kitchenMenuReducer';
import { setCurrentRoute } from '../../../redux/reducers/utils/navReducer';
import { Flex, Typography, Image, Pagination, Input, Select, Empty, Button } from 'antd';
import { StarFilled, CheckOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import emptyImage from '../../../assets/img/empty-kitchen-image.png';
import WelcomeComponent from './WelcomeComponent';
import { checkAccess } from '../../../core/utils';
import NewKitchenCreateModalComponent from './NewKitchenCreateModalComponent';
import InformNotes from '../../../components/InformNotes';

const { Title, Text } = Typography;

function KitchensComponent() {
  const navigate = useNavigate();
  const { isAuth } = useSelector((store) => store.auth);
  const { cities } = useSelector((store) => store.settingsCatalogs);
  const { accessComponentsForUser } = useSelector((store) => store.services);
  const { allKitchenStatuses } = useSelector((store) => store.kitchenSettings);
  const {
    kitchens,
    isKitchenOwner,
    totalKitchens,
    currentPage,
    userKitchenCreateRequestStatus,
   } = useSelector((store) => store.kitchen);
  const dispatch = useDispatch();
  const [searchName, setSearchName] = useState('');
  const inputRef = useRef(null);
  const [searchCities, setSearchCities] = useState([]);
  const [searchStatus, setSearchStatus] = useState(0);
  const [createModalVisible, setCreateModalVisible] = useState(false);

  const requestData = {
    token: localStorage.getItem('token'),
    offset: (currentPage - 1) * 20,
    limit: 20,
    name: searchName.length ? searchName : undefined,
    statusId: searchStatus != 0 ? searchStatus : undefined,
  }

  const citiesOptions = cities?.map((item) => ({
    ...item,
    value: item.id,
    label: item.name,
  }));

  const searchStatusOptions = [
    { value: 0, label: 'Выберите статус' },
    ...allKitchenStatuses.map((item) => ({
      ...item,
      value: item.id,
      label: item.name,
    })),
  ]

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestKitchens(requestData));
      dispatch(sendRequestGetAllKitchenStatuses({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  useEffect(() => {
    if (userKitchenCreateRequestStatus === 'resolved') {
      dispatch(sendRequestKitchens(requestData));
    }
  }, [userKitchenCreateRequestStatus]);

  const handleKitchenClick = (kitchen) => {
    console.log(kitchen.id);
    dispatch(setSelectKitchen(kitchen));
    dispatch(setSelectedTemplate(null));
    dispatch(setCurrentRoute('kitchens/statistics'));
    navigate('/kitchens/statistics');
  }

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
    dispatch(sendRequestKitchens({...requestData, offset: (page - 1) * 20 }));
  };

  const handleSearch = () => {
    dispatch(sendRequestKitchens({ ...requestData, name: searchName.length ? searchName : undefined }));
  };

  const handleCityChange = (value) => {
    setSearchCities(value);
    dispatch(sendRequestKitchens({ ...requestData, cities: value.length ? value : undefined }));
  };

  const handleStatusChange = (value) => {
    setSearchStatus(value);
    dispatch(sendRequestKitchens({ ...requestData, statusId: value != 0 ? value : undefined }));
  };

  return (
    <>
      {isKitchenOwner ? 
        <div style={{padding: '30px 0'}}>
          {checkAccess(accessComponentsForUser, 'moderation') &&
            <Flex
              style={{padding: '0 40px 20px'}}
              gap={16}
              wrap='wrap'
            >
              <Flex vertical flex='1 0 250px'>
                <Text>Поиск</Text>
                <Input
                  ref={inputRef}
                  placeholder='Поиск'
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                  onBlur={handleSearch}
                  onPressEnter={() => inputRef.current.blur()}
                />
              </Flex>
              <InformNotes id='main-page-search' locale='EN'/>
              <Flex vertical flex='1 0 250px'>
                <Text>Фильтр по городу</Text>
                <Select
                  mode="multiple"
                  showSearch
                  placeholder="Выберите город"
                  optionFilterProp="children"
                  options={citiesOptions}
                  defaultValue={searchCities}
                  filterOption={(input, option) =>
                    (option?.name?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  optionRender={(option) => (
                    <Flex vertical>
                      <Title level={5}>{option.data.name}</Title>
                    </Flex>
                  )}
                  onChange={handleCityChange}
                />
              </Flex>
              <InformNotes id='main-page-city-filter'/>
              <Flex vertical flex='1 0 250px'>
                <Text>Фильтр по статусу</Text>
                <Select
                  defaultValue={0}
                  options={searchStatusOptions}
                  onChange={handleStatusChange}
                />
              </Flex>
              <InformNotes id='main-page-status-filter'/>
            </Flex>
          }

          <Flex style={{padding: '0 40px'}} justify='space-between' align='center' gap={16}>
            <div>
              <Title style={{margin: 0}}>Мои кухни</Title>
              {totalKitchens > 1 && <Text type='secondary'>Найдено: {totalKitchens}</Text>}
            </div>
            <Button type='primary' shape='round' icon={<PlusOutlined/>} onClick={() => setCreateModalVisible(true)}>Cоздать новую кухню</Button>
          </Flex>

          {kitchens && kitchens.length > 0 ? 
            <KitchenCardWrapper>
              {kitchens.map(kitchen => (
                <KitchenCardComponent
                  key={kitchen.id}
                  kitchen={kitchen}
                  onClick={() => handleKitchenClick(kitchen)}
                />
              ))}
            </KitchenCardWrapper>
          :
            <Empty description="Нет совпадений"/>
          }
          
          {totalKitchens > 20 &&
            <Pagination
              current={currentPage}
              pageSize={20}
              total={totalKitchens}
              onChange={handlePageChange}
              showQuickJumper={true}
              showSizeChanger={false}
              style={{ textAlign: 'right', marginTop: '20px' }}
            />
          }

          <NewKitchenCreateModalComponent
            createModalVisible={createModalVisible}
            setCreateModalVisible={setCreateModalVisible}
          />
        </div>
      : 
        <WelcomeComponent/>
      }
    </>
  );
}

export default KitchensComponent;

const KitchenCardComponent = ({ kitchen, onClick }) => {
  const isWrite = false

  return (
    <KitchenCard>
      <Flex style={{marginBottom: '10px', height: '70px', overflow: 'hidden'}} justify='space-between' align='center'>
        <KitchenCardName onClick={onClick}>
        <Text type='secondary' style={{fontSize: '10px'}}>ID:{kitchen.id}</Text>
          {kitchen.name}
          <Text type='secondary'>{kitchen.cityName}</Text>
        </KitchenCardName>
        {kitchen.kitchenRating && <KitchenCardRating><StarFilled style={{color: '#ddce00'}}/> {kitchen.kitchenRating.toFixed(1)}</KitchenCardRating>}
      </Flex>
      <KitchenCardImage onClick={onClick}>
        <Image
          style={{filter: kitchen.status === 5 ? 'grayscale(100%)' : '', width: '100%', height: '100%', objectFit: 'cover'}}
          preview={false}
          src={kitchen.filePathPublic}
          fallback={emptyImage}
        />
        <KitchenCardStatus>Статус: {kitchen.statusDescription}</KitchenCardStatus>
      </KitchenCardImage>
      {kitchen.status === 1 &&
        <KitchenCardStatistic>
          <b>Сегодня заказов:</b> {kitchen.countOrders} ({kitchen.sumPrice} руб)<br/>
          <b>Завтра:</b> {kitchen.publicationMenuTomorrow ? <Link to='/kitchens/menupublication' onClick={onClick}>посмотреть меню</Link> : 'меню не опубликовано'}
        </KitchenCardStatistic>
      }
      {kitchen.status === 4 &&
        <Flex vertical style={{margin: '10px 0'}}>

          <Flex gap={10}>
            <CheckOutlined style={{fontSize: '18px', color: isWrite ? '#00ff00' : '#dddddd'}}/>
            <Text style={{textDecoration: isWrite ? 'line-through' : 'none'}}>
              { isWrite ? <>Заполнены реквизиты</> : <>Заполните реквизиты</>}
            </Text>
          </Flex>

          <Flex gap={10}>
            <CheckOutlined style={{fontSize: '18px', color: isWrite ? 'green' : '#dddddd'}}/>
            <Text style={{textDecoration: isWrite ? 'line-through' : 'none'}}>
              { isWrite ? <>Проведены настройки</> : <>Настройте кухню</>}
            </Text>
          </Flex>

          <Flex gap={10}>
            <CheckOutlined style={{fontSize: '18px', color: isWrite ? 'green' : '#dddddd'}}/>
            <Text style={{textDecoration: isWrite ? 'line-through' : 'none'}}>
              Договор подписан
            </Text>
          </Flex>

          <Flex gap={10}>
            <CheckOutlined style={{fontSize: '18px', color: isWrite ? 'green' : '#dddddd'}}/>
            <Text style={{textDecoration: isWrite ? 'line-through' : 'none'}}>
              Меню внесено
            </Text>
          </Flex>

          <Flex gap={10}>
            <CheckOutlined style={{fontSize: '18px', color: isWrite ? 'green' : '#dddddd'}}/>
            <Text style={{textDecoration: isWrite ? 'line-through' : 'none'}}>
              Пакеты и посуда в наличии
            </Text>
          </Flex>

        </Flex>
      }
    </KitchenCard>
  );
};

const KitchenCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
  padding: 20px 40px;
  @media (max-width: 998px) {
    grid-template-columns: 1fr;
  }
`
const KitchenCard = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  max-width: 500px;
`
const KitchenCardName = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
`
const KitchenCardRating = styled.div`
  min-width: fit-content;
  font-size: 24px;
  font-weight: bold;
`
const KitchenCardImage = styled.div`
  position: relative;
  flex: 1;
  border-radius: 25px;
  overflow: hidden;
  cursor: pointer;
  & .ant-image { height: 100%; }
`
const KitchenCardStatus = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: 10px 20px;
  max-width: 80%;
  background: #ff0000;
  color: #ffffff;
  font-weight: bold;
  border-bottom-left-radius: 24px;
  border-top-right-radius: 24px;
`
const KitchenCardStatistic = styled.div`
  margin-top: 10px;
`
