import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Image, Popconfirm, Flex } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import ImageUploadAndCrop from './ImageUploadAndCrop';

const ImageComponent = ({ isAdmin, onUploadImage, onRemoveImage }) => {
  const { selectKitchen } = useSelector((store) => store.kitchen);

  return (
    <div style={{display: 'flex', width: '100%', marginBottom: '20px'}}>
      {selectKitchen.filePathPublic ? 
        <div style={{position: 'relative', maxWidth: '500px', height: '250px', borderRadius: '25px', overflow: 'hidden'}}>
          <Image
            style={{ width: '100%', height: '250px', objectFit: 'cover'}}
            alt="example"
            src={selectKitchen.filePathPublic}
            mask='false'
            preview={{
              toolbarRender: (_,{}) => (<Flex size={12} className="toolbar-wrapper"></Flex>),
            }}
          />
          {isAdmin && <Popconfirm
            title="Удаление картинки!"
            description={`Вы действительно хотите удалить картинку`}
            okText="Да"
            cancelText="Нет"
            placement="bottom"
            onConfirm={onRemoveImage}
          >
            <Button shape="circle" style={{position: 'absolute', top: '5px', right: '5px'}} icon={<DeleteOutlined/>}/>
          </Popconfirm>}
        </div>
      :
        <ImageUploadAndCrop
          isAdmin={isAdmin}
          onFileUpload={onUploadImage}
        />
      }
    </div>
  );
};

export default ImageComponent;
