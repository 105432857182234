import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCurrentDeliveryServicePolygon,
  sendRequestDeliveryServicePolygonDelete,
} from '../../../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Row, Col, Flex, Table, Input, ColorPicker, Checkbox, Button, Popconfirm } from 'antd';
import { EyeOutlined, CloseOutlined } from '@ant-design/icons'
import FileUploader from './components/FileUploader';
import PolygonsMap from './components/PolygonsMap';
import NewPolygonsTableComponent from './components/NewPolygonsTableComponent';
import PrintTable from '../../../../../../../components/PrintTable';

const { Search } = Input;

const PolygonsTableComponent = () => {
  const navigate = useNavigate();
  const { deliveryServicePolygons, currentDeliveryServicePolygon, uploadNewPoligons } = useSelector((store) => store.deliveryServices);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [polygonCenter, setPolygonCenter] = useState([deliveryServicePolygons[0].coordinates[0][0][1], deliveryServicePolygons[0].coordinates[0][0][0]]);
  const [modifyPolygons, setModifyPolygons] = useState([]);

  useEffect(() => {
    const modifyPolygons = deliveryServicePolygons?.map(item => {  
      return { ...item, key: item.id, isShow: true};
    });

    setModifyPolygons(modifyPolygons)
  }, [deliveryServicePolygons]);

  const filteredPolygons = modifyPolygons?.filter((item) =>
    item.name?.toString().toLowerCase().includes(searchTerm.toLowerCase())
  )

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleCellClick = (record) => {
    console.log(record)
    setPolygonCenter([record.coordinates[0][0][1], record.coordinates[0][0][0]]);
    dispatch(setCurrentDeliveryServicePolygon(record));
    // navigate(`polygon/${record.id}`);
  };

  const handleCheckboxChange = (checked, recordId) => {
    const updatedPolygons = filteredPolygons.map(polygon => {
      if (polygon.id === recordId) {
        return { ...polygon, isShow: checked };
      }
      return polygon;
    });
    setModifyPolygons(updatedPolygons);
  };

  const handleDelete = (record) => {
    dispatch(sendRequestDeliveryServicePolygonDelete({
      token: localStorage.getItem('token'),
      polygonId: record.id,
    }))
  };

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Цвет',
      key: 'color',
      render: (_, record) => (
        <Flex gap='small'>
          <ColorPicker value={record.strokeColor} size="small" disabled style={{cursor: 'pointer'}}/>
          <ColorPicker value={record.fillColor} size="small" disabled style={{cursor: 'pointer'}}/>
        </Flex>
      ),
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Кол-во рейсов',
      dataIndex: 'races',
      key: 'races',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: <EyeOutlined/>,
      dataIndex: 'isShow',
      key: 'isShow',
      render: (isShow, record) => (
        <Checkbox checked={isShow} onChange={(e) => handleCheckboxChange(e.target.checked, record.id)} />
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Popconfirm
          title="Удаление полигона!"
          description={`Вы действительно хотите удалить полигон?`}
          okText="Да"
          cancelText="Нет"
          placement="left"
          onConfirm={() => handleDelete(record)}
        >
          <Button danger icon={<CloseOutlined />} size='small'/>
        </Popconfirm>
      ),
    },
  ];

  console.log(filteredPolygons) 

  return (
    <Row gutter={16}>
      <Col xs={10}>
        <Flex vertical gap={20} style={{ marginBottom: '20px'}}>
          {uploadNewPoligons ? 
            <NewPolygonsTableComponent/>
          :
            <FileUploader/>
          }
          <Search
            placeholder="Поиск по названию или описанию"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Flex>
        <PrintTable>
          <Table
            dataSource={filteredPolygons}
            columns={columns}
            size="small"
            pagination={{
              defaultPageSize: 10,
            }}
            onRow={(record) => ({
              style: { cursor: 'pointer', background: record.key === currentDeliveryServicePolygon?.key ? '#e6f7ff' : '' }
            })}
          />
        </PrintTable>
      </Col>
      <Col xs={14}>
        <PolygonsMap
          polygons={filteredPolygons}
          polygonCenter={polygonCenter}
        />
      </Col>
    </Row>
  );
};

export default PolygonsTableComponent;
