import apiInstance from '../../../../../api';
import {call, put, select} from 'redux-saga/effects';
import {
  //Organizations
  sendRequestOrganizationsGetSuccess,
  sendRequestOrganizationsGetError,
  setOrganizations,
  sendRequestOrganizationsCreateSuccess,
  sendRequestOrganizationsCreateError,
  sendRequestOrganizationsUpdateSuccess,
  sendRequestOrganizationsUpdateError,
  sendRequestOrganizationsDeleteSuccess,
  sendRequestOrganizationsDeleteError,

  //Cities
  sendRequestCitiesGetSuccess,
  sendRequestCitiesGetError,
  setCities,
  sendRequestCitiesCreateSuccess,
  sendRequestCitiesCreateError,
  sendRequestCitiesUpdateSuccess,
  sendRequestCitiesUpdateError,
  sendRequestCitiesDeleteSuccess,
  sendRequestCitiesDeleteError,
} from '../../../../reducers/utils/settingsReducers/settingsCatalogsReducer';
import { setMessagePopupContent, selectCommonState } from '../../../../reducers/utils/commonReducer';

//Organizations
export function* handleGetOrganizations(action) {
  const api = '/organizations/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestOrganizationsGetSuccess());
      yield put(setOrganizations(response.data.data.organizations));
    } else {
      yield put(sendRequestOrganizationsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestOrganizationsGetError());
  }
}

export function* handleCreateOrganizations(action) {
  const api = '/organizations/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestOrganizationsCreateSuccess());
    } else {
      yield put(sendRequestOrganizationsCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestOrganizationsCreateError());
  }
}

export function* handleUpdateOrganizations(action) {
  const api = '/organizations/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestOrganizationsUpdateSuccess());
    } else {
      yield put(sendRequestOrganizationsUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestOrganizationsUpdateError());
  }
}

export function* handleDeleteOrganizations(action) {
  const api = '/organizations/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestOrganizationsDeleteSuccess());
    } else {
      yield put(sendRequestOrganizationsDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestOrganizationsDeleteError());
  }
}

//Cities
export function* handleGetCities(action) {
  const api = '/cities/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestCitiesGetSuccess());
      yield put(setCities(response.data.data.cities));
    } else {
      yield put(sendRequestCitiesGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestCitiesGetError());
  }
}

export function* handleCreateCities(action) {
  const api = '/cities/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestCitiesCreateSuccess());
    } else {
      yield put(sendRequestCitiesCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestCitiesCreateError());
  }
}

export function* handleUpdateCities(action) {
  const api = '/cities/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestCitiesUpdateSuccess());
    } else {
      yield put(sendRequestCitiesUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestCitiesUpdateError());
  }
}

export function* handleDeleteCities(action) {
  const api = '/cities/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestCitiesDeleteSuccess());
    } else {
      yield put(sendRequestCitiesDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestCitiesDeleteError());
  }
}
