import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentModifierGroupId } from '../../../../../../redux/reducers/utils/kitchenReducers/kitchenMenuReducer';
import { Table, Tag } from 'antd';
import PrintTable from '../../../../../../components/PrintTable';

const ModifiersTableComponent = ({ modifiers }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const columns = [
    {
      title: 'Название группы',
      dataIndex: 'name',
      key: 'name',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
      sorter: (a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        if (!a.name && b.name) return 1;
        if (a.name && !b.name) return -1;
        return 0;
      }
    },
    {
      title: 'Публичное название группы',
      dataIndex: 'public_name',
      key: 'public_name',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
      sorter: (a, b) => {
        if (a.public_name && b.public_name) {
          return a.public_name.localeCompare(b.public_name);
        }
        if (!a.public_name && b.public_name) return 1;
        if (a.public_name && !b.public_name) return -1;
        return 0;
      }
    },
    {
      title: 'Минимальное кол-во модификаторов',
      dataIndex: 'min',
      key: 'min',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
      sorter: (a, b) => a.min - b.min,
    },
    {
      title: 'Максимальное кол-во модификаторов',
      dataIndex: 'max',
      key: 'max',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
      sorter: (a, b) => a.max - b.max,
    },
    {
      title: 'Список модификаторов',
      key: 'energyValue',
      render: (record) => (
        <></>
      ),
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Статус',
      key: 'isEnabled',
      render: (record) => {
        let color = '';
        let status = ''
        if (!!record.isEnabled === true) {
          color = 'green';
          status = 'Включен'
        } else {
          color = 'volcano';
          status = 'Выключен'
        }
        return (
          <Tag color={color}>
            {status.toUpperCase()}
          </Tag>
        );
      },
      filters: [
        {
          text: 'Включен',
          value: true,
        },
        {
          text: 'Выключен',
          value: false,
        },
      ],
      onFilter: (value, record) => record.isEnabled === value,
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
  ];

  const handleCellClick = record => {
    dispatch(setCurrentModifierGroupId(record.id))
    navigate(`${record.id}`);
  }

  return (
    <PrintTable>
      <Table
        dataSource={modifiers}
        columns={columns}
        scroll={{ x: 'auto' }}
        bordered
        size="small"
        pagination={false}
      />
    </PrintTable>
  );
};

export default ModifiersTableComponent;
