import React, { useState } from 'react';
import { Table, Modal, Form, Input, Popconfirm, Button } from 'antd';
import styled from 'styled-components';
import { ReactComponent as IconEdit } from '../../../../../assets/icons/icon-edit.svg';
import { ReactComponent as IconClose } from '../../../../../assets/icons/icon-close.svg';

const { Search } = Input;

const AccessListMethod = ({accessPublicMethods, onUpdateMethod, onDeleteMethod}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const accessPublicMethodsWithKey = accessPublicMethods.map(item => ({...item, key: item.id}))
  const filteredAccessMethods = accessPublicMethodsWithKey?.filter((accessMethod) =>
    accessMethod.keyPublicMethod.toLowerCase().includes(searchTerm.toLowerCase()) ||
    accessMethod.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    accessMethod.description.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const columns = [
    {
      title: 'Ключ',
      dataIndex: 'keyPublicMethod',
      key: 'keyPublicMethod',
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: '',
      key: 'actions',
      render: (_, record) => (
        <AccessAction
          accessMethod={record}
          onUpdateMethod={onUpdateMethod}
          onDeleteMethod={onDeleteMethod}
        />
      ),
    },
  ];

  return (
    <>
      <Search
        placeholder="Поиск по названию или описанию"
        onChange={(e) => handleSearch(e.target.value)}
        style={{ margin: '20px 0' }}
      />
      <Table
        dataSource={filteredAccessMethods}
        columns={columns}
        size="small"
        pagination={{
          defaultPageSize: 50,
        }}
      />
    </>
  );
};

export default AccessListMethod;

const AccessAction = ({accessMethod, onUpdateMethod, onDeleteMethod}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [keyPublicMethod, setKeyPublicMethod] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleEdit = () => {
    setName(accessMethod.name);
    setDescription(accessMethod.description);
    setIsModalVisible(true);
  }

  const handleSuccess = () => {
    onUpdateMethod(name, description, keyPublicMethod, accessMethod.id);
    setIsModalVisible(false);
  }

  const handleModalCancel = () => {
    setKeyPublicMethod('');
    setName('');
    setDescription('');
    setIsModalVisible(false);
  };

  const handleDelete = () => {
    onDeleteMethod(accessMethod.id);
  };

  return (
    <Action>
      <Button onClick={handleEdit}><IconEdit/></Button>
      <Modal
        title="Редактирование метода"
        open={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        <Form initialValues={{ keyPublicMethod: accessMethod.keyPublicMethod, name: accessMethod.name, description: accessMethod.description }}>
          <Form.Item label="Ключ" name="keyPublicMethod">
            <Input value={keyPublicMethod} onChange={(e) => setKeyPublicMethod(e.target.value)} />
          </Form.Item>
          <Form.Item label="Название" name="name">
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </Form.Item>
          <Form.Item label="Описание" name="description">
            <Input value={description} onChange={(e) => setDescription(e.target.value)} />
          </Form.Item>
          <Button type="primary" onClick={handleSuccess}>
            Сохранить
          </Button>
        </Form>
      </Modal>
      <Popconfirm
        title="Удаление метода доступа!"
        description={`Вы действительно хотите удалить метод доступа ${accessMethod.name}`}
        okText="Да"
        cancelText="Нет"
        placement="left"
        onConfirm={handleDelete}
      >
        <Button danger>Удалить</Button>
      </Popconfirm>
    </Action>
  );
};

const Action = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`