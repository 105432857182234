import { takeLatest } from 'redux-saga/effects';
import {
  // Wallets
  handleSearchWallets,
  handleUploadWallets,
  handleCreateWallet,
  handleUpdateWallet,
  handleGetWalletsStatuses,

  // Transactions
  handleSearchTransactions,
  handleCreateTransaction,
} from './financeWalletsHandler';
import {
  // Wallets
  sendRequestWalletsSearch,
  sendRequestUploadWallets,
  sendRequestWalletCreate,
  sendRequestWalletUpdate,
  sendRequestWalletsStatusesGet,

  // Transactions
  sendRequestTransactionsSearch,
  sendRequestTransactionCreate,
} from '../../../../reducers/utils/financeReducers/financeWalletsReducer';

export function* financeWalletsWatcherSaga() {
  // Wallets
  yield takeLatest(sendRequestWalletsSearch.type, handleSearchWallets);
  yield takeLatest(sendRequestUploadWallets.type, handleUploadWallets);
  yield takeLatest(sendRequestWalletCreate.type, handleCreateWallet);
  yield takeLatest(sendRequestWalletUpdate.type, handleUpdateWallet);
  yield takeLatest(sendRequestWalletsStatusesGet.type, handleGetWalletsStatuses);

  // Transactions
  yield takeLatest(sendRequestTransactionsSearch.type, handleSearchTransactions);
  yield takeLatest(sendRequestTransactionCreate.type, handleCreateTransaction);
}