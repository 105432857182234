import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectOrder,
  setCurrentLocation,
} from '../../../../../redux/reducers/utils/deliveryReducers/deliveryMapReducer';
import styled from 'styled-components';
import LocationsComponent from './LocationsComponent';
import AddressDetailComponent from './AddressDetailComponent';
import AppointComponent from './AppointComponent';
import LocationDetailComponent from './LocationDetailComponent';
import LocationAddressAddComponent from './LocationAddressAddComponent';

const DeliveryLeftPanel= () => {
  const {
    selectedCourierToAppoint,
    selectedOrder,
    currentLocation,
    locations,
    locationsLib,
    mapMode,
  } = useSelector((store) => store.deliveryMap);
  const dispatch = useDispatch();
  
  console.log(locations)

  useEffect(() => {
    if (locations.length > 0 && currentLocation) {
      const matchingLocation = locations.find(location => location.id === currentLocation.id) || locationsLib.find(location => location.id === currentLocation.id);
      console.log(matchingLocation)
      dispatch(setCurrentLocation(matchingLocation));
    }

    if (locations.length === 0 && currentLocation) {
      const matchingLocation = locationsLib.find(location => location.id === currentLocation.id);
      console.log(matchingLocation)
      dispatch(setCurrentLocation(matchingLocation));
    }

    if (locations.length > 0 && selectedOrder) {
      const matchingLocation = locations.find(location => location.id === currentLocation.id);
      const matchingAddress = matchingLocation ? matchingLocation.addresses[selectedOrder.addressId] : null;

      dispatch(selectOrder({
        ...matchingAddress,
        locationId: currentLocation.id,
        locationEntrance: currentLocation.entrances,
        locationMidpoints: currentLocation.meeting_points
      }));
    }
  }, [
    locations,
    locationsLib,
  ])

  return (
    <Wrapper>
      {selectedCourierToAppoint ? (
        <AppointComponent/>
      ) : mapMode === 'addingAddressesMode' ? (
        <LocationAddressAddComponent/>
      ) : (
        <LocationsComponent/>
      )}
      {currentLocation && <LocationDetailComponent/>}
      {selectedOrder && <AddressDetailComponent/>}
    </Wrapper>
  );
};

export default DeliveryLeftPanel;

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 600;
  display: flex;
  flex-direction: row;
  height: 0;
`;
