import React from 'react';
import styled from 'styled-components';

const ScrolledContainer = ({ children, height }) => {
  return (
    <Wrapper height={height}>
      {children}
    </Wrapper>
  )
}

export default ScrolledContainer;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  flex: 1;
  height: ${props => props.height ? props.height : 'calc(100vh - 45px)'};
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
  }
`