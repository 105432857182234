import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestKitchenActWorksGet,
  sendRequestKitchenActWorksCreate,
  sendRequestKitchenActWorksUpdate,
  sendRequestKitchenActWorksDelete,
  sendRequestKitchenActWorksSignedStaffSO,
  sendRequestKitchenActWorksSignedStaffKitchen,
  sendRequestKitchenActWorksSetStatusPaymentKitchen,
} from '../../../../redux/reducers/utils/kitchenReducers/kitchenFinanceReducer';
import { Select, Typography, Modal, Input } from 'antd';
import styled from 'styled-components';
import Loader from '../../../../components/Loader';
import KitchenSelectorComponent from '../../components/KitchenSelectorComponent';
import KitchenActWorksCreateModalComponent from './components/KitchenActWorksCreateModalComponent';
import KitchenActWorksTableComponent from './components/KitchenActWorksTableComponent';

const { Title, Text } = Typography;
const { Option } = Select;

const FinancePage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const { selectKitchen } = useSelector((store) => store.kitchen);
  const {
    kitchenActWorksGetRequestStatus,
    kitchenActWorks,
    kitchenActWorksCreateRequestStatus,
    kitchenActWorksSignedStaffKitchenRequestStatus,
    kitchenActWorksSetStatusPaymentKitchenRequestStatus,
    kitchenActWorksSignedStaffSORequestStatus,
  } = useSelector((store) => store.kitchenFinance);
  const dispatch = useDispatch();
  const [isCodeSended, setIsCodeSended] = useState(false);
  const [paymentKitchen, setPaymentKitchen] = useState(null);

  useEffect(() => {
    if (selectKitchen) {
      dispatch(sendRequestKitchenActWorksGet({ token: localStorage.getItem('token'), kitchenId: selectKitchen.id}));
    }
  }, [selectKitchen]);

  useEffect(() => {
    if (
      selectKitchen && 
      kitchenActWorksCreateRequestStatus === 'resolved' ||
      kitchenActWorksSetStatusPaymentKitchenRequestStatus === 'resolved' ||
      kitchenActWorksSignedStaffSORequestStatus === 'resolved'
    ) {
      dispatch(sendRequestKitchenActWorksGet({ token: localStorage.getItem('token'), kitchenId: selectKitchen.id}));
    }
  }, [
    kitchenActWorksCreateRequestStatus,
    kitchenActWorksSetStatusPaymentKitchenRequestStatus,
    kitchenActWorksSignedStaffSORequestStatus,
  ]);

  useEffect(() => {
    if (kitchenActWorksSignedStaffKitchenRequestStatus === 'resolved') {
      setIsCodeSended(true);
    }
  }, [kitchenActWorksSignedStaffKitchenRequestStatus]);

  const handleCreateAct = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      kitchenId: selectKitchen.id,
      dateFrom: values.dateFrom.format('YYYY-MM-DD'),
      dateTo: values.dateTo.format('YYYY-MM-DD'),
    }

    console.log(requestData);
    dispatch(sendRequestKitchenActWorksCreate(requestData));
  };

  const handleSignedStaffSO = (id, signed) => {
    const requestData = {
      token: localStorage.getItem('token'),
      id: id,
      signed: signed,
    }

    console.log(requestData);
    dispatch(sendRequestKitchenActWorksSignedStaffSO(requestData));
  };

  const handleSignedStaffKitchen = (id, signed) => {
    const requestData = {
      token: localStorage.getItem('token'),
      ids: [id],
    }

    console.log(requestData);
    setPaymentKitchen({ids: [id], signed: signed, confirmCode: ''})
    dispatch(sendRequestKitchenActWorksSignedStaffKitchen(requestData));
  };

  const handleSetStatusPaymentKitchen = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      ...paymentKitchen,
    }

    console.log(requestData);
    dispatch(sendRequestKitchenActWorksSetStatusPaymentKitchen(requestData));
    setPaymentKitchen(null);
    setIsCodeSended(false);
  };

  const handleCancel = () => {
    setPaymentKitchen(null);
    setIsCodeSended(false);
  }

  console.log(selectKitchen);

  return (
    <>
      {(
        kitchenActWorksGetRequestStatus === 'pending'
      ) && <Loader />}
      <Title level={2}>Финансы</Title>
      <SelectWrapper>
        <KitchenSelectorComponent/>
        <KitchenActWorksCreateModalComponent onSubmit={handleCreateAct}/>
      </SelectWrapper>
      <KitchenActWorksTableComponent
        acts={kitchenActWorks}
        onSignedStaffSO={handleSignedStaffSO}
        onSignedStaffKitchen={handleSignedStaffKitchen}
      />
      <Modal
        title="Код подтверждения"
        open={isCodeSended}
        onOk={handleSetStatusPaymentKitchen}
        onCancel={handleCancel}
      >
        <Input maxLength={6} value={paymentKitchen ? paymentKitchen.confirmCode : ''} onChange={(e) => setPaymentKitchen({...paymentKitchen, confirmCode: e.target.value})}/>
      </Modal>
    </>
  );
};

export default FinancePage;

const SelectWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
`
