import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestDeliveryServiceRaceCourierEnable,
  sendRequestDeliveryServiceRaceCourierDisable,
} from '../../../../../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Flex, Typography, Table, Form, Input, Button } from 'antd';
import moment from 'moment';
import PrintTable from '../../../../../../../../../components/PrintTable';


const { Title, Text } = Typography;

const FinanceTableComponent = () => {
  const { currentDeliveryServiceCourier } = useSelector((store) => store.deliveryServices);
  const [transactionData, setTransactionData] = useState(transactions);
  const [form] = Form.useForm();
  const nameInputRef = useRef(null); // создаем реф для поля name

  const columns = [
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Операция',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Сумма',
      dataIndex: 'transaction',
      key: 'transaction',
    },
  ];

  const handleResetBalance = () => {
    // Установка суммы в поле value формы и фокусировка на поле name
    form.setFieldsValue({ value: currentDeliveryServiceCourier.walletBalance });
    nameInputRef.current.focus();
  };

  const handleCreateTransaction = (values) => {
    // Обработка создания транзакции
    console.log('Создание транзакции:', values);
    const newTransaction = {
      date: moment().format('YYYY/MM/DD'),
      name: values.name,
      description: 'Операция',
      transaction: `${values.value} р.`,
    };
    setTransactionData([...transactionData, newTransaction]);
    form.resetFields();
    nameInputRef.current.focus();
  };

  return (
    <>
      <Flex gap={20} wrap='wrap' style={{ marginBottom: '100px' }}>
        <Typography.Title level={3}>Баланс счёта: {currentDeliveryServiceCourier.walletBalance} р.</Typography.Title>
        <Button type='primary' size='large' onClick={handleResetBalance} disabled={currentDeliveryServiceCourier.walletBalance === 0}>Обнулить счёт</Button>
      </Flex>
      <Form
        form={form}
        onFinish={handleCreateTransaction}
        layout='inline'
        style={{ marginBottom: '20px' }}
      >
        <Form.Item name="value"><Input placeholder="Сумма" /></Form.Item>
        <Form.Item name="name"><Input ref={nameInputRef} placeholder="Название" /></Form.Item>
        <Button type="primary" htmlType="submit">Добавить</Button>
      </Form>
      <PrintTable>
        <Table
          dataSource={transactionData.sort((a, b) => moment(b.date) - moment(a.date))}
          columns={columns}
          size="small"
          pagination={{
            defaultPageSize: 20,
          }}
        />
      </PrintTable>
    </>
  );
};

export default FinanceTableComponent;

var transactions = [
  {
    date: '2023/03/20',
    name: 'Заказ 145646136',
    description: 'Зачисление',
    transaction: '80.00 р.',
  },
  {
    date: '2023/03/25',
    name: 'Заказ 456316543',
    description: 'Зачисление',
    transaction: '85.00 р.',
  },
  {
    date: '2023/04/25',
    name: 'Премия',
    description: 'Зачисление',
    transaction: '500.00 р.',
  },
  {
    date: '2023/05/03',
    name: 'Заказ 134651334',
    description: 'Зачисление',
    transaction: '85.00 р.',
  },
  {
    date: '2023/05/18',
    name: 'Комплимент от сытого',
    description: 'Зачисление',
    transaction: '200.00 р.',
  },
  {
    date: '2023/06/09',
    name: 'Заказ 013120543',
    description: 'Списание',
    transaction: '-80.00 р.',
  },
  {
    date: '2024/01/15',
    name: 'Заказ 555413543',
    description: 'Зачисление',
    transaction: '250.00 р.',
  },
  {
    date: '2024/02/02',
    name: 'Удержание комиссии',
    description: 'Списание',
    transaction: '-50.00 р.',
  },
  {
    date: '2024/02/10',
    name: 'Заказ 12316546130',
    description: 'Зачисление',
    transaction: '100.00 р.',
  },
  {
    date: '2024/03/05',
    name: 'Заказ 0015643205',
    description: 'Списание',
    transaction: '-300.00 р.',
  },
];