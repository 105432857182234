import React from 'react';
import { Flex, Typography, Image, Table } from 'antd';
import emptyImage from '../../../../../../assets/img/empty-kitchen-image.png';
import PrintTable from '../../../../../../components/PrintTable';
import { formatDate } from '../../../../../../core/utils';

const { Title, Text } = Typography;

const NotificationlogsTableComponent = ({ dataSource, currentPage, total, onChange }) => {
  const columns = [
    {
      title: 'Дата',
      key: 'date',
      render: ((record) => (
        <>
          <Title level={5}>{formatDate(record.created)}</Title>
          <Text type='secondary'>{record.phone} <small>({record.userId})</small></Text>
        </>
      )),
      width: '130px'
    },
    {
      title: 'Проект',
      dataIndex: 'project',
      key: 'project',
    },
    {
      title: 'Устройство',
      dataIndex: 'device',
      key: 'device',
      render: text => <Text>{text}</Text>,
      width: '100px'
    },
    {
      title: 'FCM Token',
      dataIndex: 'fcmToken',
      key: 'fcmToken',
      render: text => <Text copyable>{text}</Text>,
    },
    {
      title: 'Сообщение',
      key: 'message',
      render: ((record) => (
        <Card
          title={record.title}
          description={record.body}
          image={record.filePathPublic}
        />
      )),
    },
    {
      title: 'Клиент',
      key: 'client',
      render: ((record) => (
        <div>
          <div>{record.name} ({record.userId})</div>
          <Text type='secondary'>{record.phone}</Text>
        </div>
      )),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Город',
      dataIndex: 'cityName',
      key: 'cityName',
    },
    {
      title: 'Бренд',
      dataIndex: 'brand',
      key: 'brand',
    },
    {
      title: 'Модель',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'Версия ОС',
      dataIndex: 'appVersion',
      key: 'appVersion',
    },
  ];

  const handleTableChange = (pagination) => {
    onChange(pagination.current);
  };

  return (
    <PrintTable>
      <Table
        dataSource={dataSource}
        columns={columns}
        size='small'
        pagination={{
          current: currentPage,
          total: total,
          pageSize: 10,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        onChange={handleTableChange}
      />
    </PrintTable>
  );
};

export default NotificationlogsTableComponent;

const Card = ({ title, description, image }) => {
  return(
    <Flex gap={5}>
      <Flex vertical flex='1 1 100%' style={{minWidth: '200px'}}>
        <Title level={4} style={{marginBottom: '4px'}}>{title}</Title>
        <Text>{description}</Text>
      </Flex>
      <Flex vertical flex='1 0 60px' style={{minWidth: '60px'}}>
        <Image
          style={{
            width: '100%',
            height: '60px',
            objectFit: 'cover',
            background: '#eeeeee',
            borderRadius: '10px',
            overflow: 'hidden',
          }} 
          src={image}
          fallback={emptyImage}
        />
      </Flex>
    </Flex>
  )
}
