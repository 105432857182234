import React from 'react';
import { Form, Input, Select, DatePicker, Button, Flex, Typography } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Title, Text } = Typography;

const serverFormat = 'YYYY-MM-DD';
const clientFormat = 'DD-MM-YYYY';

const NotificationlogsFiltersComponent = ({ projectsOptions, statusesOptions, citiesOptions, total, onSearch }) => {
  const handleSearch = (values) => {
    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(([_, v]) => v !== undefined && v !== null && v !== '')
    );
  
    if (filteredValues.created) {
      const [createdFrom, createdTo] = filteredValues.created;
      filteredValues.createdFrom = createdFrom.format(serverFormat);
      filteredValues.createdTo = createdTo.format(serverFormat);
      delete filteredValues.created;
    }
  
    onSearch(filteredValues);
  };

  return (
    <Form
      layout='vertical'
      onFinish={handleSearch}
    >
      <Flex gap={10} wrap='wrap'>
        <Form.Item style={{flex: 1, marginBottom: '0px', minWidth: '300px'}} name='phone' label='Номер телефон'>
          <Input placeholder='Введите номер телефона' maxLength={12}/>
        </Form.Item>

        <Form.Item style={{flex: 1, marginBottom: '0px', minWidth: '300px'}} name='project' label='Проект'>
          <Select
            placeholder='Введите название проекта'
            options={projectsOptions}
          />
        </Form.Item>
      
        <Form.Item style={{flex: 1, marginBottom: '0px', minWidth: '300px'}} name='title' label='Поиск по заголовку'>
          <Input placeholder='Введите текст заголовка' />
        </Form.Item>

        <Form.Item style={{flex: 1, marginBottom: '0px', minWidth: '300px'}} name='body' label='Поиск по тексту'>
          <Input placeholder='Введите текст' />
        </Form.Item>

        <Form.Item style={{flex: 1, marginBottom: '0px', minWidth: '300px'}} name='created' label='Период времени отправки'>
          <RangePicker style={{width: '100%'}} format={clientFormat}/>
        </Form.Item>

        <Form.Item style={{flex: 1, marginBottom: '0px', minWidth: '300px'}} name='status' label='Статус отправки'>
          <Select
            placeholder='Выберите статус'
            options={statusesOptions}
          />
        </Form.Item>

        <Form.Item style={{flex: 1, marginBottom: '0px', minWidth: '300px'}} name='cityId' label='Поиск по городу'>
          <Select
            showSearch
            placeholder="Выберите город"
            optionFilterProp="children"
            options={citiesOptions}
            filterOption={(input, option) =>
              (option?.name?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
            }
            optionRender={(option) => (
              <>{option.data.name}</>
            )}
          />
        </Form.Item>

        <Form.Item style={{flex: 1, marginBottom: '0px', minWidth: '300px'}} name='brand' label='Поиск по бренду смартфона'>
          <Input placeholder='Введите бренд смартфона' />
        </Form.Item>

        <Form.Item style={{flex: 1, marginBottom: '0px', minWidth: '300px'}} name='model' label='Поиск по модели смартфона'>
          <Input placeholder='Введите модель смартфона' />
        </Form.Item>

        <Form.Item style={{flex: 1, marginBottom: '0px', minWidth: '300px'}} name='appVersion' label='Поиск по версии ОС'>
          <Input placeholder='Введите версию ОС' />
        </Form.Item>

        <Form.Item style={{flex: 1, marginBottom: '0px', minWidth: '300px'}} name='device' label='Поиск по устройству'>
          <Input placeholder='Введите устройство' />
        </Form.Item>

        <Form.Item style={{flex: 1, marginBottom: '0px', minWidth: '300px'}} name='fcmToken' label='Поиск по FCMToken'>
          <Input placeholder='Введите FCMToken' />
        </Form.Item>
      </Flex>

      <Flex style={{marginTop: '20px'}} justify='space-between' gap={16}>
        <Text type='secondary'>Найдено уведомлений: {new Intl.NumberFormat().format(total)}</Text>
        <Flex gap={16}>
          <Button type='primary' htmlType='submit'>Поиск</Button>
          <Button htmlType='reset'>Сбросить</Button>
        </Flex>
      </Flex>
    </Form>
  );
};

export default NotificationlogsFiltersComponent;
