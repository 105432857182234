import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  //Races For Days
  isOpenRacesDrawer: true,
  isOpenTemplatesDrawer: true,
  racesForDaysRequestStatus: null,
  racesForDaysData: [],
  selectedMenuDate: null,
  selectedMenuRace: null,

  //Menu
  createMenuRequestStatus: null,
  enableMenuStatus: null,
  disableMenuStatus: null,

  //Products
  menuAllProductsRequestStatus: null,
  menuAllProductsData: [],
  addProductInMenuStatus: null,
  enableProductInMenuStatus: null,
  disableProductInMenuStatus: null,
  updateProductInMenuStatus: null,

  //Menu Template
  getMenuTemplatesRequestStatus: null,
  menuTemplates: [],
  selectedTemplate: null,
  lastCreatedTemplateId: null,
  createMenuTemplateStatus: null,
  updateMenuTemplateStatus: null,
  deleteMenuTemplateStatus: null,
  copyMenuTemplateStatus: null,
  transferMenuTemplateStatus: null,

  //Menu Template Products
  menuTemplateProductsRequestStatus: null,
  menuTemplateProducts: [],
  addProductMenuTemplateStatus: null,
  updateProductMenuTemplateStatus: null,
  removeProductMenuTemplateStatus: null,

  //Modifier Groups
  modifierGroupsGetRequestStatus: null,
  modifierGroups: [],
  currentModifierGroupId: null,
  modifierGroupsCreateRequestStatus: null,
  modifierGroupsUpdateRequestStatus: null,
  modifierGroupsDeleteRequestStatus: null,

  //Modifier Groups Products
  modifierGroupsProductsGetRequestStatus: null,
  modifierGroupsProducts: [],
  modifierGroupsProductCreateRequestStatus: null,
  modifierGroupsProductUpdateRequestStatus: null,
  modifierGroupsProductDeleteRequestStatus: null,
};

const kitchenMenuSlice = createSlice({
  name: 'kitchenMenu',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    //Races For Days
    setIsOpenRacesDrawer: (state, action) => {
      state.isOpenRacesDrawer = action.payload;
    },
    setIsOpenTemplatesDrawer: (state, action) => {
      state.isOpenTemplatesDrawer = action.payload;
    },
    sendRequestRacesForDays: (state) => { state.racesForDaysRequestStatus = 'pending'; },
    sendRequestRacesForDaysSuccess: (state) => { state.racesForDaysRequestStatus = 'resolved'; },
    sendRequestRacesForDaysError: (state) => { state.racesForDaysRequestStatus = 'rejected'; },
    clearRacesForDaysStatus: (state) => { state.accessKitchensRequestStatus = null; },
    setRacesForDaysData: (state, action) => {
      state.racesForDaysData = action.payload;
    },
    setSelectedMenuDate: (state, action) => {
      state.selectedMenuDate = action.payload;
    },
    setSelectedMenuRace: (state, action) => {
      state.selectedMenuRace = action.payload;
    },
    updateRacesForDaysData: (state, action) => {
      const menu = action.payload;
 
      const updatedRacesForDaysData = state.racesForDaysData.map(stateDay => {
        if (stateDay.day === menu.day) {
          const updatedRaces = stateDay.races.map(stateRace =>
            stateRace.raceId === menu.raceId
              ? { ...stateRace, status: menu.status, menuId: menu.id }
              : stateRace
          );
    
          return { ...stateDay, races: updatedRaces };
        } else {
          return stateDay;
        }
      });

      const updatedSelectedMenuRace = {...state.selectedMenuRace, status: menu.status, menuId: menu.id}
    
      return {
        ...state,
        racesForDaysData: updatedRacesForDaysData,
        selectedMenuRace: updatedSelectedMenuRace,
      };
    },

    //Menu
    sendRequestCreateMenu: (state) => { state.createMenuRequestStatus = 'pending'; },
    sendRequestCreateMenuSuccess: (state) => { state.createMenuRequestStatus = 'resolved'; },
    sendRequestCreateMenuError: (state) => { state.createMenuRequestStatus = 'rejected'; },
    clearCreateMenuStatus: (state) => { state.createMenuRequestStatus = null; },
    sendRequestEnableMenu: (state) => { state.enableMenuStatus = 'pending'; },
    sendRequestEnableMenuSuccess: (state) => { state.enableMenuStatus = 'resolved'; },
    sendRequestEnableMenuError: (state) => { state.enableMenuStatus = 'rejected'; },
    clearEnableMenuStatus: (state) => { state.enableMenuStatus = null; },
    sendRequestDisableMenu: (state) => { state.disableMenuStatus = 'pending'; },
    sendRequestDisableMenuSuccess: (state) => { state.disableMenuStatus = 'resolved'; },
    sendRequestDisableMenuError: (state) => { state.disableMenuStatus = 'rejected'; },
    clearDisableMenuStatus: (state) => { state.disableMenuStatus = null; },

    //Products
    sendRequestMenuAllProducts: (state) => { state.menuAllProductsRequestStatus = 'pending'; },
    sendRequestMenuAllProductsSuccess: (state) => { state.menuAllProductsRequestStatus = 'resolved'; },
    sendRequestMenuAllProductsError: (state) => { state.menuAllProductsRequestStatus = 'rejected'; },
    clearMenuAllProductsStatus: (state) => { state.menuAllProductsRequestStatus = null; },
    setMenuAllProductsData: (state, action) => {
      state.menuAllProductsData = action.payload;
    },
    updateMenuAllProductsData: (state, action) => {
      const updatedProduct = action.payload;
    
      const updatedData = state.menuAllProductsData.map(stateProduct => ({
        ...stateProduct,
        ...updatedProduct,
      }));
    
      return {
        ...state,
        menuAllProductsData: updatedData,
      };
    },
    updateMenuProductDataById: (state, action) => {
      const updatedProduct = action.payload;
    
      const updatedData = state.menuAllProductsData.map(stateProduct =>
        stateProduct.productId === updatedProduct.productId
          ? { ...stateProduct, ...updatedProduct }
          : stateProduct
      );
    
      return {
        ...state,
        menuAllProductsData: updatedData,
      };
    },
    sendRequestAddProductInMenu: (state) => { state.addProductInMenuStatus = 'pending'; },
    sendRequestAddProductInMenuSuccess: (state) => { state.addProductInMenuStatus = 'resolved'; },
    sendRequestAddProductInMenuError: (state) => { state.addProductInMenuStatus = 'rejected'; },
    clearAddProductInMenuStatus: (state) => { state.addProductInMenuStatus = null; },
    sendRequestEnableProductInMenu: (state) => { state.enableProductInMenuStatus = 'pending'; },
    sendRequestEnableProductInMenuSuccess: (state) => { state.enableProductInMenuStatus = 'resolved'; },
    sendRequestEnableProductInMenuError: (state) => { state.enableProductInMenuStatus = 'rejected'; },
    clearEnableProductInMenuStatus: (state) => { state.enableProductInMenuStatus = null; },
    sendRequestDisableProductInMenu: (state) => { state.disableProductInMenuStatus = 'pending'; },
    sendRequestDisableProductInMenuSuccess: (state) => { state.disableProductInMenuStatus = 'resolved'; },
    sendRequestDisableProductInMenuError: (state) => { state.disableProductInMenuStatus = 'rejected'; },
    clearDisableProductInMenuStatus: (state) => { state.disableProductInMenuStatus = null; },
    sendRequestUpdateProductInMenu: (state) => { state.updateProductInMenuStatus = 'pending'; },
    sendRequestUpdateProductInMenuSuccess: (state) => { state.updateProductInMenuStatus = 'resolved'; },
    sendRequestUpdateProductInMenuError: (state) => { state.updateProductInMenuStatus = 'rejected'; },
    clearUpdateProductInMenuStatus: (state) => { state.updateProductInMenuStatus = null; },

    //Menu Template
    sendRequestMenuTemplates: (state) => { state.getMenuTemplatesRequestStatus = 'pending'; },
    sendRequestMenuTemplatesSuccess: (state) => { state.getMenuTemplatesRequestStatus = 'resolved'; },
    sendRequestMenuTemplatesError: (state) => { state.getMenuTemplatesRequestStatus = 'rejected'; },
    clearMenuTemplatesStatus: (state) => { state.getMenuTemplatesRequestStatus = null; },
    setMenuTemplatesData: (state, action) => {
      state.menuTemplates = action.payload;
    },
    setSelectedTemplate: (state, action) => {
      state.selectedTemplate = action.payload;
    },
    updateSelectedTemplate: (state, action) => {
      const updatedKeys = action.payload;

      return {
        ...state,
        selectedTemplate: {
          ...state.selectedTemplate,
          ...updatedKeys,
        },
      };
    },
    setLastCreatedTemplateId: (state, action) => {
      state.lastCreatedTemplateId = action.payload;
    },
    sendRequestCreateMenuTemplate: (state) => { state.createMenuTemplateStatus = 'pending'; },
    sendRequestCreateMenuTemplateSuccess: (state) => { state.createMenuTemplateStatus = 'resolved'; },
    sendRequestCreateMenuTemplateError: (state) => { state.createMenuTemplateStatus = 'rejected'; },
    clearCreateMenuTemplateStatus: (state) => { state.createMenuTemplateStatus = null; },
    sendRequestUpdateMenuTemplate: (state) => { state.updateMenuTemplateStatus = 'pending'; },
    sendRequestUpdateMenuTemplateSuccess: (state) => { state.updateMenuTemplateStatus = 'resolved'; },
    sendRequestUpdateMenuTemplateError: (state) => { state.updateMenuTemplateStatus = 'rejected'; },
    clearUpdateMenuTemplateStatus: (state) => { state.updateMenuTemplateStatus = null; },
    sendRequestDeleteMenuTemplate: (state) => { state.deleteMenuTemplateStatus = 'pending'; },
    sendRequestDeleteMenuTemplateSuccess: (state) => {
      state.deleteMenuTemplateStatus = 'resolved';
      state.selectedTemplate = null;
    },
    sendRequestDeleteMenuTemplateError: (state) => { state.deleteMenuTemplateStatus = 'rejected'; },
    clearDeleteMenuTemplateStatus: (state) => { state.deleteMenuTemplateStatus = null; },
    sendRequestCopyMenuTemplate: (state) => { state.copyMenuTemplateStatus = 'pending'; },
    sendRequestCopyMenuTemplateSuccess: (state) => { state.copyMenuTemplateStatus = 'resolved'; },
    sendRequestCopyMenuTemplateError: (state) => { state.copyMenuTemplateStatus = 'rejected'; },
    clearCopyMenuTemplateStatus: (state) => { state.copyMenuTemplateStatus = null; },
    sendRequestTransferMenuTemplate: (state) => { state.transferMenuTemplateStatus = 'pending'; },
    sendRequestTransferMenuTemplateSuccess: (state) => { state.transferMenuTemplateStatus = 'resolved'; },
    sendRequestTransferMenuTemplateError: (state) => { state.transferMenuTemplateStatus = 'rejected'; },
    clearTransferMenuTemplateStatus: (state) => { state.transferMenuTemplateStatus = null; },

    //Menu Template Products
    sendRequestMenuTemplateProducts: (state) => { state.menuTemplateProductsRequestStatus = 'pending'; },
    sendRequestMenuTemplateProductsSuccess: (state) => { state.menuTemplateProductsRequestStatus = 'resolved'; },
    sendRequestMenuTemplateProductsError: (state) => { state.menuTemplateProductsRequestStatus = 'rejected'; },
    clearMenuTemplatesProductsStatus: (state) => { state.menuTemplateProductsRequestStatus = null; },
    setMenuTemplateProductsData: (state, action) => {
      state.menuTemplateProducts = action.payload;
    },
    updateMenuTemplateProducts: (state, action) => {
      const updatedProduct = action.payload;
    
      const updatedData = state.menuTemplateProducts.map(stateProduct =>
        stateProduct.productId === updatedProduct.productId
          ? { ...stateProduct, ...updatedProduct }
          : stateProduct
      );
    
      return {
        ...state,
        menuTemplateProducts: updatedData,
      };
    },
    sendRequestAddProductMenuTemplate: (state) => { state.addProductMenuTemplateStatus = 'pending'; },
    sendRequestAddProductMenuTemplateSuccess: (state) => { state.addProductMenuTemplateStatus = 'resolved'; },
    sendRequestAddProductMenuTemplateError: (state) => { state.addProductMenuTemplateStatus = 'rejected'; },
    clearAddProductMenuTemplateStatus: (state) => { state.addProductMenuTemplateStatus = null; },
    sendRequestUpdateProductMenuTemplate: (state) => { state.updateProductMenuTemplateStatus = 'pending'; },
    sendRequestUpdateProductMenuTemplateSuccess: (state) => { state.updateProductMenuTemplateStatus = 'resolved'; },
    sendRequestUpdateProductMenuTemplateError: (state) => { state.updateProductMenuTemplateStatus = 'rejected'; },
    clearUpdateProductMenuTemplateStatus: (state) => { state.updateProductMenuTemplateStatus = null; },
    sendRequestRemoveProductMenuTemplate: (state) => { state.removeProductMenuTemplateStatus = 'pending'; },
    sendRequestRemoveProductMenuTemplateSuccess: (state) => { state.removeProductMenuTemplateStatus = 'resolved'; },
    sendRequestRemoveProductMenuTemplateError: (state) => { state.removeProductMenuTemplateStatus = 'rejected'; },
    clearRemoveProductMenuTemplateStatus: (state) => { state.removeProductMenuTemplateStatus = null; },
  
    //Modifier Groups
    sendRequestModifierGroupsGet: (state) => { state.modifierGroupsGetRequestStatus = 'pending'; },
    sendRequestModifierGroupsGetSuccess: (state) => { state.modifierGroupsGetRequestStatus = 'resolved'; },
    sendRequestModifierGroupsGetError: (state) => { state.modifierGroupsGetRequestStatus = 'rejected'; },
    clearModifierGroupsGetStatus: (state) => { state.modifierGroupsGetRequestStatus = null; },
    setModifierGroups: (state, action) => {
      state.modifierGroups = action.payload;
    },
    setCurrentModifierGroupId: (state, action) => {
      state.currentModifierGroupId = action.payload;
    },
    sendRequestModifierGroupsCreate: (state) => { state.modifierGroupsCreateRequestStatus = 'pending'; },
    sendRequestModifierGroupsCreateSuccess: (state) => { state.modifierGroupsCreateRequestStatus = 'resolved'; },
    sendRequestModifierGroupsCreateError: (state) => { state.modifierGroupsCreateRequestStatus = 'rejected'; },
    clearModifierGroupsCreateStatus: (state) => { state.modifierGroupsCreateRequestStatus = null; },
    sendRequestModifierGroupsUpdate: (state) => { state.modifierGroupsUpdateRequestStatus = 'pending'; },
    sendRequestModifierGroupsUpdateSuccess: (state) => { state.modifierGroupsUpdateRequestStatus = 'resolved'; },
    sendRequestModifierGroupsUpdateError: (state) => { state.modifierGroupsUpdateRequestStatus = 'rejected'; },
    clearModifierGroupsUpdateStatus: (state) => { state.modifierGroupsUpdateRequestStatus = null; },
    sendRequestModifierGroupsDelete: (state) => { state.modifierGroupsDeleteRequestStatus = 'pending'; },
    sendRequestModifierGroupsDeleteSuccess: (state) => { state.modifierGroupsDeleteRequestStatus = 'resolved'; },
    sendRequestModifierGroupsDeleteError: (state) => { state.modifierGroupsDeleteRequestStatus = 'rejected'; },
    clearModifierGroupsDeleteStatus: (state) => { state.modifierGroupsDeleteRequestStatus = null; },

    //Modifier Groups Products
    sendRequestModifierGroupsProductsGet: (state) => { state.modifierGroupsProductsGetRequestStatus = 'pending'; },
    sendRequestModifierGroupsProductsGetSuccess: (state) => { state.modifierGroupsProductsGetRequestStatus = 'resolved'; },
    sendRequestModifierGroupsProductsGetError: (state) => { state.modifierGroupsProductsGetRequestStatus = 'rejected'; },
    clearModifierGroupsProductsGetStatus: (state) => { state.modifierGroupsProductsGetRequestStatus = null; },
    setModifierGroupsProducts: (state, action) => {
      state.modifierGroupsProducts = action.payload;
    },
    sendRequestModifierGroupsProductsCreate: (state) => { state.modifierGroupsProductCreateRequestStatus = 'pending'; },
    sendRequestModifierGroupsProductsCreateSuccess: (state) => { state.modifierGroupsProductCreateRequestStatus = 'resolved'; },
    sendRequestModifierGroupsProductsCreateError: (state) => { state.modifierGroupsProductCreateRequestStatus = 'rejected'; },
    clearModifierGroupsProductsCreateStatus: (state) => { state.modifierGroupsProductCreateRequestStatus = null; },
    sendRequestModifierGroupsProductUpdate: (state) => { state.modifierGroupsProductUpdateRequestStatus = 'pending'; },
    sendRequestModifierGroupsProductUpdateSuccess: (state) => { state.modifierGroupsProductUpdateRequestStatus = 'resolved'; },
    sendRequestModifierGroupsProductUpdateError: (state) => { state.modifierGroupsProductUpdateRequestStatus = 'rejected'; },
    clearModifierGroupsProductUpdateStatus: (state) => { state.modifierGroupsProductUpdateRequestStatus = null; },
    sendRequestModifierGroupsProductDelete: (state) => { state.modifierGroupsProductDeleteRequestStatus = 'pending'; },
    sendRequestModifierGroupsProductDeleteSuccess: (state) => { state.modifierGroupsProductDeleteRequestStatus = 'resolved'; },
    sendRequestModifierGroupsProductDeleteError: (state) => { state.modifierGroupsProductDeleteRequestStatus = 'rejected'; },
    clearModifierGroupsProductDeleteStatus: (state) => { state.modifierGroupsProductDeleteRequestStatus = null; },
  },
});

export const {
  //Races For Days
  setIsOpenRacesDrawer,
  setIsOpenTemplatesDrawer,
  sendRequestRacesForDays,
  sendRequestRacesForDaysSuccess,
  sendRequestRacesForDaysError,
  clearRacesForDaysStatus,
  setRacesForDaysData,
  setSelectedMenuDate,
  setSelectedMenuRace,
  updateRacesForDaysData,

  //Menu
  sendRequestCreateMenu,
  sendRequestCreateMenuSuccess,
  sendRequestCreateMenuError,
  clearCreateMenuStatus,
  sendRequestEnableMenu,
  sendRequestEnableMenuSuccess,
  sendRequestEnableMenuError,
  clearEnableMenuStatus,
  sendRequestDisableMenu,
  sendRequestDisableMenuSuccess,
  sendRequestDisableMenuError,
  clearDisableMenuStatus,

  //Products
  sendRequestMenuAllProducts,
  sendRequestMenuAllProductsSuccess,
  sendRequestMenuAllProductsError,
  clearMenuAllProductsStatus,
  setMenuAllProductsData,
  updateMenuAllProductsData,
  updateMenuProductDataById,
  setLastCreatedTemplateId,
  sendRequestAddProductInMenu,
  sendRequestAddProductInMenuSuccess,
  sendRequestAddProductInMenuError,
  clearAddProductInMenuStatus,
  sendRequestEnableProductInMenu,
  sendRequestEnableProductInMenuSuccess,
  sendRequestEnableProductInMenuError,
  clearEnableProductInMenuStatus,
  sendRequestDisableProductInMenu,
  sendRequestDisableProductInMenuSuccess,
  sendRequestDisableProductInMenuError,
  clearDisableProductInMenuStatus,
  sendRequestUpdateProductInMenu,
  sendRequestUpdateProductInMenuSuccess,
  sendRequestUpdateProductInMenuError,
  clearUpdateProductInMenuStatus,

  //Menu Template
  sendRequestMenuTemplates,
  sendRequestMenuTemplatesSuccess,
  sendRequestMenuTemplatesError,
  clearMenuTemplatesStatus,
  setMenuTemplatesData,
  setSelectedTemplate,
  updateSelectedTemplate,
  sendRequestCreateMenuTemplate,
  sendRequestCreateMenuTemplateSuccess,
  sendRequestCreateMenuTemplateError,
  clearCreateMenuTemplateStatus,
  sendRequestUpdateMenuTemplate,
  sendRequestUpdateMenuTemplateSuccess,
  sendRequestUpdateMenuTemplateError,
  clearUpdateMenuTemplateStatus,
  sendRequestDeleteMenuTemplate,
  sendRequestDeleteMenuTemplateSuccess,
  sendRequestDeleteMenuTemplateError,
  clearDeleteMenuTemplateStatus,
  sendRequestCopyMenuTemplate,
  sendRequestCopyMenuTemplateSuccess,
  sendRequestCopyMenuTemplateError,
  clearCopyMenuTemplateStatus,
  sendRequestTransferMenuTemplate,
  sendRequestTransferMenuTemplateSuccess,
  sendRequestTransferMenuTemplateError,
  clearTransferMenuTemplateStatus,

  //Menu Template Products
  sendRequestMenuTemplateProducts,
  sendRequestMenuTemplateProductsSuccess,
  sendRequestMenuTemplateProductsError,
  clearMenuTemplatesProductsStatus,
  setMenuTemplateProductsData,
  updateMenuTemplateProducts,
  sendRequestAddProductMenuTemplate,
  sendRequestAddProductMenuTemplateSuccess,
  sendRequestAddProductMenuTemplateError,
  clearAddProductMenuTemplateStatus,
  sendRequestUpdateProductMenuTemplate,
  sendRequestUpdateProductMenuTemplateSuccess,
  sendRequestUpdateProductMenuTemplateError,
  clearUpdateProductMenuTemplateStatus,
  sendRequestRemoveProductMenuTemplate,
  sendRequestRemoveProductMenuTemplateSuccess,
  sendRequestRemoveProductMenuTemplateError,
  clearRemoveProductMenuTemplateStatus,

  //Modifier Groups
  sendRequestModifierGroupsGet,
  sendRequestModifierGroupsGetSuccess,
  sendRequestModifierGroupsGetError,
  clearModifierGroupsGetStatus,
  setModifierGroups,
  setCurrentModifierGroupId,
  sendRequestModifierGroupsCreate,
  sendRequestModifierGroupsCreateSuccess,
  sendRequestModifierGroupsCreateError,
  clearModifierGroupsCreateStatus,
  sendRequestModifierGroupsUpdate,
  sendRequestModifierGroupsUpdateSuccess,
  sendRequestModifierGroupsUpdateError,
  clearModifierGroupsUpdateStatus,
  sendRequestModifierGroupsDelete,
  sendRequestModifierGroupsDeleteSuccess,
  sendRequestModifierGroupsDeleteError,
  clearModifierGroupsDeleteStatus,

  //Modifier Groups Products
  sendRequestModifierGroupsProductsGet,
  sendRequestModifierGroupsProductsGetSuccess,
  sendRequestModifierGroupsProductsGetError,
  clearModifierGroupsProductsGetStatus,
  setModifierGroupsProducts,
  sendRequestModifierGroupsProductsCreate,
  sendRequestModifierGroupsProductsCreateSuccess,
  sendRequestModifierGroupsProductsCreateError,
  clearModifierGroupsProductsCreateStatus,
  sendRequestModifierGroupsProductUpdate,
  sendRequestModifierGroupsProductUpdateSuccess,
  sendRequestModifierGroupsProductUpdateError,
  clearModifierGroupsProductUpdateStatus,
  sendRequestModifierGroupsProductDelete,
  sendRequestModifierGroupsProductDeleteSuccess,
  sendRequestModifierGroupsProductDeleteError,
  clearModifierGroupsProductDeleteStatus,
} = kitchenMenuSlice.actions;

export default kitchenMenuSlice.reducer;
