import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  addressesSearchRequestStatus: null,
  addresses: [],
  totalAddresses: 0,
  currentAddressesPage: 1,
  addressesCreateRequestStatus: null,
  addressesUpdateRequestStatus: null,
  addressesDeleteRequestStatus: null,
};

const addressesSearchSlice = createSlice({
  name: 'addressesSearch',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    sendRequestAddressesSearch: (state) => { state.addressesSearchRequestStatus = 'pending'; },
    sendRequestAddressesSearchSuccess: (state) => { state.addressesSearchRequestStatus = 'resolved'; },
    sendRequestAddressesSearchError: (state) => { state.addressesSearchRequestStatus = 'rejected'; },
    clearAddressesSearchStatus: (state) => { state.addressesSearchRequestStatus = null; },
    setAddresses: (state, action) => {
      const data = action.payload;

      state.totalAddresses = data.countAddresses;
      state.addresses = data.addresses;
    },
    setCurrentAddressesPage: (state, action) => {
      state.currentAddressesPage = action.payload;
    },

    sendRequestAddressesCreate: (state) => { state.addressesCreateRequestStatus = 'pending'; },
    sendRequestAddressesCreateSuccess: (state) => { state.addressesCreateRequestStatus = 'resolved'; },
    sendRequestAddressesCreateError: (state) => { state.addressesCreateRequestStatus = 'rejected'; },
    clearAddressesCreateStatus: (state) => { state.addressesCreateRequestStatus = null; },

    sendRequestAddressesUpdate: (state) => { state.addressesUpdateRequestStatus = 'pending'; },
    sendRequestAddressesUpdateSuccess: (state) => { state.addressesUpdateRequestStatus = 'resolved'; },
    sendRequestAddressesUpdateError: (state) => { state.addressesUpdateRequestStatus = 'rejected'; },
    clearAddressesUpdateStatus: (state) => { state.addressesUpdateRequestStatus = null; },

    sendRequestAddressesDelete: (state) => { state.addressesDeleteRequestStatus = 'pending'; },
    sendRequestAddressesDeleteSuccess: (state) => { state.addressesDeleteRequestStatus = 'resolved'; },
    sendRequestAddressesDeleteError: (state) => { state.addressesDeleteRequestStatus = 'rejected'; },
    clearAddressesDeleteStatus: (state) => { state.addressesDeleteRequestStatus = null; },
  },
});

export const {
  sendRequestAddressesSearch,
  sendRequestAddressesSearchSuccess,
  sendRequestAddressesSearchError,
  clearAddressesSearchStatus,
  setAddresses,
  setCurrentAddressesPage,

  sendRequestAddressesCreate,
  sendRequestAddressesCreateSuccess,
  sendRequestAddressesCreateError,
  clearAddressesCreateStatus,

  sendRequestAddressesUpdate,
  sendRequestAddressesUpdateSuccess,
  sendRequestAddressesUpdateError,
  clearAddressesUpdateStatus,

  sendRequestAddressesDelete,
  sendRequestAddressesDeleteSuccess,
  sendRequestAddressesDeleteError,
  clearAddressesDeleteStatus,
} = addressesSearchSlice.actions;

export default addressesSearchSlice.reducer;