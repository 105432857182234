import React, { useState } from 'react';
import { Table, Modal, Form, Input, Popconfirm, Button } from 'antd';
import styled from 'styled-components';
import { ReactComponent as IconEdit } from '../../../../../assets/icons/icon-edit.svg';
import { ReactComponent as IconClose } from '../../../../../assets/icons/icon-close.svg';

const { Search } = Input;

const AccessListComponent = ({ accessComponents, onUpdateComponent, onDeleteComponent }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const accessComponentsWithKey = accessComponents.map(item => ({...item, key: item.id}))
  const filteredAccessComponents = accessComponentsWithKey?.filter((accessComponent) =>
    accessComponent.keyComponent.toLowerCase().includes(searchTerm.toLowerCase()) ||
    accessComponent.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    accessComponent.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const columns = [
    {
      title: 'Ключ',
      dataIndex: 'keyComponent',
      key: 'keyComponent',
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: '',
      key: 'actions',
      render: (_, record) => (
        <AccessAction
          accessComponent={record}
          onUpdateComponent={onUpdateComponent}
          onDeleteComponent={onDeleteComponent}
        />
      ),
    },
  ];

  return (
    <>
      <Search
        placeholder="Поиск по названию или описанию"
        onChange={(e) => handleSearch(e.target.value)}
        style={{ margin: '20px 0' }}
      />
      <Table
        dataSource={filteredAccessComponents}
        columns={columns}
        size="small"
        pagination={{
          defaultPageSize: 50,
        }}
      />
    </>
  );
};

export default AccessListComponent;

const AccessAction = ({accessComponent, onUpdateComponent, onDeleteComponent}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [keyComponent, setKeyComponent] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleEdit = () => {
    setKeyComponent(accessComponent.keyComponent);
    setName(accessComponent.name);
    setDescription(accessComponent.description);
    setIsModalVisible(true);
  }
  
  const handleSuccess = () => {
    onUpdateComponent(accessComponent.id, keyComponent, name, description);
    setIsModalVisible(false);
  }

  const handleModalCancel = () => {
    setKeyComponent('');
    setName('');
    setDescription('');
    setIsModalVisible(false);
  };

  const handleDelete = () => {
    onDeleteComponent(accessComponent.id);
  };

  return (
    <Action>
      <Button onClick={handleEdit}><IconEdit/></Button>
      <Modal
        title="Редактирование компонента"
        open={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        <Form initialValues={{ keyComponent: accessComponent.keyComponent, name: accessComponent.name, description: accessComponent.description }}>
          <Form.Item label="Ключ" name="keyComponent">
            <Input value={keyComponent} onChange={(e) => setKeyComponent(e.target.value)} />
          </Form.Item>
          <Form.Item label="Название" name="name">
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </Form.Item>
          <Form.Item label="Описание" name="description">
            <Input value={description} onChange={(e) => setDescription(e.target.value)} />
          </Form.Item>
          <Button type="primary" onClick={handleSuccess}>
            Сохранить
          </Button>
        </Form>
      </Modal>
      <Popconfirm
        title="Удаление компонента доступа!"
        description={`Вы действительно хотите удалить компонента доступа ${accessComponent.name}`}
        okText="Да"
        cancelText="Нет"
        placement="left"
        onConfirm={handleDelete}
      >
        <Button danger>Удалить</Button>
      </Popconfirm>
    </Action>
  );
};
const Action = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`