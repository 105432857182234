import React from 'react';
import { Dropdown, Button } from 'antd';
import styled from 'styled-components';

const MenuTemplateTopRightDropdown = ({selectedTemplate, onDeleteMenuTemplate}) => {
  const handleDeleteMenuTemplate = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      menuTemplateId: selectedTemplate.id,
    }

    onDeleteMenuTemplate(requestData);
  }

  const items = [
    {
      key: '1',
      label: (<a onClick={handleDeleteMenuTemplate}>Удалить шаблон</a>),
    },
  ];

  return (
    <Dropdown menu={{items}} placement="bottomRight" trigger={['click']}>
      <StyledButton type="primary">•••</StyledButton>
    </Dropdown>
  );
};

export default MenuTemplateTopRightDropdown;

const StyledButton = styled(Button)`
  /* position: absolute;
  z-index: 600;
  top: 40px;
  right: 10px; */
  margin: 5px 0 0 auto;
`;