import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  kitchenActWorksGetRequestStatus: null,
  kitchenActWorks: [],
  kitchenActWorksCreateRequestStatus: null,
  kitchenActWorksUpdateRequestStatus: null,
  kitchenActWorksDeleteRequestStatus: null,

  kitchenActWorksSignedStaffSORequestStatus: null,
  kitchenActWorksSignedStaffKitchenRequestStatus: null,
  kitchenActWorksSetStatusPaymentKitchenRequestStatus: null,
};

const kitchenFinanceSlice = createSlice({
  name: 'kitchenFinance',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    sendRequestKitchenActWorksGet: (state) => { state.kitchenActWorksGetRequestStatus = 'pending'; },
    sendRequestKitchenActWorksGetSuccess: (state) => { state.kitchenActWorksGetRequestStatus = 'resolved'; },
    sendRequestKitchenActWorksGetError: (state) => { state.kitchenActWorksGetRequestStatus = 'rejected'; },
    clearKitchenActWorksGetStatus: (state) => { state.kitchenActWorksGetRequestStatus = null; },
    setKitchenActWorks: (state, action) => {
      state.kitchenActWorks = action.payload;
    },
    sendRequestKitchenActWorksCreate: (state) => { state.kitchenActWorksCreateRequestStatus = 'pending'; },
    sendRequestKitchenActWorksCreateSuccess: (state) => { state.kitchenActWorksCreateRequestStatus = 'resolved'; },
    sendRequestKitchenActWorksCreateError: (state) => { state.kitchenActWorksCreateRequestStatus = 'rejected'; },
    clearKitchenActWorksCreateStatus: (state) => { state.kitchenActWorksCreateRequestStatus = null; },
    sendRequestKitchenActWorksUpdate: (state) => { state.kitchenActWorksUpdateRequestStatus = 'pending'; },
    sendRequestKitchenActWorksUpdateSuccess: (state) => { state.kitchenActWorksUpdateRequestStatus = 'resolved'; },
    sendRequestKitchenActWorksUpdateError: (state) => { state.kitchenActWorksUpdateRequestStatus = 'rejected'; },
    clearKitchenActWorksUpdateStatus: (state) => { state.kitchenActWorksUpdateRequestStatus = null; },
    sendRequestKitchenActWorksDelete: (state) => { state.kitchenActWorksDeleteRequestStatus = 'pending'; },
    sendRequestKitchenActWorksDeleteSuccess: (state) => { state.kitchenActWorksDeleteRequestStatus = 'resolved'; },
    sendRequestKitchenActWorksDeleteError: (state) => { state.kitchenActWorksDeleteRequestStatus = 'rejected'; },
    clearKitchenActWorksDeleteStatus: (state) => { state.kitchenActWorksDeleteRequestStatus = null; },

    sendRequestKitchenActWorksSignedStaffSO: (state) => { state.kitchenActWorksSignedStaffSORequestStatus = 'pending'; },
    sendRequestKitchenActWorksSignedStaffSOSuccess: (state) => { state.kitchenActWorksSignedStaffSORequestStatus = 'resolved'; },
    sendRequestKitchenActWorksSignedStaffSOError: (state) => { state.kitchenActWorksSignedStaffSORequestStatus = 'rejected'; },
    clearKitchenActWorksSignedStaffSOStatus: (state) => { state.kitchenActWorksSignedStaffSORequestStatus = null; },

    sendRequestKitchenActWorksSignedStaffKitchen: (state) => { state.kitchenActWorksSignedStaffKitchenRequestStatus = 'pending'; },
    sendRequestKitchenActWorksSignedStaffKitchenSuccess: (state) => { state.kitchenActWorksSignedStaffKitchenRequestStatus = 'resolved'; },
    sendRequestKitchenActWorksSignedStaffKitchenError: (state) => { state.kitchenActWorksSignedStaffKitchenRequestStatus = 'rejected'; },
    clearKitchenActWorksSignedStaffKitchenStatus: (state) => { state.kitchenActWorksSignedStaffKitchenRequestStatus = null; },

    sendRequestKitchenActWorksSetStatusPaymentKitchen: (state) => { state.kitchenActWorksSetStatusPaymentKitchenRequestStatus = 'pending'; },
    sendRequestKitchenActWorksSetStatusPaymentKitchenSuccess: (state) => { state.kitchenActWorksSetStatusPaymentKitchenRequestStatus = 'resolved'; },
    sendRequestKitchenActWorksSetStatusPaymentKitchenError: (state) => { state.kitchenActWorksSetStatusPaymentKitchenRequestStatus = 'rejected'; },
    clearKitchenActWorksSetStatusPaymentKitchenStatus: (state) => { state.kitchenActWorksSetStatusPaymentKitchenRequestStatus = null; },
  },
});

export const {
  sendRequestKitchenActWorksGet,
  sendRequestKitchenActWorksGetSuccess,
  sendRequestKitchenActWorksGetError,
  clearKitchenActWorksGetStatus,
  setKitchenActWorks,
  sendRequestKitchenActWorksCreate,
  sendRequestKitchenActWorksCreateSuccess,
  sendRequestKitchenActWorksCreateError,
  clearKitchenActWorksCreateStatus,
  sendRequestKitchenActWorksUpdate,
  sendRequestKitchenActWorksUpdateSuccess,
  sendRequestKitchenActWorksUpdateError,
  clearKitchenActWorksUpdateStatus,
  sendRequestKitchenActWorksDelete,
  sendRequestKitchenActWorksDeleteSuccess,
  sendRequestKitchenActWorksDeleteError,
  clearKitchenActWorksDeleteStatus,

  sendRequestKitchenActWorksSignedStaffSO,
  sendRequestKitchenActWorksSignedStaffSOSuccess,
  sendRequestKitchenActWorksSignedStaffSOError,
  clearKitchenActWorksSignedStaffSOStatus,

  sendRequestKitchenActWorksSignedStaffKitchen,
  sendRequestKitchenActWorksSignedStaffKitchenSuccess,
  sendRequestKitchenActWorksSignedStaffKitchenError,
  clearKitchenActWorksSignedStaffKitchenStatus,

  sendRequestKitchenActWorksSetStatusPaymentKitchen,
  sendRequestKitchenActWorksSetStatusPaymentKitchenSuccess,
  sendRequestKitchenActWorksSetStatusPaymentKitchenError,
  clearKitchenActWorksSetStatusPaymentKitchenStatus,
} = kitchenFinanceSlice.actions;

export default kitchenFinanceSlice.reducer;
