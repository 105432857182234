import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DatePicker, Select, Table, Tabs, Button, Typography } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import KitchenSelectorComponent from '../../components/KitchenSelectorComponent';
import PrintTable from '../../../../components/PrintTable';

const { Title, Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const OrdersPage = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [orders, setOrders] = useState([]);
  const [productionOrders, setProductionOrders] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch orders and production orders from API or store
    // Example:
    // dispatch(fetchOrders(selectedDate, selectedFlight));
  }, [selectedDate, selectedFlight, dispatch]);

  useEffect(() => {
    // Фейковые данные для заказов
    const fakeOrders = [
      {
        key: '1',
        orderNumber: 'P-34719',
        creationDate: '20-05-2024 12:34',
        orderComposition: 'Пицца, Кока-Кола',
        orderAmount: '1500 руб',
      },
      {
        key: '2',
        orderNumber: 'N-21543',
        creationDate: '20-05-2024 12:34',
        orderComposition: 'Салат, Суп',
        orderAmount: '800 руб',
      },
      // Добавьте больше фейковых данных при необходимости
    ];

    // Фейковые данные для производственных заказов
    const fakeProductionOrders = [
      {
        key: '1',
        productName: 'Пицца',
        description: 'Большая пицца с сыром',
        totalQuantity: '10 шт',
      },
      {
        key: '2',
        productName: 'Кока-Кола',
        description: 'Бутылка 1л',
        totalQuantity: '5 шт',
      },
      // Добавьте больше фейковых данных при необходимости
    ];

    setOrders(fakeOrders);
    setProductionOrders(fakeProductionOrders);
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date ? date.format('YYYY-MM-DD') : null);
  };

  const handleFlightChange = (value) => {
    setSelectedFlight(value);
  };

  const handleExportPDF = () => {
    // Handle PDF export for selected orders
    alert('В дальнейшем когда раздел будет готов, тут можно будет печатать заказы');
  };

  const orderColumns = [
    {
      title: '№ заказа',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
    },
    {
      title: 'Дата и время создания заказа',
      dataIndex: 'creationDate',
      key: 'creationDate',
    },
    {
      title: 'Состав заказа',
      dataIndex: 'orderComposition',
      key: 'orderComposition',
    },
    {
      title: 'Сумма заказа',
      dataIndex: 'orderAmount',
      key: 'orderAmount',
    },
  ];

  const productionOrderColumns = [
    {
      title: 'Название товара',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Количество позиций во всех заказах',
      dataIndex: 'totalQuantity',
      key: 'totalQuantity',
    },
  ];

  return (
    <>
      <Title level={2}>Заказы</Title>
      <FlexContainer>
        <KitchenSelectorComponent/>
        <DatePicker onChange={handleDateChange}/>
        <Select
          placeholder="Выберите рейс"
          style={{ width: 200 }}
          onChange={handleFlightChange}
        >
          <Option value="flight1">Рейс 1</Option>
          <Option value="flight2">Рейс 2</Option>
          {/* Add more options as needed */}
        </Select>
      </FlexContainer>
      <Summary>
        <Text>Заказов 50шт. на сумму 40901руб</Text>
      </Summary>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Заказы" key="1">
          <PrintTable>
            <Table
              columns={orderColumns}
              dataSource={orders}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedRowKeys,
                onChange: (selectedRowKeys) => {
                  setSelectedRowKeys(selectedRowKeys);
                },
              }}
              rowKey="orderNumber"
            />
          </PrintTable>
          <Button type="primary" onClick={handleExportPDF}>
            Экспорт в PDF
          </Button>
        </TabPane>
        <TabPane tab="Производственные заказы" key="2">
          <PrintTable>
            <Table
              columns={productionOrderColumns}
              dataSource={productionOrders}
              rowKey="productName"
            />
          </PrintTable>
        </TabPane>
      </Tabs>
    </>
  );
};

export default OrdersPage;

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 20px;
`;

const Summary = styled.div`
  margin-bottom: 20px;
`;
