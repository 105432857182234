import apiInstance from '../../../../../api';
import {call, put, select} from 'redux-saga/effects';
import {
  //Kitchen
  sendRequestKitchensSuccess,
  sendRequestKitchensError,
  setKitchensData,
  updateSelectKitchen,

  //UserKitchenCreate
  sendRequestUserKitchenCreateSuccess,
  sendRequestUserKitchenCreateError,

  //CategoriesCatalog
  sendRequestCategoriesCatalogGetSuccess,
  sendRequestCategoriesCatalogGetError,
  setCategoriesCatalog,
} from '../../../../reducers/utils/kitchenReducers/kitchenReducer';
import { setMessagePopupContent, selectCommonState } from '../../../../reducers/utils/commonReducer';

//Kitchen
export function* handleGetKitchens(action) {
  const api = '/kitchens/getAvailableKitchens?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload;
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestKitchensSuccess());
      yield put(setKitchensData(response.data.data));
      yield put(updateSelectKitchen(response.data.data.kitchens));
    } else {
      yield put(sendRequestKitchensError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestKitchensError());
  }
}

//UserKitchenCreate
export function* handleCreateUserKitchen(action) {
  const api = '/kitchens/userKitchenCreation?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestUserKitchenCreateSuccess());
    } else {
      yield put(sendRequestUserKitchenCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestUserKitchenCreateError());
  }
}

//CategoriesCatalog
export function* handleGetCategoriesCatalog(action) {
  const api = '/categories/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload;
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestCategoriesCatalogGetSuccess());
      yield put(setCategoriesCatalog(response.data.data.categories));
    } else {
      yield put(sendRequestCategoriesCatalogGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestCategoriesCatalogGetError());
  }
}
