import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestFinancialSettlementGet } from "../../../../redux/reducers/utils/moderationReducers/financialSettlementReducer";
import { DatePicker, Select, Typography, Row, Col, Steps, Divider, Card } from 'antd';
import {  } from '@ant-design/icons';
import styled from 'styled-components';
import KitchenSelectorComponent from '../../../kitchenModule/components/KitchenSelectorComponent';
import FinancialSettlementTable from "./components/FinancialSettlementTable";
import FinancialSettlementFilters from "./components/FinancialSettlementFilters";
import DraggableWindow from "../../../../components/DraggableWindow";
import ActiveOrdersList from "./components/ActiveOrdersList";
import Loader from "../../../../components/Loader";

const { Title, Text } = Typography;
const settlements = [
  {
    id: 3215,
    contract: "Кушать подано, ООО “Комфорт” Дог №22 от 12.03.2011",
    payments: [
      {
        id: 1,
        date: "01.05.2024",
        amount: "500",
        statusId: 3,
        statusName: "Оплачен",
        idActWork: 12313,
        actWorkName: "Акт №2133 от 12.05.2024",
        paymentOrder: "Платёжное поручение №209 от 18.01.2024",
        contractId: 3215,
        contract: "Кушать подано, ООО “Комфорт” Дог №22 от 12.03.2011",
      },
      {
        id: 2,
        date: "02.05.2024",
        amount: "540",
        statusId: 3,
        statusName: "Оплачен",
        idActWork: 14231,
        actWorkName: "Акт №2133 от 12.05.2024",
        paymentOrder: "Платёжное поручение №209 от 18.01.2024",
        contractId: 3215,
        contract: "Кушать подано, ООО “Комфорт” Дог №22 от 12.03.2011",
      },
      {
        id: 3,
        date: "03.05.2024",
        amount: "510",
        statusId: 2,
        statusName: "Не оплачен",
        idActWork: 1242331,
        actWorkName: "Акт №2133 от 12.05.2024",
        paymentOrder: "Платёжное поручение №209 от 18.01.2024",
        contractId: 3215,
        contract: "Кушать подано, ООО “Комфорт” Дог №22 от 12.03.2011",
      },
      {
        id: 4,
        date: "04.05.2024",
        amount: "490",
        statusId: 4,
        statusName: "Ожидание оплаты",
        idActWork: 1252331,
        actWorkName: "Акт №2133 от 12.05.2024",
        paymentOrder: "Платёжное поручение №209 от 18.01.2024",
        contractId: 3215,
        contract: "Кушать подано, ООО “Комфорт” Дог №22 от 12.03.2011",
      },
      {
        id: 5,
        date: "05.05.2024",
        amount: "500",
        statusId: 1,
        statusName: "Ожидание оплаты",
        idActWork: 12231431,
        actWorkName: "Акт №2133 от 12.05.2024",
        paymentOrder: "Платёжное поручение №209 от 18.01.2024",
        contractId: 3215,
        contract: "Кушать подано, ООО “Комфорт” Дог №22 от 12.03.2011",
      },
      {
        id: 6,
        date: "06.05.2024",
        amount: "510",
        statusId: 1,
        statusName: "Ожидание оплаты",
        idActWork: 1264531,
        actWorkName: "Акт №2133 от 12.05.2024",
        paymentOrder: "Платёжное поручение №209 от 18.01.2024",
        contractId: 3215,
        contract: "Кушать подано, ООО “Комфорт” Дог №22 от 12.03.2011",
      },
      {
        id: 7,
        date: "07.05.2024",
        amount: "510",
        statusId: 1,
        statusName: "Ожидание оплаты",
        idActWork: 12375681,
        actWorkName: "Акт №2133 от 12.05.2024",
        paymentOrder: "Платёжное поручение №209 от 18.01.2024",
        contractId: 3215,
        contract: "Кушать подано, ООО “Комфорт” Дог №22 от 12.03.2011",
      },
      {
        id: 8,
        date: "08.05.2024",
        amount: "570",
        statusId: 1,
        statusName: "Ожидание оплаты",
        idActWork: 1239781,
        actWorkName: "Акт №2133 от 12.05.2024",
        paymentOrder: "Платёжное поручение №209 от 18.01.2024",
        contractId: 3215,
        contract: "Кушать подано, ООО “Комфорт” Дог №22 от 12.03.2011",
      },
      {
        id: 9,
        date: "09.05.2024",
        amount: "500",
        statusId: 1,
        statusName: "Ожидание оплаты",
        idActWork: 1209731,
        actWorkName: "Акт №2133 от 12.05.2024",
        paymentOrder: "Платёжное поручение №209 от 18.01.2024",
        contractId: 3215,
        contract: "Кушать подано, ООО “Комфорт” Дог №22 от 12.03.2011",
      },
      {
        id: 10,
        date: "10.05.2024",
        amount: "450",
        statusId: 1,
        statusName: "Ожидание оплаты",
        idActWork: 1234671,
        actWorkName: "Акт №2133 от 12.05.2024",
        paymentOrder: "Платёжное поручение №209 от 18.01.2024",
        contractId: 3215,
        contract: "Кушать подано, ООО “Комфорт” Дог №22 от 12.03.2011",
      },
    ]
  },
  {
    id: 3345,
    contract: "ГОСТ, ООО “Гост” Дог №276 от 17.06.2010",
    payments: [
      {
        id: 11,
        date: "01.05.2024",
        amount: "500",
        statusId: 3,
        statusName: "Оплачен",
        idActWork: 1352231,
        actWorkName: "Акт №2133 от 12.05.2024",
        paymentOrder: "Платёжное поручение №209 от 18.01.2024",
        contractId: 3345,
        contract: "ГОСТ, ООО “Гост” Дог №276 от 17.06.2010",
      },
      {
        id: 12,
        date: "02.05.2024",
        amount: "540",
        statusId: 3,
        statusName: "Оплачен",
        idActWork: 126331,
        actWorkName: "Акт №2133 от 12.05.2024",
        paymentOrder: "Платёжное поручение №209 от 18.01.2024",
        contractId: 3345,
        contract: "ГОСТ, ООО “Гост” Дог №276 от 17.06.2010",
      },
      {
        id: 13,
        date: "03.05.2024",
        amount: "510",
        statusId: 1,
        statusName: "Ожидание оплаты",
        idActWork: 12124531,
        actWorkName: "Акт №2133 от 12.05.2024",
        paymentOrder: "Платёжное поручение №209 от 18.01.2024",
        contractId: 3345,
        contract: "ГОСТ, ООО “Гост” Дог №276 от 17.06.2010",
      },
      {
        id: 14,
        date: "04.05.2024",
        amount: "440",
        statusId: 1,
        statusName: "Ожидание оплаты",
        idActWork: 1633231,
        actWorkName: "Акт №2133 от 12.05.2024",
        paymentOrder: "Платёжное поручение №209 от 18.01.2024",
        contractId: 3345,
        contract: "ГОСТ, ООО “Гост” Дог №276 от 17.06.2010",
      },
    ]
  }
]

const FinancialSettlementPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const {
    financialSettlementGetRequestStatus,
    financialSettlement,
  } = useSelector((store) => store.financialSettlement);
  const dispatch = useDispatch();
  const [activeOrders, setActiveOrders] = useState([]);

  const handleSearchSettlement = (data) => {
    const requestData = {
      token: localStorage.getItem('token'),
      ...data,
    }

    dispatch(sendRequestFinancialSettlementGet(requestData));
  }

  const handleCellClick = (payment) => {console.log(payment);
    if (!payment) return;
  
    const payments = Array.isArray(payment) ? payment : [payment];
  
    setActiveOrders((prevActiveCells) => {
      let newActiveCells = [...prevActiveCells];
  
      if (payments.every(item => item.status === 1)) {
        payments.forEach(paymentItem => {
          if (paymentItem.status === 1) {
            const isActive = newActiveCells.some(cell => cell.idActWork === paymentItem.idActWork);
    
            if (isActive) {
              newActiveCells = newActiveCells.filter(cell => cell.idActWork !== paymentItem.idActWork);
            } else {
              newActiveCells.push(paymentItem);
            }
          }
        });
      }
  
      return newActiveCells;
    });
  };

  const handleSubmitActiveOrders = (data) => {
    console.log(data);
  }

  const handleResetActiveOrders = () => {
    setActiveOrders([]);
  }

  return(
    <>
      {(
        financialSettlementGetRequestStatus === 'pending'
      ) && <Loader/>}

      <Title level={2}>Расчёт с кухнями</Title>
      <FiltersWrapper>
        <FinancialSettlementFilters onSearch={handleSearchSettlement}/>
      </FiltersWrapper>

      <FinancialSettlementTable
        dataSource={financialSettlement}
        activeOrders={activeOrders}
        cellClick={handleCellClick}
        resetActiveOrders={handleResetActiveOrders}
      />

      {activeOrders.length > 0 && (
        <DraggableWindow>
          <ActiveOrdersList
            activeOrders={activeOrders}
            submit={handleSubmitActiveOrders}
            cancel={handleResetActiveOrders}
          />
        </DraggableWindow>
      )}
    </>
  )
}

export default FinancialSettlementPage;

const FiltersWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 16px;
`;
