import { takeLatest } from 'redux-saga/effects';
import {
  //Categories
  handleGetCategories,
  handleSortCategories,

  //Products
  handleGetProducts,
  handleGetSearchProducts,
  handleCreateProducts,
  handleUpdateProducts,
  handleDeleteProducts,
  handleProductChangeModeration,
  handleDeleteProductsImage,
} from './kitchenAssortmentHandler';
import {
  //Categories
  sendRequestCategoriesGet,
  sendRequestCategoriesSort,

  //Products
  sendRequestProductsGet,
  sendRequestSearchProductsGet,
  sendRequestProductsCreate,
  sendRequestProductsUpdate,
  sendRequestProductsDelete,
  sendRequestProductChangeModeration,
  sendRequestProductsDeleteImage,
} from '../../../../reducers/utils/kitchenReducers/kitchenAssortmentReducer';

export function* kitchenAssortmentWatcherSaga() {
  //Categories
  yield takeLatest(sendRequestCategoriesGet.type, handleGetCategories);
  yield takeLatest(sendRequestCategoriesSort.type, handleSortCategories);

  //Products
  yield takeLatest(sendRequestProductsGet.type, handleGetProducts);
  yield takeLatest(sendRequestSearchProductsGet.type, handleGetSearchProducts);
  yield takeLatest(sendRequestProductsCreate.type, handleCreateProducts);
  yield takeLatest(sendRequestProductsUpdate.type, handleUpdateProducts);
  yield takeLatest(sendRequestProductsDelete.type, handleDeleteProducts);
  yield takeLatest(sendRequestProductChangeModeration.type, handleProductChangeModeration);
  yield takeLatest(sendRequestProductsDeleteImage.type, handleDeleteProductsImage);
}