import { takeLatest } from 'redux-saga/effects';
import {
  //Search
  handleFinancialSettlement,
} from './financialSettlementHandler';
import {
  //Search
  sendRequestFinancialSettlementGet,
} from '../../../../reducers/utils/moderationReducers/financialSettlementReducer';

export function* financialSettlementWatcherSaga() {
  //Search
  yield takeLatest(sendRequestFinancialSettlementGet.type, handleFinancialSettlement);
}