import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentRoute } from '../../redux/reducers/utils/navReducer';

const PartnersModule = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  dispatch(setCurrentRoute('partners/statistics'));
  navigate('statistics');
}

export default PartnersModule;