import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestDeliveryServiceRaceKitchenTermsCreate,
  sendRequestDeliveryServiceRaceKitchenTermsUpdate,
  sendRequestDeliveryServiceRaceKitchenTermsDelete,
} from '../../../../../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Button, Table, Form, InputNumber } from 'antd';
import PrintTable from '../../../../../../../../../components/PrintTable';

const KitchenTermsComponent = () => {
  const { currentRaceKitchen } = useSelector((store) => store.deliveryServices);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const modifyRaceKitchens = currentRaceKitchen?.termsDelivery?.map(item => ({ ...item, key: item.id }));

  useEffect(() => {
    form.resetFields();
  }, [currentRaceKitchen]);

  const handleCellClick = (record) => {

  };

  const handleCreateTerm = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      kitchenRacePolygonId: currentRaceKitchen.kitchenRacePolygonId,
      serviceFee: values.serviceFee,
      minPrice: values.minPrice,
      deliveryPrice: values.deliveryPrice,
    }

    console.log(requestData);
    dispatch(sendRequestDeliveryServiceRaceKitchenTermsCreate(requestData));
  }

  const handleDeleteTerm = (id) => {
    const requestData = {
      token: localStorage.getItem('token'),
      deliveryPriceId: id,
    }

    console.log(requestData);
    dispatch(sendRequestDeliveryServiceRaceKitchenTermsDelete(requestData));
  }

  const columns = [
    {
      title: 'Доставка от',
      key: 'minPrice',
      dataIndex: 'minPrice',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Цена доставки',
      key: 'deliveryPrice',
      dataIndex: 'deliveryPrice',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: 'Сервисный сбор',
      key: 'serviceFee',
      dataIndex: 'serviceFee',
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => handleCellClick(record),
      }),
    },
    {
      title: '',
      key: 'action',
      render: (record) => {
        return (
          <Button onClick={() => handleDeleteTerm(record.id)}>Удалить</Button>
        )
      },
    },
  ];

  return (
    <>
      <Form
        form={form}
        onFinish={handleCreateTerm}
        initialValues={{ minPrice: 0, deliveryPrice: 0, serviceFee: 0 }}
        layout='inline'
        disabled={!currentRaceKitchen?.isEnabled}
      >
        <Form.Item name="minPrice">
          <InputNumber />
        </Form.Item>
        <Form.Item name="deliveryPrice">
          <InputNumber />
        </Form.Item>
        <Form.Item name="serviceFee">
          <InputNumber />
        </Form.Item>
        <Form.Item style={{marginLeft: 'auto'}}>
          <Button type="primary" htmlType="submit">Создать</Button>
        </Form.Item>
      </Form>
      <PrintTable>
        <Table
          dataSource={modifyRaceKitchens}
          columns={columns}
          size="small"
          pagination={false}
        />
      </PrintTable>
    </>
  );
};

export default KitchenTermsComponent;