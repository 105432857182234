import axios from 'axios';
import { store } from './redux/configureStore';
import { setAuth } from './redux/reducers/utils/authReducer';
import { setMessagePopupContent } from './redux/reducers/utils/commonReducer';

let baseURL = 'https://api-test.menuforme.online/api/v1/admin';

const apiInstance = axios.create({
  baseURL, //process.env.REACT_APP_SERVER_URL,
});

apiInstance.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => handleApiError(error)
);

const handleResponse = (response) => {
  const { result, data: { message } } = response.data;

  // Костыль для дефолтной роли. Плохо работает индикатор newUser от сервера. Старые роли без дефолтной роли. выдают false
  if (message !== 'Дефолтная роль не создана') {
    store.dispatch(setMessagePopupContent({ type: result ? 'success' : 'error', content: message }));
  }

  return response;
};

const handleApiError = (error) => {
  if (error.response) {
    const status = error.response.status;
    switch (status) {
      case 400:
        console.log('Некорректные данные!');
        store.dispatch(setMessagePopupContent({ type: 'error', content: 'Некорректные данные!' }));
        break;
      case 401:
        console.log('Не авторизован!');
        store.dispatch(setAuth(false));
        localStorage.removeItem('token');
        localStorage.removeItem('session');
        break;
      case 403:
        console.log('Не достаточно прав!');
        store.dispatch(setMessagePopupContent({ type: 'error', content: 'Не достаточно прав!' }));
        break;
      case 404:
        console.log('Не достаточно прав!');
        store.dispatch(setMessagePopupContent({ type: 'error', content: 'Api не найден!' }));
        break;
      default:
        console.log('Проблемы на сервере!');
        store.dispatch(setMessagePopupContent({ type: 'error', content: 'Проблемы на сервере!' }));
    }
  } else {
    console.log('Ошибка сети или другие проблемы!');
    store.dispatch(setMessagePopupContent({ type: 'error', content: 'Ошибка сети или другие проблемы!' }));
  }
  return Promise.reject(error);
};

// Функция для изменения базового URL
export const updateServerURL = (newURL) => {
  baseURL = newURL;
  apiInstance.defaults.baseURL = newURL;
};

// Универсальная функция для отправки запросов
export const apiRequest = async (method, url, data = {}, config = {}) => {
  try {
    const response = await apiInstance({
      method,
      url,
      data,
      ...config
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// Специфические методы для различных типов запросов
export const apiGet = (url, config = {}) => apiRequest('get', url, {}, config);
export const apiPost = (url, data, config = {}) => apiRequest('post', url, data, config);
export const apiPut = (url, data, config = {}) => apiRequest('put', url, data, config);
export const apiDelete = (url, config = {}) => apiRequest('delete', url, {}, config);

export default apiInstance;

