import { takeLatest } from 'redux-saga/effects';
import {
  handleSearchPromocodes,
  handleCreatePromocodes,
  handleUpdatePromocodes,
  handleDeletePromocodes,
} from './financePromocodesHandler';
import {
  sendRequestPromocodesSearch,
  sendRequestPromocodeCreate,
  sendRequestPromocodeUpdate,
  sendRequestPromocodeDelete,
} from '../../../../reducers/utils/financeReducers/financePromocodesReducer';

export function* financePromocodesWatcherSaga() {
  yield takeLatest(sendRequestPromocodesSearch.type, handleSearchPromocodes);
  yield takeLatest(sendRequestPromocodeCreate.type, handleCreatePromocodes);
  yield takeLatest(sendRequestPromocodeUpdate.type, handleUpdatePromocodes);
  yield takeLatest(sendRequestPromocodeDelete.type, handleDeletePromocodes);
}