import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestDeliveryServicesGet,
  setCurrentDeliveryService,
  sendRequestDeliveryServiceRacesGet,
  sendRequestDeliveryServiceCouriersGet,
  sendRequestDeliveryServicePolygonsGet,
} from '../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { sendRequestOrganizationsGet, sendRequestCitiesGet } from '../../../../../redux/reducers/utils/settingsReducers/settingsCatalogsReducer';
import { Row, Col, Breadcrumb, Typography, Divider, Tabs } from 'antd';
import EditableForm from './deliveryServiceDetail/EditableForm';
import RacesTableComponent from './deliveryServiceDetail/races/RacesTableComponent';
import CouriersTableComponent from './deliveryServiceDetail/couriers/CouriersTableComponent';
import PolygonsTableComponent from './deliveryServiceDetail/polygons/PolygonsTableComponent';
import Loader from '../../../../../components/Loader'
import ScrolledContainer from '../../../../../components/ScrolledContainer';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const DeliveryServiceDetailComponent = () => {
  const { serviceId } = useParams();
  const { isAuth } = useSelector((store) => store.auth);
  const {
    deliveryServices,
    currentDeliveryService,
    deliveryServiceRacesCreateRequestStatus,
    deliveryServiceRacesUpdateRequestStatus,
    deliveryServiceRacesDeleteRequestStatus,

    deliveryServiceCouriersCreateRequestStatus,
    deliveryServiceCouriersUpdateRequestStatus,
    deliveryServiceCouriersDeleteRequestStatus,

    deliveryServicePolygonCreateRequestStatus,
    deliveryServicePolygonUpdateRequestStatus,
    deliveryServicePolygonDeleteRequestStatus,
    deliveryServicePolygonsMassUpdateRequestStatus,
  } = useSelector((store) => store.deliveryServices);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestDeliveryServicesGet({ token: localStorage.getItem('token') }));
      dispatch(sendRequestOrganizationsGet({ token: localStorage.getItem('token') }));
      dispatch(sendRequestCitiesGet({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  useEffect(() => {
    if (deliveryServices) {
      console.log(deliveryServices);
      const matchDeliveryService = deliveryServices.find(service => service.id == serviceId);

      console.log(matchDeliveryService);
      dispatch(setCurrentDeliveryService(matchDeliveryService));
    }
  }, [deliveryServices]);

  useEffect(() => {
    if (deliveryServices && currentDeliveryService) {
      console.log(deliveryServices, currentDeliveryService);

      const requestData = {
        token: localStorage.getItem('token'),
        deliveryServiceId: currentDeliveryService.id,
      }

      dispatch(sendRequestDeliveryServiceRacesGet(requestData));
      dispatch(sendRequestDeliveryServiceCouriersGet(requestData));
      dispatch(sendRequestDeliveryServicePolygonsGet(requestData));
    }
  }, [deliveryServices, currentDeliveryService]);

  useEffect(() => {
    if (
      deliveryServiceRacesCreateRequestStatus === 'resolved' ||
      deliveryServiceRacesUpdateRequestStatus === 'resolved' ||
      deliveryServiceRacesDeleteRequestStatus === 'resolved' ||
      deliveryServiceCouriersCreateRequestStatus === 'resolved' ||
      deliveryServiceCouriersUpdateRequestStatus === 'resolved' ||
      deliveryServiceCouriersDeleteRequestStatus === 'resolved' ||
      deliveryServicePolygonCreateRequestStatus === 'resolved' ||
      deliveryServicePolygonUpdateRequestStatus === 'resolved' ||
      deliveryServicePolygonDeleteRequestStatus === 'resolved' ||
      deliveryServicePolygonsMassUpdateRequestStatus === 'resolved'
    ) {
      const requestData = {
        token: localStorage.getItem('token'),
        deliveryServiceId: currentDeliveryService.id,
      }

      dispatch(sendRequestDeliveryServiceRacesGet(requestData));
      dispatch(sendRequestDeliveryServiceCouriersGet(requestData));
      dispatch(sendRequestDeliveryServicePolygonsGet(requestData));
    }
  }, [
      deliveryServiceRacesCreateRequestStatus,
      deliveryServiceRacesUpdateRequestStatus,
      deliveryServiceRacesDeleteRequestStatus,
      deliveryServiceCouriersCreateRequestStatus,
      deliveryServiceCouriersUpdateRequestStatus,
      deliveryServiceCouriersDeleteRequestStatus,
      deliveryServicePolygonCreateRequestStatus,
      deliveryServicePolygonUpdateRequestStatus,
      deliveryServicePolygonDeleteRequestStatus,
      deliveryServicePolygonsMassUpdateRequestStatus,
    ]
  );

  const breadcrumb = [
    { title: (
        <Link to='/delivery/services'>Службы доставки</Link>
      ) 
    },
    { title: (
        <Text>Доставка "{currentDeliveryService?.name}"</Text>
      )
    },
  ]

  if (!currentDeliveryService) {
    return <div>Сервис не найден</div>;
  }

  return (
    <ScrolledContainer>
      {(
        deliveryServiceRacesCreateRequestStatus === 'pending' ||
        deliveryServiceRacesUpdateRequestStatus === 'pending' ||
        deliveryServiceRacesDeleteRequestStatus === 'pending' ||
        deliveryServiceCouriersCreateRequestStatus === 'pending' ||
        deliveryServiceCouriersUpdateRequestStatus === 'pending' ||
        deliveryServiceCouriersDeleteRequestStatus === 'pending' ||
        deliveryServicePolygonCreateRequestStatus === 'pending' ||
        deliveryServicePolygonUpdateRequestStatus === 'pending' ||
        deliveryServicePolygonDeleteRequestStatus === 'pending'
      ) && <Loader/>}
      <Breadcrumb items={breadcrumb} style={{marginTop: '15px'}}/>
      <Divider/>
      <Tabs defaultActiveKey="1" tabPosition="top">
        <TabPane tab="Настройки" key="1">
          <EditableForm/>
        </TabPane>
        <TabPane tab="Рейсы" key="2">
          <Title>Рейсы службы доставки</Title>
          <RacesTableComponent/>
        </TabPane>
        <TabPane tab="Курьеры" key="3">
          <Title>Курьеры службы доставки</Title>
          <CouriersTableComponent/>
        </TabPane>
        <TabPane tab="Зоны доставки" key="4">
          <Title>Зоны службы доставки</Title>
          <PolygonsTableComponent/>
        </TabPane>
        <TabPane tab="Анкеты соискателей" key="5" disabled>
          <Title>Анкеты соискателей</Title>
        </TabPane>
      </Tabs>
    </ScrolledContainer>
  );
};

export default DeliveryServiceDetailComponent;