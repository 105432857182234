import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestDeliveryServicesGet,
  setCurrentDeliveryService,
  sendRequestDeliveryServiceCouriersGet,
  setCurrentDeliveryServiceCourier,
  sendRequestDeliveryServiceRaceCouriersGet,
  sendRequestDeliveryServiceRacePolygonsGet,
  sendRequestDeliveryServiceRaceKitchensGet,
} from '../../../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Row, Col, Flex, Breadcrumb, Typography, Divider, Tabs } from 'antd';
import EditableForm from './courierDetail/EditableForm';
import FinanceTableComponent from './courierDetail/finance/FinanceTableComponent';
// import CouriersTableComponent from './raceDetail/couriers/CouriersTableComponent';
// import PolygonsTableComponent from './raceDetail/polygons/PolygonsTableComponent';
// import KitchensTableComponent from './raceDetail/kitchens/KitchensTableComponent';
// import KitchenDrawerComponent from './raceDetail/kitchens/KitchenDrawerComponent';
// import DeliveryServicesEditableRaceDetailComponent from './components/DeliveryServicesEditableRaceDetailComponent';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const CourierDetailComponent = () => {
  const { serviceId, courierId } = useParams();
  const { isAuth } = useSelector((store) => store.auth);
  const {
    deliveryServices,
    deliveryServiceCouriers,
    currentDeliveryService,
    currentDeliveryServiceCourier,
  } = useSelector((store) => store.deliveryServices);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestDeliveryServicesGet({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  useEffect(() => {
    if (deliveryServices.length) {
      console.log(deliveryServices);
      const matchDeliveryService = deliveryServices.find(service => service.id == serviceId);

      console.log(matchDeliveryService);
      dispatch(setCurrentDeliveryService(matchDeliveryService));
    }
  }, [deliveryServices]);

  useEffect(() => {
    if (deliveryServices && currentDeliveryService) {
      console.log(deliveryServices, currentDeliveryService);

      const requestData = {
        token: localStorage.getItem('token'),
        deliveryServiceId: currentDeliveryService.id,
      }

      dispatch(sendRequestDeliveryServiceCouriersGet(requestData));
    }
  }, [deliveryServices, currentDeliveryService]);

  useEffect(() => {
    if (deliveryServiceCouriers.length) {
      console.log(deliveryServiceCouriers);
      const matchDeliveryServiceCourier = deliveryServiceCouriers.find(courier => courier.courierId == courierId);

      console.log(matchDeliveryServiceCourier);
      dispatch(setCurrentDeliveryServiceCourier(matchDeliveryServiceCourier));
    }
  }, [deliveryServiceCouriers]);

  if (!currentDeliveryService || !currentDeliveryServiceCourier) {
    return <div>Курьер не найден</div>;
  }

  const breadcrumb = [
    { title: (
        <Link to={'/delivery/services'}>Службы доставки</Link>
      ) 
    },
    { title: (
        <Link to={`/delivery/services/${currentDeliveryService.id}`}>Доставка "{currentDeliveryService.name}"</Link>
      )
    },
    { title: (
      <Text>Курьер "{currentDeliveryServiceCourier.name}"</Text>
    )
  },
  ]

  if (!currentDeliveryService) {
    return <div>Курьер не найден</div>;
  }

  return (
    <Row>
      <Col style={{padding: '0 15px', width: '100%'}}>
        <Breadcrumb items={breadcrumb} style={{marginTop: '15px'}}/>
        <Divider/>
        <Tabs defaultActiveKey="1" tabPosition="top">
          <TabPane tab="Настройки" key="1">
            <EditableForm/>
          </TabPane>
          <TabPane tab="Финансы" key="2">
            <Title>Финансы</Title>
            <FinanceTableComponent/>
          </TabPane>
          <TabPane tab="Отзывы/рейтинг" key="3">
            <Title>Отзывы/рейтинг</Title>
          </TabPane>
          <TabPane tab="Статистика" key="4">
            <Title>Статистика</Title>
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default CourierDetailComponent;