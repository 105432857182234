import React from 'react';
import { Input } from 'antd';

const { Search } = Input;

const SearchComponent = ({ onSearchTerm }) => {
  const handleSearch = (value) => {
    if (value.length > 2) {
      onSearchTerm(value);
    } else {
      onSearchTerm('');
    }
  };

  return (
    <Search
      placeholder="Поиск"
      onChange={(e) => handleSearch(e.target.value)}
      style={{ maxWidth: '500px' }}
    />
  );
}

export default SearchComponent;