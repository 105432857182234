import React, { useState } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const EntityCreateModal = ({ onCreate }) => {
  const [ isCreate, setIsCreate] = useState(false);
  const [form] = Form.useForm();

  const handleOpenModal = () => {
    setIsCreate(true);
  };

  const handleCancel = () => {
    setIsCreate(false);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    onCreate(values);
    setIsCreate(false);
    form.resetFields();
  }
  
  return (
    <>
      <Button
        type={'primary'}
        shape="round"
        icon={<PlusOutlined />}
        onClick={handleOpenModal}
      >
        Добавить сущность
      </Button>
      <Modal
        title="Создание сущности"
        open={isCreate}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} name='createForm' onFinish={handleSubmit}>
          <Form.Item label='Название' name='name' rules={[{ required: true, message: 'Введите название' }]}>
            <Input />
          </Form.Item>
          <Form.Item label='Описание' name='description' rules={[{ required: true, message: 'Введите описание' }]}>
            <Input />
          </Form.Item>
          <Button type='primary' htmlType='submit'>
            Создать
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default EntityCreateModal;
