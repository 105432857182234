import React from 'react';
import SwaggerUIcomponent from '../components/SwagerUIcomponent';

function CourierAppDocsPage() {
  const CourierAppApi = 'https://api-test.menuforme.online/documentation/courier/v1/api';
  return (
    <SwaggerUIcomponent api={CourierAppApi}/>
  );
}

export default CourierAppDocsPage;