import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  //Kitchen Main Settings
  renameKitchenRequestStatus: null,
  createKitchenPlashkaRequestStatus: null,
  deleteKitchenPlashkaRequestStatus: null,
  changeCityKitchenRequestStatus: null,
  getAllKitchenStatusesRequestStatus: null,
  allKitchenStatuses: [],
  changeStatusKitchenRequestStatus: null,

  //Kitchen Settings Not Working
  kitchenNotWorkingGetRequestStatus: null,
  kitchenNotWorking: [],
  kitchenNotWorkingCreateRequestStatus: null,
  kitchenNotWorkingUpdateRequestStatus: null,
  kitchenNotWorkingDeleteRequestStatus: null,

  //Kitchen Settings Access
  kitchenSettingsAccessGetRequestStatus: null,
  kitchenSettingsAccess: null,
  kitchenSettingsAccessSetRequestStatus: null,
  kitchenSettingsAccessDeleteRequestStatus: null,

  //Kitchen Contracts
  kitchenContractsGetRequestStatus: null,
  kitchenContracts: [],
  kitchenContractsCreateRequestStatus: null,
  kitchenContractsUpdateRequestStatus: null,
  kitchenContractsDeleteRequestStatus: null,

  //Kitchen Contract Commissions
  kitchenContractCommissionsGetRequestStatus: null,
  kitchenContractCommissions: [],
  kitchenContractCommissionsCreateRequestStatus: null,
  kitchenContractCommissionsUpdateRequestStatus: null,
  kitchenContractCommissionsDeleteRequestStatus: null,
};

const kitchenSettingsSlice = createSlice({
  name: 'kitchenSettings',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    //Kitchen Main Settings
    sendRequestRenameKitchen: (state) => { state.renameKitchenRequestStatus = 'pending'; },
    sendRequestRenameKitchenSuccess: (state) => { state.renameKitchenRequestStatus = 'resolved'; },
    sendRequestRenameKitchenError: (state) => { state.renameKitchenRequestStatus = 'rejected'; },
    clearRenameKitchenStatus: (state) => { state.renameKitchenRequestStatus = null; },
    sendRequestCreateKitchenPlashka: (state) => { state.createKitchenPlashkaRequestStatus = 'pending'; },
    sendRequestCreateKitchenPlashkaSuccess: (state) => { state.createKitchenPlashkaRequestStatus = 'resolved'; },
    sendRequestCreateKitchenPlashkaError: (state) => { state.createKitchenPlashkaRequestStatus = 'rejected'; },
    clearCreateKitchenPlashkaStatus: (state) => { state.createKitchenPlashkaRequestStatus = null; },
    sendRequestDeleteKitchenPlashka: (state) => { state.deleteKitchenPlashkaRequestStatus = 'pending'; },
    sendRequestDeleteKitchenPlashkaSuccess: (state) => { state.deleteKitchenPlashkaRequestStatus = 'resolved'; },
    sendRequestDeleteKitchenPlashkaError: (state) => { state.deleteKitchenPlashkaRequestStatus = 'rejected'; },
    clearDeleteKitchenPlashkaStatus: (state) => { state.deleteKitchenPlashkaRequestStatus = null; },
    sendRequestChangeCityKitchen: (state) => { state.changeCityKitchenRequestStatus = 'pending'; },
    sendRequestChangeCityKitchenSuccess: (state) => { state.changeCityKitchenRequestStatus = 'resolved'; },
    sendRequestChangeCityKitchenError: (state) => { state.changeCityKitchenRequestStatus = 'rejected'; },
    clearChangeCityKitchenStatus: (state) => { state.changeCityKitchenRequestStatus = null; },
    sendRequestGetAllKitchenStatuses: (state) => { state.getAllKitchenStatusesRequestStatus = 'pending'; },
    sendRequestGetAllKitchenStatusesSuccess: (state) => { state.getAllKitchenStatusesRequestStatus = 'resolved'; },
    sendRequestGetAllKitchenStatusesError: (state) => { state.getAllKitchenStatusesRequestStatus = 'rejected'; },
    clearGetAllKitchenStatusesStatus: (state) => { state.getAllKitchenStatusesRequestStatus = null; },
    setAllKitchenStatuses: (state, action) => {
      state.allKitchenStatuses = action.payload;
    },
    sendRequestChangeStatusKitchen: (state) => { state.changeStatusKitchenRequestStatus = 'pending'; },
    sendRequestChangeStatusKitchenSuccess: (state) => { state.changeStatusKitchenRequestStatus = 'resolved'; },
    sendRequestChangeStatusKitchenError: (state) => { state.changeStatusKitchenRequestStatus = 'rejected'; },
    clearChangeStatusKitchenStatus: (state) => { state.changeStatusKitchenRequestStatus = null; },

    //Kitchen Settings Not Working
    sendRequestKitchenNotWorkingGet: (state) => { state.kitchenNotWorkingGetRequestStatus = 'pending'; },
    sendRequestKitchenNotWorkingGetSuccess: (state) => { state.kitchenNotWorkingGetRequestStatus = 'resolved'; },
    sendRequestKitchenNotWorkingGetError: (state) => { state.kitchenNotWorkingGetRequestStatus = 'rejected'; },
    clearKitchenNotWorkingGetStatus: (state) => { state.kitchenNotWorkingGetRequestStatus = null; },
    setKitchenNotWorking: (state, action) => {
      state.kitchenNotWorking = action.payload;
    },
    sendRequestKitchenNotWorkingCreate: (state) => { state.kitchenNotWorkingCreateRequestStatus = 'pending'; },
    sendRequestKitchenNotWorkingCreateSuccess: (state) => { state.kitchenNotWorkingCreateRequestStatus = 'resolved'; },
    sendRequestKitchenNotWorkingCreateError: (state) => { state.kitchenNotWorkingCreateRequestStatus = 'rejected'; },
    clearKitchenNotWorkingCreateStatus: (state) => { state.kitchenNotWorkingCreateRequestStatus = null; },
    sendRequestKitchenNotWorkingUpdate: (state) => { state.kitchenNotWorkingUpdateRequestStatus = 'pending'; },
    sendRequestKitchenNotWorkingUpdateSuccess: (state) => { state.kitchenNotWorkingUpdateRequestStatus = 'resolved'; },
    sendRequestKitchenNotWorkingUpdateError: (state) => { state.kitchenNotWorkingUpdateRequestStatus = 'rejected'; },
    clearKitchenNotWorkingUpdateStatus: (state) => { state.kitchenNotWorkingUpdateRequestStatus = null; },
    sendRequestKitchenNotWorkingDelete: (state) => { state.kitchenNotWorkingDeleteRequestStatus = 'pending'; },
    sendRequestKitchenNotWorkingDeleteSuccess: (state) => { state.kitchenNotWorkingDeleteRequestStatus = 'resolved'; },
    sendRequestKitchenNotWorkingDeleteError: (state) => { state.kitchenNotWorkingDeleteRequestStatus = 'rejected'; },
    clearKitchenNotWorkingDeleteStatus: (state) => { state.kitchenNotWorkingDeleteRequestStatus = null; },

    //Kitchen Settings Access
    sendRequestkitchenSettingsAccessGet: (state) => { state.kitchenSettingsAccessGetRequestStatus = 'pending'; },
    sendRequestkitchenSettingsAccessGetSuccess: (state) => { state.kitchenSettingsAccessGetRequestStatus = 'resolved'; },
    sendRequestkitchenSettingsAccessGetError: (state) => { state.kitchenSettingsAccessGetRequestStatus = 'rejected'; },
    clearkitchenSettingsAccessGetStatus: (state) => { state.kitchenSettingsAccessGetRequestStatus = null; },
    setKitchenSettingsAccess: (state, action) => {
      state.kitchenSettingsAccess = action.payload;
    },
    sendRequestkitchenSettingsAccessSet: (state) => { state.kitchenSettingsAccessSetRequestStatus = 'pending'; },
    sendRequestkitchenSettingsAccessSetSuccess: (state) => { state.kitchenSettingsAccessSetRequestStatus = 'resolved'; },
    sendRequestkitchenSettingsAccessSetError: (state) => { state.kitchenSettingsAccessSetRequestStatus = 'rejected'; },
    clearkitchenSettingsAccessSetStatus: (state) => { state.kitchenSettingsAccessSetRequestStatus = null; },
    sendRequestkitchenSettingsAccessDelete: (state) => { state.kitchenSettingsAccessDeleteRequestStatus = 'pending'; },
    sendRequestkitchenSettingsAccessDeleteSuccess: (state) => { state.kitchenSettingsAccessDeleteRequestStatus = 'resolved'; },
    sendRequestkitchenSettingsAccessDeleteError: (state) => { state.kitchenSettingsAccessDeleteRequestStatus = 'rejected'; },
    clearkitchenSettingsAccessDeleteStatus: (state) => { state.kitchenSettingsAccessDeleteRequestStatus = null; },

    //Kitchen Contracts
    sendRequestKitchenContractsGet: (state) => { state.kitchenContractsGetRequestStatus = 'pending'; },
    sendRequestKitchenContractsGetSuccess: (state) => { state.kitchenContractsGetRequestStatus = 'resolved'; },
    sendRequestKitchenContractsGetError: (state) => { state.kitchenContractsGetRequestStatus = 'rejected'; },
    clearKitchenContractsGetStatus: (state) => { state.kitchenContractsGetRequestStatus = null; },
    setKitchenContracts: (state, action) => {
      state.kitchenContracts = action.payload;
    },
    sendRequestKitchenContractsCreate: (state) => { state.kitchenContractsCreateRequestStatus = 'pending'; },
    sendRequestKitchenContractsCreateSuccess: (state) => { state.kitchenContractsCreateRequestStatus = 'resolved'; },
    sendRequestKitchenContractsCreateError: (state) => { state.kitchenContractsCreateRequestStatus = 'rejected'; },
    clearKitchenContractsCreateStatus: (state) => { state.kitchenContractsCreateRequestStatus = null; },
    sendRequestKitchenContractsUpdate: (state) => { state.kitchenContractsUpdateRequestStatus = 'pending'; },
    sendRequestKitchenContractsUpdateSuccess: (state) => { state.kitchenContractsUpdateRequestStatus = 'resolved'; },
    sendRequestKitchenContractsUpdateError: (state) => { state.kitchenContractsUpdateRequestStatus = 'rejected'; },
    clearKitchenContractsUpdateStatus: (state) => { state.kitchenContractsUpdateRequestStatus = null; },
    sendRequestKitchenContractsDelete: (state) => { state.kitchenContractsDeleteRequestStatus = 'pending'; },
    sendRequestKitchenContractsDeleteSuccess: (state) => { state.kitchenContractsDeleteRequestStatus = 'resolved'; },
    sendRequestKitchenContractsDeleteError: (state) => { state.kitchenContractsDeleteRequestStatus = 'rejected'; },
    clearKitchenContractsDeleteStatus: (state) => { state.kitchenContractsDeleteRequestStatus = null; },

    //Kitchen Contract Commissions
    sendRequestKitchenContractCommissionsGet: (state) => { state.kitchenContractCommissionsGetRequestStatus = 'pending'; },
    sendRequestKitchenContractCommissionsGetSuccess: (state) => { state.kitchenContractCommissionsGetRequestStatus = 'resolved'; },
    sendRequestKitchenContractCommissionsGetError: (state) => { state.kitchenContractCommissionsGetRequestStatus = 'rejected'; },
    clearKitchenContractCommissionsGetStatus: (state) => { state.kitchenContractCommissionsGetRequestStatus = null; },
    setKitchenContractCommissions: (state, action) => {
      state.kitchenContractCommissions = action.payload;
    },
    sendRequestKitchenContractCommissionsCreate: (state) => { state.kitchenContractCommissionsCreateRequestStatus = 'pending'; },
    sendRequestKitchenContractCommissionsCreateSuccess: (state) => { state.kitchenContractCommissionsCreateRequestStatus = 'resolved'; },
    sendRequestKitchenContractCommissionsCreateError: (state) => { state.kitchenContractCommissionsCreateRequestStatus = 'rejected'; },
    clearKitchenContractCommissionsCreateStatus: (state) => { state.kitchenContractCommissionsCreateRequestStatus = null; },
    sendRequestKitchenContractCommissionsUpdate: (state) => { state.kitchenContractCommissionsUpdateRequestStatus = 'pending'; },
    sendRequestKitchenContractCommissionsUpdateSuccess: (state) => { state.kitchenContractCommissionsUpdateRequestStatus = 'resolved'; },
    sendRequestKitchenContractCommissionsUpdateError: (state) => { state.kitchenContractCommissionsUpdateRequestStatus = 'rejected'; },
    clearKitchenContractCommissionsUpdateStatus: (state) => { state.kitchenContractCommissionsUpdateRequestStatus = null; },
    sendRequestKitchenContractCommissionsDelete: (state) => { state.kitchenContractCommissionsDeleteRequestStatus = 'pending'; },
    sendRequestKitchenContractCommissionsDeleteSuccess: (state) => { state.kitchenContractCommissionsDeleteRequestStatus = 'resolved'; },
    sendRequestKitchenContractCommissionsDeleteError: (state) => { state.kitchenContractCommissionsDeleteRequestStatus = 'rejected'; },
    clearKitchenContractCommissionsDeleteStatus: (state) => { state.kitchenContractCommissionsDeleteRequestStatus = null; },
  },
});

export const {
  //Kitchen Main Settings
  sendRequestRenameKitchen,
  sendRequestRenameKitchenSuccess,
  sendRequestRenameKitchenError,
  clearRenameKitchenStatus,
  sendRequestCreateKitchenPlashka,
  sendRequestCreateKitchenPlashkaSuccess,
  sendRequestCreateKitchenPlashkaError,
  clearCreateKitchenPlashkaStatus,
  sendRequestDeleteKitchenPlashka,
  sendRequestDeleteKitchenPlashkaSuccess,
  sendRequestDeleteKitchenPlashkaError,
  clearDeleteKitchenPlashkaStatus,
  sendRequestChangeCityKitchen,
  sendRequestChangeCityKitchenSuccess,
  sendRequestChangeCityKitchenError,
  clearChangeCityKitchenStatus,
  sendRequestGetAllKitchenStatuses,
  sendRequestGetAllKitchenStatusesSuccess,
  sendRequestGetAllKitchenStatusesError,
  clearGetAllKitchenStatusesStatus,
  setAllKitchenStatuses,
  sendRequestChangeStatusKitchen,
  sendRequestChangeStatusKitchenSuccess,
  sendRequestChangeStatusKitchenError,
  clearChangeStatusKitchenStatus,

  //Kitchen Settings Not Working
  sendRequestKitchenNotWorkingGet,
  sendRequestKitchenNotWorkingGetSuccess,
  sendRequestKitchenNotWorkingGetError,
  clearKitchenNotWorkingGetStatus,
  setKitchenNotWorking,
  sendRequestKitchenNotWorkingCreate,
  sendRequestKitchenNotWorkingCreateSuccess,
  sendRequestKitchenNotWorkingCreateError,
  clearKitchenNotWorkingCreateStatus,
  sendRequestKitchenNotWorkingUpdate,
  sendRequestKitchenNotWorkingUpdateSuccess,
  sendRequestKitchenNotWorkingUpdateError,
  clearKitchenNotWorkingUpdateStatus,
  sendRequestKitchenNotWorkingDelete,
  sendRequestKitchenNotWorkingDeleteSuccess,
  sendRequestKitchenNotWorkingDeleteError,
  clearKitchenNotWorkingDeleteStatus,

  //Kitchen Settings Access
  sendRequestkitchenSettingsAccessGet,
  sendRequestkitchenSettingsAccessGetSuccess,
  sendRequestkitchenSettingsAccessGetError,
  clearkitchenSettingsAccessGetStatus,
  setKitchenSettingsAccess,
  sendRequestkitchenSettingsAccessSet,
  sendRequestkitchenSettingsAccessSetSuccess,
  sendRequestkitchenSettingsAccessSetError,
  clearkitchenSettingsAccessSetStatus,
  sendRequestkitchenSettingsAccessDelete,
  sendRequestkitchenSettingsAccessDeleteSuccess,
  sendRequestkitchenSettingsAccessDeleteError,
  clearkitchenSettingsAccessDeleteStatus,

  //Kitchen Contracts
  sendRequestKitchenContractsGet,
  sendRequestKitchenContractsGetSuccess,
  sendRequestKitchenContractsGetError,
  clearKitchenContractsGetStatus,
  setKitchenContracts,
  sendRequestKitchenContractsCreate,
  sendRequestKitchenContractsCreateSuccess,
  sendRequestKitchenContractsCreateError,
  clearKitchenContractsCreateStatus,
  sendRequestKitchenContractsUpdate,
  sendRequestKitchenContractsUpdateSuccess,
  sendRequestKitchenContractsUpdateError,
  clearKitchenContractsUpdateStatus,
  sendRequestKitchenContractsDelete,
  sendRequestKitchenContractsDeleteSuccess,
  sendRequestKitchenContractsDeleteError,
  clearKitchenContractsDeleteStatus,

  //Kitchen Contract Commissions
  sendRequestKitchenContractCommissionsGet,
  sendRequestKitchenContractCommissionsGetSuccess,
  sendRequestKitchenContractCommissionsGetError,
  clearKitchenContractCommissionsGetStatus,
  setKitchenContractCommissions,
  sendRequestKitchenContractCommissionsCreate,
  sendRequestKitchenContractCommissionsCreateSuccess,
  sendRequestKitchenContractCommissionsCreateError,
  clearKitchenContractCommissionsCreateStatus,
  sendRequestKitchenContractCommissionsUpdate,
  sendRequestKitchenContractCommissionsUpdateSuccess,
  sendRequestKitchenContractCommissionsUpdateError,
  clearKitchenContractCommissionsUpdateStatus,
  sendRequestKitchenContractCommissionsDelete,
  sendRequestKitchenContractCommissionsDeleteSuccess,
  sendRequestKitchenContractCommissionsDeleteError,
  clearKitchenContractCommissionsDeleteStatus,
} = kitchenSettingsSlice.actions;

export default kitchenSettingsSlice.reducer;
