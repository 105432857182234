import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Flex, Typography, Image } from 'antd';
import emptyImage from '../../../../../assets/img/empty-kitchen-image.png';
import PrintTable from '../../../../../components/PrintTable';
import { formatDate } from '../../../../../core/utils';

const { Title, Text } = Typography;

const NotificationsTableComponent = ({ notifications, currentPage, totalNotifications, onChange }) => {
  const clientsWithKey = notifications.map(item => ({...item, key: item.id}))

  const columns = [
    {
      title: 'Дата и время отправки',
      key: 'created',
      render: ((record) => (
        <>
          <Title level={5}>{formatDate(record.created)}</Title>
          <Text type='secondary'>{record.phone} <small>({record.userId})</small></Text>
        </>
      )),
    },
    {
      title: 'PUSH-уведомление',
      key: 'push',
      render: ((record) => (
        <Card
          title={record.push_title}
          description={record.push_body}
          image={record.picturePushFilePathPublic}
        />
      )),
    },
    {
      title: 'Сообщение в чат',
      key: 'message',
      render: ((record) => (
        <Card
          title={record.messege_title}
          description={record.messege_body}
          image={record.pictureMessegeFilePathPublic}
        />
      )),
    },
    {
      title: 'Кол-во устройств на которые отправилось',
      dataIndex: 'count_devices_sent',
      key: 'count_devices_sent',
    },
    {
      title: 'Кол-во устройств на которые доставлено',
      dataIndex: 'count_devices_sent_delivered',
      key: 'count_devices_sent_delivered',
    },
    {
      title: 'Кол-во устройств, которые кликнули по пушу',
      dataIndex: 'count_devices_clicked',
      key: 'count_devices_clicked',
    },
  ];

  const handleTableChange = (pagination) => {
    onChange(pagination.current);
  };

  return (
    <PrintTable>
      <Table
        dataSource={clientsWithKey}
        columns={columns}
        bordered
        size="small"
        pagination={{
          current: currentPage,
          total: totalNotifications,
          pageSize: 20,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        onChange={handleTableChange}
      />
    </PrintTable>
  );
};

export default NotificationsTableComponent;

const Card = ({ title, description, image }) => {
  return(
    <Flex gap={5}>
      <Flex vertical flex='1 1 100%' style={{minWidth: '200px'}}>
        <Title level={4}>{title}</Title>
        <Text>{description}</Text>
      </Flex>
      <Flex vertical flex='1 0 100px' style={{minWidth: '100px'}}>
        <Image
          style={{
            width: '100%',
            height: '100px',
            objectFit: 'cover',
            background: '#eeeeee',
            borderRadius: '10px',
            overflow: 'hidden',
          }} 
          src={image}
          fallback={emptyImage}
        />
      </Flex>
    </Flex>
  )
}