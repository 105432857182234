import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestKitchenContractsCreate } from '../../../../../redux/reducers/utils/kitchenReducers/kitchenSettingsReducer';
import { Flex, Typography, Modal, Form, Select, Input, DatePicker, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Title, Text } = Typography;

const ContractsCreateModalComponent = () => {
  const { organizations } = useSelector((store) => store.settingsCatalogs);
  const { selectKitchen } = useSelector((store) => store.kitchen);
  const dispatch = useDispatch();
  const [ isCreate, setIsCreate] = useState(false);
  const [form] = Form.useForm();

  const handleOpen = () => {
    setIsCreate(true);
  };

  const handleCancel = () => {
    setIsCreate(false);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      kitchenId: selectKitchen.id,
      organizationId: values.organization,
      contractNumber: values.contractNumber,
      contractDate: moment(new Date(values.contractDate)).format('YYYY-MM-DD'),
      // contractCommission: values.contractCommission,
      // commissionStartDate: moment(new Date(values.commissionStartDate)).format('YYYY-MM-DD'),
      walletKitchenId: 0,
    }

    dispatch(sendRequestKitchenContractsCreate(requestData));
    setIsCreate(false);
    form.resetFields();
  }

  const organizationOptions = organizations?.map((item) => ({
    ...item,
    value: item.id,
    label: item.urName,
  }));
  
  return (
    <>
      <Button
        type='primary'
        shape="round"
        icon={<PlusOutlined />}
        onClick={handleOpen}
        style={{marginLeft: 'auto'}}
      >
        Добавить новый договор
      </Button>
      <Modal
        title="Создание нового договора"
        open={isCreate}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          style={{ width: '100%' }}
          form={form}
          name='contracts'
          layout='vertical'
          onFinish={handleSubmit}
        >
          <Form.Item label='Организация' name='organization' rules={[{ required: true, message: 'Выберите организацию' }]}>
            <Select
              showSearch
              placeholder="Выберите организацию"
              optionFilterProp="children"
              options={organizationOptions}
              filterOption={(input, option) =>
                (option?.urName?.toString() ?? '').toLowerCase().includes(input.toLowerCase()) ||
                (option?.inn?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
              }
              optionRender={(option) => (
                <Flex
                  vertical
                  style={{ margin: '5px 0', height: '80px' }}
                >
                  <div>ID: {option.data.id}</div>
                  <Title level={5}>{option.data.urName}</Title>
                  <div>ИНН: {option.data.inn}</div>
                </Flex>
              )}
            />
          </Form.Item>
          <Flex gap='small' align='flex-end'>
            <Form.Item style={{width:'50%'}} label='Номер договора' name='contractNumber' rules={[{ required: true, message: 'Введите номер договора' }]}>
              <Input placeholder='Номер договора'/>
            </Form.Item>
            <Form.Item style={{width:'50%'}} label='Дата заключения договора' name='contractDate' rules={[{ required: true, message: 'Выберите дату' }]}>
              <DatePicker style={{ width:'100%' }} placeholder='Выберите дату'/>
            </Form.Item>
          </Flex>
          {/* <Flex gap='small' align='flex-end'>
            <Form.Item style={{width:'50%'}} label='Комиссия по договору' name='contractCommission'>
              <Input placeholder='Комиссия по договору'/>
            </Form.Item>
            <Form.Item style={{width:'50%'}} label='Дата начала действия комиссии по договору' name='commissionStartDate'>
              <DatePicker style={{ width:'100%' }} placeholder='Выберите дату'/>
            </Form.Item>
          </Flex> */}
          <Button type='primary' htmlType='submit' style={{ display: 'flex', marginLeft: 'auto' }}>Создать</Button>
        </Form>
      </Modal>
    </>
  );
};

export default ContractsCreateModalComponent;
