import React from 'react';
import { Form, Input, Select, DatePicker, Button, Flex, Typography } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const { Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const serverFormat = 'YYYY-MM-DD';
const clientFormat = 'DD-MM-YYYY';

const WalletsFiltersComponent = ({ totalWallets, onSearch }) => {

  const handleSearch = (values) => {
    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(([_, v]) => v !== undefined && v !== null && v !== '')
    );
  
    if (filteredValues.validToday) {
      filteredValues.validToday = filteredValues.validToday.format(serverFormat);
    }
  
    if (filteredValues.transactions) {
      const [transactionsFrom, transactionsTo] = filteredValues.transactions;
      filteredValues.transactionsFrom = transactionsFrom.format(serverFormat);
      filteredValues.transactionsTo = transactionsTo.format(serverFormat);
      delete filteredValues.transactions;
    }
  
    if (filteredValues.create) {
      const [createFrom, createTo] = filteredValues.create;
      filteredValues.createFrom = createFrom.format(serverFormat);
      filteredValues.createTo = createTo.format(serverFormat);
      delete filteredValues.create;
    }
  
    onSearch(filteredValues);
  };

  return (
    <Form
      layout='vertical'
      onFinish={handleSearch}
    >
      <Flex gap={16}>
        <Form.Item style={{flex: 1, margin: '5px'}} name='walletId' label='ID кошелька'>
          <Input placeholder='Введите ID кошелька'/>
        </Form.Item>

        <Form.Item style={{flex: 1, margin: '5px'}} name='phone' label='Телефон пользователя кошелька'>
          <Input placeholder='Введите номер телефона'/>
        </Form.Item>

        <Form.Item style={{flex: 1, margin: '5px'}} name='orderNumber' label='Номер заказа'>
          <Input placeholder='Введите номер заказа'/>
        </Form.Item>
      </Flex>

      <Flex gap={16}>
        <Form.Item style={{flex: 1, margin: '5px'}} name='validToday' label='Действующий на'>
          <DatePicker style={{width: '100%'}} placeholder='Выберите дату' format={clientFormat}/>
        </Form.Item>

        <Form.Item style={{flex: 1, margin: '5px'}} name='transactions' label='Были транзакции за период'>
          <RangePicker style={{width: '100%'}} format={clientFormat}/>
        </Form.Item>

        <Form.Item style={{flex: 1, margin: '5px'}} name='create' label='Был создан в период'>
          <RangePicker style={{width: '100%'}} format={clientFormat}/>
        </Form.Item>
      </Flex>

      <Flex gap={16}>
        <Form.Item style={{flex: 1, margin: '5px'}} name='company' label='Принадлежит компании/договору'>
          <Select mode="multiple" placeholder='Выберите компанию'>
            <Option value='company1'>Компания 1</Option>
            <Option value='company2'>Компания 2</Option>
          </Select>
        </Form.Item>

        <Form.Item style={{flex: 1, margin: '5px'}} name='isDefaultWallet' label='Дефолтный кошелёк'>
          <Select placeholder='Выберите кошелек'>
            <Option value={true}>Да</Option>
            <Option value={false}>Нет</Option>
          </Select>
        </Form.Item>
      </Flex>

      <Flex style={{marginTop: '20px'}} justify='space-between' gap={16}>
        <Text type='secondary'>Найдено кошельков: {new Intl.NumberFormat().format(totalWallets)}</Text>
        <Flex gap={16}>
          <Button type='primary' htmlType='submit'>Поиск</Button>
          <Button htmlType='reset'>Сбросить</Button>
        </Flex>
      </Flex>
    </Form>
  );
};

export default WalletsFiltersComponent;
