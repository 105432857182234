import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  //News
  newsGetRequestStatus: null,
  news: [],
  newsCreateRequestStatus: null,
  newsUpdateRequestStatus: null,
  newsDeleteRequestStatus: null,
};

export const selectCommonState = state => state.common;

const mainSlice = createSlice({
  name: 'main',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    //News
    sendRequestNewsGet: (state) => { state.newsGetRequestStatus = 'pending'; },
    sendRequestNewsGetSuccess: (state) => { state.newsGetRequestStatus = 'resolved'; },
    sendRequestNewsGetError: (state) => { state.newsGetRequestStatus = 'rejected'; },
    clearNewsGetStatus: (state) => { state.newsGetRequestStatus = null; },
    setNews: (state, action) => {
      state.news = action.payload;
    },

    sendRequestNewsCreate: (state) => { state.newsCreateRequestStatus = 'pending'; },
    sendRequestNewsCreateSuccess: (state) => { state.newsCreateRequestStatus = 'resolved'; },
    sendRequestNewsCreateError: (state) => { state.newsCreateRequestStatus = 'rejected'; },
    clearNewsCreateStatus: (state) => { state.newsCreateRequestStatus = null; },

    sendRequestNewsUpdate: (state) => { state.newsUpdateRequestStatus = 'pending'; },
    sendRequestNewsUpdateSuccess: (state) => { state.newsUpdateRequestStatus = 'resolved'; },
    sendRequestNewsUpdateError: (state) => { state.newsUpdateRequestStatus = 'rejected'; },
    clearNewsUpdateStatus: (state) => { state.newsUpdateRequestStatus = null; },

    sendRequestNewsDelete: (state) => { state.newsDeleteRequestStatus = 'pending'; },
    sendRequestNewsDeleteSuccess: (state) => { state.newsDeleteRequestStatus = 'resolved'; },
    sendRequestNewsDeleteError: (state) => { state.newsDeleteRequestStatus = 'rejected'; },
    clearNewsDeleteStatus: (state) => { state.newsDeleteRequestStatus = null; },
  },
});

export const {
  //News
  sendRequestNewsGet,
  sendRequestNewsGetSuccess,
  sendRequestNewsGetError,
  clearNewsGetStatus,
  setNews,
  sendRequestNewsCreate,
  sendRequestNewsCreateSuccess,
  sendRequestNewsCreateError,
  clearNewsCreateStatus,
  sendRequestNewsUpdate,
  sendRequestNewsUpdateSuccess,
  sendRequestNewsUpdateError,
  clearNewsUpdateStatus,
  sendRequestNewsDelete,
  sendRequestNewsDeleteSuccess,
  sendRequestNewsDeleteError,
  clearNewsDeleteStatus,
} = mainSlice.actions;

export default mainSlice.reducer;
