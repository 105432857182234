import React from 'react';
import { Typography, Button, Flex } from 'antd';
import { getCorrectWordForm } from '../../../../../core/utils';

const { Title, Text } = Typography;

const calculateTotalAmounts = (payments) => {
  const totalsByContract = payments.reduce((acc, payment) => {
    if (!acc[payment.contractId]) {
      acc[payment.contractId] = {
        contractName: payment.contractName,
        contractId: payment.contractId,
        totalAmountByContract: 0
      };
    }
    acc[payment.contractId].totalAmountByContract += parseFloat(payment.amount);
    return acc;
  }, {});

  const totalAmount = payments.reduce((acc, payment) => {
    return acc + parseFloat(payment.amount);
  }, 0);

  return {
    contractSummaries: Object.values(totalsByContract),
    totalAmount,
  };
};

function ActiveOrdersList({activeOrders, submit, cancel }) {
  const results  = calculateTotalAmounts(activeOrders);

  const handleSubmit = () => {
    submit(activeOrders)
  }

  return (
    <Flex vertical gap={16}>
      <Flex vertical gap={16} style={{paddingBottom: '20px', borderBottom: 'solid 1px #cccccc'}}>
        {results.contractSummaries.map((contract, index) => (
          <Flex key={index}>{contract.contractName} на общую сумму {contract.totalAmountByContract}р</Flex>
        ))}
      </Flex>
      <Title level={4}>
        {getCorrectWordForm(results.contractSummaries.length, ['Договор', 'Договора', 'Договоров'])} на общую сумму {results.totalAmount.toFixed(2)}р
      </Title>
      <Flex justify='flex-end' gap={16}>
        <Button onClick={cancel}>Отменить</Button>
        <Button type='primary' onClick={handleSubmit}>Отправить в банк</Button>
      </Flex>
    </Flex>
  );
}

export default ActiveOrdersList;
