import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setMapMode,
  clearGeocodeAddress,
  fetchCreateLocation,
  setAllEmptylocationsShow,
} from '../../../../../redux/reducers/utils/deliveryReducers/deliveryMapReducer';
import styled from 'styled-components';
import { Modal, Form, Button, Input, Flex, Checkbox, Select, message } from 'antd';
import { HomeOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';

const { Option } = Select;

const GeocodeComponent = () => {
  const { mapMode, geocodeAddress, selectedFilters, allEmptylocationsShow } = useSelector((store) => store.deliveryMap);
  const dispatch = useDispatch();

  const handleModeToggle = () => {
    if (selectedFilters.city) {
      if (mapMode !== 'geocodeMode') {
        dispatch(setMapMode('geocodeMode'));
        dispatch(setAllEmptylocationsShow(true));
      } else {
        dispatch(setMapMode('defaultMode'));
        dispatch(setAllEmptylocationsShow(false));
        dispatch(clearGeocodeAddress());
      }
    } else {
      message.error('Выберите город')
    }
  };

  const handleClose = () => {
    dispatch(clearGeocodeAddress());
  };

  const handleSubmit = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      locationTypeObjectId: 0,
      addressFormated: geocodeAddress.addressFormated,
      coords: geocodeAddress.coords,
      cityId: selectedFilters.city,
      ...values,
    }

    dispatch(fetchCreateLocation(requestData));
    dispatch(clearGeocodeAddress());
  };

  const handleChange = () => {
    if (selectedFilters.city !== null) {
      dispatch(setAllEmptylocationsShow(!allEmptylocationsShow));
    } else {
      message.error('Выберите город, чтобы посмотреть локации!');
    }
  }

  return (
    <Wrapper>
      <Flex
        style={{marginTop: '10px', height: 'fit-content'}}
        vertical
        align='flex-end'
      >
        <Button
          type={mapMode === 'geocodeMode' ? 'primary' : 'default'}
          onClick={handleModeToggle}
        >
          <HomeOutlined />
        </Button>
        <Flex
          style={{
            marginTop: '-2px',
            padding: '0 2px',
            width: '100%',
            background: '#ffffff',
            border: 'solid 1px #e2e2e2',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px'
          }}
          gap='5px'
          justify='space-between'
          align='center'
        >
          <EyeOutlined/>
          <Checkbox checked={allEmptylocationsShow} onChange={handleChange}/>
        </Flex>
      </Flex>

      {geocodeAddress && (
        <Modal
          open={!!geocodeAddress}
          onCancel={handleClose}
          title="Создание локации"
          footer={false}
        >
          <Form
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={geocodeAddress}
          >
            <Form.Item style={{marginBottom: '10px'}} label='Страна' name='country'>
              <Input placeholder='Страна'/>
            </Form.Item>
            <Form.Item style={{marginBottom: '10px'}} label='Регион' name='administrativeArea'>
              <Input placeholder='Регион'/>
            </Form.Item>
            <Form.Item style={{marginBottom: '10px'}} label='Город' name='city'>
              <Input placeholder='Город'/>
            </Form.Item>
            <Form.Item style={{marginBottom: '10px'}} label='Улица' name='street'>
              <Input placeholder='Улица'/>
            </Form.Item>
            <Form.Item style={{marginBottom: '10px'}} label='Дом' name='house'>
              <Input placeholder='Дом'/>
            </Form.Item>
            <Form.Item style={{marginBottom: '10px'}} label='Почтовый индекс' name='postalCodeNumber'>
              <Input placeholder='Почтовый индекс'/>
            </Form.Item>
            <Form.Item style={{marginBottom: '10px'}} label='Название' name='name'>
              <Input placeholder='Название'/>
            </Form.Item>
            <Form.Item style={{marginBottom: '10px'}} label='Тип объекта' name='locationTypeObjectId'>
              <Select
                defaultValue={0}
              >
                <Option key={0} value={0}>Без типа объекта</Option>
                <Option key={1} value={1}>Офисное здание</Option>
                <Option key={2} value={2}>Частный дом</Option>
                <Option key={3} value={3}>Жилой многоквартирный дом</Option>
                <Option key={4} value={4}>Предприятие</Option>
              </Select>
            </Form.Item>
            
            <ButtonWrapper>
              <Button htmlType='submit' type="primary">
                Создать
              </Button>
            </ButtonWrapper>
          </Form>
        </Modal>
      )}
    </Wrapper>
  );
};

export default GeocodeComponent;

const Wrapper = styled.div`
  position: relative;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 16px;
`;
