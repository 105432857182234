import React from 'react';
import { Popover } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

const locale = 'RU'
const InformNotesData = {
  'main-page-search': { 
    'RU': { title: 'Поиск', content: 'Просто поиск' },
    'EN': { title: 'Search', content: 'Just search' },
  },
  'main-page-city-filter': {
    'RU': { title: 'Поиск', content: 'Поискт по городу' },
    'EN': { title: 'Search', content: 'City search' },
  },
  'main-page-status-filter': {
    'RU': { title: 'Поиск', content: 'Поискт по статусу' },
  },
};

const getTranslation = (id, locale) => {
  const noteData = InformNotesData[id] && InformNotesData[id][locale];
  
  if (!noteData) {
    return InformNotesData[id] && InformNotesData[id]['RU'];
  }

  return noteData;
};

const InformNotes = ({ id }) => {
  const noteData = getTranslation(id, locale);

  const title = noteData ? noteData.title : 'Default title';
  const content = noteData ? noteData.content : 'Default content';

  return (
    <>
      {id && (
        <Popover title={title} content={content}>
          <ExclamationCircleFilled id={id} style={{ color: '#FFA800' }} />
        </Popover>
      )}
    </>
  );
};

export default InformNotes;