import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestModifierGroupsGet,
  sendRequestModifierGroupsProductsGet,
} from '../../../../../../redux/reducers/utils/kitchenReducers/kitchenMenuReducer';
import { Breadcrumb, Typography, Divider, Tabs } from 'antd';
import EditableForm from './modifiersDetail/EditableForm';
import Loader from '../../../../../../components/Loader';
import ScrolledContainer from '../../../../../../components/ScrolledContainer';
import ModifiersTableComponent from './modifiersDetail/ModifiersTableComponent';
import { Container, Page, ScrolledCol } from '../../../../../../core/styles';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const ModifiersDetailComponent = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const { selectKitchen } = useSelector((store) => store.kitchen);
  const {
    modifierGroups,
    modifierGroupsCreateRequestStatus,
    modifierGroupsUpdateRequestStatus,
    modifierGroupsDeleteRequestStatus,
    currentModifierGroupId,
    modifierGroupsProducts,
    modifierGroupsProductCreateRequestStatus,
    modifierGroupsProductUpdateRequestStatus,
    modifierGroupsProductDeleteRequestStatus,
  } = useSelector((store) => store.kitchenMenu);
  const dispatch = useDispatch();

  const currentModifierGroups = modifierGroups.find(modifierGroup => modifierGroup.id.toString().toLowerCase() === currentModifierGroupId.toString().toLowerCase());

  useEffect(() => {
    if (
      modifierGroupsCreateRequestStatus === 'resolved' ||
      modifierGroupsUpdateRequestStatus === 'resolved' ||
      modifierGroupsDeleteRequestStatus === 'resolved'
    ) {
      const requestData = {
        token: localStorage.getItem('token'),
        kitchenId: selectKitchen.id,
      }
      dispatch(sendRequestModifierGroupsGet(requestData));
    }
  }, [
    modifierGroupsCreateRequestStatus,
    modifierGroupsUpdateRequestStatus,
    modifierGroupsDeleteRequestStatus,
  ]);

  useEffect(() => {
    if (currentModifierGroupId) {
      const requestData = {
        token: localStorage.getItem('token'),
        modifierGroupId: currentModifierGroupId,
      }

      dispatch(sendRequestModifierGroupsProductsGet(requestData));
    }
  }, [currentModifierGroupId]);

  useEffect(() => {
    if (
      modifierGroupsProductCreateRequestStatus === 'resolved' ||
      modifierGroupsProductUpdateRequestStatus === 'resolved' ||
      modifierGroupsProductDeleteRequestStatus === 'resolved' 
    ) {
      const requestData = {
        token: localStorage.getItem('token'),
        modifierGroupId: currentModifierGroupId,
      }

      dispatch(sendRequestModifierGroupsProductsGet(requestData));
    }
  }, [
      modifierGroupsProductCreateRequestStatus,
      modifierGroupsProductUpdateRequestStatus,
      modifierGroupsProductDeleteRequestStatus,
    ]
  );

  const breadcrumb = [
    { title: (
        <Link to='/kitchens/menumodifiers'>Группы модификаторов</Link>
      ) 
    },
    { title: (
        <Text>Группа "{currentModifierGroups?.name}"</Text>
      )
    },
  ]

  if (!currentModifierGroups) {
    return <div>Сервис не найден</div>;
  }

  return (
    <Page>
      {(
        modifierGroupsProductCreateRequestStatus === 'pending' ||
        modifierGroupsProductUpdateRequestStatus === 'pending' ||
        modifierGroupsProductDeleteRequestStatus === 'pending' 
      ) && <Loader/>}
      <ScrolledCol>
        <Container>
          <Breadcrumb items={breadcrumb} style={{marginTop: '15px'}}/>
          <Divider/>
          <Tabs defaultActiveKey="1" tabPosition="top">
            <TabPane tab="Настройки" key="1">
              <EditableForm modifier={currentModifierGroups}/>
            </TabPane>
            <TabPane tab="Продукты группы модификатора" key="2">
              <ModifiersTableComponent products={modifierGroupsProducts}/>
            </TabPane>
          </Tabs>
        </Container>
      </ScrolledCol>
    </Page>
  );
};

export default ModifiersDetailComponent;