import React from 'react';

const PrintPdfLink = ({ url, children }) => {
  const openPrintPdf = (event) => {
    event.preventDefault();
    const printWindow = window.open(url, '_blank');
    printWindow.addEventListener('load', () => {
      printWindow.print();
    }, { once: true });
  };

  return (
    <a href={url} onClick={openPrintPdf}>
      {children}
    </a>
  );
};

export default PrintPdfLink;