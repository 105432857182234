import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestTransferMenuTemplate,
  sendRequestDisableMenu,
  sendRequestEnableMenu,
  sendRequestCreateMenuTemplate,
} from '../../../../../../redux/reducers/utils/kitchenReducers/kitchenMenuReducer';
import { Dropdown, Button, Modal, Input } from 'antd';
import styled from 'styled-components';

const MenuTopRightDropdown = ({ published }) => {
  const { selectKitchen } = useSelector((store) => store.kitchen);
  const { selectedMenuRace, menuTemplates } = useSelector((store) => store.kitchenMenu);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalValue, setModalValue] = useState('');

  const handleOpenModal = () => setIsModalOpen(true);

  const handleModalSuccess = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      name: modalValue,
      kitchenId: selectKitchen.id,
      menuId: selectedMenuRace?.menuId,
    }

    console.log(requestData)
    dispatch(sendRequestCreateMenuTemplate(requestData))
    setIsModalOpen(false);
  }

  const handleModalCancel = () => {
    setModalValue('');
    setIsModalOpen(false);
  }

  const handleDisableMenu = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      menuId: selectedMenuRace.menuId,
    }

    dispatch(sendRequestDisableMenu(requestData));
  }

  const handleEnableMenu = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      menuId: selectedMenuRace.menuId,
    }

    dispatch(sendRequestEnableMenu(requestData));
  }

  const handleTransferMenuTemplateImMenu = (id) => {
    const requestData = {
      token: localStorage.getItem('token'),
      menuTemplateId: id,
      menuId: selectedMenuRace?.menuId,
    }

    dispatch(sendRequestTransferMenuTemplate(requestData));
  }

  const modifyMenuTemplates = menuTemplates.map((template) => ({
    key: template.id.toString(),
    label: (<a onClick={() => handleTransferMenuTemplateImMenu(template.id)}>{template.name}</a>),
  }));

  const items = [
    {
      key: 'copyTemplate',
      label: (<a onClick={handleOpenModal}>Сохранить как шаблон</a>),
    },
    published ? { 
      key: 'disableMenu',
      label: (<a onClick={handleDisableMenu}>Отключить всё меню</a>),
    }
    :
    {
      key: 'enabledMenu',
      label: (<a onClick={handleEnableMenu}>Включить всё меню</a>),
    },
    { key: '3', label: 'Скачать чек-листы' },
    { key: '4', label: 'Скачать производст. заказ' },
    {
      key: '5',
      type: 'group',
      label: 'Опубликовать из шаблона:',
      children: modifyMenuTemplates || [], 
    },
  ].filter(Boolean);

  return (
    <>
      <Dropdown menu={{items}} placement="bottomRight" trigger={['click']}>
        <StyledButton type="primary">•••</StyledButton>
      </Dropdown>
      <Modal title="Создание нового шаблона" open={isModalOpen} onOk={handleModalSuccess} onCancel={handleModalCancel}>
        <Input placeholder='Введите название шаблона' value={modalValue} onChange={(e) => setModalValue(e.target.value)}/>
      </Modal>
    </>
  );
};

export default MenuTopRightDropdown;

const StyledButton = styled(Button)`
  /* position: absolute;
  z-index: 600;
  top: 40px;
  right: 10px; */
  margin: 5px 0 0 auto;
`;