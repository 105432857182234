import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  //Delivery Statuses Orders
  deliveryStatusesOrdersGetRequestStatus: null,
  deliveryStatusesOrders: [],

  //Delivery Services
  deliveryServicesGetRequestStatus: null,
  deliveryServices: [],
  currentDeliveryService: null,
  deliveryServicesCreateRequestStatus: null,
  deliveryServicesUpdateRequestStatus: null,
  deliveryServicesDeleteRequestStatus: null,

  //Delivery Services Races
  deliveryServiceRacesGetRequestStatus: null,
  deliveryServiceRaces: [],
  currentDeliveryServiceRace: null,
  deliveryServiceRacesCreateRequestStatus: null,
  deliveryServiceRacesUpdateRequestStatus: null,
  deliveryServiceRacesDeleteRequestStatus: null,

  //Delivery Services Couriers
  deliveryServiceCouriersGetRequestStatus: null,
  deliveryServiceCouriers: [],
  currentDeliveryServiceCourier: null,
  deliveryServiceCouriersCreateRequestStatus: null,
  deliveryServiceCouriersUpdateRequestStatus: null,
  deliveryServiceCouriersDeleteRequestStatus: null,

  //Delivery Services Polygons
  deliveryServicePolygonsGetRequestStatus: null,
  deliveryServicePolygons: [],
  currentDeliveryServicePolygon: null,
  uploadNewPoligons: null,
  currentNewPoligon: null,
  deliveryServicePolygonCreateRequestStatus: null,
  deliveryServicePolygonUpdateRequestStatus: null,
  deliveryServicePolygonDeleteRequestStatus: null,
  deliveryServicePolygonsMassUpdateRequestStatus: null,

  //Delivery Service Race Couriers
  deliveryServiceRaceCouriersGetRequestStatus: null,
  deliveryServiceRaceCouriers: [],
  deliveryServiceRaceCourierEnableRequestStatus: null,
  deliveryServiceRaceCourierDisableRequestStatus: null,

  //Delivery Service Race Polygons
  deliveryServiceRacePolygonsGetRequestStatus: null,
  deliveryServiceRacePolygons: [],
  deliveryServiceRacePolygonEnableRequestStatus: null,
  deliveryServiceRacePolygonDisableRequestStatus: null,

  //Delivery Service Race Kitchens
  deliveryServiceRaceKitchensGetRequestStatus: null,
  deliveryServiceRaceKitchens: [],
  deliveryServiceRaceKitchenAddRequestStatus: null,
  deliveryServiceRaceKitchenUpdateRequestStatus: null,
  deliveryServiceRaceKitchenTermsCreateRequestStatus: null,
  deliveryServiceRaceKitchenTermsUpdateRequestStatus: null,
  deliveryServiceRaceKitchenTermsDeleteRequestStatus: null,

  isShowRaceKitchenDrawer: false,
  currentRaceKitchen: null,

  //Delivery Service Not Working
  deliveryServiceNotWorkingGetRequestStatus: null,
  deliveryServiceNotWorking: [],
  deliveryServiceNotWorkingCreateRequestStatus: null,
  deliveryServiceNotWorkingDeleteRequestStatus: null,
};

const deliveryServicesSlice = createSlice({
  name: 'deliveryServices',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    //Delivery Statuses Orders
    sendRequestDeliveryStatusesOrdersGet: (state) => { state.deliveryStatusesOrdersGetRequestStatus = 'pending'; },
    sendRequestDeliveryStatusesOrdersGetSuccess: (state) => { state.deliveryStatusesOrdersGetRequestStatus = 'resolved'; },
    sendRequestDeliveryStatusesOrdersGetError: (state) => { state.deliveryStatusesOrdersGetRequestStatus = 'rejected'; },
    clearDeliveryStatusesOrdersGetStatus: (state) => { state.deliveryStatusesOrdersGetRequestStatus = null; },
    setDeliveryStatusesOrders: (state, action) => {
      state.deliveryStatusesOrders = action.payload;
    },

    //Delivery Services
    sendRequestDeliveryServicesGet: (state) => { state.deliveryServicesGetRequestStatus = 'pending'; },
    sendRequestDeliveryServicesGetSuccess: (state) => { state.deliveryServicesGetRequestStatus = 'resolved'; },
    sendRequestDeliveryServicesGetError: (state) => { state.deliveryServicesGetRequestStatus = 'rejected'; },
    clearDeliveryServicesGetStatus: (state) => { state.deliveryServicesGetRequestStatus = null; },
    setDeliveryServices: (state, action) => {
      state.deliveryServices = action.payload;
    },
    updateDeliveryServices: (state, action) => {
      const updatedKeys = action.payload;

      return {
        ...state,
        deliveryServices: {
          ...state.deliveryServices,
          ...updatedKeys,
        },
      };
    },
    setCurrentDeliveryService: (state, action) => {
      state.currentDeliveryService = action.payload;
    },
    updateCurrentDeliveryService: (state, action) => {
      const updatedKeys = action.payload;

      return {
        ...state,
        currentDeliveryService: {
          ...state.currentDeliveryService,
          ...updatedKeys,
        },
      };
    },
    sendRequestDeliveryServicesCreate: (state) => { state.deliveryServicesCreateRequestStatus = 'pending'; },
    sendRequestDeliveryServicesCreateSuccess: (state) => { state.deliveryServicesCreateRequestStatus = 'resolved'; },
    sendRequestDeliveryServicesCreateError: (state) => { state.deliveryServicesCreateRequestStatus = 'rejected'; },
    clearDeliveryServicesCreateStatus: (state) => { state.deliveryServicesCreateRequestStatus = null; },

    sendRequestDeliveryServicesUpdate: (state) => { state.deliveryServicesUpdateRequestStatus = 'pending'; },
    sendRequestDeliveryServicesUpdateSuccess: (state) => { state.deliveryServicesUpdateRequestStatus = 'resolved'; },
    sendRequestDeliveryServicesUpdateError: (state) => { state.deliveryServicesUpdateRequestStatus = 'rejected'; },
    clearDeliveryServicesUpdateStatus: (state) => { state.deliveryServicesUpdateRequestStatus = null; },

    sendRequestDeliveryServicesDelete: (state) => { state.deliveryServicesDeleteRequestStatus = 'pending'; },
    sendRequestDeliveryServicesDeleteSuccess: (state) => { state.deliveryServicesDeleteRequestStatus = 'resolved'; },
    sendRequestDeliveryServicesDeleteError: (state) => { state.deliveryServicesDeleteRequestStatus = 'rejected'; },
    clearDeliveryServicesDeleteStatus: (state) => { state.deliveryServicesDeleteRequestStatus = null; },

    //Delivery Service Races
    sendRequestDeliveryServiceRacesGet: (state) => { state.deliveryServiceRacesGetRequestStatus = 'pending'; },
    sendRequestDeliveryServiceRacesGetSuccess: (state) => { state.deliveryServiceRacesGetRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceRacesGetError: (state) => { state.deliveryServiceRacesGetRequestStatus = 'rejected'; },
    clearDeliveryServiceRacesGetStatus: (state) => { state.deliveryServiceRacesGetRequestStatus = null; },
    setDeliveryServiceRaces: (state, action) => {
      state.deliveryServiceRaces = action.payload;
    },
    updateDeliveryServiceRaces: (state, action) => {
      const updatedKeys = action.payload;

      return {
        ...state,
        deliveryServiceRaces: {
          ...state.deliveryServiceRaces,
          ...updatedKeys,
        },
      };
    },
    setCurrentDeliveryServiceRace: (state, action) => {
      state.currentDeliveryServiceRace = action.payload;
    },
    updateCurrentDeliveryServiceRace: (state, action) => {
      const updatedKeys = action.payload;

      return {
        ...state,
        currentDeliveryServiceRace: {
          ...state.currentDeliveryServiceRace,
          ...updatedKeys,
        },
      };
    },
    sendRequestDeliveryServiceRacesCreate: (state) => { state.deliveryServiceRacesCreateRequestStatus = 'pending'; },
    sendRequestDeliveryServiceRacesCreateSuccess: (state) => { state.deliveryServiceRacesCreateRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceRacesCreateError: (state) => { state.deliveryServiceRacesCreateRequestStatus = 'rejected'; },
    clearDeliveryServiceRacesCreateStatus: (state) => { state.deliveryServiceRacesCreateRequestStatus = null; },

    sendRequestDeliveryServiceRacesUpdate: (state) => { state.deliveryServiceRacesUpdateRequestStatus = 'pending'; },
    sendRequestDeliveryServiceRacesUpdateSuccess: (state) => { state.deliveryServiceRacesUpdateRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceRacesUpdateError: (state) => { state.deliveryServiceRacesUpdateRequestStatus = 'rejected'; },
    clearDeliveryServiceRacesUpdateStatus: (state) => { state.deliveryServiceRacesUpdateRequestStatus = null; },

    sendRequestDeliveryServiceRacesDelete: (state) => { state.deliveryServiceRacesDeleteRequestStatus = 'pending'; },
    sendRequestDeliveryServiceRacesDeleteSuccess: (state) => { state.deliveryServiceRacesDeleteRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceRacesDeleteError: (state) => { state.deliveryServiceRacesDeleteRequestStatus = 'rejected'; },
    clearDeliveryServiceRacesDeleteStatus: (state) => { state.deliveryServiceRacesDeleteRequestStatus = null; },
  
    //Delivery Services Couriers
    sendRequestDeliveryServiceCouriersGet: (state) => { state.deliveryServiceCouriersGetRequestStatus = 'pending'; },
    sendRequestDeliveryServiceCouriersGetSuccess: (state) => { state.deliveryServiceCouriersGetRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceCouriersGetError: (state) => { state.deliveryServiceCouriersGetRequestStatus = 'rejected'; },
    clearDeliveryServiceCouriersGetStatus: (state) => { state.deliveryServiceCouriersGetRequestStatus = null; },
    setDeliveryServiceCouriers: (state, action) => {
      state.deliveryServiceCouriers = action.payload;
    },
    updateDeliveryServiceCouriers: (state, action) => {
      const updatedKeys = action.payload;

      return {
        ...state,
        deliveryServiceCouriers: {
          ...state.deliveryServiceCouriers,
          ...updatedKeys,
        },
      };
    },
    setCurrentDeliveryServiceCourier: (state, action) => {
      state.currentDeliveryServiceCourier = action.payload;
    },
    updateCurrentDeliveryServiceCourier: (state, action) => {
      const updatedKeys = action.payload;

      return {
        ...state,
        currentDeliveryServiceCourier: {
          ...state.currentDeliveryServiceCourier,
          ...updatedKeys,
        },
      };
    },
    sendRequestDeliveryServiceCouriersCreate: (state) => { state.deliveryServiceCouriersCreateRequestStatus = 'pending'; },
    sendRequestDeliveryServiceCouriersCreateSuccess: (state) => { state.deliveryServiceCouriersCreateRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceCouriersCreateError: (state) => { state.deliveryServiceCouriersCreateRequestStatus = 'rejected'; },
    clearDeliveryServiceCouriersCreateStatus: (state) => { state.deliveryServiceCouriersCreateRequestStatus = null; },
    
    sendRequestDeliveryServiceCouriersUpdate: (state) => { state.deliveryServiceCouriersUpdateRequestStatus = 'pending'; },
    sendRequestDeliveryServiceCouriersUpdateSuccess: (state) => { state.deliveryServiceCouriersUpdateRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceCouriersUpdateError: (state) => { state.deliveryServiceCouriersUpdateRequestStatus = 'rejected'; },
    clearDeliveryServiceCouriersUpdateStatus: (state) => { state.deliveryServiceCouriersUpdateRequestStatus = null; },
    
    sendRequestDeliveryServiceCouriersDelete: (state) => { state.deliveryServiceCouriersDeleteRequestStatus = 'pending'; },
    sendRequestDeliveryServiceCouriersDeleteSuccess: (state) => { state.deliveryServiceCouriersDeleteRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceCouriersDeleteError: (state) => { state.deliveryServiceCouriersDeleteRequestStatus = 'rejected'; },
    clearDeliveryServiceCouriersDeleteStatus: (state) => { state.deliveryServiceCouriersDeleteRequestStatus = null; },

    //Delivery Services Polygons
    sendRequestDeliveryServicePolygonsGet: (state) => { state.deliveryServicePolygonsGetRequestStatus = 'pending'; },
    sendRequestDeliveryServicePolygonsGetSuccess: (state) => { state.deliveryServicePolygonsGetRequestStatus = 'resolved'; },
    sendRequestDeliveryServicePolygonsGetError: (state) => { state.deliveryServicePolygonsGetRequestStatus = 'rejected'; },
    clearDeliveryServicePolygonsGetStatus: (state) => { state.deliveryServicePolygonsGetRequestStatus = null; },
    setDeliveryServicePolygons: (state, action) => {
      state.deliveryServicePolygons = action.payload;
    },
    updateDeliveryServicePolygons: (state, action) => {
      const updatedKeys = action.payload;

      return {
        ...state,
        deliveryServicePolygons: {
          ...state.deliveryServicePolygons,
          ...updatedKeys,
        },
      };
    },
    setCurrentDeliveryServicePolygon: (state, action) => {
      state.currentDeliveryServicePolygon = action.payload;
    },
    setUploadNewPoligons: (state, action) => {
      state.uploadNewPoligons = action.payload;
    },
    setCurrentNewPoligon: (state, action) => {
      state.currentNewPoligon = action.payload;
    },

    sendRequestDeliveryServicePolygonCreate: (state) => { state.deliveryServicePolygonCreateRequestStatus = 'pending'; },
    sendRequestDeliveryServicePolygonCreateSuccess: (state) => { state.deliveryServicePolygonCreateRequestStatus = 'resolved'; },
    sendRequestDeliveryServicePolygonCreateError: (state) => { state.deliveryServicePolygonCreateRequestStatus = 'rejected'; },
    clearDeliveryServicePolygonCreateStatus: (state) => { state.deliveryServicePolygonCreateRequestStatus = null; },
    
    sendRequestDeliveryServicePolygonUpdate: (state) => { state.deliveryServicePolygonUpdateRequestStatus = 'pending'; },
    sendRequestDeliveryServicePolygonUpdateSuccess: (state) => { state.deliveryServicePolygonUpdateRequestStatus = 'resolved'; },
    sendRequestDeliveryServicePolygonUpdateError: (state) => { state.deliveryServicePolygonUpdateRequestStatus = 'rejected'; },
    clearDeliveryServicePolygonUpdateStatus: (state) => { state.deliveryServicePolygonUpdateRequestStatus = null; },
    
    sendRequestDeliveryServicePolygonDelete: (state) => { state.deliveryServicePolygonDeleteRequestStatus = 'pending'; },
    sendRequestDeliveryServicePolygonDeleteSuccess: (state) => { state.deliveryServicePolygonDeleteRequestStatus = 'resolved'; },
    sendRequestDeliveryServicePolygonDeleteError: (state) => { state.deliveryServicePolygonDeleteRequestStatus = 'rejected'; },
    clearDeliveryServicePolygonDeleteStatus: (state) => { state.deliveryServicePolygonDeleteRequestStatus = null; },

    sendRequestDeliveryServicePolygonsMassUpdate: (state) => { state.deliveryServicePolygonsMassUpdateRequestStatus = 'pending'; },
    sendRequestDeliveryServicePolygonsMassUpdateSuccess: (state) => { state.deliveryServicePolygonsMassUpdateRequestStatus = 'resolved'; },
    sendRequestDeliveryServicePolygonsMassUpdateError: (state) => { state.deliveryServicePolygonsMassUpdateRequestStatus = 'rejected'; },
    clearDeliveryServicePolygonsMassUpdateStatus: (state) => { state.deliveryServicePolygonsMassUpdateRequestStatus = null; },
    
    //Delivery Service Race Couriers
    sendRequestDeliveryServiceRaceCouriersGet: (state) => { state.deliveryServiceRaceCouriersGetRequestStatus = 'pending'; },
    sendRequestDeliveryServiceRaceCouriersGetSuccess: (state) => { state.deliveryServiceRaceCouriersGetRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceRaceCouriersGetError: (state) => { state.deliveryServiceRaceCouriersGetRequestStatus = 'rejected'; },
    clearDeliveryServiceRaceCouriersGetStatus: (state) => { state.deliveryServiceRaceCouriersGetRequestStatus = null; },
    setDeliveryServiceRaceCouriers: (state, action) => {
      state.deliveryServiceRaceCouriers = action.payload;
    },
    updateDeliveryServiceRaceCourierById: (state, action) => {
      const updatedItem = action.payload;
    
      const updatedData = state.deliveryServiceRaceCouriers.map(stateItem =>
        stateItem.courierId === updatedItem.courierId
          ? { ...stateItem, ...updatedItem }
          : stateItem
      );
    
      return {
        ...state,
        deliveryServiceRaceCouriers: updatedData,
      };
    },

    sendRequestDeliveryServiceRaceCourierEnable: (state) => { state.deliveryServiceRaceCourierEnableRequestStatus = 'pending'; },
    sendRequestDeliveryServiceRaceCourierEnableSuccess: (state) => { state.deliveryServiceRaceCourierEnableRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceRaceCourierEnableError: (state) => { state.deliveryServiceRaceCourierEnableRequestStatus = 'rejected'; },
    clearDeliveryServiceRaceCourierEnableStatus: (state) => { state.deliveryServiceRaceCourierEnableRequestStatus = null; },

    sendRequestDeliveryServiceRaceCourierDisable: (state) => { state.deliveryServiceRaceCourierDisableRequestStatus = 'pending'; },
    sendRequestDeliveryServiceRaceCourierDisableSuccess: (state) => { state.deliveryServiceRaceCourierDisableRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceRaceCourierDisableError: (state) => { state.deliveryServiceRaceCourierDisableRequestStatus = 'rejected'; },
    clearDeliveryServiceRaceCourierDisableStatus: (state) => { state.deliveryServiceRaceCourierDisableRequestStatus = null; },

    // //Delivery Service Race Polygons
    sendRequestDeliveryServiceRacePolygonsGet: (state) => { state.deliveryServiceRacePolygonsGetRequestStatus = 'pending'; },
    sendRequestDeliveryServiceRacePolygonsGetSuccess: (state) => { state.deliveryServiceRacePolygonsGetRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceRacePolygonsGetError: (state) => { state.deliveryServiceRacePolygonsGetRequestStatus = 'rejected'; },
    clearDeliveryServiceRacePolygonsGetStatus: (state) => { state.deliveryServiceRacePolygonsGetRequestStatus = null; },
    setDeliveryServiceRacePolygons: (state, action) => {
      state.deliveryServiceRacePolygons = action.payload;
    },
    updateDeliveryServiceRacePolygonById: (state, action) => {
      const updatedItem = action.payload;
    
      const updatedData = state.deliveryServiceRacePolygons.map(stateItem =>
        stateItem.polygonId === updatedItem.polygonId
          ? { ...stateItem, ...updatedItem }
          : stateItem
      );
    
      return {
        ...state,
        deliveryServiceRacePolygons: updatedData,
      };
    },

    sendRequestDeliveryServiceRacePolygonEnable: (state) => { state.deliveryServiceRacePolygonEnableRequestStatus = 'pending'; },
    sendRequestDeliveryServiceRacePolygonEnableSuccess: (state) => { state.deliveryServiceRacePolygonEnableRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceRacePolygonEnableError: (state) => { state.deliveryServiceRacePolygonEnableRequestStatus = 'rejected'; },
    clearDeliveryServiceRacePolygonEnableStatus: (state) => { state.deliveryServiceRacePolygonEnableRequestStatus = null; },

    sendRequestDeliveryServiceRacePolygonDisable: (state) => { state.deliveryServiceRacePolygonDisableRequestStatus = 'pending'; },
    sendRequestDeliveryServiceRacePolygonDisableSuccess: (state) => { state.deliveryServiceRacePolygonDisableRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceRacePolygonDisableError: (state) => { state.deliveryServiceRacePolygonDisableRequestStatus = 'rejected'; },
    clearDeliveryServiceRacePolygonDisableStatus: (state) => { state.deliveryServiceRacePolygonDisableRequestStatus = null; },

    // //Delivery Service Race Kitchens
    sendRequestDeliveryServiceRaceKitchensGet: (state) => { state.deliveryServiceRaceKitchensGetRequestStatus = 'pending'; },
    sendRequestDeliveryServiceRaceKitchensGetSuccess: (state) => { state.deliveryServiceRaceKitchensGetRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceRaceKitchensGetError: (state) => { state.deliveryServiceRaceKitchensGetRequestStatus = 'rejected'; },
    clearDeliveryServiceRaceKitchensGetStatus: (state) => { state.deliveryServiceRaceKitchensGetRequestStatus = null; },
    setDeliveryServiceRaceKitchens: (state, action) => {
      state.deliveryServiceRaceKitchens = action.payload;
    },
    updateDeliveryServiceRaceKitchenById: (state, action) => {
      const updatedItem = action.payload;
    
      const updatedData = state.deliveryServiceRaceKitchens.map(stateItem =>
        stateItem.kitchenId === updatedItem.kitchenId
          ? { ...stateItem, ...updatedItem }
          : stateItem
      );
    
      return {
        ...state,
        deliveryServiceRaceKitchens: updatedData,
      };
    },

    sendRequestDeliveryServiceRaceKitchenAdd: (state) => { state.deliveryServiceRaceKitchenAddRequestStatus = 'pending'; },
    sendRequestDeliveryServiceRaceKitchenAddSuccess: (state) => { state.deliveryServiceRaceKitchenAddRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceRaceKitchenAddError: (state) => { state.deliveryServiceRaceKitchenAddRequestStatus = 'rejected'; },
    clearDeliveryServiceRaceKitchenAddStatus: (state) => { state.deliveryServiceRaceKitchenAddRequestStatus = null; },

    sendRequestDeliveryServiceRaceKitchenUpdate: (state) => { state.deliveryServiceRaceKitchenUpdateRequestStatus = 'pending'; },
    sendRequestDeliveryServiceRaceKitchenUpdateSuccess: (state) => { state.deliveryServiceRaceKitchenUpdateRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceRaceKitchenUpdateError: (state) => { state.deliveryServiceRaceKitchenUpdateRequestStatus = 'rejected'; },
    clearDeliveryServiceRaceKitchenUpdateStatus: (state) => { state.deliveryServiceRaceKitchenUpdateRequestStatus = null; },

    sendRequestDeliveryServiceRaceKitchenTermsCreate: (state) => { state.deliveryServiceRaceKitchenTermsCreateRequestStatus = 'pending'; },
    sendRequestDeliveryServiceRaceKitchenTermsCreateSuccess: (state) => { state.deliveryServiceRaceKitchenTermsCreateRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceRaceKitchenTermsCreateError: (state) => { state.deliveryServiceRaceKitchenTermsCreateRequestStatus = 'rejected'; },
    clearDeliveryServiceRaceKitchenTermsCreateStatus: (state) => { state.deliveryServiceRaceKitchenTermsCreateRequestStatus = null; },

    sendRequestDeliveryServiceRaceKitchenTermsUpdate: (state) => { state.deliveryServiceRaceKitchenTermsUpdateRequestStatus = 'pending'; },
    sendRequestDeliveryServiceRaceKitchenTermsUpdateSuccess: (state) => { state.deliveryServiceRaceKitchenTermsUpdateRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceRaceKitchenTermsUpdateError: (state) => { state.deliveryServiceRaceKitchenTermsUpdateRequestStatus = 'rejected'; },
    clearDeliveryServiceRaceKitchenTermsUpdateStatus: (state) => { state.deliveryServiceRaceKitchenTermsUpdateRequestStatus = null; },

    sendRequestDeliveryServiceRaceKitchenTermsDelete: (state) => { state.deliveryServiceRaceKitchenTermsDeleteRequestStatus = 'pending'; },
    sendRequestDeliveryServiceRaceKitchenTermsDeleteSuccess: (state) => { state.deliveryServiceRaceKitchenTermsDeleteRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceRaceKitchenTermsDeleteError: (state) => { state.deliveryServiceRaceKitchenTermsDeleteRequestStatus = 'rejected'; },
    clearDeliveryServiceRaceKitchenTermsDeleteStatus: (state) => { state.deliveryServiceRaceKitchenTermsDeleteRequestStatus = null; },
  
    setIsShowRaceKitchenDrawer: (state, action) => {
      state.isShowRaceKitchenDrawer = action.payload;
    },
    setCurrentRaceKitchen: (state, action) => {
      state.currentRaceKitchen = action.payload;
    },

    //Delivery Service Not Working
    sendRequestDeliveryServiceNotWorkingGet: (state) => { state.deliveryServiceNotWorkingGetRequestStatus = 'pending'; },
    sendRequestDeliveryServiceNotWorkingGetSuccess: (state) => { state.deliveryServiceNotWorkingGetRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceNotWorkingGetError: (state) => { state.deliveryServiceNotWorkingGetRequestStatus = 'rejected'; },
    clearDeliveryServiceNotWorkingGetStatus: (state) => { state.deliveryServiceNotWorkingGetRequestStatus = null; },
    setDeliveryServiceNotWorking: (state, action) => {
      state.deliveryServiceNotWorking = action.payload;
    },

    sendRequestDeliveryServiceNotWorkingCreate: (state) => { state.deliveryServiceNotWorkingCreateRequestStatus = 'pending'; },
    sendRequestDeliveryServiceNotWorkingCreateSuccess: (state) => { state.deliveryServiceNotWorkingCreateRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceNotWorkingCreateError: (state) => { state.deliveryServiceNotWorkingCreateRequestStatus = 'rejected'; },
    clearDeliveryServiceNotWorkingCreateStatus: (state) => { state.deliveryServiceNotWorkingCreateRequestStatus = null; },

    sendRequestDeliveryServiceNotWorkingDelete: (state) => { state.deliveryServiceNotWorkingDeleteRequestStatus = 'pending'; },
    sendRequestDeliveryServiceNotWorkingDeleteSuccess: (state) => { state.deliveryServiceNotWorkingDeleteRequestStatus = 'resolved'; },
    sendRequestDeliveryServiceNotWorkingDeleteError: (state) => { state.deliveryServiceNotWorkingDeleteRequestStatus = 'rejected'; },
    clearDeliveryServiceNotWorkingDeleteStatus: (state) => { state.deliveryServiceNotWorkingDeleteRequestStatus = null; },
  },
});

export const {
  //Delivery Statuses Orders
  sendRequestDeliveryStatusesOrdersGet,
  sendRequestDeliveryStatusesOrdersGetSuccess,
  sendRequestDeliveryStatusesOrdersGetError,
  clearDeliveryStatusesOrdersGetStatus,
  setDeliveryStatusesOrders,

  //Delivery Services
  sendRequestDeliveryServicesGet,
  sendRequestDeliveryServicesGetSuccess,
  sendRequestDeliveryServicesGetError,
  clearDeliveryServicesGetStatus,
  setDeliveryServices,
  updateDeliveryServices,
  setCurrentDeliveryService,
  updateCurrentDeliveryService,
  sendRequestDeliveryServicesCreate,
  sendRequestDeliveryServicesCreateSuccess,
  sendRequestDeliveryServicesCreateError,
  clearDeliveryServicesCreateStatus,
  sendRequestDeliveryServicesUpdate,
  sendRequestDeliveryServicesUpdateSuccess,
  sendRequestDeliveryServicesUpdateError,
  clearDeliveryServicesUpdateStatus,
  sendRequestDeliveryServicesDelete,
  sendRequestDeliveryServicesDeleteSuccess,
  sendRequestDeliveryServicesDeleteError,
  clearDeliveryServicesDeleteStatus,

  //Delivery Service Races
  sendRequestDeliveryServiceRacesGet,
  sendRequestDeliveryServiceRacesGetSuccess,
  sendRequestDeliveryServiceRacesGetError,
  clearDeliveryServiceRacesGetStatus,
  setDeliveryServiceRaces,
  updateDeliveryServiceRaces,
  setCurrentDeliveryServiceRace,
  updateCurrentDeliveryServiceRace,
  sendRequestDeliveryServiceRacesCreate,
  sendRequestDeliveryServiceRacesCreateSuccess,
  sendRequestDeliveryServiceRacesCreateError,
  clearDeliveryServiceRacesCreateStatus,
  sendRequestDeliveryServiceRacesUpdate,
  sendRequestDeliveryServiceRacesUpdateSuccess,
  sendRequestDeliveryServiceRacesUpdateError,
  clearDeliveryServiceRacesUpdateStatus,
  sendRequestDeliveryServiceRacesDelete,
  sendRequestDeliveryServiceRacesDeleteSuccess,
  sendRequestDeliveryServiceRacesDeleteError,
  clearDeliveryServiceRacesDeleteStatus,

  //Delivery Services Couriers
  sendRequestDeliveryServiceCouriersGet,
  sendRequestDeliveryServiceCouriersGetSuccess,
  sendRequestDeliveryServiceCouriersGetError,
  clearDeliveryServiceCouriersGetStatus,
  setDeliveryServiceCouriers,
  updateDeliveryServiceCouriers,
  setCurrentDeliveryServiceCourier,
  updateCurrentDeliveryServiceCourier,
  sendRequestDeliveryServiceCouriersCreate,
  sendRequestDeliveryServiceCouriersCreateSuccess,
  sendRequestDeliveryServiceCouriersCreateError,
  clearDeliveryServiceCouriersCreateStatus,
  sendRequestDeliveryServiceCouriersUpdate,
  sendRequestDeliveryServiceCouriersUpdateSuccess,
  sendRequestDeliveryServiceCouriersUpdateError,
  clearDeliveryServiceCouriersUpdateStatus,
  sendRequestDeliveryServiceCouriersDelete,
  sendRequestDeliveryServiceCouriersDeleteSuccess,
  sendRequestDeliveryServiceCouriersDeleteError,
  clearDeliveryServiceCouriersDeleteStatus,

  //Delivery Services Polygons
  sendRequestDeliveryServicePolygonsGet,
  sendRequestDeliveryServicePolygonsGetSuccess,
  sendRequestDeliveryServicePolygonsGetError,
  clearDeliveryServicePolygonsGetStatus,
  setDeliveryServicePolygons,
  updateDeliveryServicePolygons,
  setCurrentDeliveryServicePolygon,
  setUploadNewPoligons,
  setCurrentNewPoligon,
  sendRequestDeliveryServicePolygonCreate,
  sendRequestDeliveryServicePolygonCreateSuccess,
  sendRequestDeliveryServicePolygonCreateError,
  clearDeliveryServicePolygonCreateStatus,
  sendRequestDeliveryServicePolygonUpdate,
  sendRequestDeliveryServicePolygonUpdateSuccess,
  sendRequestDeliveryServicePolygonUpdateError,
  clearDeliveryServicePolygonUpdateStatus,
  sendRequestDeliveryServicePolygonDelete,
  sendRequestDeliveryServicePolygonDeleteSuccess,
  sendRequestDeliveryServicePolygonDeleteError,
  clearDeliveryServicePolygonDeleteStatus,
  sendRequestDeliveryServicePolygonsMassUpdate,
  sendRequestDeliveryServicePolygonsMassUpdateSuccess,
  sendRequestDeliveryServicePolygonsMassUpdateError,
  clearDeliveryServicePolygonsMassUpdateStatus,

  //Delivery Service Race Couriers
  sendRequestDeliveryServiceRaceCouriersGet,
  sendRequestDeliveryServiceRaceCouriersGetSuccess,
  sendRequestDeliveryServiceRaceCouriersGetError,
  clearDeliveryServiceRaceCouriersGetStatus,
  setDeliveryServiceRaceCouriers,
  updateDeliveryServiceRaceCourierById,
  sendRequestDeliveryServiceRaceCourierEnable,
  sendRequestDeliveryServiceRaceCourierEnableSuccess,
  sendRequestDeliveryServiceRaceCourierEnableError,
  clearDeliveryServiceRaceCourierEnableStatus,
  sendRequestDeliveryServiceRaceCourierDisable,
  sendRequestDeliveryServiceRaceCourierDisableSuccess,
  sendRequestDeliveryServiceRaceCourierDisableError,
  clearDeliveryServiceRaceCourierDisableStatus,

  //Delivery Service Race Polygons
  sendRequestDeliveryServiceRacePolygonsGet,
  sendRequestDeliveryServiceRacePolygonsGetSuccess,
  sendRequestDeliveryServiceRacePolygonsGetError,
  clearDeliveryServiceRacePolygonsGetStatus,
  setDeliveryServiceRacePolygons,
  updateDeliveryServiceRacePolygonById,
  sendRequestDeliveryServiceRacePolygonEnable,
  sendRequestDeliveryServiceRacePolygonEnableSuccess,
  sendRequestDeliveryServiceRacePolygonEnableError,
  clearDeliveryServiceRacePolygonEnableStatus,
  sendRequestDeliveryServiceRacePolygonDisable,
  sendRequestDeliveryServiceRacePolygonDisableSuccess,
  sendRequestDeliveryServiceRacePolygonDisableError,
  clearDeliveryServiceRacePolygonDisableStatus,

  //Delivery Service Race Kitchens
  sendRequestDeliveryServiceRaceKitchensGet,
  sendRequestDeliveryServiceRaceKitchensGetSuccess,
  sendRequestDeliveryServiceRaceKitchensGetError,
  clearDeliveryServiceRaceKitchensGetStatus,
  setDeliveryServiceRaceKitchens,
  updateDeliveryServiceRaceKitchenById,
  sendRequestDeliveryServiceRaceKitchenAdd,
  sendRequestDeliveryServiceRaceKitchenAddSuccess,
  sendRequestDeliveryServiceRaceKitchenAddError,
  clearDeliveryServiceRaceKitchenAddStatus,
  sendRequestDeliveryServiceRaceKitchenUpdate,
  sendRequestDeliveryServiceRaceKitchenUpdateSuccess,
  sendRequestDeliveryServiceRaceKitchenUpdateError,
  clearDeliveryServiceRaceKitchenUpdateStatus,

  sendRequestDeliveryServiceRaceKitchenTermsCreate,
  sendRequestDeliveryServiceRaceKitchenTermsCreateSuccess,
  sendRequestDeliveryServiceRaceKitchenTermsCreateError,
  clearDeliveryServiceRaceKitchenTermsCreateStatus,
  sendRequestDeliveryServiceRaceKitchenTermsUpdate,
  sendRequestDeliveryServiceRaceKitchenTermsUpdateSuccess,
  sendRequestDeliveryServiceRaceKitchenTermsUpdateError,
  clearDeliveryServiceRaceKitchenTermsUpdateStatus,
  sendRequestDeliveryServiceRaceKitchenTermsDelete,
  sendRequestDeliveryServiceRaceKitchenTermsDeleteSuccess,
  sendRequestDeliveryServiceRaceKitchenTermsDeleteError,
  clearDeliveryServiceRaceKitchenTermsDeleteStatus,
  setIsShowRaceKitchenDrawer,
  setCurrentRaceKitchen,

  //Delivery Service Not Working
  sendRequestDeliveryServiceNotWorkingGet,
  sendRequestDeliveryServiceNotWorkingGetSuccess,
  sendRequestDeliveryServiceNotWorkingGetError,
  clearDeliveryServiceNotWorkingGetStatus,
  setDeliveryServiceNotWorking,
  sendRequestDeliveryServiceNotWorkingCreate,
  sendRequestDeliveryServiceNotWorkingCreateSuccess,
  sendRequestDeliveryServiceNotWorkingCreateError,
  clearDeliveryServiceNotWorkingCreateStatus,
  sendRequestDeliveryServiceNotWorkingDelete,
  sendRequestDeliveryServiceNotWorkingDeleteSuccess,
  sendRequestDeliveryServiceNotWorkingDeleteError,
  clearDeliveryServiceNotWorkingDeleteStatus,
} = deliveryServicesSlice.actions;

export default deliveryServicesSlice.reducer;
