import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCouriersShow,
  sendRequestWhereCouriersGet,
  updateCourierLocationAndRoute,
} from '../../../../../redux/reducers/utils/deliveryReducers/deliveryMapReducer';
import { Flex, Space, Typography, Divider, Button } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import CourierCard from './CourierCard';
import { ReactComponent as IconUser } from '../../../../../assets/icons/icon-user.svg';
import { formatPrice } from '../../../../../core/utils'
import CourierProgressBar from './CourierProgressBar';
import moment from 'moment';

const { Title, Text } = Typography;

const CourierComponent = () => {
  const {
    couriersShow,
    couriers,
    selectedFilters,
    selectedCourierLocationAndRoute,
  } = useSelector((store) => store.deliveryMap);
  const dispatch = useDispatch();

  const couriersComplited = couriers.reduce((total, courier) => total + courier.lastPoint, 0);

  const totalPrice = couriers.reduce((total, courier) => total + courier.salary.sum, 0);

  const minTimeFirstOrderDelivered = couriers.reduce((min, courier) => {
    const deliveryTime = parseFloat(courier.firstDeliveryTimeTimestamp);
    return !isNaN(deliveryTime) ? Math.min(min, deliveryTime) : min;
  }, Infinity);

  const maxTimeLastOrderDelivered = couriers.reduce((max, courier) => {
    const deliveryTime = parseFloat(courier.lastDeliveryTimeTimestamp);
    return !isNaN(deliveryTime) ? Math.max(max, deliveryTime) : max;
  }, -Infinity);

  const numberAddresses = couriers.reduce((total, courier) => total + courier.numberAddresses, 0);

  const numberAddressesDelivered = couriers.reduce(
    (total, courier) => total + courier.numberAddressesDelivered,
    0
  );

  const couriersIds = couriers.map(courier => courier.courierId);

  const handleOpen = () => {
    dispatch(setCouriersShow(true));
  };

  const handleClose = () => {
    dispatch(setCouriersShow(false));
  };

  const handleProgressBarClick = () => {
    const showAllCourier = selectedCourierLocationAndRoute.showAllCourier;
  
    if (showAllCourier) {
      dispatch(updateCourierLocationAndRoute({ showAllCourier: false, locationCourierIds: selectedCourierLocationAndRoute.trackCourierIds }));
    } else {
      dispatch(updateCourierLocationAndRoute({ showAllCourier: true, locationCourierIds: couriersIds }));
    }
  };

  useEffect(() => {
    if (selectedFilters.date) {
      const requestData = {
        token: localStorage.getItem('token'),
        ...selectedCourierLocationAndRoute,
        deliveryDay: selectedFilters.date,
      };

      dispatch(sendRequestWhereCouriersGet(requestData));
    }
  }, [selectedCourierLocationAndRoute])

  return (
    <Wrapper>
    {!couriersShow && 
      <Button
        style={{marginTop: '10px', marginRight: '10px'}}
        onClick={handleOpen}
        type="primary"
      >
        <MenuOutlined/>
      </Button>
    }
    
    {couriersShow &&
      <Flex
        vertical
        style={{
          minWidth: '300px',
          height: 'calc(100vh - 40px)',
          background: '#ffffff',
          borderRight: '1px solid #dddddd'
        }}
      >
        <Button
          style={{ position: 'absolute', top: '5px', right: '5px', zIndex: 800 }}
          type='text'
          icon={<CloseOutlined/>}
          onClick={handleClose}
        />
        <Flex
          vertical
          style={{
            background: '#e6f4ff',
          }}
        >
          <Title level={5} type='secondary' style={{margin: '7px 10px'}}>Курьеры</Title>
          <Flex align='center' style={{padding: '4px 10px 10px'}}>
            <Space style={{ width: '100px' }}>
              <Title level={5} style={{margin: '0'}}><IconUser /> {couriersComplited}/{couriers.length}</Title>
            </Space>
            <CourierProgressBar
              addresses={numberAddresses}
              addressesDelivered={numberAddressesDelivered}
              timeStart={isFinite(minTimeFirstOrderDelivered) ? moment(minTimeFirstOrderDelivered * 1000).format('HH:mm') : null}
              timeFinish={isFinite(maxTimeLastOrderDelivered) ? moment(maxTimeLastOrderDelivered * 1000).format('HH:mm') : null}
              active={selectedCourierLocationAndRoute.showAllCourier}
              onClick={handleProgressBarClick}
            />
            <Flex vertical align='flex-end' style={{ width: '100px' }}>
              <Title level={5} style={{margin: '0'}}>{formatPrice(totalPrice)} р</Title>
            </Flex>
          </Flex>
        </Flex>

        <StyledContent>
          {couriers.filter(courier => courier.numberAddresses > 0).length > 0 ? (
            couriers
              .filter(courier => courier.numberAddresses > 0)
              .map((courier, index) => (
                <CourierCard key={index} courier={courier} />
              ))
          ) : (
            <Text type='secondary' style={{margin: '7px 10px'}}>Нет курьеров назначенных на адрес</Text>
          )}
          <Divider/>
          {couriers.filter(courier => courier.numberAddresses === 0 && courier.isOnline === true).length > 0 ? (
            couriers
              .filter(courier => courier.numberAddresses === 0)
              .map((courier, index) => (
                <CourierCard key={index} courier={courier} />
              ))
          ) : (
            <Text type='secondary' style={{margin: '7px 10px'}}>Нет курьеров готовых к работе</Text>
          )}
          <Divider/>
          {couriers.filter(courier => courier.numberAddresses === 0 && courier.isOnline === false).length > 0 && (
            couriers
              .filter(courier => courier.numberAddresses === 0)
              .map((courier, index) => (
                <CourierCard key={index} courier={courier} />
              ))
          )}
        </StyledContent>
      </Flex>
    }
  </Wrapper>
  );
};

export default CourierComponent;

const Wrapper = styled.div`

`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: auto;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
  }
`;
