import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestAccessRoleComponentsGet,
  sendRequestAccessGetComponentsForUser,
  sendRequestAccessRoleComponentsCreate,
  clearAccessRoleComponentsCreateStatus,
  sendRequestAccessRoleComponentsDelete,
  clearAccessRoleComponentsDeleteStatus,
} from '../../../../redux/reducers/utils/servicesReducer';
import { Row, Col, Typography } from 'antd'
import Loader from '../../../../components/Loader';
import AccessSelectRoleComponent from '../../components/AccessSelectRoleComponent';
import AccessListRoleComponent from './components/AccessListRoleComponent';
import ScrolledContainer from '../../../../components/ScrolledContainer';

const { Title } = Typography;

const RoleAccessPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const {
    accessRoleComponents,
    accessRoleComponentsGetRequestStatus,
    accessRoleComponentsCreateRequestStatus,
    accessRoleComponentsUpdateRequestStatus,
    accessRoleComponentsDeleteRequestStatus,
  } = useSelector((store) => store.services);
  const dispatch = useDispatch();
  const components = accessRoleComponents.components;
  const roleComponents = accessRoleComponents.roleComponents;
  const roles = accessRoleComponents.roles;
  const [selectedRole, setSelectedRole] = useState({id: 1, value: 'super_administrator', label: 'Суперадминистратора'});

  const onSwitchChange = (isChecked, componentId, roleId, roleComponentId) => {
    const createRequestData = {
      token: localStorage.getItem('token'),
      roleComponents: [
        {
          componentId: componentId,
          roleId: roleId,
        }
      ]
    }
    
    const deleteRequestData = {
      token: localStorage.getItem('token'),
      roleComponents: [
        {
          roleComponentId: roleComponentId,
        }
      ]
    }

    if (isChecked) {
      console.log('createRequestData', createRequestData);
      dispatch(sendRequestAccessRoleComponentsCreate(createRequestData));
    } else {
      console.log('deleteRequestData', deleteRequestData);
      dispatch(sendRequestAccessRoleComponentsDelete(deleteRequestData));
    }
  }

  useEffect(() => {
    if (accessRoleComponentsCreateRequestStatus === 'resolved') {
      const requestData = {
        token: localStorage.getItem('token')
      }
      
      dispatch(sendRequestAccessRoleComponentsGet(requestData));
      dispatch(sendRequestAccessGetComponentsForUser(requestData));
      dispatch(clearAccessRoleComponentsCreateStatus());
    }
    if (accessRoleComponentsDeleteRequestStatus === 'resolved') {
      const requestData = {
        token: localStorage.getItem('token')
      }
      
      dispatch(sendRequestAccessRoleComponentsGet(requestData));
      dispatch(sendRequestAccessGetComponentsForUser(requestData));
      dispatch(clearAccessRoleComponentsDeleteStatus());
    }
  }, [accessRoleComponentsCreateRequestStatus, accessRoleComponentsDeleteRequestStatus])

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestAccessRoleComponentsGet({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  return (
    <ScrolledContainer>
      {(
        accessRoleComponentsGetRequestStatus === 'pending' ||
        accessRoleComponentsCreateRequestStatus === 'pending' ||
        accessRoleComponentsUpdateRequestStatus === 'pending' ||
        accessRoleComponentsDeleteRequestStatus === 'pending'
      ) && <Loader />}
      <Title>Управление доступами</Title>
      {roles && <AccessSelectRoleComponent
        roles={roles}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
      />}
      {components && roleComponents && <AccessListRoleComponent
        selectedRole={selectedRole}
        components={components}
        roleComponents={roleComponents}
        onSwitchChange={onSwitchChange}
      />}
    </ScrolledContainer>
  );
};

export default RoleAccessPage;
