import React, { useState } from 'react';
import { Button, Modal, Form, Input, InputNumber, Flex } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const PromocodesCreateComponent = ({ onSubmit }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const handleModalOpen = () => {
    setIsModalVisible(true);
  }

  const handleModalClose = () => {
    setIsModalVisible(false);
    form.resetFields();
  }

  const handleSubmit = (values) => {
    onSubmit(values);
    setIsModalVisible(false);
    form.resetFields();
  };

  return (
    <>
      <Button
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
        onClick={handleModalOpen}
      >
        Добавить промокод
      </Button>
      <Modal
        title="Добавить промокод"
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            style={{ margin: '5px', flex: 1}}
            name="promocode"
            label="Введите промокод"
            rules={[{ required: true, message: 'Пожалуйста, введите промокод' }]}
          >
            <Input placeholder='Введите код промокода'/>
          </Form.Item>
          <Form.Item
            style={{ margin: '5px', flex: 1}}
            name="discountPerc"
            label="Процент скидки"
            rules={[{ required: true, message: 'Пожалуйста, введите % скидки' }]}
          >
            <InputNumber style={{ width: '100%' }} min={0} max={100} formatter={value => `${value}%`}/>
          </Form.Item>
          <Flex style={{ marginTop: '20px'}} justify='flex-end'>
            <Button type="primary" htmlType="submit">Добавить</Button>
          </Flex>
        </Form>
      </Modal>
    </>
  );
};

export default PromocodesCreateComponent;
