import React, { useState } from 'react';
import { Button, Modal, Form, Input, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const WalletCreateComponent = ({ corpclientsOptions, onSubmit }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const handleModalOpen = () => {
    setIsModalVisible(true);
  }

  const handleModalClose = () => {
    setIsModalVisible(false);
    form.resetFields();
  }

  const handleSubmit = (values) => {
    onSubmit(values);
    setIsModalVisible(false);
    form.resetFields();
  };

  return (
    <>
      <Button
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
        onClick={handleModalOpen}
      >
        Добавить кошелёк
      </Button>
      <Modal
        title="Добавить кошелёк"
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            name="nameWallet"
            label="Название кошелька"
            rules={[{ required: true, message: 'Пожалуйста, введите название кошелька' }]}
          >
            <Input placeholder='Введите название'/>
          </Form.Item>
          <Form.Item
            name="corporatContractId"
            label="Выбор договора"
            rules={[{ required: true, message: 'Пожалуйста, выберите договор' }]}
          >
            <Select
              showSearch
              placeholder="Выберите договор"
              optionFilterProp="children"
              options={corpclientsOptions}
              filterOption={(input, option) =>
                (option?.contractNumber?.toString() ?? '').toLowerCase().includes(input.toLowerCase()) ||
                (option?.contractDate?.toString() ?? '').toLowerCase().includes(input.toLowerCase()) ||
                (option?.corporatContractId?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
              }
              // optionRender={(option) => (
              //   <Flex vertical >
              //     <Title level={5}>{option.data.contractNumber} от {option.data.contractDate}</Title>
              //     <Flex gap='small'>
              //       <Text type="secondary" size="small">ID: {option.data.corporatContractId}</Text>
              //     </Flex>
              //   </Flex>
              // )}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Добавить
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default WalletCreateComponent;
