import React, { useEffect } from 'react';
import { Form, Select } from 'antd';

const SoloSelectorComponent = ({ isAdmin, title, data, options, onChange }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ value: data });
  }, [data]);

  const onFinish = (values) => {
    onChange(values.value);
  }

  return (
    <Form
      form={form}
      layout='vertical'
      name="kitchen_name_form"
      initialValues={{value: data}}
      onFinish={onFinish}
      disabled={!isAdmin}
    >
      <Form.Item name='value' label={title}>
        <Select
          showSearch
          optionFilterProp="children"
          options={options}
          filterOption={(input, option) =>
            (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
          }
          placeholder={`Выберите ${title.toLowerCase()}`}
          onChange={() => form.submit()}
        />
      </Form.Item>
    </Form>
  );
};

export default SoloSelectorComponent;
