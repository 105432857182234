import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestMakeAddressActive } from '../../../../../redux/reducers/utils/clientsReducers/clientsSearchReducer';
import { Flex, Table, Input, Typography, Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import PrintTable from '../../../../../components/PrintTable';

const { Search } = Input;
const { Title, Text } = Typography;

const AddressesTableComponent = ({phone}) => {
  const { clientAddresses } = useSelector((store) => store.clientsSearch);
  const dispatch = useDispatch();

  const addressesWithKey = clientAddresses.map(item => ({...item, key: item.addressId}))

  const sortedAddresses = addressesWithKey?.sort((a, b) => {
    if (a.isActive && !b.isActive) {
        return -1;
    }
    if (!a.isActive && b.isActive) {
        return 1;
    }
    if (a.countOrders > b.countOrders) {
        return -1;
    }
    if (a.countOrders < b.countOrders) {
        return 1;
    }
    return 0;
  });

  const columns = [
    {
      title: '',
      key: 'status',
      render: ((record) => (
        <Flex>
          {record.isActive ? <CheckOutlined style={{fontSize: '16px', color: '#00cc5c'}}/> : ''}
        </Flex>
      ))
    },
    {
      title: 'Название',
      key: 'id',
      render: ((record) => (
        <Flex>
          <Text>ID: {record.addressId}</Text>
        </Flex>
      ))
    },
    {
      title: 'Город',
      dataIndex: 'citiName',
      key: 'citiName',
    },
    {
      title: 'Адрес',
      key: 'address',
      render: (record) => (
        <div>
          {record.citiName && <Text>г.{record.citiName}, </Text>} 
          {record.street && <Text>ул.{record.street}, </Text>} 
          {record.house && <Text>д.{record.house}, </Text>} 
          {record.flat && <Text>кв.{record.flat}, </Text>} 
          {record.floor && <Text>э.{record.floor}, </Text>} 
          {record.entrance && <Text>вх.{record.entrance}, </Text>} 
          {record.structure && <Text>корп.{record.structure}, </Text>} 
        </div>
      )
    },
    {
      title: 'Кол-во доставленных заказов на этот адрес',
      dataIndex: 'countOrders',
      key: 'countOrders',
    },
    {
      title: '',
      key: 'action',
      render: ((record) => (
        <>
          {record.isActive ? '' : <Button size='small' onClick={() => handleChangeAddress(record.addressId)}>Сделать текущим</Button>}
        </>
      ))
    },
  ];

  const handleChangeAddress = (addressId) => {
    const requestData = {
      token: localStorage.getItem('token'),
      phone: phone,
      addressId: addressId,
    }

    dispatch(sendRequestMakeAddressActive(requestData));
  };

  return (
    <PrintTable>
      <Table
        dataSource={sortedAddresses}
        columns={columns}
        size="small"
        pagination={{
          defaultPageSize: 50,
        }}
      />
    </PrintTable>
  );
};

export default AddressesTableComponent;
