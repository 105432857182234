import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCurrentDeliveryServicePolygon,
  setCurrentNewPoligon,
} from '../../../../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { YMaps, Map, Polygon } from 'react-yandex-maps';

const PolygonsMap = ({ polygons, polygonCenter }) => {
  const { currentDeliveryServicePolygon, uploadNewPoligons, currentNewPoligon } = useSelector((store) => store.deliveryServices);
  const dispatch = useDispatch();
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current && polygonCenter) {
      mapRef.current.setCenter(polygonCenter);
    }
  }, [polygonCenter]);

  const handlePolygonClick = (polygon) => {
    console.log('server polygon', polygon);
    dispatch(setCurrentDeliveryServicePolygon(polygon));
  };

  const handleOpenModal = (polygon) => {
    console.log('new polygon', polygon);
    dispatch(setCurrentNewPoligon(polygon));
  };

  return (
    <YMaps
      query={{
        apikey: process.env.REACT_APP_YANDEX_MAPS_API_KEY,
        lang: 'ru_RU',
        load: 'package.full',
      }}
    >
      <Map
        instanceRef={(ref) => (mapRef.current = ref)}
        defaultState={{ center: polygonCenter, zoom: 10 }}
        options={{ autoFitToViewport: "always" }}
        width='100%'
        height='calc(100vh - 300px)'
        modules={["Polygon", "geoObject.addon.editor"]}
        onLoad={(ref) => (mapRef.current = ref)}
      >
        {polygons.map((polygon, index) => {
          if (!polygon.isShow) {
            return null;
          }

          const reversedCoordinates = polygon.coordinates.map(innerArray => {
            return innerArray.map(coordinate => [coordinate[1], coordinate[0]]);
          });

          return (
            <Polygon
              key={index}
              geometry={reversedCoordinates}
              options={{
                fillColor: polygon.fillColor,
                strokeColor: polygon.strokeColor,
                strokeWidth: currentDeliveryServicePolygon?.id === polygon.id ? 10 : 2,
                opacity: 0.5,
              }}
              onClick={() => handlePolygonClick(polygon)}
            />
          )
        })}

        {uploadNewPoligons && uploadNewPoligons.map((polygon, index) => {
          const reversedCoordinates = polygon.coordinates.map(innerArray => {
            return innerArray.map(coordinate => [coordinate[1], coordinate[0]]);
          });

          return (
            <Polygon
              key={index}
              geometry={reversedCoordinates}
              options={{
                fillColor: '#ffffff',
                strokeColor: polygon.strokeColor,
                strokeWidth: currentNewPoligon?.key === polygon.key ? 10 : 2,
                opacity: 0.5,
                strokeStyle: "shortdash",
              }}
              onClick={() => handleOpenModal(polygon)}
            />
          )
        })}
      </Map>
    </YMaps>
  );
};

export default PolygonsMap;
