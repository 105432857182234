import React, { useState } from 'react';
import { Upload, Typography, Image, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const ImgUploader = ({ multiple, fileList, onUpload }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess(null, file);
    }, 0);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const beforeUpload = (file) => {
    const isImage = file?.type.startsWith('image/');
    if (isImage) {
      return true;
    } else {
      message.error('Не является изображением!');
      return false;
    }
  };

  const handleChange = ({ fileList: newFileList }) => {
    const isImage = newFileList[0]?.originFileObj ? beforeUpload(newFileList[0]?.originFileObj) : false;

    if (isImage) {
      onUpload(newFileList);
    } else {
      onUpload([]);
    }
  }
  
  return (
    <>
      <Upload
        customRequest={customRequest}
        accept="image/*"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
      >
        {(multiple ? fileList.length < 4 : fileList.length < 1) && (
          <Typography.Text><UploadOutlined/>Upload</Typography.Text>
        )}
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{
            display: 'none',
          }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </>
  );
};

export default ImgUploader;
