import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestAddressesSearch,
  setAddresses,
  setCurrentAddressesPage,
  sendRequestAddressesCreate,
  sendRequestAddressesUpdate,
  sendRequestAddressesDelete,
} from '../../../../redux/reducers/utils/clientsReducers/addressesSearchReducer';
import { Typography, Divider, Flex } from 'antd';
import Loader from '../../../../components/Loader';
import AddressesSearchFilterComponent from "./components/AddressesSearchFilterComponent";
import AddressesTableComponent from "./components/AddressesTableComponent";

const { Title } = Typography;

const AddressesSearchPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const { cities } = useSelector((store) => store.settingsCatalogs);
  const {
    addressesSearchRequestStatus,
    addresses,
    totalAddresses,
    currentAddressesPage,
    addressesCreateRequestStatus,
    addressesUpdateRequestStatus,
    addressesDeleteRequestStatus,
  } = useSelector((store) => store.addressesSearch);
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState({});

  const requestData = {
    token: localStorage.getItem('token'),
    offset: (currentAddressesPage - 1) * 20,
    limit: 20,
  }

  const citiesOptions = cities?.map((item) => ({
    ...item,
    value: item.id,
    label: item.name,
  }));

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestAddressesSearch(requestData));
    }
  }, [isAuth]);

  useEffect(() => {
    if (
      addressesCreateRequestStatus === 'resolved' ||
      addressesUpdateRequestStatus === 'resolved' ||
      addressesDeleteRequestStatus === 'resolved'
    ) {
      dispatch(sendRequestAddressesSearch(requestData));
    }
  }, [
    addressesCreateRequestStatus,
    addressesUpdateRequestStatus,
    addressesDeleteRequestStatus,
  ]);

  const handleAddressesSearch = (data) => {
    setSearchData(data)
    dispatch(setCurrentAddressesPage(1));
    dispatch(sendRequestAddressesSearch({...requestData, ...data, offset: 0 }))
  }

  const handleAddressesPageChange = (page) => {
    dispatch(setCurrentAddressesPage(page));
    dispatch(sendRequestAddressesSearch({...requestData, ...searchData, offset: (page - 1) * 20 }));
  }

  return (
    <>
      {(
        addressesSearchRequestStatus === 'pending' ||
        addressesCreateRequestStatus === 'pending' ||
        addressesUpdateRequestStatus === 'pending' ||
        addressesDeleteRequestStatus === 'pending'
      ) && <Loader />}

      <Title>Поиск адресов</Title>

      <AddressesSearchFilterComponent
        citiesOptions={citiesOptions}
        totalAddresses={totalAddresses}
        onSearch={handleAddressesSearch}
      />

      <Divider/>

      {/* <Flex justify='flex-end' style={{marginBottom: '20px'}} gap={16}>
        <WalletCreateComponent
          corpclientsOptions={corpclientsOptions}
          onSubmit={handleCreateWallet}
        />
      </Flex> */}

      <AddressesTableComponent
        addresses={addresses}
        totalAddresses={totalAddresses}
        currentAddressesPage={currentAddressesPage}
        onChange={handleAddressesPageChange}
      />
    </>
  )
}

export default AddressesSearchPage;