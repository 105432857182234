import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const Loader = () => {
  return (
    <Wrapper>
      <Spin size="large" />
    </Wrapper>
  );
};

export default Loader;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 800;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, .3);
`;
