import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendRequestForAssignCourierToAddresses,
  sendRequestForAssignCourierToAddressesClear,
  selectOrderArray,
  selectCourierToAppoint,
  setFirstPlacemarkToGroupOrders,
  setSecondPlacemarkToGroupOrders,
  fetchDataForMap,
} from '../../../../../redux/reducers/utils/deliveryReducers/deliveryMapReducer';
import styled from 'styled-components';
import { Flex, Button, Typography } from 'antd';
import AddressCard from './AddressCard';

const { Title } = Typography;

const AppointComponent = () => {
  const {
    selectedFilters,
    selectedCourierToAppoint,
    selectedOrderArray,
    assignCourierToAddressesStatus,
  } = useSelector((store) => store.deliveryMap);
  const dispatch = useDispatch();

  const selectedLocationsArrayIds = [...new Set(
    selectedOrderArray.map(item => Number(item.id))
  )];

  const onFinish = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      courierId: selectedCourierToAppoint.courierId,
      locationIds: selectedLocationsArrayIds,
      day: selectedFilters.date,
      raceId: selectedFilters.timePeriod,
    };
    
    dispatch(sendRequestForAssignCourierToAddresses(requestData));
  };

  const handleCancel = () => {
    dispatch(selectOrderArray([]));
    dispatch(selectCourierToAppoint(null));
    dispatch(setFirstPlacemarkToGroupOrders(null));
    dispatch(setSecondPlacemarkToGroupOrders(null));
  };

  useEffect(() => {
    if (assignCourierToAddressesStatus === 'resolved') {
      const requestData = {
        token: localStorage.getItem('token'),
        raceId: selectedFilters.timePeriod,
        racePolygonIds: selectedFilters.polygons,
        day: selectedFilters.date,
      };
  
      dispatch(fetchDataForMap(requestData));
      dispatch(selectOrderArray([]));
      dispatch(selectCourierToAppoint(null));
      dispatch(setFirstPlacemarkToGroupOrders(null));
      dispatch(setSecondPlacemarkToGroupOrders(null));
      dispatch(sendRequestForAssignCourierToAddressesClear());
    }
  }, [assignCourierToAddressesStatus, dispatch]);

  return (
    <Flex
      vertical
      style={{
        width: '280px',
        height: 'calc(100vh - 40px)',
        background: '#ffffff',
        border: '1px solid #f1f1f1',
      }}
    >
      <StyledHeader>
        <Button type="primary" htmlType="submit"  onClick={onFinish}>Назначить</Button>
        <Button type="primary" htmlType="submit"  onClick={handleCancel}>Отменить</Button>
      </StyledHeader>
      <StyledOrderCounter>Выбрано локаций: {selectedOrderArray.length}</StyledOrderCounter>
      <StyledContent>
      {selectedOrderArray && selectedOrderArray.map((location) => (
        <div key={location.id}>
          <LocationTitle
            color={location.courierColor ? location.courierColor : '#e2e2e2'}
            appointColor={selectedCourierToAppoint.color}
          >
            <Title style={{ margin: '0' }} level={5}>
              {location.id} - {location.name || 'Нет названия'}
            </Title>
          </LocationTitle>
          {Object.values(location.addresses).map((point) => (
            <AddressCard key={point.addressId} point={point} />
          ))}
        </div>
      ))}
      </StyledContent>
    </Flex>
  );
};

export default AppointComponent;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 270px;
  height: 100vh;
  background: #ffffff;
  border: 1px solid #f1f1f1;
`;
const StyledHeader = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px;
  width: 100%;
`;
const StyledOrderCounter = styled.div`
margin: 0 0 5px 10px;
  color: #999999;
`;
const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
  }
`;

const LocationTitle = styled.div`
  padding: 10px 0;
  background: ${({ color, appointColor }) => (`linear-gradient(to right, ${color}, ${appointColor})`)};
  cursor: pointer;
`;