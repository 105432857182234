import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestDeliveryServicesUpdate,
  sendRequestDeliveryServicesDelete,
  setCurrentDeliveryService,
} from '../../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Flex, Select, Form, DatePicker, Popconfirm, Input, Button, Typography } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

const { Title } = Typography;

const EditableForm = () => {
  const navigate = useNavigate();
  const { organizations } = useSelector((store) => store.settingsCatalogs);
  const {
    currentDeliveryService,
  } = useSelector((store) => store.deliveryServices);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (currentDeliveryService) {
      form.setFieldsValue({
        name: currentDeliveryService.name,
        description: currentDeliveryService.description,
        contractNumber: currentDeliveryService.contract_number,
        contractDate: currentDeliveryService.contract_date ? dayjs(currentDeliveryService.contract_date, dateFormat) : '',
        id: {value: currentDeliveryService.organizationId, label: organizations?.find(organization => organization.id.toString() === currentDeliveryService.organizationId.toString())?.urName,}.value,
      });
    }
  }, [currentDeliveryService, form]);

  dayjs.extend(customParseFormat);
  const dateFormat = 'YYYY-MM-DD';

  const handleSubmit = () => {
    form.validateFields()
    .then((values) => {
      const requestData = {
        token: localStorage.getItem('token'),
        deliveryServiceId: currentDeliveryService.id,
        name: values.name,
        description: values.description,
        contract_number: values.contractNumber,
        contract_date: moment(new Date(values.contractDate)).format('YYYY-MM-DD'),
        organizationId: values.id,
      };

      dispatch(sendRequestDeliveryServicesUpdate(requestData));
      form.resetFields();
    })
    .catch((errorInfo) => {
      console.error('Ошибка при валидации формы:', errorInfo);
    });
  }

  const organizationOptions = organizations?.map((item) => ({
    ...item,
    value: item?.id,
    label: item?.urName,
  }));

  const handleDelete = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      deliveryServiceId: currentDeliveryService.id,
    }

    dispatch(sendRequestDeliveryServicesDelete(requestData));
    navigate(`/delivery/services`);
    setCurrentDeliveryService(null);
  };

  return (
    <Flex vertical>
      <Form
        style={{ width: '100%' }}
        form={form}
        name='deliveryService'
        layout='vertical'
        initialValues={{
          name: currentDeliveryService.name,
          description: currentDeliveryService.description,
          contractNumber: currentDeliveryService.contract_number,
          contractDate: currentDeliveryService.contract_date ? dayjs(currentDeliveryService.contract_date, dateFormat) : '',
          id: {value: currentDeliveryService.organizationId, label: organizations?.find(organization => organization.id.toString() === currentDeliveryService.organizationId.toString())?.urName,}.value,
        }}
      >
        <Form.Item label='Название' name='name' rules={[{ required: true, message: 'Введите название' }]}>
          <Input placeholder='Название службы доставки'/>
        </Form.Item>
        <Form.Item label='Описание' name='description' rules={[{ required: true, message: 'Введите описание' }]}>
          <Input placeholder='Название службы доставки'/>
        </Form.Item>
        <Form.Item label='Номер договора' name='contractNumber' rules={[{ required: true, message: 'Введите номер договора' }]}>
          <Input placeholder='Название службы доставки'/>
        </Form.Item>
        <Form.Item label='Дата заключения договора' name='contractDate' rules={[{ required: true, message: 'Выберите дату' }]}>
          <DatePicker style={{ width:'100%' }} placeholder='Выберите дату'/>
        </Form.Item>
        <Form.Item label='Организация' name='id' rules={[{ required: true, message: 'Выберите организацию' }]}>
          <Select
            showSearch
            placeholder="Выберите организацию"
            optionFilterProp="children"
            options={organizationOptions}
            filterOption={(input, option) =>
              (option?.urName?.toString() ?? '').toLowerCase().includes(input.toLowerCase()) ||
              (option?.inn?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
            }
            optionRender={(option) => (
              <Flex
                vertical
                style={{ margin: '5px 0', height: '80px' }}
              >
                <div>ID: {option.data.id}</div>
                <Title level={5}>{option.data.urName}</Title>
                <div>ИНН: {option.data.inn}</div>
              </Flex>
            )}
          />
        </Form.Item>
      </Form>
      <Flex gap='large'>
        <Button type='primary' htmlType='submit' onClick={handleSubmit} style={{ display: 'flex', marginLeft: 'auto' }}>Сохранить</Button>
        <Popconfirm
          title="Удаление службы доставки!"
          description={`Вы действительно хотите удалить службу доставки?`}
          okText="Да"
          cancelText="Нет"
          placement="left"
          onConfirm={handleDelete}
        >
          <Button danger>Удалить</Button>
        </Popconfirm>
      </Flex>
    </Flex>
  );
};

export default EditableForm;
