import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import MenuDateComponent from './MenuDateComponent';

const MenuDateListComponent = () => {
  const { racesForDaysData } = useSelector((store) => store.kitchenMenu);

  return (
    <MenuDateList>
      {racesForDaysData.length > 0 && racesForDaysData.map((dayData) => (
        <MenuDateComponent
          key={dayData.day}
          dayData={dayData}
        />
      ))}
    </MenuDateList>
  );
};

export default MenuDateListComponent;

const MenuDateList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 200px;
`
