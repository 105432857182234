import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestClientsGet } from '../../../../../redux/reducers/utils/clientsReducers/clientsSearchReducer';
import { Flex, Form, Typography, Input, InputNumber, Select, DatePicker, Button } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

dayjs.extend(customParseFormat);
const serverFormat = 'YYYY-MM-DD';
const clientFormat = 'DD-MM-YYYY';

const ClientsSearchFilterComponent = () => {
  const { cities } = useSelector((store) => store.settingsCatalogs);
  const { deliveryStatusesOrders } = useSelector((store) => store.deliveryServices);
  const { currentPage, totalClients } = useSelector((store) => store.clientsSearch);
  const dispatch = useDispatch();
  const [filters] = Form.useForm();
  const [ requestValues, setRequestValues ] = useState({})

  const deliveryStatusesOrdersOptions = deliveryStatusesOrders?.map((item) => ({
    ...item,
    value: item.id,
  }));

  const citiesOptions = cities?.map((item) => ({
    ...item,
    value: item.id,
    label: item.name,
  }));

  useEffect(() => {
    const requestData = {
      token: localStorage.getItem('token'),
      ...requestValues,
      offset: (currentPage - 1) * 50,
      limit: 50,
    }

    console.log(requestData);
    dispatch(sendRequestClientsGet(requestData));
  }, [currentPage]);

  const handleValuesChange = (changedValues, allValues) => {
    const updatedRequestValues = { ...requestValues };
  
    Object.keys(allValues).forEach(key => {
      if (allValues[key] != null) {
        if (['dateUserRegistration', 'dateUserOrder', 'dateUserNotOrder'].includes(key)) {
          const [start, end] = allValues[key];
          if (start && end) {
            updatedRequestValues[key + 'From'] = start.format(serverFormat);
            updatedRequestValues[key + 'To'] = end.format(serverFormat);
          }
        } else {
          updatedRequestValues[key] = allValues[key];
        }

        if (!allValues[key].length) {
          delete updatedRequestValues[key];
        }

      } else {
        delete updatedRequestValues[key + 'From'];
        delete updatedRequestValues[key + 'To'];
      }
    });
  
    console.log(updatedRequestValues);
    setRequestValues(updatedRequestValues);
  };

  const handleSubmit = () => {
    const requestData = {
      token: localStorage.getItem('token'),
      ...requestValues,
      offset: 0,
      limit: 50,
    }

    console.log(requestData);
    dispatch(sendRequestClientsGet(requestData));
  }

  const handleClearForm = () => {
    filters.resetFields();
    setRequestValues({});
  }

  return (
    <Form style={{ width: '100%' }} form={filters} name='deliveryService' layout='vertical' onValuesChange={handleValuesChange} onFinish={handleSubmit}>
      <Flex justify='space-between' align='flex-end' gap='small' wrap='wrap'>
        <Form.Item style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }} label='Период дат регистрации пользователя' name='dateUserRegistration'>
          <RangePicker style={{ width: '100%' }} format={clientFormat}/>
        </Form.Item>
        <Form.Item style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }} label='Период дат когда пользователь получал заказ (по дате доставки)' name='dateUserOrder'>
          <RangePicker style={{ width: '100%' }} format={clientFormat}/>
        </Form.Item>
        <Form.Item style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }} label='Период дат когда пользователь НЕ получал заказ (по дате доставки)' name='dateUserNotOrder'>
          <RangePicker style={{ width: '100%' }} format={clientFormat}/>
        </Form.Item>
      </Flex>
      <Flex justify='space-between' align='flex-end' gap='small' wrap='wrap'>
        <Form.Item style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }} label='Имя' name='nameUser'>
          <Input style={{ width: '100%' }} placeholder='Введите имя клиента'/>
        </Form.Item>
        <Form.Item style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }} label='Телефон' name='phone'>
          <Input style={{ width: '100%' }} placeholder='+7(---)--- -- --' maxLength={12}/>
        </Form.Item>
      </Flex>
      <Flex justify='space-between' align='flex-end' gap='small' wrap='wrap'>
        <Form.Item style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }} label='ID клиента' name='userId'>
          <Input style={{ width: '100%' }} placeholder='0000000001'/>
        </Form.Item>
        <Form.Item style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }} label='ID адреса заказов' name='addressId'>
          <Input style={{ width: '100%' }} placeholder='0000000001'/>
        </Form.Item>
        <Form.Item style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }} label='ID заказа клиента' name='orderId'>
          <Input style={{ width: '100%' }} placeholder='0000000001'/>
        </Form.Item>
      </Flex>
      <Flex justify='space-between' align='flex-end' gap='small' wrap='wrap'>
        <Form.Item style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }} label='Выбранный текущий город пользователя (мультивыбор)' name='cityIds'>
          <Select
            mode="multiple"
            showSearch
            placeholder="Выберите город"
            optionFilterProp="children"
            options={citiesOptions}
            filterOption={(input, option) =>
              (option?.name?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
            }
            optionRender={(option) => (
              <Flex vertical>
                <Title level={5}>{option.data.name}</Title>
              </Flex>
            )}
          />
        </Form.Item>
        <Form.Item style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }} label='Статус заказа клиента' name='statusesOrder'>
          <Select
            mode="multiple"
            showSearch
            placeholder="Выберите статус заказа"
            optionFilterProp="children"
            options={deliveryStatusesOrdersOptions}
            filterOption={(input, option) =>
              (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
            }
            optionRender={(option) => (
              <Flex vertical>
                <Title level={5}>{option.data.label}</Title>
              </Flex>
            )}
          />
        </Form.Item>
      </Flex>
      <Flex style={{marginTop: '20px'}} gap='large' justify='space-between'>
        <Text type='secondary'>Найдено клиентов: {new Intl.NumberFormat().format(totalClients)}</Text>
        <Flex gap='large' justify='flex-end'>
          <Button type='primary' htmlType='submit'>Искать</Button>
          <Button onClick={handleClearForm}>Очистить</Button>
        </Flex>
      </Flex>
    </Form>
  );
};

export default ClientsSearchFilterComponent;
