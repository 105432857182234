import React, { useState, useEffect } from 'react';
import { Typography, Table, Modal, Form, Input, Popconfirm, Button, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const { Text } = Typography;

const EntityKeyRolesTable = ({entityData, roles, actions, onCellClick, onUpdate, onDelete}) => {
  const columns = [
    {
      title: 'Роль',
      key: 'roleId',
      render: (record) => (
        <Text>{roles.find(role => role.value === record.roleId)?.label}</Text>
      ),
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => onCellClick(record),
      }),
    },
    {
      title: 'Действие',
      key: 'action',
      render: (record) => (
        <Text>{actions.find(action => action.value === record.action)?.label}</Text>
      ),
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => onCellClick(record),
      }),
    },
    {
      title: '',
      key: 'actions',
      width: '160px',
      render: (_, record) => (
        <AccessAction
          record={record}
          roles={roles}
          actions={actions}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      ),
    },
  ];

  return (
    <Table
      dataSource={entityData}
      columns={columns}
      size="small"
      pagination={{
        defaultPageSize: 45,
      }}
    />
  );
};

export default EntityKeyRolesTable;

const AccessAction = ({record, roles, actions, onUpdate, onDelete}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(record);
  }, [record]);

  const handleEdit = () => {
    setIsModalVisible(true);
  }

  const handleSubmit = (values) => {
    onUpdate(values, record.id);
    setIsModalVisible(false);
    form.resetFields();
  }

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleDelete = () => {
    onDelete(record.id);
  };

  return (
    <Action>
      <Button onClick={handleEdit}><EditOutlined/></Button>
      <Modal
        title="Редактирование метода"
        open={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        <Form
          form={form}
          name='updateForm'
          onFinish={handleSubmit}
        >
          <Form.Item label='Роль' name='roleId'>
            <Select options={roles} placeholder='Выберите роль'/>
          </Form.Item>
          <Form.Item label='Действие' name='action'>
            <Select options={actions} placeholder='Выберите действие'/>
          </Form.Item>
          <Button type="primary" htmlType='submit'>
            Сохранить
          </Button>
        </Form>
      </Modal>
      <Popconfirm
        title="Удаление метода доступа!"
        description={`Вы действительно хотите удалить метод доступа ${record.name}`}
        okText="Да"
        cancelText="Нет"
        placement="left"
        onConfirm={handleDelete}
      >
        <Button danger>Удалить</Button>
      </Popconfirm>
    </Action>
  );
};

const Action = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`