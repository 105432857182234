import React from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';
import { DragOutlined } from '@ant-design/icons';

const DraggableWindow = ({children}) => {
  return (
    <Draggable handle=".handle">
      <WindowWrapper>
        <WindowHeader className="handle">
          <DragOutlined style={{paddingBottom: '5px', fontSize: '24px', color: '#cccccc'}}/>
        </WindowHeader>
        <WindowContent>
          {children}
        </WindowContent>
      </WindowWrapper>
    </Draggable>
  );
};

export default DraggableWindow;

const WindowWrapper = styled.div`
  min-width: 400px;
  width: fit-content;
  min-height: 200px;
  padding: 15px 20px;
  background: white;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
`;
const WindowHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: solid 1px #cccccc;
  cursor: move;
`;
const WindowContent = styled.div`
  padding: 20px;
`;
