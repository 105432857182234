import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestKitchens } from '../../../../../redux/reducers/utils/kitchenReducers/kitchenReducer';
import { sendRequestModifierGroupsGet } from '../../../../../redux/reducers/utils/kitchenReducers/kitchenMenuReducer';
import { Flex, Typography, Button } from 'antd';
import ModifiersTableComponent from './components/ModifiersTableComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import Loader from '../../../../../components/Loader';
import ModifiersCreateModalComponent from './components/ModifiersCreateModalComponent';
import { Container, Page, ScrolledCol } from '../../../../../core/styles';

const { Title } = Typography;

const MenuModifiersPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const { selectKitchen } = useSelector((store) => store.kitchen);
  const {
    modifierGroupsGetRequestStatus,
    modifierGroupsCreateRequestStatus,
    modifierGroupsUpdateRequestStatus,
    modifierGroupsDeleteRequestStatus,
    modifierGroups,
  } = useSelector((store) => store.kitchenMenu);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedModifierId, setSelectedModifierId] = useState(null);

  const modifierGroupsWithKey = modifierGroups.map(item => ({...item, key: item.id}))

  const filteredModifierGroups = modifierGroupsWithKey?.filter((item) =>
    item.name?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.public_name?.toString().toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestKitchens({ token: localStorage.getItem('token'), offset: 0, limit: 100 }));
    }
  }, [isAuth]);

  useEffect(() => {
    if (selectKitchen) {
      const requestData = {
        token: localStorage.getItem('token'),
        kitchenId: selectKitchen.id,
      }
      dispatch(sendRequestModifierGroupsGet(requestData));
    }
  }, [selectKitchen]);

  useEffect(() => {
    if (
      modifierGroupsCreateRequestStatus === 'resolved' ||
      modifierGroupsUpdateRequestStatus === 'resolved' ||
      modifierGroupsDeleteRequestStatus === 'resolved'
    ) {
      const requestData = {
        token: localStorage.getItem('token'),
        kitchenId: selectKitchen.id,
      }
      dispatch(sendRequestModifierGroupsGet(requestData));
    }
  }, [
    modifierGroupsCreateRequestStatus,
    modifierGroupsUpdateRequestStatus,
    modifierGroupsDeleteRequestStatus,
  ]);

  const onSearchTerm = value =>  setSearchTerm(value);
  const onSelectedModifierId = id => setSelectedModifierId(id);

  return (
    <Page>
      {(
        modifierGroupsGetRequestStatus === 'pending' ||
        modifierGroupsCreateRequestStatus === 'pending' ||
        modifierGroupsUpdateRequestStatus === 'pending' ||
        modifierGroupsDeleteRequestStatus === 'pending'
      ) && <Loader />}
        
      <ScrolledCol>
        <Container>
          <Title style={{marginBottom: '80px'}}>Группы модификаторов</Title>
          <Flex gap={20} wrap='wrap' justify='space-between' style={{ margin: '20px 0' }}>
            <SearchComponent onSearchTerm={onSearchTerm}/>
            <ModifiersCreateModalComponent/>
          </Flex>
          <ModifiersTableComponent
            modifiers={filteredModifierGroups}
          />
        </Container>
      </ScrolledCol>
    </Page>
  );
}

export default MenuModifiersPage;