import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';

const MenuTemplateEditableTitle = ({selectedTemplate, onUpdateMenuTemplate}) => {
  const [inputValue, setInputValue] = useState(selectedTemplate?.name);

  const handleUpdateMenuTemplate = (e) => {
    const requestData = {
      token: localStorage.getItem('token'),
      menuTemplateId: selectedTemplate.id,
      name: inputValue,
    }

    onUpdateMenuTemplate(requestData);
  }

  useEffect(() => {
    handleUpdateMenuTemplate()
  }, [inputValue])

  return (
    <Typography.Title
      editable={{onChange: setInputValue}}
      level={3}
      style={{margin: '10px 0 20px'}}
    >
      {selectedTemplate?.name}
    </Typography.Title>
  );
};

export default MenuTemplateEditableTitle;
