import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestCorpclientsGet,
  sendRequestCorpclientsCreate,
  sendRequestCorpclientsUpdate,
  sendRequestCorpclientsDelete,
  setCurrentCorpclientsPage,
  sendRequestCorpclientsContractsGet,
  sendRequestCorpclientsContractsCreate,
  sendRequestCorpclientsContractsUpdate,
  sendRequestCorpclientsContractsDelete,
  mergeCorpclientsWithContracts,
} from '../../../../redux/reducers/utils/financeReducers/financeCorpclientsReducer';
import { Typography, Flex } from 'antd';
import CorpclientCreateComponent from './components/CorpclientCreateComponent';
import CorpclientDrawerComponent from './components/CorpclientDrawerComponent';
import CorpclientsTableComponent from './components/CorpclientsTableComponent';
import Loader from '../../../../components/Loader';

const { Title } = Typography;

const CorpclientsPage = () => {
  const dispatch = useDispatch();
  const { isAuth } = useSelector(state => state.auth);
  const {organizations} = useSelector((store) => store.settingsCatalogs);
  const {
    corpclientsGetRequestStatus,
    corpclients,
    totalCorpclients,
    currentCorpclientsPage,
    corpclientsCreateRequestStatus,
    corpclientsUpdateRequestStatus,
    corpclientsDeleteRequestStatus,
    corpclientsContracts,
    currentCorpclientsContarctsPage,
    corpclientsContractsCreateRequestStatus,
    corpclientsContractsUpdateRequestStatus,
    corpclientsContractsDeleteRequestStatus,
  } = useSelector((store) => store.financeCorpclients);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentCorpclient, setCurrentCorpclient] = useState(null);

  const requestCorpclientsData = {
    token: localStorage.getItem('token'),
    offset: (currentCorpclientsPage - 1) * 20,
    limit: 20,
  }

  const requestCorpclientsContractsData = {
    token: localStorage.getItem('token'),
    offset: (currentCorpclientsContarctsPage - 1) * 20,
    limit: 20,
  }

  const organizationOptions = organizations?.map((item) => ({
    ...item,
    value: item.id,
    label: item.urName,
  }));

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestCorpclientsGet({...requestCorpclientsData, offset: 0}));
      dispatch(sendRequestCorpclientsContractsGet({...requestCorpclientsContractsData, offset: 0}));
    }
  }, [isAuth]);

  useEffect(() => {
    if (
      corpclientsCreateRequestStatus === 'resolved' ||
      corpclientsUpdateRequestStatus === 'resolved' ||
      corpclientsDeleteRequestStatus === 'resolved' ||
      corpclientsContractsCreateRequestStatus === 'resolved' ||
      corpclientsContractsUpdateRequestStatus === 'resolved' ||
      corpclientsContractsDeleteRequestStatus
    ) {
      dispatch(sendRequestCorpclientsGet({...requestCorpclientsData}));
      dispatch(sendRequestCorpclientsContractsGet({...requestCorpclientsContractsData}));
    }
  }, [
    corpclientsCreateRequestStatus,
    corpclientsUpdateRequestStatus,
    corpclientsDeleteRequestStatus,
    corpclientsContractsCreateRequestStatus,
    corpclientsContractsUpdateRequestStatus,
    corpclientsContractsDeleteRequestStatus,
  ]);

  useEffect(() => {
    if (corpclientsGetRequestStatus === 'resolved' && corpclientsContracts.length > 0) {
      dispatch(mergeCorpclientsWithContracts());
    }
  }, [corpclientsGetRequestStatus, corpclientsContracts]);

  useEffect(() => {
    if (currentCorpclient) {
      const updatetCurrentCorpclient = corpclients.find(corpclient => corpclient.corporatId === currentCorpclient.corporatId)
      setCurrentCorpclient(updatetCurrentCorpclient);
    }
  }, [corpclients]);

  const handleOpenDrawer = (record) => {
    setIsDrawerOpen(true);
    setCurrentCorpclient(record);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setCurrentCorpclient(null);
  };

  const handleCreateCorpclient = (data) => {
    dispatch(sendRequestCorpclientsCreate({token: localStorage.getItem('token'), ...data}));
  }

  const handleUpdateCorpclient = (data) => {
    dispatch(sendRequestCorpclientsUpdate({token: localStorage.getItem('token'), ...data}));
  }

  const handleDeleteCorpclient = (id) => {
    dispatch(sendRequestCorpclientsDelete({token: localStorage.getItem('token'), corporatId: id}));
  }
  
  const handleCorpclientsPageChange = (page) => {
    dispatch(setCurrentCorpclientsPage(page));
    dispatch(sendRequestCorpclientsGet({...requestCorpclientsData, offset: (page - 1) * 20 }));
  }

  const handleCreateCorpclientContracts = (data) => {
    dispatch(sendRequestCorpclientsContractsCreate({token: localStorage.getItem('token'), ...data}));
  }

  const handleUpdateCorpclientContracts = (data) => {
    dispatch(sendRequestCorpclientsContractsUpdate({token: localStorage.getItem('token'), ...data}));
  }

  const handleDeleteCorpclientContracts = (id) => {
    dispatch(sendRequestCorpclientsContractsDelete({token: localStorage.getItem('token'), corporatContractId: id}));
  }

  return (
    <>
      {(
        corpclientsGetRequestStatus === 'pending' ||
        corpclientsCreateRequestStatus === 'pending' ||
        corpclientsUpdateRequestStatus === 'pending' ||
        corpclientsDeleteRequestStatus === 'pending'
      ) && <Loader/>}

      <Title level={2}>Корпоративные клиенты</Title>

      <Flex justify='flex-end' style={{marginBottom: '20px'}}>
        <CorpclientCreateComponent
          organizationOptions={organizationOptions}
          onSubmit={handleCreateCorpclient}
        />
      </Flex>
      
      <CorpclientsTableComponent
        dataSource={corpclients}
        currentCorpclient={currentCorpclient}
        totalCorpclients={totalCorpclients}
        currentPage={currentCorpclientsPage}
        onRowClick={handleOpenDrawer}
        onChange={handleCorpclientsPageChange}
      />

      <CorpclientDrawerComponent
        isDrawerOpen={isDrawerOpen}
        onClose={handleCloseDrawer}
        record={currentCorpclient}
        organizationOptions={organizationOptions}
        onUpdate={handleUpdateCorpclient}
        onDelete={handleDeleteCorpclient}

        onCreateContract={handleCreateCorpclientContracts}
        onUpdateContract={handleUpdateCorpclientContracts}
        onDeleteContract={handleDeleteCorpclientContracts}
      />
    </>
  );
};

export default CorpclientsPage;
