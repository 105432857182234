import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setLocationsShow,
  setSearchQuery,
  setCurrentLocation,
  setMapAddressLocation,
  fetchCurrentLocationAddresses,
  selectOrder,
} from '../../../../../redux/reducers/utils/deliveryReducers/deliveryMapReducer';
import { Input, Button, Flex, Typography, Empty, Tabs } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import AddressCard from './AddressCard';

const { TabPane } = Tabs;
const { Text, Title } = Typography;
const { Search } = Input;

const LocationsComponent = () => {
  const {
    locationsShow,
    searchQuery,
    locations,
    points,
    currentLocation,
  } = useSelector((store) => store.deliveryMap);
  const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(setLocationsShow(true));
  };

  const handleClose = () => {
    dispatch(setLocationsShow(false));
  };

  const onSearch = (value) => {
    dispatch(setSearchQuery(value));
  };

  const handleLocationClick = (location) => {
    dispatch(setMapAddressLocation(location.coords));
    dispatch(setCurrentLocation(location));
    dispatch(selectOrder(null));
    dispatch(fetchCurrentLocationAddresses({token: localStorage.getItem('token'), locationId: location.id}));
  }

  const filteredLocations = useMemo(() => {
    if (!searchQuery) return locations;
    return locations.filter(location =>
      location.name?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      location.id?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      Object.values(location.addresses).some(point =>
        point.addressId?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
        point.address?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
        point.addressName?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
        point.courierName?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
        point.orders.some(order => order.id.toString() === searchQuery.toLowerCase())
      )
    );
  }, [locations, searchQuery]);

  const filteredPoints = useMemo(() => {
    if (!searchQuery) return points;
    return points.filter(point =>
      point.addressId?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      point.address?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      point.addressName?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      point.courierName?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      point.orders.some(order => order.id.toString() === searchQuery.toLowerCase())
    );
  }, [points, searchQuery]);

  return (
    <>
      {!locationsShow &&
        <Button
          style={{marginTop: '10px', marginLeft: '10px', marginRight: '10px'}}
          type="primary"
          onClick={handleOpen}
        >
          <MenuOutlined/>
        </Button>
      }
      {locationsShow &&
        <Wrapper>
          <Header>
            <Flex>
              <Title level={5}>Заказы</Title>
              <Button
                style={{ position: 'absolute', top: '5px', right: '5px', zIndex: 800 }}
                type='text'
                icon={<CloseOutlined/>}
                onClick={handleClose}
              />
            </Flex>
            <Search
              placeholder="Введите текст для поиска"
              allowClear
              defaultValue={searchQuery}
              onSearch={onSearch}
              style={{ width: '100%' }}
            />
            
          </Header>

          <Content>
            {filteredPoints.length > 0 || filteredLocations.length > 0 ? (
              <Tabs defaultActiveKey="locations">
                {filteredLocations.length > 0 && (
                  <TabPane
                    tab={`Локации (${filteredLocations.length})`}
                    key="locations"
                  >
                    {filteredLocations.map((location) => (
                      <div key={location.id}>
                        <LocationItem
                          selected={!!currentLocation && currentLocation.id === location.id}
                          onClick={() => handleLocationClick(location)}
                        >
                          <Title style={{ margin: '0' }} level={5}>
                            {location.id} - {location.name || 'Нет названия'}
                          </Title>
                          <Text type='secondary'>
                            {location.country && `${location.country}, `}
                            {location.administrativeArea && `${location.administrativeArea}, `}
                            {location.city && `${location.city}, `}
                            {location.street && `${location.street}, `}
                            {location.house && `${location.house} `}
                            {location.postalCodeNumber && `(${location.postalCodeNumber})`}
                          </Text>
                        </LocationItem>
                      </div>
                    ))}
                  </TabPane>
                )}
                {filteredPoints.length > 0 && (
                  <TabPane
                    tab={`Адреса (${filteredPoints.length})`}
                    key="points"
                  >
                    {filteredPoints.map((point) => (
                      <AddressCard
                        key={point.addressId}
                        point={point}
                      />
                    ))}
                  </TabPane>
                )}
              </Tabs>
            ) : (
              <Empty description="Нет данных" />
            )}
          </Content>
        </Wrapper>
      }
    </>
  );
};

export default LocationsComponent;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 280px;
  height: calc(100vh - 45px);
  background: #ffffff;
  border: 1px solid #f1f1f1;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
  }
`;

const LocationItem = styled.div`
  border: 1px solid #f1f1f1;
  padding: 10px;
  background: ${({ selected }) => (selected ? '#f1f1f1' : '#ffffff')};
  cursor: pointer;
  &:hover {
    background: #f1f1f1;
  }
`;
