import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendRequestDeliveryServicesCreate } from '../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Flex, Typography, Modal, Form, Select, Input, DatePicker, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Title, Text } = Typography;

const DeliveryServicesCreateModalComponent = () => {
  const { organizations } = useSelector((store) => store.settingsCatalogs);
  const dispatch = useDispatch();
  const [ isCreate, setIsCreate] = useState(false);
  const [form] = Form.useForm();

  const handleOpen = () => {
    setIsCreate(true);
  };

  const handleCancel = () => {
    setIsCreate(false);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      name: values.name,
      description: values.description,
      contract_number: values.contractNumber,
      contract_date: moment(new Date(values.contractDate)).format('YYYY-MM-DD'),
      organizationId: values.id,
    }

    dispatch(sendRequestDeliveryServicesCreate(requestData));
    setIsCreate(false);
    form.resetFields();
  }

  const organizationOptions = organizations?.map((item) => ({
    ...item,
    value: item.id,
    label: item.urName,
  }));

  
  return (
    <>
      <Button
        type='primary'
        shape="round"
        icon={<PlusOutlined />}
        onClick={handleOpen}
        style={{marginLeft: 'auto'}}
      >
        Добавить службу доставки
      </Button>
      <Modal
        title="Создание службы доставки"
        open={isCreate}
        onCancel={handleCancel}
        footer={null}
      >
        <Form style={{ width: '100%' }} form={form} name='deliveryService' layout='vertical' onFinish={handleSubmit}>
          <Form.Item label='Название' name='name' rules={[{ required: true, message: 'Введите название' }]}>
            <Input placeholder='Название службы доставки'/>
          </Form.Item>
          <Form.Item label='Описание' name='description' rules={[{ required: true, message: 'Введите описание' }]}>
            <Input placeholder='Название службы доставки'/>
          </Form.Item>
          <Form.Item label='Номер договора' name='contractNumber' rules={[{ required: true, message: 'Введите номер договора' }]}>
            <Input placeholder='Название службы доставки'/>
          </Form.Item>
          <Form.Item label='Дата заключения договора' name='contractDate' rules={[{ required: true, message: 'Выберите дату' }]}>
            <DatePicker style={{ width:'100%' }} placeholder='Выберите дату'/>
          </Form.Item>
          <Form.Item label='Организация' name='id' rules={[{ required: true, message: 'Выберите организацию' }]}>
            <Select
              showSearch
              placeholder="Выберите организацию"
              optionFilterProp="children"
              options={organizationOptions}
              filterOption={(input, option) =>
                (option?.urName?.toString() ?? '').toLowerCase().includes(input.toLowerCase()) ||
                (option?.inn?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
              }
              optionRender={(option) => (
                <Flex
                  vertical
                  style={{ margin: '5px 0', height: '80px' }}
                >
                  <div>ID: {option.data.id}</div>
                  <Title level={5}>{option.data.urName}</Title>
                  <div>ИНН: {option.data.inn}</div>
                </Flex>
              )}
            />
          </Form.Item>
          <Button type='primary' htmlType='submit' style={{ display: 'flex', marginLeft: 'auto' }}>Создать</Button>
        </Form>
      </Modal>
    </>
  );
};

export default DeliveryServicesCreateModalComponent;
