import React from 'react';
import { Flex, Table, Tag, Typography } from 'antd';

const { Text } = Typography;

const WalletsTableComponent = ({dataSource, currentWallet, totalWallets, currentPage, onRowClick, onChange}) => {
  const columns = [
    {
      title: 'ID кошелька',
      key: 'walletId',
      render: ((record) => (
        <Text copyable>{record.walletId}</Text>
      )),
    },
    {
      title: 'Название кошелька',
      key: 'name',
      render: (record) => (
        <Flex justify='space-between'>
          <Flex>
            {record.name}
          </Flex>
          <Flex align='center'>
            {record.status === 'deleted' && <Tag color='red'>Не активный</Tag>}
          </Flex>
        </Flex>
      ),
      onCell: (record) => ({
        onClick: () => handleCellClick(record),
        style: {cursor: 'pointer'}
      })
    },
    {
      title: 'Сумма на кошельке',
      dataIndex: 'amount',
      key: 'amount',
      onCell: (record) => ({
        onClick: () => handleCellClick(record),
        style: {cursor: 'pointer'}
      })
    },
    {
      title: 'Список владельцев',
      dataIndex: 'wallets_users',
      key: 'wallets_users',
      render: wallets_users => wallets_users.map(owner => `${owner?.name} (${owner?.phone})`).join(', '),
      onCell: (record) => ({
        onClick: () => handleCellClick(record),
        style: {cursor: 'pointer'}
      })
    },
    {
      title: 'Ограничение по адресам',
      dataIndex: 'wallets_addresses',
      key: 'wallets_addresses',
      render: wallets_addresses => wallets_addresses.map(address => `${address?.name} (${address?.id})`).join(', '),
      onCell: (record) => ({
        onClick: () => handleCellClick(record),
        style: {cursor: 'pointer'}
      })
    },
  ]

  const handleCellClick = (record) => {
    onRowClick(record);
  };

  const handleTableChange = (pagination) => {
    onChange(pagination.current);
  };

  return (
    <Table
      size='small'
      dataSource={dataSource}
      columns={columns}
      onRow={(record) => ({
        style: { background: record.walletId === currentWallet?.walletId ? '#e6f7ff' : '' },
      })}
      pagination={{
        current: currentPage,
        total: totalWallets,
        pageSize: 20,
        showSizeChanger: false,
        showQuickJumper: true,
      }}
      onChange={handleTableChange}
    />
  );
};

export default WalletsTableComponent;
