import React, { useState, useEffect } from "react";
import { Typography, Table, Button, Form, Input, InputNumber, Tabs, Drawer, Flex, Select, DatePicker, Space, Switch, Slider, Row, Col, Popconfirm, Modal } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PrintTable from "../../../../../components/PrintTable";
dayjs.extend(customParseFormat);

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const serverFormat = 'YYYY-MM-DD';
const clientFormat = 'DD-MM-YYYY';

const CorpclientDrawerComponent = ({ isDrawerOpen, onClose, record, organizationOptions, onUpdate, onDelete, onCreateContract, onUpdateContract, onDeleteContract }) => {
  const [formUpdateCorpclient] = Form.useForm();
  const [isFormChanged, setIsFormChanged] = useState(false);

  const columns = [
    {
      title: 'Контракт',
      key: 'contract',
      render: (record) => (
        <Flex>{record.contractNumber} от {record.contractDate}</Flex>
      ),
      width: '100%',
    },
    {
      title: '',
      key: 'actions',
      render: (record) => (
        <ActionComponent
          record={record}
          onUpdateContract={onUpdateContract}
          onDeleteContract={onDeleteContract}
        />
      ),
    },
  ];

  useEffect(() => {
    if (record) {
      setIsFormChanged(false);
      formUpdateCorpclient.setFieldsValue({
        commercialName: record.commercial_name,
        organizationId: record.organizationId,
        nameResponsiblePerson: record.name_responsible_person,
        phoneResponsiblePerson: record.phone_responsible_person,
        address: record.address,
      });
    }
  }, [record, formUpdateCorpclient]);

  const handleCloseDrawer = () => { onClose(false); };
  const handleFormChange = () => { setIsFormChanged(true); };

  const handleUpdate = () => {
    formUpdateCorpclient.validateFields()
    .then((values) => {
      onUpdate({...values, corporatId: record.corporatId});
    })
    .catch((errorInfo) => {
      console.error('Ошибка при валидации формы:', errorInfo);
    });
  };

  const handleDelete = () => {
    onDelete(record.corporatId);
  };

  return (
    <Drawer
      title={
        <Flex gap={20}>
          {isFormChanged &&
            <Flex gap={20}>
              <Button type="primary" onClick={handleUpdate}>Сохранить</Button>
              <Button onClick={handleCloseDrawer}>Отменить</Button>
            </Flex>
          }
          <Popconfirm
            title="Удаление корп. клиента"
            description={'Вы уверены что хотите удалить корп. клиента?'}
            okText="Да"
            cancelText="Нет"
            placement="bottom"
            onConfirm={handleDelete}
          >
            <Button style={{marginLeft: 'auto'}}  type='primary' danger>Удалить</Button>
          </Popconfirm>
        </Flex>
      }
      placement="right"
      mask={false}
      width='40%'
      open={isDrawerOpen}
      onClose={handleCloseDrawer}
    >
      {record && 
      <>
        <Title level={4}>{record.commercial_name}</Title>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Параметры" key="1">
            <Form
              form={formUpdateCorpclient}
              layout="vertical"
              onValuesChange={handleFormChange}
            >
              <Form.Item style={{ margin: '5px', flex: 1 }} label="Коммерческое название" name="commercialName" rules={[{ required: true, message: 'Введите название' }]}>
                <Input placeholder='Введите название'/>
              </Form.Item>
              <Form.Item style={{ margin: '5px', flex: 1 }} label='Организация' name='organizationId' rules={[{ required: true, message: 'Выберите организацию' }]}>
                <Select
                  showSearch
                  placeholder="Выберите организацию"
                  optionFilterProp="children"
                  options={organizationOptions}
                  filterOption={(input, option) =>
                    (option?.urName?.toString() ?? '').toLowerCase().includes(input.toLowerCase()) ||
                    (option?.inn?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  optionRender={(option) => (
                    <Flex
                      vertical
                      style={{ margin: '5px 0', height: '80px' }}
                    >
                      <Text type='secondary'>ID: {option.data.id}</Text>
                      <Title level={5} style={{margin: '0'}}>{option.data.urName}</Title>
                      <Text type='secondary'>ИНН: {option.data.inn}</Text>
                    </Flex>
                  )}
                />
              </Form.Item>
              <Form.Item style={{ margin: '5px', flex: 1 }} label="Ответственное лицо (ФИО)" name="nameResponsiblePerson" rules={[{ required: true, message: 'Укажите ответственное лицо' }]}>
                <Input placeholder='Укажите ответственное лицо'/>
              </Form.Item>
              <Form.Item style={{ margin: '5px', flex: 1 }} label="Телефон контактного лица" name="phoneResponsiblePerson" rules={[{ required: true, message: 'Укажите телефон' }]}>
                <Input placeholder='Укажите телефон' maxLength={13}/>
              </Form.Item>
              <Form.Item style={{ margin: '5px', flex: 1 }} label="Адрес доставки" name="address" rules={[{ required: true, message: 'Выберите адрес доставки' }]}>
                <Input placeholder='Выберите адрес доставки'/>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Договоры" key="2">
            <CreateContractComponent
              record={record}
              onCreateContract={onCreateContract}
            />
            <PrintTable>
              <Table
                size='small'
                dataSource={record.contracts}
                columns={columns}
              />
            </PrintTable>
          </TabPane>
        </Tabs>
      </>
      }
    </Drawer>
  );
};

export default CorpclientDrawerComponent;

const CreateContractComponent = ({record, onCreateContract}) => {
  const [ isCreate, setIsCreate] = useState(false);
  const [form] = Form.useForm();

  const handleOpen = () => {
    setIsCreate(true);
  };

  const handleCancel = () => {
    setIsCreate(false);
    form.resetFields();
  };

  const handleCreate = (values) => {
    const requestData = {
      corporatId: record.corporatId,
      contractNumber: values.contractNumber,
      contractDate: values.contractDate.format(serverFormat),
    }

    onCreateContract(requestData);
    setIsCreate(false);
    form.resetFields();
  }

  return (
    <Flex>
      <Button
        type='primary'
        icon={<PlusOutlined />}
        onClick={handleOpen}
        style={{margin: '0 0 20px auto'}}
      >
        Создать контракт
      </Button>
      <Modal
        title="Создание договора"
        open={isCreate}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          style={{ width: '100%' }}
          form={form}
          name='createContract'
          layout='vertical'
          onFinish={handleCreate}
        >
          <Form.Item label='Номер договора' name='contractNumber' rules={[{ required: true, message: 'Введите номер договора' }]}>
            <Input placeholder='Номер договора'/>
          </Form.Item>
          <Form.Item label='Дата договора' name='contractDate' rules={[{ required: true, message: 'Выберите дату' }]}>
            <DatePicker style={{ width:'100%' }} placeholder='Выберите дату' format={clientFormat}/>
          </Form.Item>
          <Button type='primary' htmlType='submit' style={{ display: 'flex', marginLeft: 'auto' }}>Создать</Button>
        </Form>
      </Modal>
    </Flex>
  );
};

const ActionComponent = ({record, onUpdateContract, onDeleteContract}) => {
  const [ isCreate, setIsCreate] = useState(false);
  const [form] = Form.useForm();

  const handleOpen = () => {
    setIsCreate(true);
  };

  const handleCancel = () => {
    setIsCreate(false);
    form.resetFields();
  };

  const handleUpdate = (values) => {
    const requestData = {
      corporatContractId: record.corporatContractId,
      corporatId: record.corporatId,
      contractNumber: values.contractNumber,
      contractDate: values.contractDate.format(serverFormat),
    }

    onUpdateContract(requestData);
    setIsCreate(false);
    form.resetFields();
  }

  const handleDelete = () => {
    onDeleteContract(record.corporatContractId);
  };

  return (
    <Action>
      <Button
        icon={<EditOutlined />}
        onClick={handleOpen}
      >
      </Button>
      <Modal
        title="Редактирование контракта"
        open={isCreate}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          style={{ width: '100%' }}
          form={form}
          name='updateContract'
          layout='vertical'
          onFinish={handleUpdate}
          initialValues={{
            contractNumber: record.contractNumber,
            contractDate: dayjs(record.contractDate),
          }}
        >
          <Form.Item label='Номер договора' name='contractNumber' rules={[{ required: true, message: 'Введите номер договора' }]}>
            <Input placeholder='Номер договора'/>
          </Form.Item>
          <Form.Item label='Дата договора' name='contractDate' rules={[{ required: true, message: 'Выберите дату' }]}>
            <DatePicker style={{ width:'100%' }} placeholder='Выберите дату' format={clientFormat}/>
          </Form.Item>
          <Button type='primary' htmlType='submit' style={{ display: 'flex', marginLeft: 'auto' }}>Сохранить</Button>
        </Form>
      </Modal>
      <Popconfirm
        title="Удаление договора!"
        description={`Вы действительно хотите удалить договор`}
        okText="Да"
        cancelText="Нет"
        placement="left"
        onConfirm={handleDelete}
      >
        <Button danger>Удалить</Button>
      </Popconfirm>
    </Action>
  );
};

const Action = styled.div`
  position: relative;
  z-index: 600;
  display: flex;
  gap: 5px;
  align-items: center;
`