import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Flex, Typography, Select, DatePicker, Button, Form } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

dayjs.extend(customParseFormat);
const serverFormat = 'YYYY-MM-DD';
const clientFormat = 'DD-MM-YYYY';

function FinancialSettlementFilters({ onSearch }) {
  const { cities } = useSelector((store) => store.settingsCatalogs);
  const [filters] = Form.useForm();
  const [requestValues, setRequestValues] = useState({});

  useEffect(() => {
    const initialStartDate = dayjs().subtract(60, 'day');
    const initialEndDate = dayjs();
    filters.setFieldsValue({ date: [initialStartDate, initialEndDate] });

    const initialRequestValues = {
      dateFrom: initialStartDate.format(serverFormat),
      dateTo: initialEndDate.format(serverFormat),
    };
    setRequestValues(initialRequestValues);
  }, [filters]);

  const citiesOptions = cities?.map((item) => ({
    ...item,
    value: item.id,
    label: item.name,
  }));

  const handleValuesChange = (changedValues, allValues) => {
    const updatedRequestValues = { ...requestValues };

    Object.keys(allValues).forEach((key) => {
      if (allValues[key] != null) {
        if (key === 'date') {
          const [start, end] = allValues[key];
          if (start && end) {
            updatedRequestValues[key + 'From'] = start.format(serverFormat);
            updatedRequestValues[key + 'To'] = end.format(serverFormat);
          }
        } else {
          updatedRequestValues[key] = allValues[key];
        }

        if (!allValues[key].length) {
          delete updatedRequestValues[key];
        }
      } else {
        delete updatedRequestValues[key + 'From'];
        delete updatedRequestValues[key + 'To'];
      }
    });

    setRequestValues(updatedRequestValues);
  };

  const handleRefresh = () => {
    onSearch(requestValues);
  };

  const handleClearForm = () => {
    filters.resetFields();
    setRequestValues({});
  };

  return (
    <Form
      style={{ width: '100%' }}
      form={filters}
      name='settlement'
      layout='inline'
      onValuesChange={handleValuesChange}
      onFinish={handleRefresh}
    >
      <Form.Item
        style={{ minWidth: '300px', marginBottom: '20px' }}
        label='Период'
        name='date'
        rules={[{ required: true, message: 'Выберите дату' }]}
      >
        <RangePicker style={{ width: '100%' }} format={clientFormat} />
      </Form.Item>

      <Form.Item
        style={{ minWidth: '300px', marginBottom: '20px' }}
        label='Город'
        name='cities'
      >
        <Select
          mode="multiple"
          showSearch
          placeholder="Выберите город"
          optionFilterProp="children"
          options={citiesOptions}
          filterOption={(input, option) =>
            (option?.name?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
          }
          optionRender={(option) => (
            <Flex vertical>
              <Title level={5}>{option.data.name}</Title>
            </Flex>
          )}
        />
      </Form.Item>

      <Flex style={{ marginBottom: '20px' }} gap='small' justify='flex-end'>
        <Button type='primary' icon={<ReloadOutlined />} htmlType='submit'>Обновить</Button>
        <Button onClick={handleClearForm}>Очистить</Button>
      </Flex>
    </Form>
  );
}

export default FinancialSettlementFilters;

