import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestClientProfileGet,
  sendRequestClientOrdersStatisticsGet,
  sendRequestClientOrdersGet,
  sendRequestClientAddressesGet,
  sendRequestClientDevicesGet,
  sendRequestPushNotificationGet,
  sendRequestMessagesGet,
} from '../../../../redux/reducers/utils/clientsReducers/clientsSearchReducer';
import { sendRequestForUpdateOrderComment } from '../../../../redux/reducers/utils/deliveryReducers/deliveryMapReducer';
import { sendRequestKitchenComplaintCreate } from '../../../../redux/reducers/utils/kitchenReducers/kitchenComplaintReducer';
import { Row, Col, Typography, Divider, Tabs } from 'antd';
import ReactECharts from 'echarts-for-react';

import Loader from '../../../../components/Loader';

import OrdersTableComponent from './components/OrdersTableComponent';
import { Container, Page, ScrolledCol, SitiyCard } from '../../../../core/styles';
import AddressesTableComponent from './components/AddressesTableComponent';
import { formatDate } from '../../../../core/utils';
import DevicesTableComponent from './components/DevicesTableComponent';
import PushNotificationTableComponent from './components/PushNotificationTableComponent';
import MessagesTableComponent from './components/MessagesTableComponent';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const ClientDetailPage = () => {
  const { phone } = useParams();
  const { isAuth } = useSelector((store) => store.auth);
  const {
    clientProfileGetRequestStatus,
    makeAddressActiveRequestStatus,
    clientProfile,
    clientOrdersStatistics,
    clientOrders,
    clientAddresses,
    currentOrdersPage,
    clientDevices,
    pushNotification,
    messages,
  } = useSelector((store) => store.clientsSearch);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth) {
      const requestData = {
        token: localStorage.getItem('token'),
        phone: phone,
      }
      console.log(requestData);
      dispatch(sendRequestClientProfileGet(requestData));
      dispatch(sendRequestClientOrdersStatisticsGet(requestData));
      dispatch(sendRequestClientOrdersGet({...requestData, offset: 0, limit: 50}));
      dispatch(sendRequestClientAddressesGet(requestData));
      dispatch(sendRequestClientDevicesGet(requestData));
      dispatch(sendRequestPushNotificationGet(requestData));
      dispatch(sendRequestMessagesGet(requestData));
    }
  }, [isAuth]);

  useEffect(() => {
    if (isAuth) {
      const requestData = {
        token: localStorage.getItem('token'),
        phone: phone,
        offset: (currentOrdersPage - 1) * 50,
        limit: 50,
      }
      console.log(requestData);
      dispatch(sendRequestClientOrdersGet(requestData));
    }
  }, [currentOrdersPage]);

  useEffect(() => {
    if (makeAddressActiveRequestStatus === 'resolved') {
      const requestData = {
        token: localStorage.getItem('token'),
        phone: phone,
      }

      dispatch(sendRequestClientAddressesGet(requestData));
    }
  }, [makeAddressActiveRequestStatus]);

  const handleUpdateComment = (orderId, comment) => {
    const requestData = {
      token: localStorage.getItem('token'),
      orderId: orderId,
      comment: comment || '',
    }

    dispatch(sendRequestForUpdateOrderComment(requestData));
  }

  const handleCreateComplaint = (data) => {
    const requestData = {
      token: localStorage.getItem('token'),
      ...data,
    }

    console.log(requestData);
    dispatch(sendRequestKitchenComplaintCreate(requestData));
  }

  const getSalesOption = () => ({
    title: {
      text: 'Граффик по месяцам',
    },
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: clientOrdersStatistics.amountOrdersByMonths.map((data) => data.month),
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'Продажи',
        type: 'line',
        data: clientOrdersStatistics.amountOrdersByMonths.map((data) => data.countOrders),
      },
    ],
  });

  return (
    <Page>
      {(
        clientProfileGetRequestStatus === 'pending' ||
        makeAddressActiveRequestStatus === 'pending'
      ) && <Loader />}
      <ScrolledCol>
        <Container>
          {clientProfile ? 
            <>
              <Title level={3}>{clientProfile.name} {clientProfile.phone}</Title>
              <Text type='secondary'>Дата регистрации: {formatDate(clientProfile.created)}</Text>
              <Divider/>
              <Tabs defaultActiveKey="1" tabPosition="top">

                <TabPane tab="Статистика" key="1"> 
                  {clientOrdersStatistics && 
                    <SitiyCard>
                      <ReactECharts option={getSalesOption()}/>
                    
                      <div>
                        <Text>Средний чек: {clientOrdersStatistics.averageAmountOrders}р.</Text>
                      </div>
                      <div>
                        <Text strong>Общая сумма оплаты: {clientOrdersStatistics.amountSummOrders}р. </Text>
                        <Text type='secondary'>из них баллами: {clientOrdersStatistics.amountSummOrdersPoints}р. / деньгами: {clientOrdersStatistics.amountSummOOrdersMoney}р.</Text>
                      </div>
                    </SitiyCard>
                  }
                </TabPane>

                <TabPane tab="Заказы" key="2">
                  <Title level={3}>Все заказы:</Title>
                  {clientOrders && 
                    <OrdersTableComponent
                      onUpdateComment={handleUpdateComment}
                      onCreateComplaint={handleCreateComplaint}
                    />
                  }
                </TabPane>

                <TabPane tab="Адреса" key="3">
                  <Title level={3}>Адреса</Title>
                  {clientAddresses && <AddressesTableComponent phone={phone}/>}
                </TabPane>

                <TabPane tab="Кошельки" key="4">
                  <Title level={3}>Кошельки</Title>
                </TabPane>
                
                <TabPane tab="Отзывы" key="5">
                  <Title level={3}>Отзывы</Title>
                </TabPane>

                <TabPane tab="Устройства" key="6">
                  <Title level={3}>Устройства</Title>
                  <DevicesTableComponent
                    dataSource={clientDevices}
                  />
                </TabPane>

                <TabPane tab="PUSH-уведомления" key="7">
                  <Title level={3}>PUSH-уведомления</Title>
                  <PushNotificationTableComponent
                    dataSource={pushNotification}
                  />
                </TabPane>

                <TabPane tab="Сообщения" key="8">
                  <Title level={3}>Сообщения</Title>
                  <MessagesTableComponent
                    dataSource={messages}
                  />
                </TabPane>

              </Tabs>
            </>
          :
            <Title>Клиент {phone} не найден!</Title>
          }
        </Container>
      </ScrolledCol>
    </Page>
  )
}

export default ClientDetailPage;
