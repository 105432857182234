import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestDeliveryServiceRaceCourierEnable,
  sendRequestDeliveryServiceRaceCourierDisable,
} from '../../../../../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Flex, Typography, Select, Table, Input, ColorPicker, Switch } from 'antd';
import PrintTable from '../../../../../../../../../components/PrintTable';

const { Title, Text } = Typography;
const { Search } = Input;

const CouriersTableComponent = () => {
  const { deliveryServiceRaceCouriers } = useSelector((store) => store.deliveryServices);
  const [searchTerm, setSearchTerm] = useState('');

  const modifyCurrentcurrentCouriers = deliveryServiceRaceCouriers?.map(item => {  
    return { ...item, key: item.id};
  });

  const filteredCurrentCouriers = modifyCurrentcurrentCouriers?.filter((item) =>
    item.name?.toString().toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const columns = [
    {
      title: '',
      key: 'actions',
      render: (_, record) => (
        <AccessActionSwitch record={record}/>
      ),
    },
    {
      title: 'Имя',
      key: 'name',
      render: (_, record) => {
        console.log(record.color, record.name)
        return (
        <Flex gap='large' align='center'>
          <ColorPicker value={record.color} disabled />
          <Text>{record.name}</Text>
        </Flex>
      )},
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Схема расчета ЗП',
      key: 'salaryCalculationScheme',
      render: (_, record) => (
        <AccessActionSelect record={record}/>
      ),
    },
  ];

  return (
    <>
      <Flex gap={20} wrap='wrap' style={{ margin: '20px 0' }}>
        <Search
          placeholder="Поиск по имени"
          onChange={(e) => handleSearch(e.target.value)}
          style={{ maxWidth: '500px' }}
        />
      </Flex>
      <PrintTable>
        <Table
          dataSource={filteredCurrentCouriers}
          columns={columns}
          size="small"
          pagination={{
            defaultPageSize: 50,
          }}
        />
      </PrintTable>
    </>
  );
};

export default CouriersTableComponent;

const AccessActionSwitch = ({record}) => {
  const dispatch = useDispatch();

  const onChange = (record) => {
    const requestData = {
      token: localStorage.getItem('token'),
      raceId: record.raceId,
      courierId: record.courierId
    }

    if (record.isEnabled === 0) {
      dispatch(sendRequestDeliveryServiceRaceCourierEnable(requestData));
      
    } else {
      dispatch(sendRequestDeliveryServiceRaceCourierDisable(requestData));
    }
  };

  return (
    <Switch checked={record.isEnabled} onChange={() => onChange(record)}/>
  );
};

const AccessActionSelect = ({record}) => {
  const salaryOption = [{value: record.salaryCalculationScheme, label: 'salaryCalculationScheme'}];
  
  return (
    <Select defaultValue={record.salaryCalculationScheme} options={salaryOption}/>
  )
};
