import React from 'react';
import { Flex, Progress } from 'antd';

const CourierProgressBar = ({addresses, addressesDelivered, timeStart, timeFinish, active, hasError, onClick}) => {
  const percent = addresses !== 0 ? (addressesDelivered / addresses) * 100 : 0;

  return(
    <Flex style={{position: 'relative', height: '30px', cursor: 'pointer', boxShadow: hasError ? '0px 0px 0px 2px rgba(255, 0, 0, 1)' : (active ? '0px 0px 5px 0px rgba(22, 0, 255, 1)' : ''), borderRadius: '50px', transition: 'all .3s'}} onClick={onClick}>
      <Progress style={{margin: 0}} percent={percent} showInfo={false} size={[250, 30]} strokeColor="#7aff8f"/>
      <Flex justify='space-around' style={{ position: 'absolute', top: '50%', transform: 'translate(0, -50%)', width: '100%'}}>
        <div>{timeStart ? timeStart : '--:--'}</div>
        <div>{addressesDelivered} / {addresses}</div>
        <div>{timeFinish ? timeFinish : '--:--'}</div>
      </Flex>
    </Flex>
  )
}

export default CourierProgressBar;
