import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setIsShowCityDrawer, setCurrentCity } from '../../../../../redux/reducers/utils/settingsReducers/settingsCatalogsReducer';
import { Flex, Table, Input, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { Search } = Input;

const CitiesTableComponent = ({ cities }) => {
  const { currentCity } = useSelector((store) => store.settingsCatalogs);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const citiesWithKey = cities.map(item => ({...item, key: item.id}))

  const filteredCities = citiesWithKey?.filter((item) =>
    item.name?.toString().toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Временная зона',
      dataIndex: 'timezone',
      key: 'timezone',
    },
    {
      title: 'Широта',
      dataIndex: 'latitude',
      key: 'latitude',
    },
    {
      title: 'Долгота',
      dataIndex: 'longitude',
      key: 'longitude',
    },
  ];

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleRowClick = (record) => {
    dispatch(setCurrentCity(record));
    dispatch(setIsShowCityDrawer(true));
  };

  const handleOpenCreateDrawer = () => {
    dispatch(setIsShowCityDrawer(true));
  };

  return (
    <>
      <Flex gap={20} wrap='wrap' style={{ margin: '20px 0' }}>
        <Search
          placeholder="Поиск по названию"
          onChange={(e) => handleSearch(e.target.value)}
          style={{ maxWidth: '500px' }}
        />
        <Button type='primary' shape="round" icon={<PlusOutlined />} style={{marginLeft: 'auto'}} onClick={handleOpenCreateDrawer}>Добавить город</Button>
      </Flex>
      <Table
        dataSource={filteredCities}
        columns={columns}
        bordered
        size="small"
        pagination={{
          defaultPageSize: 50,
        }}
        onRow={(record) => ({
          style: { cursor: 'pointer', background: record.key === currentCity?.key ? '#e6f7ff' : '' },
          onClick: () => handleRowClick(record),
        })}
      />
    </>
  );
};

export default CitiesTableComponent;
