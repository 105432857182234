import {configureStore} from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { persistedReducer } from './redux-persist-config'; 
import combineSaga from './sagas/combineSaga';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware(),
    sagaMiddleware,
  ],
});

sagaMiddleware.run(combineSaga);

let persistor;

try {
  persistor = persistStore(store);
} catch (error) {
  console.error('Ошибка при инициализации persistStore:', error);
}

export { store, persistor };
