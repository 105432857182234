import React from 'react';
import { Table, Typography } from 'antd';
import PrintTable from '../../../../../components/PrintTable';

const { Text } = Typography;

const AddressesTableComponent = ({ addresses, currentAddressesPage, totalAddresses, onChange }) => {
  const addressesWithKey = addresses.map(item => ({...item, key: item.addressId}))

  const columns = [
    {
      title: 'ID адреса',
      key: 'id',
      render: ((record) => (
        <Text copyable>{record.id}</Text>
      )),
    },
    {
      title: 'Город',
      dataIndex: 'citiName',
      key: 'citiName',
      onCell: (record) => ({
        style: {cursor: 'pointer'},
        onClick: () => handleCellClick(record),
      })
    },
    {
      title: 'Улица',
      dataIndex: 'street',
      key: 'street',
      onCell: (record) => ({
        style: {cursor: 'pointer'},
        onClick: () => handleCellClick(record),
      })
    },
    {
      title: 'Дом',
      dataIndex: 'house',
      key: 'house',
      onCell: (record) => ({
        style: {cursor: 'pointer'},
        onClick: () => handleCellClick(record),
      })
    },
    {
      title: 'Строение',
      dataIndex: 'structure',
      key: 'structure',
      onCell: (record) => ({
        style: {cursor: 'pointer'},
        onClick: () => handleCellClick(record),
      })
    },
    {
      title: 'Квартира',
      dataIndex: 'flat',
      key: 'flat',
      onCell: (record) => ({
        style: {cursor: 'pointer'},
        onClick: () => handleCellClick(record),
      })
    },
    {
      title: 'Этаж',
      dataIndex: 'floor',
      key: 'floor',
      onCell: (record) => ({
        style: {cursor: 'pointer'},
        onClick: () => handleCellClick(record),
      })
    },
    {
      title: 'Подъезд',
      dataIndex: 'entrance',
      key: 'entrance',
      onCell: (record) => ({
        style: {cursor: 'pointer'},
        onClick: () => handleCellClick(record),
      })
    },
  ];

  const handleTableChange = (pagination) => {
    onChange(pagination.current);
  };

  const handleCellClick = (record) => {
    console.log(record)
  };

  return (
    <PrintTable>
      <Table
        dataSource={addressesWithKey}
        columns={columns}
        bordered
        size="small"
        pagination={{
          current: currentAddressesPage,
          total: totalAddresses,
          pageSize: 20,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        onChange={handleTableChange}
      />
    </PrintTable>
  );
};

export default AddressesTableComponent;
