import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { persistor } from '../redux/configureStore';
import { logout } from '../redux/reducers/utils/authReducer';
import { Dropdown, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const HeaderProfileDropdownComponent = () => {
  const { userProfile, logoutStatus } = useSelector((store) => store.auth);
  const dispatch = useDispatch();

  const handleLogout = () => {
    const requestData = {
      token: localStorage.getItem('token')
    }

    dispatch(logout(requestData));
  };

  useEffect(() => {
    if (logoutStatus === 'resolved') {
      localStorage.removeItem('token');
      localStorage.removeItem('session');
      persistor.purge();
    }
  },[logoutStatus])

  const items = [
    {
      key: '1',
      label: userProfile
        ? `${userProfile.userId}\n${userProfile.phone}\n${userProfile.name}`
        : 'Loading...',
    },
    {
      key: '2',
      label: (
        <a onClick={handleLogout}>Выйти</a>
      ),
    },
  ];

  return (
    <StyledDropdown menu={{ items }}>
      <Avatar icon={<UserOutlined />} onClick={(e) => e.preventDefault()}/>
    </StyledDropdown>
  );
};

export default HeaderProfileDropdownComponent;

const StyledDropdown = styled(Dropdown)`
  margin-left: auto;
`
