import { takeLatest } from 'redux-saga/effects';
import {
  // Corpclients
  handleGetCorpclients,
  handleCreateCorpclients,
  handleUpdateCorpclients,
  handleDeleteCorpclients,

  // Corpclients contracts
  handleGetCorpclientsContracts,
  handleCreateCorpclientsContracts,
  handleUpdateCorpclientsContracts,
  handleDeleteCorpclientsContracts,
} from './financeCorpclientsHandler';
import {
  // Corpclients
  sendRequestCorpclientsGet,
  sendRequestCorpclientsCreate,
  sendRequestCorpclientsUpdate,
  sendRequestCorpclientsDelete,

  // Corpclients contracts
  sendRequestCorpclientsContractsGet,
  sendRequestCorpclientsContractsCreate,
  sendRequestCorpclientsContractsUpdate,
  sendRequestCorpclientsContractsDelete,
} from '../../../../reducers/utils/financeReducers/financeCorpclientsReducer';

export function* financeCorpclientsWatcherSaga() {
  // Corpclients
  yield takeLatest(sendRequestCorpclientsGet.type, handleGetCorpclients);
  yield takeLatest(sendRequestCorpclientsCreate.type, handleCreateCorpclients);
  yield takeLatest(sendRequestCorpclientsUpdate.type, handleUpdateCorpclients);
  yield takeLatest(sendRequestCorpclientsDelete.type, handleDeleteCorpclients);

  // Corpclients contracts
  yield takeLatest(sendRequestCorpclientsContractsGet.type, handleGetCorpclientsContracts);
  yield takeLatest(sendRequestCorpclientsContractsCreate.type, handleCreateCorpclientsContracts);
  yield takeLatest(sendRequestCorpclientsContractsUpdate.type, handleUpdateCorpclientsContracts);
  yield takeLatest(sendRequestCorpclientsContractsDelete.type, handleDeleteCorpclientsContracts);
}