import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import styled from 'styled-components';
import { checkAccess } from '../core/utils'

const HeaderMenuComponent = ({pages}) => {
  const { currentRoute } = useSelector((store) => store.nav);
  const { accessComponentsForUser } = useSelector((store) => store.services);
  const navigate = useNavigate();

  const handleChangeCurrentRoute = (e) => {
    navigate(e.key);
  }

  const accessPages = pages?.map(page => 
    checkAccess(accessComponentsForUser, page.key) ? page : null
  ).filter(Boolean);
  
  return <StyledMenu mode="horizontal" selectedKeys={[currentRoute]} items={accessPages} onClick={handleChangeCurrentRoute}/>
};

export default HeaderMenuComponent;

const StyledMenu = styled(Menu)`
  width: 80%;
  height: 100%;
`;
