import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestAccessRoleUsersGet,
  sendRequestAccessRoleComponentsGet,
  sendRequestAccessRoleUsersCreate,
  clearAccessRoleUsersCreateStatus,
  sendRequestAccessRoleUsersUpdate,
  clearAccessRoleUsersUpdateStatus,
  sendRequestAccessRoleUsersDelete,
  clearAccessRoleUsersDeleteStatus,
} from '../../../../redux/reducers/utils/servicesReducer';
import { Row, Col, Typography, Button } from 'antd';
import Loader from '../../../../components/Loader';
import AccessListRoleUsers from './components/AccessListRoleUsers';
import ScrolledContainer from '../../../../components/ScrolledContainer';

const { Title } = Typography;

const RightsManagementPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const {
    accessRoleUsers,
    accessRoleUsersGetRequestStatus,
    accessRoleUsersCreateRequestStatus,
    accessRoleUsersUpdateRequestStatus,
    accessRoleUsersDeleteRequestStatus,
  } = useSelector((store) => store.services);
  const dispatch = useDispatch();
  const roleUsers = accessRoleUsers.roleUsers || [];
  const dataForTypeObjects = accessRoleUsers.dataForTypeObjects || [];

  const onCreateRoleUsers = (value) => {
    const requestData = {
      token: localStorage.getItem('token'),
      roleUsers: [
        {
          phone: value.phone,
          roleId: value.role,
          typeObjectId: value.typeObject,
          objectId: value.object,
        }
      ]
    };
    dispatch(sendRequestAccessRoleUsersCreate(requestData));
  };

  useEffect(() => {
    if (accessRoleUsersCreateRequestStatus === 'resolved') {
      const requestData = {
        token: localStorage.getItem('token')
      }
      
      dispatch(sendRequestAccessRoleUsersGet(requestData));
      dispatch(clearAccessRoleUsersCreateStatus());
    }
  }, [accessRoleUsersCreateRequestStatus])

  const onUpdateRoleUsers = (value) => {
    const requestData = {
      token: localStorage.getItem('token'),
      roleUsers: [
        {
          roleId: value.role,
          userId: value.userId,
          typeObjectId: value.typeObjectId,
          objectId: value.objectId,
          roleUserId: value.roleUserId,
        }
      ]
    };
    console.log(requestData)
    dispatch(sendRequestAccessRoleUsersUpdate(requestData));
  };

  useEffect(() => {
    if (accessRoleUsersUpdateRequestStatus === 'resolved') {
      const requestData = {
        token: localStorage.getItem('token')
      }
      
      dispatch(sendRequestAccessRoleUsersGet(requestData));
      dispatch(clearAccessRoleUsersUpdateStatus());
    }
  }, [accessRoleUsersUpdateRequestStatus])

  const onDeleteRoleUsers = (roleUserId) => {
    const requestData = {
      token: localStorage.getItem('token'),
      roleUsers: [
        {
          roleUserId: roleUserId,
        }
      ]
    };

    dispatch(sendRequestAccessRoleUsersDelete(requestData));
  };

  useEffect(() => {
    if (accessRoleUsersDeleteRequestStatus === 'resolved') {
      const requestData = {
        token: localStorage.getItem('token')
      }
      
      dispatch(sendRequestAccessRoleUsersGet(requestData));
      dispatch(clearAccessRoleUsersDeleteStatus());
    }
  }, [accessRoleUsersDeleteRequestStatus]);

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestAccessRoleUsersGet({ token: localStorage.getItem('token') }));
      dispatch(sendRequestAccessRoleComponentsGet({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  return (
    <ScrolledContainer>
      {(
        accessRoleUsersGetRequestStatus === 'pending' ||
        accessRoleUsersCreateRequestStatus === 'pending' ||
        accessRoleUsersUpdateRequestStatus === 'pending' ||
        accessRoleUsersDeleteRequestStatus === 'pending'
      ) && <Loader />}
      <Title>Права пользователей</Title>
      <AccessListRoleUsers
        roleUsers={roleUsers}
        dataForTypeObjects={dataForTypeObjects}
        onCreateRoleUsers={onCreateRoleUsers}
        onUpdateRoleUsers={onUpdateRoleUsers}
        onDeleteRoleUsers={onDeleteRoleUsers}
      />
    </ScrolledContainer>
  );
};

export default RightsManagementPage;
