import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  //Notification Logs
  getNotificationsLogsRequestStatus: null,
  notificationsLogs: [],
  totalNotifications: 0,
  currentNotificationsPage: 1,

  projectsOptions: null,
  statusesOptions: null,
};

const servicesLogsSlice = createSlice({
  name: 'servicesLogs',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    //Notification Logs
    sendRequestNotificationLogsGet: (state) => { state.getNotificationsLogsRequestStatus = 'pending'; },
    sendRequestNotificationLogsGetSuccess: (state) => { state.getNotificationsLogsRequestStatus = 'resolved'; },
    sendRequestNotificationLogsGetError: (state) => { state.getNotificationsLogsRequestStatus = 'rejected'; },
    clearNotificationLogsGetStatus: (state) => { state.getNotificationsLogsRequestStatus = null; },
    setNotificationLogs: (state, action) => {
      const data = action.payload;

      state.notificationsLogs = data.pushSearch;
      state.totalNotifications = data.countPush;

      state.projectsOptions = data.references.projects.map(item => { return {value: item, label: item} });
      state.statusesOptions = data.references.statuses.map(item => { return {value: item, label: item} });
    },
    setCurrentNotificationPage: (state, action) => {
      state.currentNotificationsPage = action.payload;
    },
  },
});

export const {
  //Notification Logs
  sendRequestNotificationLogsGet,
  sendRequestNotificationLogsGetSuccess,
  sendRequestNotificationLogsGetError,
  clearNotificationLogsGetStatus,
  setNotificationLogs,
  setCurrentNotificationPage,
} = servicesLogsSlice.actions;

export default servicesLogsSlice.reducer;
