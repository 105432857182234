import { takeLatest } from 'redux-saga/effects';
import {
  //Kitchen
  handleGetKitchens,

  //UserKitchenCreate
  handleCreateUserKitchen,

  //CategoriesCatalog
  handleGetCategoriesCatalog,
} from './kitchenHandler';
import {
  //Kitchen
  sendRequestKitchens,

  //UserKitchenCreate
  sendRequestUserKitchenCreate,

  //CategoriesCatalog
  sendRequestCategoriesCatalogGet,
} from '../../../../reducers/utils/kitchenReducers/kitchenReducer';

export function* kitchenWatcherSaga() {
  //Kitchen
  yield takeLatest(sendRequestKitchens.type, handleGetKitchens);

  //UserKitchenCreate
  yield takeLatest(sendRequestUserKitchenCreate.type, handleCreateUserKitchen);

  //CategoriesCatalog
  yield takeLatest(sendRequestCategoriesCatalogGet.type, handleGetCategoriesCatalog);
}