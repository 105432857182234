import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchDataForMap,
  sendRequestWhereCouriersGet,
  fetchLocationsLib,
  fetchCurrentLocationAddresses,
} from '../../../../../redux/reducers/utils/deliveryReducers/deliveryMapReducer';
import { Flex, Button, Checkbox, message } from 'antd';
import { LoadingOutlined, SyncOutlined } from '@ant-design/icons';

const RefreshButton = () => {
  const {
    selectedFilters,
    selectedCourierLocationAndRoute,
    mapDataLoading,
    isNeedUpdateData,
    currentLocation,
  } = useSelector((store) => store.deliveryMap);
  const dispatch = useDispatch();
  const [isAutoRefresh, setIsAutoRefresh] = useState(false);

  const handleRefreshMapData = () => {
    const requestPointData = {
      token: localStorage.getItem('token'),
      raceId: selectedFilters.timePeriod,
      racePolygonIds: selectedFilters.polygons,
      day: selectedFilters.date,
    };

    const requestCourierData = {
      token: localStorage.getItem('token'),
      ...selectedCourierLocationAndRoute,
      deliveryDay: selectedFilters.date,
    };

    if (selectedFilters.city) {
      dispatch(fetchLocationsLib({ token: localStorage.getItem('token'), cityId: selectedFilters.city }));
    }

    if (selectedFilters.date) {
      dispatch(fetchDataForMap(requestPointData));
      dispatch(sendRequestWhereCouriersGet(requestCourierData));
    } else {
      message.error('Выберите дату и полигон!');
    }
  };

  useEffect(() => {
    let intervalId;

    if (isAutoRefresh) {
      intervalId = setInterval(() => {
        handleRefreshMapData();
      }, 5000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isAutoRefresh]);

  useEffect(() => {
    if (isNeedUpdateData) {
      const requestPointData = {
        token: localStorage.getItem('token'),
        raceId: selectedFilters.timePeriod,
        racePolygonIds: selectedFilters.polygons,
        day: selectedFilters.date,
      };
  
      const requestCourierData = {
        token: localStorage.getItem('token'),
        ...selectedCourierLocationAndRoute,
        deliveryDay: selectedFilters.date,
      };
  
      if (selectedFilters.city) {
        dispatch(fetchLocationsLib({ token: localStorage.getItem('token'), cityId: selectedFilters.city }));
      }
  
      if (selectedFilters.date) {
        dispatch(fetchDataForMap(requestPointData));
        dispatch(sendRequestWhereCouriersGet(requestCourierData));
      }

      if (currentLocation) {
        dispatch(fetchCurrentLocationAddresses({token: localStorage.getItem('token'), locationId: currentLocation.id}));
      }
    }
  }, [isNeedUpdateData]);

  const handleChange = () => {
    if (selectedFilters.timePeriod && selectedFilters.polygons) {
      setIsAutoRefresh(!isAutoRefresh);
    } else {
      message.error('Выберите дату и полигон!');
    }
  }

  return (
    <Flex
      style={{marginTop: '10px', height: 'fit-content'}}
      vertical
      align='flex-end'
    >
      <Button
        style={{width: '100%'}}
        onClick={handleRefreshMapData}
        type="primary"
        disabled={mapDataLoading === true}
        icon={mapDataLoading === true ? <LoadingOutlined spin /> : <SyncOutlined />} 
      />
      <Flex
        style={{
          marginTop: '-2px',
          padding: '0 2px',
          width: '100%',
          background: '#ffffff',
          border: 'solid 1px #e2e2e2',
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px'
        }}
        gap='5px'
        justify='space-between'
        align='center'
      >
        <span style={{fontSize: '10px'}}>Auto</span>
        <Checkbox checked={isAutoRefresh} onChange={handleChange}/>
      </Flex>
    </Flex>
  );
};

export default RefreshButton;