import React from 'react';
import { Flex, Table } from 'antd';
import PrintTable from '../../../../../components/PrintTable';

const CorpclientsTableComponent = ({dataSource, currentCorpclient, totalCorpclients, currentPage, onRowClick, onChange}) => {
  const columns = [
    {
      title: 'Коммерческое название',
      dataIndex: 'commercial_name',
      key: 'commercial_name',
      onCell: (record) => ({
        onClick: () => handleCellClick(record),
        style: {cursor: 'pointer'}
      })
    },
    {
      title: 'Организация',
      dataIndex: 'organizationUrName',
      key: 'organizationUrName',
      onCell: (record) => ({
        onClick: () => handleCellClick(record),
        style: {cursor: 'pointer'}
      })
    },
    {
      title: 'Ответственное лицо',
      dataIndex: 'name_responsible_person',
      key: 'name_responsible_person',
      onCell: (record) => ({
        onClick: () => handleCellClick(record),
        style: {cursor: 'pointer'}
      })
    },
    {
      title: 'Телефон контактного лица',
      dataIndex: 'phone_responsible_person',
      key: 'phone_responsible_person',
      onCell: (record) => ({
        onClick: () => handleCellClick(record),
        style: {cursor: 'pointer'}
      })
    },
    {
      title: 'Адрес доставки',
      dataIndex: 'address',
      key: 'address',
      onCell: (record) => ({
        onClick: () => handleCellClick(record),
        style: {cursor: 'pointer'}
      })
    },
    {
      title: 'Договоры',
      key: 'contracts',
      render: (record) => {
        return record.contracts && record.contracts.length > 0 ? (
          record.contracts.map((item, index) => (
            <Flex key={index}>{item.contractNumber} от {item.contractDate}</Flex>
          ))
        ) : null;
      },
      onCell: (record) => ({
        onClick: () => handleCellClick(record),
        style: {cursor: 'pointer'}
      })
    },
  ];

  const handleCellClick = (record) => {
    onRowClick(record);
  };

  const handleTableChange = (pagination) => {
    onChange(pagination.current);
  };

  return (
    <PrintTable>
      <Table
        size='small'
        dataSource={dataSource}
        columns={columns}
        onRow={(record) => ({
          style: { background: record.corporatId === currentCorpclient?.corporatId ? '#e6f7ff' : '' },
        })}
        pagination={{
          current: currentPage,
          total: totalCorpclients,
          pageSize: 20,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        onChange={handleTableChange}
      />
    </PrintTable>
  );
};

export default CorpclientsTableComponent;
