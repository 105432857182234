import apiInstance from '../../../../../api';
import {call, put, select} from 'redux-saga/effects';
import {
  // Corpclients
  sendRequestCorpclientsGetSuccess,
  sendRequestCorpclientsGetError,
  setCorpclients,
  sendRequestCorpclientsCreateSuccess,
  sendRequestCorpclientsCreateError,
  sendRequestCorpclientsUpdateSuccess,
  sendRequestCorpclientsUpdateError,
  sendRequestCorpclientsDeleteSuccess,
  sendRequestCorpclientsDeleteError,

  // Corpclients contracts
  sendRequestCorpclientsContractsGetSuccess,
  sendRequestCorpclientsContractsGetError,
  setCorpclientsContracts,
  sendRequestCorpclientsContractsCreateSuccess,
  sendRequestCorpclientsContractsCreateError,
  sendRequestCorpclientsContractsUpdateSuccess,
  sendRequestCorpclientsContractsUpdateError,
  sendRequestCorpclientsContractsDeleteSuccess,
  sendRequestCorpclientsContractsDeleteError,
} from '../../../../reducers/utils/financeReducers/financeCorpclientsReducer';
import { setMessagePopupContent, selectCommonState } from '../../../../reducers/utils/commonReducer';

// Corpclients
export function* handleGetCorpclients(action) {
  const api = '/corporats/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestCorpclientsGetSuccess());
      yield put(setCorpclients(response.data.data));
    } else {
      yield put(sendRequestCorpclientsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestCorpclientsGetError());
  }
}

export function* handleCreateCorpclients(action) {
  const api = '/corporats/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestCorpclientsCreateSuccess());
    } else {
      yield put(sendRequestCorpclientsCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestCorpclientsCreateError());
  }
}

export function* handleUpdateCorpclients(action) {
  const api = '/corporats/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));

    if (response.data.result) {
      yield put(sendRequestCorpclientsUpdateSuccess());
    } else {
      yield put(sendRequestCorpclientsUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    yield put(sendRequestCorpclientsUpdateError());
  }
}

export function* handleDeleteCorpclients(action) {
  const api = '/corporats/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestCorpclientsDeleteSuccess());
    } else {
      yield put(sendRequestCorpclientsDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestCorpclientsDeleteError());
  }
}

// Corpclients contracts
export function* handleGetCorpclientsContracts(action) {
  const api = '/corporats/contracts/get?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestCorpclientsContractsGetSuccess());
      yield put(setCorpclientsContracts(response.data.data));
    } else {
      yield put(sendRequestCorpclientsContractsGetError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestCorpclientsContractsGetError());
  }
}

export function* handleCreateCorpclientsContracts(action) {
  const api = '/corporats/contracts/create?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestCorpclientsContractsCreateSuccess());
    } else {
      yield put(sendRequestCorpclientsContractsCreateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestCorpclientsContractsCreateError());
  }
}

export function* handleUpdateCorpclientsContracts(action) {
  const api = '/corporats/contracts/update?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestCorpclientsContractsUpdateSuccess());
    } else {
      yield put(sendRequestCorpclientsContractsUpdateError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestCorpclientsContractsUpdateError());
  }
}

export function* handleDeleteCorpclientsContracts(action) {
  const api = '/corporats/contracts/delete?XDEBUG_SESSION_START=MyVSCode';
  const data = action.payload
  const commonState = yield select(selectCommonState);
  const fullApi = commonState.apiUrl + api

  try {
    const response = yield call(() => apiInstance.post(fullApi, JSON.stringify({ data })));
    

    if (response.data.result) {
      yield put(sendRequestCorpclientsContractsDeleteSuccess());
    } else {
      yield put(sendRequestCorpclientsContractsDeleteError());
    }
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    
    yield put(sendRequestCorpclientsContractsDeleteError());
  }
}
