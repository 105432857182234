import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import CourierComponent from './СourierComponent';
import MapFilter from './MapFilter';
import RefreshButton from './RefreshButton';
import DetailCourierComponent from './DetailCourierComponent';
import GeocodeComponent from './GeocodeComponent';

const DeliveryRightPanel = () => {
  const {
    detailCouriersShow,
  } = useSelector((store) => store.deliveryMap);

  return (
    <StyledDeliveryRightPanel>
      {detailCouriersShow ?
        <DetailCourierComponent/>
      :
        <CourierComponent/>
      }
      <MapFilter/>
      <RefreshButton/>
      <GeocodeComponent/>
    </StyledDeliveryRightPanel>
  );
};

export default DeliveryRightPanel;

const StyledDeliveryRightPanel = styled.div`
  position: sticky;
  top: 0;
  right: 0;
  z-index: 600;
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  height: 0;
`;
