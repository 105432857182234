import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestKitchens,
} from '../../../../../redux/reducers/utils/kitchenReducers/kitchenReducer';
import {
  setIsOpenTemplatesDrawer,
  sendRequestRacesForDays,
  sendRequestMenuTemplates,
  sendRequestUpdateMenuTemplate,
  sendRequestDeleteMenuTemplate,
  sendRequestAddProductMenuTemplate,
  sendRequestRemoveProductMenuTemplate,
} from '../../../../../redux/reducers/utils/kitchenReducers/kitchenMenuReducer';
import { Flex, Typography, Drawer, Button } from 'antd';
import { RightOutlined, CloseOutlined } from '@ant-design/icons';
import MenuTemplateTopRightDropdown from './components/MenuTemplateTopRightDropdown';
import Loader from '../../../../../components/Loader';
import KitchenSelectorComponent from '../../../components/KitchenSelectorComponent';
import MenuTemplateListComponent from './components/MenuTemplateListComponent';
import MenuTemplateProductListComponent from './components/MenuTemplateProductListComponent';
import MenuTemplateEditableTitle from './components/MenuTemplateEditableTitle';
import { Container, Page, ScrolledCol } from '../../../../../core/styles';

const { Title, Text } = Typography;

const MenuTemplatesPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const {
    selectKitchen,
  } = useSelector((store) => store.kitchen);
  const {
    isOpenTemplatesDrawer,
    getMenuTemplatesRequestStatus,
    menuTemplateProducts,
    menuTemplateProductsRequestStatus,
    createMenuTemplateStatus,
    updateMenuTemplateStatus,
    deleteMenuTemplateStatus,
    selectedTemplate,
  } = useSelector((store) => store.kitchenMenu);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectKitchen !== null) {
      const requestData = {
        token: localStorage.getItem('token'),
        kitchenId: selectKitchen.id,
      }
  
      dispatch(sendRequestRacesForDays(requestData));
      dispatch(sendRequestMenuTemplates(requestData));
    }
  }, [selectKitchen])

  const onUpdateMenuTemplate = data => dispatch(sendRequestUpdateMenuTemplate(data))
  const onDeleteMenuTemplate = data => dispatch(sendRequestDeleteMenuTemplate(data))
  const onAddProductMenuTemplate = data => dispatch(sendRequestAddProductMenuTemplate(data))
  const onRemoveProductMenuTemplate = data => dispatch(sendRequestRemoveProductMenuTemplate(data))

  useEffect(() => {
    if (
      createMenuTemplateStatus === 'resolved' ||
      updateMenuTemplateStatus === 'resolved' ||
      deleteMenuTemplateStatus === 'resolved' 
    ) {
      const requestData = {
        token: localStorage.getItem('token'),
        kitchenId: selectKitchen.id,
      }

      dispatch(sendRequestMenuTemplates(requestData));
    }
  }, [createMenuTemplateStatus, updateMenuTemplateStatus, deleteMenuTemplateStatus])
  
  const showDrawer = () => {
    dispatch(setIsOpenTemplatesDrawer(true));
  };

  const onClose = () => {
    dispatch(setIsOpenTemplatesDrawer(false));
  };

  return (
    <>
      {(
        getMenuTemplatesRequestStatus === 'pending' ||
        menuTemplateProductsRequestStatus === 'pending'
      ) && <Loader />}

      
        
        <Button
          onClick={showDrawer}
          icon={<RightOutlined />}
          style={{
            position: 'absolute',
            top: '50%',
            left: 0,
            transform: 'translate(0, -50%)',
            zIndex: 100,
            width: '24px',
            height: '70px',
            borderRadius: '0 15px 15px 0',
          }}
        />
        <Drawer
          title="Шаблоны"
          placement="left"
          open={isOpenTemplatesDrawer}
          mask={false}
          width='fit-content'
          getContainer={false}
          closeIcon={
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={onClose}
              style={{ position: 'absolute', top: '12px', right: '6px', zIndex: 1 }}
            />
          }
          style={{ height: 'calc(100vh - 44px)', left: '-15px',}}
        >
          <MenuTemplateListComponent/>
        </Drawer>
        
      <ScrolledCol>
        <Title level={2}>Шаблоны меню</Title>
        <Flex justify='space-between' align='center' gap={16}>
          <KitchenSelectorComponent/>
          {selectedTemplate && <MenuTemplateTopRightDropdown selectedTemplate={selectedTemplate} onDeleteMenuTemplate={onDeleteMenuTemplate}/>}
        </Flex>
          
        {selectedTemplate && <MenuTemplateEditableTitle selectedTemplate={selectedTemplate} onUpdateMenuTemplate={onUpdateMenuTemplate}/>}
        <MenuTemplateProductListComponent
          menuTemplateProducts={menuTemplateProducts}
          selectedTemplate={selectedTemplate}
          onAddProductMenuTemplate={onAddProductMenuTemplate}
          onRemoveProductMenuTemplate={onRemoveProductMenuTemplate}
        />
      </ScrolledCol>
    </>
  );
}

export default MenuTemplatesPage