import React, { useState } from 'react';
import { Table, Input, Switch } from 'antd';
import styled from 'styled-components';

const { Search } = Input;

const AccessListRoleMethod = ({ selectedRole, methods, roleMethods, onSwitchChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const methodsWithKey = methods.map(item => ({...item, key: item.id}))
  const filteredMethods = methodsWithKey.filter((method) =>
    method.keyPublicMethod.toLowerCase().includes(searchTerm.toLowerCase()) ||
    method.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    method.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredRoleMethods = roleMethods.filter(
    (roleMethod) => roleMethod.roleId === selectedRole.id
  );

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const columns = [
    {
      title: 'Ключ',
      dataIndex: 'keyPublicMethod',
      key: 'keyPublicMethod',
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: '',
      key: 'actions',
      render: (_, record) => {
        const roleMethod = filteredRoleMethods?.find(
          (rm) => rm.publicMethodId === record.id
        );

        return (
          <AccessAction
            selectedRole={selectedRole}
            method={record}
            isChecked={!!roleMethod}
            roleMethodId={roleMethod ? roleMethod.id : null}
            onSwitchChange={onSwitchChange}
          />
        )
      },
    },
  ];

  return (
    <>
      <Search
        placeholder="Поиск по названию или описанию"
        onChange={(e) => handleSearch(e.target.value)}
        style={{ margin: '20px 0' }}
      />
      <Table
        dataSource={filteredMethods}
        columns={columns}
        size="small"
        pagination={{
          defaultPageSize: 50,
        }}
      />
    </>
  );
};

export default AccessListRoleMethod;

const AccessAction = ({selectedRole, method, isChecked, roleMethodId, onSwitchChange }) => {
  const handleChange = (checked) => {
    onSwitchChange(checked, method.id, selectedRole.id, roleMethodId);
  };

  return (
    <Action>
      <Switch checked={isChecked} onChange={handleChange} />
    </Action>
  );
};

const Action = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`