import styled from 'styled-components';

export const antdToken = {
  fontSize: 14,
  colorPrimary: '#EA394B',
  
  Layout: {
    bodyBg: '#f5f5f5',
    
    headerBg: '#ffffff',
    headerColor: '#EA394B',
    headerHeight: 45,
    headerPadding: '0 30px',

    siderBg: '#ffffff',
    triggerBg: '#ffffff',
    triggerColor: '#000000',

    footerBg: '#ffffff',
  },
}

export const Page = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px 10px;
  @media (max-width: 1470px) {
    max-width: 1200px;
  }
  @media (max-width: 1230px) {
    max-width: 1024px;
  }
  @media (max-width: 1054px) {
    max-width: 100%;
  }
`
export const ScrolledCol = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: ${(props) => props.width ? `0 0 ${props.width}` : 1};
  height: 100%;
  max-height: ${(props) => props.height ? props.height : 'calc(100vh - 45px)'};
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #f5f5f5;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: #999999;
  }
`

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SitiyCard = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-left: 8px solid #EA394B;
  border-radius: 8px;
  margin: 10px;
  padding: 10px 20px;
`