import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestCitiesGet,
  sendRequestCitiesCreate,
  sendRequestCitiesUpdate,
  sendRequestCitiesDelete,
} from '../../../../redux/reducers/utils/settingsReducers/settingsCatalogsReducer';
import { Row, Col, Typography } from 'antd';
import Loader from '../../../../components/Loader';
import CitiesTableComponent from "./components/CitiesTableComponent";
import CityDrawerComponent from "./components/CityDrawerComponent";
import ScrolledContainer from "../../../../components/ScrolledContainer";

const { Title } = Typography;

const CitiesCatalogPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const {
    citiesGetRequestStatus,
    cities,
    currentCity,
    citiesCreateRequestStatus,
    citiesUpdateRequestStatus,
    citiesDeleteRequestStatus,
    isShowCityDrawer,
  } = useSelector((store) => store.settingsCatalogs);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestCitiesGet({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  useEffect(() => {
    const requestData = {
      token: localStorage.getItem('token'),
    }

    dispatch(sendRequestCitiesGet(requestData))
  }, [
    citiesCreateRequestStatus,
    citiesUpdateRequestStatus,
    citiesDeleteRequestStatus,
  ])

  const onCreateCity = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      name: values.name,
      longitude: values.longitude,
      latitude: values.latitude,
      timezone: values.timezone,
    }

    dispatch(sendRequestCitiesCreate(requestData));
  }

  const onUpdateCity = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      cityId: values.cityId,
      name: values.name,
      longitude: values.longitude,
      latitude: values.latitude,
      timezone: values.timezone,
    }

    dispatch(sendRequestCitiesUpdate(requestData));
  }

  const onDeleteCity = (cityId) => {
    const requestData = {
      token: localStorage.getItem('token'),
      cityId: cityId,
    }

    dispatch(sendRequestCitiesDelete(requestData));
  }

  return (
    <ScrolledContainer>
      {(
        citiesGetRequestStatus === 'pending' ||
        citiesCreateRequestStatus === 'pending' ||
        citiesUpdateRequestStatus === 'pending' ||
        citiesDeleteRequestStatus === 'pending'
      ) && <Loader />}
      <Title>Справочник городов</Title>
      <CitiesTableComponent
        cities={cities}
        currentCity={currentCity}
      />
      <CityDrawerComponent
        isShowCityDrawer={isShowCityDrawer}
        currentCity={currentCity}
        onCreateCity={onCreateCity}
        onUpdateCity={onUpdateCity}
        onDeleteCity={onDeleteCity}
      />
    </ScrolledContainer>
  );
}

export default CitiesCatalogPage;