import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestOrganizationsGet,
  sendRequestOrganizationsCreate,
  sendRequestOrganizationsUpdate,
  sendRequestOrganizationsDelete,
} from '../../../../redux/reducers/utils/settingsReducers/settingsCatalogsReducer';
import { Row, Col, Typography } from 'antd';
import Loader from '../../../../components/Loader';
import OrganizationsTableComponent from "./components/OrganizationsTableComponent";
import OrganizationDrawerComponent from "./components/OrganizationDrawerComponent";
import ScrolledContainer from "../../../../components/ScrolledContainer";

const { Title } = Typography;

const OrganizationsCatalogPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const {
    organizationsGetRequestStatus,
    organizations,
    currentOrganization,
    organizationsCreateRequestStatus,
    organizationsUpdateRequestStatus,
    organizationsDeleteRequestStatus,
    isShowOrganizationDrawer,
  } = useSelector((store) => store.settingsCatalogs);
  const dispatch = useDispatch();
console.log(organizations)
  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestOrganizationsGet({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  useEffect(() => {
    const requestData = {
      token: localStorage.getItem('token'),
    }

    dispatch(sendRequestOrganizationsGet(requestData))
  }, [
    organizationsCreateRequestStatus,
    organizationsUpdateRequestStatus,
    organizationsDeleteRequestStatus,
  ])

  const onCreateOrganization = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      urName: values.urName,
      inn: values.inn,
      kpp: values.kpp,
      fio: values.fio,
      phone: values.phone,
      email: values.email,
      factAddres: values.factAddres,
      urAddres: values.urAddres,
      name: values.name,
      desc: values.desc,
      bank_accAt: values.bank_accAt,
      bank_rcbic: values.bank_rcbic,
      bank_corrAcc: values.bank_corrAcc,
      bank_name: values.bank_name,
      entities: "Kitchen"
    }

    dispatch(sendRequestOrganizationsCreate(requestData));
  }

  const onUpdateOrganization = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      organizationId: values.organizationId,
      urName: values.urName,
      inn: values.inn,
      kpp: values.kpp,
      fio: values.fio,
      phone: values.phone,
      email: values.email,
      factAddres: values.factAddres,
      urAddres: values.urAddres,
      name: values.name,
      desc: values.desc,
      bank_accAt: values.bank_accAt,
      bank_rcbic: values.bank_rcbic,
      bank_corrAcc: values.bank_corrAcc,
      bank_name: values.bank_name,
      entities: "Kitchen",
    }

    dispatch(sendRequestOrganizationsUpdate(requestData));
  }

  const onDeleteOrganization = (values) => {
    const requestData = {
      token: localStorage.getItem('token'),
      organizationId: values,
    }

    dispatch(sendRequestOrganizationsDelete(requestData));
  }

  return (
    <ScrolledContainer>
      {(
        organizationsGetRequestStatus === 'pending' ||
        organizationsCreateRequestStatus === 'pending' ||
        organizationsUpdateRequestStatus === 'pending' ||
        organizationsDeleteRequestStatus === 'pending'
      ) && <Loader />}
      <Title>Справочник организаций</Title>
      <OrganizationsTableComponent
        organizations={organizations}
        currentOrganization={currentOrganization}
      />
      <OrganizationDrawerComponent
        isShowOrganizationDrawer={isShowOrganizationDrawer}
        currentOrganization={currentOrganization}
        onCreateOrganization={onCreateOrganization}
        onUpdateOrganization={onUpdateOrganization}
        onDeleteOrganization={onDeleteOrganization}
      />
    </ScrolledContainer>
  );
}

export default OrganizationsCatalogPage;