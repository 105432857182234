import React from 'react';
import { Switch, Table, Tag, Typography } from 'antd';
import PrintTable from '../../../../../components/PrintTable';

const { Text } = Typography;

const PromocodesTableComponent = ({dataSource, kitchenOptions, currentPromocode, currentPromocodesPage, totalPromocodes, onRowClick, onChange}) => {
  const columns = [
    {
      title: 'ID',
      key: 'id',
      render: ((record) => (
        <Text copyable>{record.id}</Text>
      )),
    },
    {
      title: 'Промокод',
      key: 'promocode',
      render: ((record) => (
        <Text copyable>{record.promocode}</Text>
      )),
    },
    {
      title: 'Процент скидки',
      dataIndex: 'discountPerc',
      key: 'discountPerc',
      render: (value) => `${value}%`,
      onCell: (record) => ({
        onClick: () => handleCellClick(record),
        style: {cursor: 'pointer'}
      })
    },
    {
      title: 'Количество применения',
      dataIndex: 'maxUsing',
      key: 'maxUsing',
      onCell: (record) => ({
        onClick: () => handleCellClick(record),
        style: {cursor: 'pointer'}
      })
    },
    {
      title: 'Дата начала действия',
      dataIndex: 'periods',
      key: 'periods',
      onCell: (record) => ({
        onClick: () => handleCellClick(record),
        style: {cursor: 'pointer'}
      })
    },
    {
      title: 'Дата окончания действия',
      dataIndex: 'promoPeriodEnd',
      key: 'promoPeriodEnd',
      onCell: (record) => ({
        onClick: () => handleCellClick(record),
        style: {cursor: 'pointer'}
      })
    },
    {
      title: 'Кухни, в которых можно применять',
      dataIndex: 'kitchens',
      key: 'kitchens',
      onCell: (record) => ({
        onClick: () => handleCellClick(record),
        style: {cursor: 'pointer'}
      }),
      render: (kitchens) => {
        const kitchenIds = kitchens.split(',').map(id => parseInt(id, 10));
        const initialKitchens = kitchenOptions.flatMap(group => 
          group.options.filter(option => kitchenIds.includes(option.value))
        );
        
        console.log(initialKitchens)

        return(
          initialKitchens.length > 0 ? initialKitchens.map(option => <Tag key={option.value}>{option.label}</Tag>) : (kitchens.length > 0 ? kitchens.split(',').map(item => <Tag key={item}>ID: {item}</Tag>) : 'ВСЕ')
        )
      },
    },
    {
      title: '',
      key: 'action',
      render: (record) => <Switch checked={record.status}/>,
    },
  ];

  const handleCellClick = (record) => {
    onRowClick(record);
  };

  const handleTableChange = (pagination) => {
    onChange(pagination.current);
  };

  return (
    <PrintTable>
      <Table
        size='small'
        dataSource={dataSource}
        columns={columns}
        onRow={(record) => ({
          style: { background: record.id === currentPromocode?.id ? '#e6f7ff' : '' },
        })}
        pagination={{
          current: currentPromocodesPage,
          total: totalPromocodes,
          pageSize: 20,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        onChange={handleTableChange}
      />
    </PrintTable>
  );
};

export default PromocodesTableComponent;
