import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentRoute } from '../../redux/reducers/utils/navReducer';
import { sendRequestCitiesGet, sendRequestOrganizationsGet } from '../../redux/reducers/utils/settingsReducers/settingsCatalogsReducer';
import { sendRequestKitchens, sendRequestCategoriesCatalogGet } from '../../redux/reducers/utils/kitchenReducers/kitchenReducer';
import { Container, Page, ScrolledCol } from '../../core/styles';

const FinanceModule = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuth } = useSelector((store) => store.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === '/finance') {
      dispatch(setCurrentRoute('finance/wallets'));
      navigate('/finance/wallets');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isAuth) {
      dispatch(sendRequestKitchens({ token: localStorage.getItem('token'), offset: 0, limit: 1000 }));
      dispatch(sendRequestCitiesGet({ token: localStorage.getItem('token') }));
      dispatch(sendRequestOrganizationsGet({ token: localStorage.getItem('token') }));
      dispatch(sendRequestCategoriesCatalogGet({ token: localStorage.getItem('token') }));
    }
  }, [isAuth]);

  return(
    <Page>
      <ScrolledCol>
        <Container>
          {children}
        </Container>
      </ScrolledCol>
    </Page>
  );
}

export default FinanceModule;