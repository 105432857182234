import React, { useState, useEffect } from 'react';
import { Calendar, Select } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import styled from 'styled-components';

dayjs.extend(customParseFormat);
const serverFormat = 'YYYY-MM-DD';

const YearCalendar = ({ data, onCreate, onDelete }) => {
  const [datesData, setDatesData] = useState([]);
  const [currentYear, setCurrentYear] = useState(dayjs().year());

  useEffect(() => {
    if (data) {
      setDatesData(data);
    }
  }, [data]);

  const headerRender = (month) => (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '25px',
        color: '#000000',
      }}
    >
      {month}
    </div>
  );

  const dateCellRender = (value, validRange) => {
    const selectedDateStr = value.format(serverFormat);
    const isInValidRange = dayjs(value).isAfter(validRange[0]) && dayjs(value).isBefore(validRange[1]);
    const notWorkingDay = datesData.find(item => item.day === selectedDateStr);

    return (
      <>
        {isInValidRange && 
          <>
            {notWorkingDay ? (
              <NotWorkingDayDateCell onClick={() => handleDelete(selectedDateStr)}>
                {value.date()}
              </NotWorkingDayDateCell>
            ) : (
              <DateCell onClick={() => handleCreate(selectedDateStr)}>
                {value.date()}
              </DateCell>
            )}
          </>
        }
      </>
    );
  };

  const handleCreate = (value) => {
    console.log(value)
    onCreate(value);
  };

  const handleDelete = (value) => {
    console.log(value)
    onDelete(value);
  };

  const handleYearChange = (year) => {
    setCurrentYear(year);
  };

  return (
    <>
      <div style={{ padding: '0 10px' }}>
        <div>Выбрать год:</div>
        <Select
          defaultValue={currentYear}
          style={{ width: 120 }}
          onChange={handleYearChange}
        >
          {Array.from({ length: 20 }, (_, index) => (
            <Select.Option key={index} value={currentYear - 10 + index}>
              {currentYear - 10 + index}
            </Select.Option>
          ))}
        </Select>
      </div>
      <CalendarWrapper>
        {Array.from({ length: 12 }, (_, month) => {
          const date = dayjs().year(currentYear).month(month);
          const monthName = date.format('MMMM');
          const startOfMonth = date.startOf('month');
          const endOfMonth = date.endOf('month');
          const validRange = [startOfMonth, endOfMonth];
          return (
            <Calendar
              key={month}
              style={{ width: '300px' }}
              value={date}
              fullscreen={false}
              validRange={validRange}
              headerRender={() => headerRender(monthName)}
              dateFullCellRender={(value) => dateCellRender(value, validRange)}
            />
          );
        })}
      </CalendarWrapper>
    </>
  );
};

export default YearCalendar;

const CalendarWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 5px;
  width: fit-content;
  padding: 10px;
`;

const DateCell = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 25px;
  height: 25px;
  color: #000000;
`;

const NotWorkingDayDateCell = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 25px;
  height: 25px;
  background: #EA394B;
  border-radius: 5px;
  color: #ffffff;
`;
