import { takeLatest } from 'redux-saga/effects';
import {
  handleGetAddresses,
} from './addressesSearchHandler';
import {
  sendRequestAddressesSearch,
  sendRequestAddressesCreate,
  sendRequestAddressesUpdate,
  sendRequestAddressesDelete,
} from '../../../../reducers/utils/clientsReducers/addressesSearchReducer';

export function* addressesSearchWatcherSaga() {
  yield takeLatest(sendRequestAddressesSearch.type, handleGetAddresses);
}