import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  //Organizations
  organizationsGetRequestStatus: null,
  organizations: [],
  currentOrganization: null,
  organizationsCreateRequestStatus: null,
  organizationsUpdateRequestStatus: null,
  organizationsDeleteRequestStatus: null,
  isShowOrganizationDrawer: false,

  //Cities
  citiesGetRequestStatus: null,
  cities: [],
  currentCity: null,
  citiesCreateRequestStatus: null,
  citiesUpdateRequestStatus: null,
  citiesDeleteRequestStatus: null,
  isShowCityDrawer: false,
};

const settingsCatalogsSlice = createSlice({
  name: 'settingsCatalogs',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
  reducers: {
    //Organizations
    sendRequestOrganizationsGet: (state) => { state.organizationsGetRequestStatus = 'pending'; },
    sendRequestOrganizationsGetSuccess: (state) => { state.organizationsGetRequestStatus = 'resolved'; },
    sendRequestOrganizationsGetError: (state) => { state.organizationsGetRequestStatus = 'rejected'; },
    clearOrganizationsGetStatus: (state) => { state.organizationsGetRequestStatus = null; },
    setOrganizations: (state, action) => {
      state.organizations = action.payload;
    },
    updateOrganizations: (state, action) => {
      const updatedKeys = action.payload;

      return {
        ...state,
        organizations: {
          ...state.organizations,
          ...updatedKeys,
        },
      };
    },
    setCurrentOrganization: (state, action) => {
      state.currentOrganization = action.payload;
    },
    sendRequestOrganizationsCreate: (state) => { state.organizationsCreateRequestStatus = 'pending'; },
    sendRequestOrganizationsCreateSuccess: (state) => { state.organizationsCreateRequestStatus = 'resolved'; },
    sendRequestOrganizationsCreateError: (state) => { state.organizationsCreateRequestStatus = 'rejected'; },
    clearOrganizationsCreateStatus: (state) => { state.organizationsCreateRequestStatus = null; },
    sendRequestOrganizationsUpdate: (state) => { state.organizationsUpdateRequestStatus = 'pending'; },
    sendRequestOrganizationsUpdateSuccess: (state) => { state.organizationsUpdateRequestStatus = 'resolved'; },
    sendRequestOrganizationsUpdateError: (state) => { state.organizationsUpdateRequestStatus = 'rejected'; },
    clearOrganizationsUpdateStatus: (state) => { state.organizationsUpdateRequestStatus = null; },
    sendRequestOrganizationsDelete: (state) => { state.organizationsDeleteRequestStatus = 'pending'; },
    sendRequestOrganizationsDeleteSuccess: (state) => { state.organizationsDeleteRequestStatus = 'resolved'; },
    sendRequestOrganizationsDeleteError: (state) => { state.organizationsDeleteRequestStatus = 'rejected'; },
    clearOrganizationsDeleteStatus: (state) => { state.organizationsDeleteRequestStatus = null; },
    setIsShowOrganizationDrawer: (state, action) => {
      state.isShowOrganizationDrawer = action.payload;
    },

    //Cities
    sendRequestCitiesGet: (state) => { state.citiesGetRequestStatus = 'pending'; },
    sendRequestCitiesGetSuccess: (state) => { state.citiesGetRequestStatus = 'resolved'; },
    sendRequestCitiesGetError: (state) => { state.citiesGetRequestStatus = 'rejected'; },
    clearCitiesGetStatus: (state) => { state.citiesGetRequestStatus = null; },
    setCities: (state, action) => {
      state.cities = action.payload;
    },
    updateCities: (state, action) => {
      const updatedKeys = action.payload;

      return {
        ...state,
        cities: {
          ...state.cities,
          ...updatedKeys,
        },
      };
    },
    setCurrentCity: (state, action) => {
      state.currentCity = action.payload;
    },
    sendRequestCitiesCreate: (state) => { state.citiesCreateRequestStatus = 'pending'; },
    sendRequestCitiesCreateSuccess: (state) => { state.citiesCreateRequestStatus = 'resolved'; },
    sendRequestCitiesCreateError: (state) => { state.citiesCreateRequestStatus = 'rejected'; },
    clearCitiesCreateStatus: (state) => { state.citiesCreateRequestStatus = null; },
    sendRequestCitiesUpdate: (state) => { state.citiesUpdateRequestStatus = 'pending'; },
    sendRequestCitiesUpdateSuccess: (state) => { state.citiesUpdateRequestStatus = 'resolved'; },
    sendRequestCitiesUpdateError: (state) => { state.citiesUpdateRequestStatus = 'rejected'; },
    clearCitiesUpdateStatus: (state) => { state.citiesUpdateRequestStatus = null; },
    sendRequestCitiesDelete: (state) => { state.citiesDeleteRequestStatus = 'pending'; },
    sendRequestCitiesDeleteSuccess: (state) => { state.citiesDeleteRequestStatus = 'resolved'; },
    sendRequestCitiesDeleteError: (state) => { state.citiesDeleteRequestStatus = 'rejected'; },
    clearCitiesDeleteStatus: (state) => { state.citiesDeleteRequestStatus = null; },
    setIsShowCityDrawer: (state, action) => {
      state.isShowCityDrawer = action.payload;
    },
  },
});

export const {
  //Organizations
  sendRequestOrganizationsGet,
  sendRequestOrganizationsGetSuccess,
  sendRequestOrganizationsGetError,
  clearOrganizationsGetStatus,
  setOrganizations,
  updateOrganizations,
  setCurrentOrganization,
  sendRequestOrganizationsCreate,
  sendRequestOrganizationsCreateSuccess,
  sendRequestOrganizationsCreateError,
  clearOrganizationsCreateStatus,
  sendRequestOrganizationsUpdate,
  sendRequestOrganizationsUpdateSuccess,
  sendRequestOrganizationsUpdateError,
  clearOrganizationsUpdateStatus,
  sendRequestOrganizationsDelete,
  sendRequestOrganizationsDeleteSuccess,
  sendRequestOrganizationsDeleteError,
  clearOrganizationsDeleteStatus,
  setIsShowOrganizationDrawer,

  //Cities
  sendRequestCitiesGet,
  sendRequestCitiesGetSuccess,
  sendRequestCitiesGetError,
  clearCitiesGetStatus,
  setCities,
  updateCities,
  setCurrentCity,
  sendRequestCitiesCreate,
  sendRequestCitiesCreateSuccess,
  sendRequestCitiesCreateError,
  clearCitiesCreateStatus,
  sendRequestCitiesUpdate,
  sendRequestCitiesUpdateSuccess,
  sendRequestCitiesUpdateError,
  clearCitiesUpdateStatus,
  sendRequestCitiesDelete,
  sendRequestCitiesDeleteSuccess,
  sendRequestCitiesDeleteError,
  clearCitiesDeleteStatus,
  setIsShowCityDrawer,
} = settingsCatalogsSlice.actions;

export default settingsCatalogsSlice.reducer;
