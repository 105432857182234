import { takeLatest } from 'redux-saga/effects';
import {
  handleGetKitchenComplaint,
  handleCreateKitchenComplaint,
  handleUpdateKitchenComplaint,
  handleDeleteKitchenComplaint,
  handleConfirmKitchenComplaint,
  handleGetTableServicesType,
  handleGetTablePretensionsStatus,
} from './kitchenComplaintHandler';
import {
  sendRequestKitchenComplaintGet,
  sendRequestKitchenComplaintCreate,
  sendRequestKitchenComplaintUpdate,
  sendRequestKitchenComplaintDelete,
  sendRequestKitchenComplaintConfirm,
  sendRequestKitchenComplaintTableServicesTypeGet,
  sendRequestKitchenComplaintTablePretensionsStatusGet,
} from '../../../../reducers/utils/kitchenReducers/kitchenComplaintReducer';

export function* kitchenComplaintWatcherSaga() {
  yield takeLatest(sendRequestKitchenComplaintGet.type, handleGetKitchenComplaint);
  yield takeLatest(sendRequestKitchenComplaintCreate.type, handleCreateKitchenComplaint);
  yield takeLatest(sendRequestKitchenComplaintUpdate.type, handleUpdateKitchenComplaint);
  yield takeLatest(sendRequestKitchenComplaintDelete.type, handleDeleteKitchenComplaint);
  yield takeLatest(sendRequestKitchenComplaintConfirm.type, handleConfirmKitchenComplaint);
  yield takeLatest(sendRequestKitchenComplaintTableServicesTypeGet.type, handleGetTableServicesType);
  yield takeLatest(sendRequestKitchenComplaintTablePretensionsStatusGet.type, handleGetTablePretensionsStatus);
}