import React from 'react';
import { Flex, Result, Button } from 'antd';
import { FrownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Page } from '../core/styles';

const AccessDenied = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate('/main');
  };

  return (
    <Flex justify='center' align='center' style={{height: '100%'}}>
      <Result
        icon={<FrownOutlined style={{ color: '#ff4d4f' }} />}
        title="У Вас нет доступа к этому разделу"
        extra={
          <Button type="primary" onClick={handleBackToHome}>
            Вернуться на главную
          </Button>
        }
        style={{padding: '50px', backgroundColor: '#f0f2f5', borderRadius: '30px'}}
      />
    </Flex>
  );
};

export default AccessDenied;
