import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import styled from 'styled-components';
import { checkAccess } from '../core/utils'

const SiderMenuComponent = ({modules, onClose}) => {
  const { currentModule } = useSelector((store) => store.nav);
  const { accessComponentsForUser } = useSelector((store) => store.services);
  const navigate = useNavigate();

  const handleModuleChange = (e) => {
    // navigate(e.key);
    if (onClose) {
      onClose();
    }
  };

  const accessModules = modules?.map(module => 
    checkAccess(accessComponentsForUser, module.key) ? module : null
  ).filter(Boolean);
  
  return <StyledMenu mode="inline" selectedKeys={[currentModule]} items={accessModules} onClick={handleModuleChange}/>
};

export default SiderMenuComponent;

const StyledMenu = styled(Menu)`
  /* flex: 0 0 60px;
  width: 60px;
  min-width: 60px;
  max-width: 60px; */
`;
