import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  sendRequestDeliveryServicesGet,
  setCurrentDeliveryService,
  sendRequestDeliveryServiceRacesGet,
  setCurrentDeliveryServiceRace,
  sendRequestDeliveryServiceRaceCouriersGet,
  sendRequestDeliveryServiceRacePolygonsGet,
  sendRequestDeliveryServiceRaceKitchensGet,
} from '../../../../../../../redux/reducers/utils/deliveryReducers/deliveryServicesReducer';
import { Row, Col, Flex, Breadcrumb, Typography, Divider, Tabs } from 'antd';
import EditableForm from './raceDetail/EditableForm';
import CouriersTableComponent from './raceDetail/couriers/CouriersTableComponent';
import PolygonsTableComponent from './raceDetail/polygons/PolygonsTableComponent';
import KitchensTableComponent from './raceDetail/kitchens/KitchensTableComponent';
import KitchenDrawerComponent from './raceDetail/kitchens/KitchenDrawerComponent';
import ScrolledContainer from '../../../../../../../components/ScrolledContainer';
// import DeliveryServicesEditableRaceDetailComponent from './components/DeliveryServicesEditableRaceDetailComponent';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const RaceDetailComponent = () => {
  const { serviceId, raceId } = useParams();
  const { isAuth } = useSelector((store) => store.auth);
  const {
    deliveryServices,
    currentDeliveryService,
    deliveryServiceRaces,
    currentDeliveryServiceRace,
    deliveryServiceRaceCouriers,
    deliveryServiceRacePolygons,
    deliveryServiceRaceKitchenAddRequestStatus,
    deliveryServiceRaceKitchenUpdateRequestStatus,
    deliveryServiceRaceKitchenTermsCreateRequestStatus,
    deliveryServiceRaceKitchenTermsDeleteRequestStatus,
  } = useSelector((store) => store.deliveryServices);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth) {
      const requestData = {
        token: localStorage.getItem('token'),
        raceId: raceId,
        deliveryServiceId: serviceId,
      }
      dispatch(sendRequestDeliveryServicesGet({ token: localStorage.getItem('token') }));
      dispatch(sendRequestDeliveryServiceRaceCouriersGet(requestData));
      dispatch(sendRequestDeliveryServiceRacePolygonsGet(requestData));
      dispatch(sendRequestDeliveryServiceRaceKitchensGet(requestData));
    }
  }, [isAuth]);

  useEffect(() => {
    if (deliveryServices.length) {
      console.log(deliveryServices);
      const matchDeliveryService = deliveryServices.find(service => service.id == serviceId);

      console.log(matchDeliveryService);
      dispatch(setCurrentDeliveryService(matchDeliveryService));
    }
  }, [deliveryServices]);

  useEffect(() => {
    if (deliveryServices && currentDeliveryService) {
      console.log(deliveryServices, currentDeliveryService);

      const requestData = {
        token: localStorage.getItem('token'),
        deliveryServiceId: currentDeliveryService.id,
      }

      dispatch(sendRequestDeliveryServiceRacesGet(requestData));
    }
  }, [deliveryServices, currentDeliveryService]);

  console.log(deliveryServiceRaces)

  useEffect(() => {
    if (deliveryServiceRaces.length) {
      console.log(deliveryServiceRaces);
      const matchDeliveryServiceRaces = deliveryServiceRaces.find(race => race.id == raceId);

      console.log(matchDeliveryServiceRaces);
      dispatch(setCurrentDeliveryServiceRace(matchDeliveryServiceRaces));
    }
  }, [deliveryServiceRaces]);

  useEffect(() => {
    if (
      deliveryServiceRaceKitchenAddRequestStatus === 'resolved' ||
      deliveryServiceRaceKitchenUpdateRequestStatus === 'resolved' ||
      deliveryServiceRaceKitchenTermsCreateRequestStatus === 'resolved' ||
      deliveryServiceRaceKitchenTermsDeleteRequestStatus === 'resolved'
    ) {
      const requestData = {
        token: localStorage.getItem('token'),
        raceId: currentDeliveryServiceRace.id,
        deliveryServiceId: currentDeliveryService.id,
      }

      dispatch(sendRequestDeliveryServiceRaceKitchensGet(requestData));
    }
  }, [
    deliveryServiceRaceKitchenAddRequestStatus,
    deliveryServiceRaceKitchenUpdateRequestStatus,
    deliveryServiceRaceKitchenTermsCreateRequestStatus,
    deliveryServiceRaceKitchenTermsDeleteRequestStatus,
  ]);

  if (!currentDeliveryService || !currentDeliveryServiceRace) {
    return <div>Рейс не найден</div>;
  }

  const breadcrumb = [
    { title: (
        <Link to={'/delivery/services'}>Службы доставки</Link>
      ) 
    },
    { title: (
        <Link to={`/delivery/services/${currentDeliveryService.id}`}>Доставка "{currentDeliveryService.name}"</Link>
      )
    },
    { title: (
      <Text>Рейс "{currentDeliveryServiceRace.cityName}"</Text>
    )
  },
  ]

  return (
    <ScrolledContainer>
      <Breadcrumb items={breadcrumb} style={{marginTop: '15px'}}/>
      <Divider/>
      <Tabs defaultActiveKey="1" tabPosition="top">
        <TabPane tab="Настройки" key="1">
          <EditableForm/>
        </TabPane>
        <TabPane tab="Курьеры" key="2">
          <Title>Курьеры</Title>
          <CouriersTableComponent/>
        </TabPane>
        <TabPane tab="Зоны доставки" key="3">
          <Title>Зоны доставки</Title>
          <PolygonsTableComponent/>
        </TabPane>
        <TabPane tab="Кухни" key="4">
          <Title>Кухни</Title>
          <KitchensTableComponent/>
          <KitchenDrawerComponent/>
        </TabPane>
      </Tabs>
    </ScrolledContainer>
  );
};

export default RaceDetailComponent;