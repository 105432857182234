import React from 'react';

function SupportModule() {
  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
      <div>
        <p style={{margin: '10px 0', fontSize: '18px', color: '#414141', textTransform: 'uppercase'}}>Страница находится в разработке</p>
        <p style={{margin: '10px 0', fontSize: '18px', color: '#414141', textTransform: 'uppercase'}}>Скоро тут появится:</p>
        <h1 style={{marginTop: '30px', fontSize: '60px', fontWeight: 'bold', color: '#666666', textTransform: 'uppercase'}}>Клиентская поддержка</h1>
      </div>
    </div>
  );
}

export default SupportModule;