import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentRoute, setCurrentModule } from '../redux/reducers/utils/navReducer';
import { Typography  } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

const HeaderTitleComponent = ({module}) => {
  const dispatch = useDispatch();

  const handleChangeCurrentRoute = (key) => {
    dispatch(setCurrentRoute(key));
    dispatch(setCurrentModule(key));
  }

  return (
    <StyledText strong>
      <Link style={{color: '#000000', textWrap: 'nowrap'}} to={module.key} onClick={() => handleChangeCurrentRoute(module.key)}>{module.label}</Link>
    </StyledText>
  );
};

export default HeaderTitleComponent;

const StyledText = styled(Text)`
  width: fit-content;
`

